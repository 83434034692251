import { defineMessages } from 'react-intl-next';

export default defineMessages({
	recommendationDefaultTitle: {
		id: 'fabric.editor.ai.proactive.recommendation.default.title.non-final',
		defaultMessage: 'Suggestion',
		description:
			'The default generic title displayed in the side context panel for AI recommendations which have not explicit matching override',
	},
	recommendationDefaultDescription: {
		id: 'fabric.editor.ai.proactive.recommendation.default.description.non-final',
		defaultMessage: 'A suggestion to improve the content.',
		description:
			'The default generic description displayed in the side context panel for AI recommendations which have no explicit matching override',
	},
	recommendationConvertToTableTitle: {
		id: 'fabric.editor.ai.proactive.recommendation.convertToTable.title.non-final',
		defaultMessage: 'Convert to table',
		description:
			'The title displayed in the side context panel for AI recommendations which suggest a conversion to table operation',
	},
	recommendationConvertToTableDescription: {
		id: 'fabric.editor.ai.proactive.recommendation.convertToTable.description.non-final',
		defaultMessage: 'Organize this data in a table to give it structure and make it clearer.',
		description:
			'The description displayed in the side context panel for AI recommendations which suggest a conversion to table operation',
	},
	recommendationConvertToNotePanelTitle: {
		id: 'fabric.editor.ai.proactive.recommendation.convertToNotePanel.title.non-final',
		defaultMessage: 'Convert to note panel',
		description:
			'The title displayed in the side context panel for AI recommendations which suggest a conversion to note panel operation',
	},
	recommendationConvertToNotePanelDescription: {
		id: 'fabric.editor.ai.proactive.recommendation.convertToNotePanel.description.non-final',
		defaultMessage: 'Make this important note stand out by putting it in a purple box.',
		description:
			'The description displayed in the side context panel for AI recommendations which suggest a conversion to note panel operation',
	},
	recommendationConvertToInfoPanelTitle: {
		id: 'fabric.editor.ai.proactive.recommendation.convertToInfoPanel.title.non-final',
		defaultMessage: 'Convert to info panel',
		description:
			'The title displayed in the side context panel for AI recommendations which suggest a conversion to note panel operation',
	},
	recommendationConvertToInfoPanelDescription: {
		id: 'fabric.editor.ai.proactive.recommendation.convertToInfoPanel.description.non-final',
		defaultMessage: 'Make this important info stand out by putting it in a blue box.',
		description:
			'The description displayed in the side context panel for AI recommendations which suggest a conversion to info panel operation',
	},
	recommendationConvertToWarnPanelTitle: {
		id: 'fabric.editor.ai.proactive.recommendation.convertToWarnPanel.title.non-final',
		defaultMessage: 'Convert to warning panel',
		description:
			'The title displayed in the side context panel for AI recommendations which suggest a conversion to warning panel operation',
	},
	recommendationConvertToWarnPanelDescription: {
		id: 'fabric.editor.ai.proactive.recommendation.convertToWarnPanel.description.non-final',
		defaultMessage: 'Make this important warning stand out by putting it in a orange box.',
		description:
			'The description displayed in the side context panel for AI recommendations which suggest a conversion to warning panel operation',
	},
	recommendationConvertToErrorPanelTitle: {
		id: 'fabric.editor.ai.proactive.recommendation.convertToErrorPanel.title.non-final',
		defaultMessage: 'Convert to error panel',
		description:
			'The title displayed in the side context panel for AI recommendations which suggest a conversion to error panel operation',
	},
	recommendationConvertToErrorPanelDescription: {
		id: 'fabric.editor.ai.proactive.recommendation.convertToErrorPanel.description.non-final',
		defaultMessage: 'Make this important error stand out by putting it in a red box.',
		description:
			'The description displayed in the side context panel for AI recommendations which suggest a conversion to error panel operation',
	},
	recommendationConvertToSuccessPanelTitle: {
		id: 'fabric.editor.ai.proactive.recommendation.convertToSuccessPanel.title.non-final',
		defaultMessage: 'Convert to success panel',
		description:
			'The title displayed in the side context panel for AI recommendations which suggest a conversion to success panel operation',
	},
	recommendationConvertToSuccessPanelDescription: {
		id: 'fabric.editor.ai.proactive.recommendation.convertToSuccessPanel.description.non-final',
		defaultMessage: 'Make this important success stand out by putting it in a green box.',
		description:
			'The description displayed in the side context panel for AI recommendations which suggest a conversion to success panel operation',
	},
	recommendationSplitParagraphTitle: {
		id: 'fabric.editor.ai.proactive.recommendation.splitParagraph.title.non-final',
		defaultMessage: 'Divide paragraph',
		description:
			'The title displayed in the side context panel for AI recommendations which suggest a split paragraph operation',
	},
	recommendationSplitParagraphDescription: {
		id: 'fabric.editor.ai.proactive.recommendation.splitParagraph.description.non-final',
		defaultMessage: 'Add paragraph breaks to seperate key ideas.',
		description:
			'The description displayed in the side context panel for AI recommendations which suggest a split paragraph operation',
	},
});
