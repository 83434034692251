import { useEffect } from 'react';

import { type ADFEntity } from '@atlaskit/adf-utils/types';
import { FabricChannel } from '@atlaskit/analytics-listeners';
import { useAnalyticsEvents } from '@atlaskit/analytics-next';
import {
	ACTION,
	ACTION_SUBJECT,
	ACTION_SUBJECT_ID,
	EVENT_TYPE,
} from '@atlaskit/editor-common/analytics';
import type { ExtensionParams } from '@atlaskit/editor-common/extensions';

import { type AIPanelParameters } from '../types';

type AnalyticsEventParams = {
	node: ExtensionParams<AIPanelParameters>;
};

type NodeMarkCount = { nodes: { [type: string]: number }; marks: { [type: string]: number } };

export const useAIPanelAnalytics = ({ node }: AnalyticsEventParams) => {
	const { createAnalyticsEvent } = useAnalyticsEvents();

	useEffect(() => {
		const getNodeMarkCount = (content: ADFEntity[]): NodeMarkCount => {
			return content.reduce<NodeMarkCount>(
				(accum, currentNode) => {
					const { type, marks } = currentNode;
					accum.nodes[type] = (accum.nodes[type] || 0) + 1;
					if (Array.isArray(marks)) {
						marks.forEach(({ type }) => {
							accum.marks[type] = (accum.marks[type] || 0) + 1;
						});
					}
					return accum;
				},
				{ nodes: {}, marks: {} },
			);
		};

		const prepareAndSendAnalytics = () => {
			const nodeMarkCount = Array.isArray(node.content) ? getNodeMarkCount(node.content) : {};

			const event = {
				action: ACTION.RENDERED,
				actionSubject: ACTION_SUBJECT.BODIED_EXTENSION,
				actionSubjectId: ACTION_SUBJECT_ID.AI_PANELS_BODIED_EXTENSION,
				eventType: EVENT_TYPE.UI,
				attributes: {
					platform: 'web',
					extensionKey: node.extensionKey,
					extensionType: node.extensionType,
					localId: node.localId,
					panelStatus: node.parameters?.macroMetadata?.status,
					nodeMarkCount,
				},
			};

			if (createAnalyticsEvent) {
				createAnalyticsEvent(event)?.fire(FabricChannel.editor);
			}
		};

		if ('requestIdleCallback' in window) {
			(window as any).requestIdleCallback(prepareAndSendAnalytics);
		} else {
			(window as any).requestAnimationFrame(prepareAndSendAnalytics);
		}
	}, [
		createAnalyticsEvent,
		node.content,
		node.extensionKey,
		node.extensionType,
		node.localId,
		node.parameters?.macroMetadata?.status,
	]);
};
