import { query } from '@confluence/query-preloader-tools';
import { fg } from '@confluence/feature-gating';

import { FavouriteButtonQuery } from './FavouriteButtonQuery.graphql';
import { OptimizedFavouriteButtonQuery } from './OptimizedFavouriteButtonQuery.graphql';

export function preloadFavouriteButton(contentId, userId) {
	// skip preloading on SSR if user is not logged in
	if (!Boolean(userId)) {
		return Promise.resolve({});
	}

	const queryToUse = fg('confluence_fe_call_new_favourite_button_query')
		? OptimizedFavouriteButtonQuery
		: FavouriteButtonQuery;

	return query({
		query: queryToUse,
		variables: {
			contentId,
		},
	});
}
