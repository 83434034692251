import type { Schema as PMSchema } from '@atlaskit/editor-prosemirror/model';

import { convoAIStatelessApiURL } from '../../prebuilt/utils';
import type { EditorPluginAIPromptRequestMarkdown } from '../../types';
import { startStreamingResponse } from '../start-streaming-response';

import type { PromptRequestOptions } from './types';
import { streamResponseParser } from './utils/stream-response-parser';

type InputIssueReformatterConvoAI = {
	/** The sessionId of the curren user journey. */
	aiSessionId?: string;
	userLocale: string;
	editorSchema: PMSchema;
	fullDocument?: string;
	currentSelection?: string;
	additionalPayload: Record<string, string | number | boolean | null>;
};

export const streamIssueReformatterConvoAI = (
	input: InputIssueReformatterConvoAI,
): EditorPluginAIPromptRequestMarkdown => {
	return function promptRequest(requestOptions: PromptRequestOptions) {
		let issue_description = input.fullDocument;

		const streamingResponseGenerator = startStreamingResponse({
			endpoint: convoAIStatelessApiURL,
			experienceId: 'issue-reformatter',
			payload: JSON.stringify({
				ai_session_id: input.aiSessionId,
				recipient_agent_named_id: 'jira_issue_reformatter_agent',
				agent_input_context: {
					locale: input.userLocale,
					issue_description,
					...input?.additionalPayload,
				},
			}),
			...requestOptions,
		});

		return streamResponseParser({
			streamingResponseGenerator,
			isConvoAI: true,
		});
	};
};
