import React, { useState } from 'react';
import { FormattedMessage, defineMessages, useIntl } from 'react-intl-next';
import { useQuery, useLazyQuery } from 'react-apollo';

import ErrorIcon from '@atlaskit/icon/core/error';
import Link from '@atlaskit/link';
import { Box, Inline, Stack, xcss, Text, Flex } from '@atlaskit/primitives';
import Heading from '@atlaskit/heading';
import Spinner from '@atlaskit/spinner';
import { token } from '@atlaskit/tokens';

import { MetricSettingsContextDefaultValue } from '@confluence/admin-center/entry-points/MetricSettingsContext';
import { COMPANY_HUB_GENERAL_SETTINGS_MOST_CLICKS_EXPERIENCE } from '@confluence/experience-tracker';
import { SubExperienceTracker } from '@confluence/admin-center/entry-points/SubExperienceTracker';
import { Attribution, withErrorBoundary } from '@confluence/error-boundary';

import {
	RecentActivityLeaderboardContentQuery,
	RecentActivityLeaderboardQuery,
} from './RecentActivityLeaderboardQuery.graphql';
import type {
	RecentActivityLeaderboardQuery as RecentActivityLeaderboardQueryType,
	RecentActivityLeaderboardQueryVariables,
} from './__types__/RecentActivityLeaderboardQuery';
import type {
	RecentActivityLeaderboardContentQuery as RecentActivityLeaderboardContentQueryType,
	RecentActivityLeaderboardContentQueryVariables,
} from './__types__/RecentActivityLeaderboardContentQuery';
import type { RecentActivityLeaderboardRowProps } from './RecentActivityLeaderboardRow';
import { RecentActivityLeaderboardRow } from './RecentActivityLeaderboardRow';

const i18n = defineMessages({
	leaderboardTitle: {
		id: 'company-hub.recent-activity.leaderboard.title',
		defaultMessage: 'Most clicked items',
		description: 'Title for the most clicked items leaderboard on the hub dashboard page',
	},
	errorTitle: {
		id: 'company-hub.recent-activity.error.title',
		defaultMessage: 'Loading error',
		description: 'Error message that content could not be displayed',
	},
	errorTitleIconLabel: {
		id: 'company-hub.recent-activity.error.title.icon-label',
		defaultMessage: 'Error icon',
		description: 'Aria-label for error icon message that content could not be displayed',
	},
	errorDescription: {
		id: 'company-hub.recent-activity.error.description',
		defaultMessage: 'Refresh the page. <Link>Contact support</Link> if this persists.',
		description: 'Describes further actions a user can take if error occurs',
	},
});

const errorTitleStyles = xcss({
	color: 'color.text.danger',
});

const ErrorComponent = () => {
	const { formatMessage } = useIntl();
	const SUPPORT_LINK = 'https://support.atlassian.com/contact/';

	return (
		<Box paddingInlineStart="space.100">
			<Flex xcss={errorTitleStyles} gap="space.075" alignItems="center">
				<ErrorIcon label={formatMessage(i18n.errorTitleIconLabel)} />
				<FormattedMessage {...i18n.errorTitle} />
			</Flex>
			<Text color="color.text.subtlest">
				<FormattedMessage
					{...i18n.errorDescription}
					values={{
						Link: (chunks: React.ReactNode[]) => <Link href={SUPPORT_LINK}>{chunks}</Link>,
					}}
				/>
			</Text>
		</Box>
	);
};

const cardMinHeight = '360px';

const HeadingStyles = xcss({
	paddingLeft: 'space.100',
	color: 'color.text.subtle',
});

const leaderboardStyles = xcss({
	border: `1px solid ${token('color.border')}`,
	borderRadius: `${token('space.100')}`,
	backgroundColor: 'elevation.surface.overlay',
	minHeight: cardMinHeight,
});

type RecentActivityLeaderboardProps = {
	isSpaceCreatedDateOver1DayAgo: boolean | undefined;
	sectionQueryLoading: boolean;
};

export const RecentActivityLeaderboardComponent = ({
	isSpaceCreatedDateOver1DayAgo,
	sectionQueryLoading,
}: RecentActivityLeaderboardProps) => {
	const { startDate, endDate } = MetricSettingsContextDefaultValue;

	const [leaderboard, setLeaderboard] = useState<RecentActivityLeaderboardRowProps[]>([]);

	const [fetchTableContent, { loading: contentLoading, error: contentError }] = useLazyQuery<
		RecentActivityLeaderboardContentQueryType,
		RecentActivityLeaderboardContentQueryVariables
	>(RecentActivityLeaderboardContentQuery, {
		onCompleted: (data) => {
			if (data?.content?.nodes) {
				setLeaderboard(
					data.content.nodes.map((node) => ({
						page: {
							id: node?.id || '',
							title: node?.title || '',
							webuiLink: node?.links?.webui || null,
						},
						count:
							countData?.countGroupByPage.nodes.find((countNode) => countNode.page === node?.id)
								?.count || 0,
					})),
				);
			}
		},
	});

	const {
		data: countData,
		loading: countLoading,
		error: countError,
	} = useQuery<RecentActivityLeaderboardQueryType, RecentActivityLeaderboardQueryVariables>(
		// eslint-disable-next-line graphql-relay-compat/no-import-graphql-operations -- Read https://go/connie-relay-migration-fyi
		RecentActivityLeaderboardQuery,
		{
			skip: !isSpaceCreatedDateOver1DayAgo,
			variables: {
				startTime: startDate.toISOString(),
				endTime: endDate.toISOString(),
			},
			context: {
				single: true,
			},
			onCompleted: (data) => {
				if (data.countGroupByPage.nodes.length > 0) {
					fetchTableContent({
						variables: {
							ids: data.countGroupByPage.nodes.map((node) => node.page),
						},
					});
				}
			},
		},
	);

	const leaderboardLoading = countLoading || contentLoading;

	return (
		<SubExperienceTracker
			experienceName={COMPANY_HUB_GENERAL_SETTINGS_MOST_CLICKS_EXPERIENCE}
			errors={[contentError, countError]}
			loading={leaderboardLoading}
		>
			<Box paddingInline="space.200" paddingBlock="space.300" xcss={leaderboardStyles}>
				<Stack space="space.300">
					<Inline alignBlock="center" spread="space-between">
						<Heading size="small">
							<Box xcss={HeadingStyles}>
								<FormattedMessage {...i18n.leaderboardTitle} />
							</Box>
						</Heading>
						{(sectionQueryLoading || leaderboardLoading) && <Spinner size="small" />}
					</Inline>
					{!leaderboardLoading && (!!countError || !!contentError) ? (
						<ErrorComponent />
					) : (
						<Box>
							<Stack space="space.050">
								{leaderboard.map((row) => (
									<RecentActivityLeaderboardRow
										key={row.page?.id}
										page={row.page}
										count={row.count}
									/>
								))}
							</Stack>
						</Box>
					)}
				</Stack>
			</Box>
		</SubExperienceTracker>
	);
};

export const RecentActivityLeaderboard = withErrorBoundary({
	attribution: Attribution.COMPANY_HUB,
})(RecentActivityLeaderboardComponent);
