import { fg } from '@atlaskit/platform-feature-flags';
import { checkAssistanceServiceFreeGenerateFg } from '@atlassian/editor-ai-common/utils/check-assistance-service-fg';

import { CONFIG_ITEM_KEYS } from '../../../config-items/config-item-keys';
import { createEditorPluginAIConfigItemMarkdown } from '../../../config-items/config-items';
import { createInsertBelow, createReplace } from '../../config-item-actions/markdown';
import { isMinLength } from '../../config-item-visible/visibility-utils';

import { messages } from './messages';

const SELECTION_TYPE = 'range';

export const generateFromSelectionConfigItem = createEditorPluginAIConfigItemMarkdown({
	key: CONFIG_ITEM_KEYS.FREE_GENERATE,
	title: messages.title,
	description: messages.description,
	selectionType: SELECTION_TYPE,
	agentId: 'editor_agent',
	canTogglePageKnowledge: true,
	intentSchemaId: ({ latestPromptResponse }) => {
		return latestPromptResponse
			? 'custom_with_interrogation_intent_schema.json'
			: 'custom_free_generate_intent_schema.json';
	},
	promptHint: messages.promptHint,
	// No prompt label is required -- as this is intended to be set via the
	// the baseGenerate
	isVisible: ({ editorView, positions }) => isMinLength({ editorView, minLength: 1, positions }),
	actions: [
		createInsertBelow({ appearance: 'secondary' }),
		createReplace({ appearance: 'primary' }),
	],
	getBackendModel: (isInterrogate) => {
		if (isInterrogate && fg('platform_editor_ai_interrogate_with_convo_ai')) {
			return 'assistance-service';
		}
		if (!isInterrogate && checkAssistanceServiceFreeGenerateFg()) {
			return 'assistance-service';
		}

		return 'xp-gen-ai';
	},
});
