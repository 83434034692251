import React from 'react';

import type { RouteMatch } from '@confluence/route';
import { CONTAINER_HOME_ID } from '@confluence/app-navigation/entry-points/navigationIDs';
import { ForgeSpacePageExtensionPoint } from '@confluence/forge-ui/entry-points/SpacePage';

import SpaceBase from '../../SpaceBase';

export const SpaceForgeAppsRoute = ({
	params: { appId, envId, forgeManifestRoute, spaceKey },
}: RouteMatch) => {
	const isSpaceAliasFFEnabled = true;

	return isSpaceAliasFFEnabled ? (
		<SpaceBase>
			<ForgeSpacePageExtensionPoint
				key={appId + envId + forgeManifestRoute}
				spaceKey={spaceKey}
				appId={appId}
				envId={envId}
				forgeManifestRoute={forgeManifestRoute}
			/>
		</SpaceBase>
	) : (
		<ForgeSpacePageExtensionPoint
			key={appId + envId + forgeManifestRoute}
			spaceKey={spaceKey}
			appId={appId}
			envId={envId}
			forgeManifestRoute={forgeManifestRoute}
		/>
	);
};

SpaceForgeAppsRoute.NAVIGATION_PARAMS = ({
	name,
	params: { appId, envId, forgeManifestRoute, spaceKey },
}: RouteMatch) => ({
	Screen: {
		screenEvent: {
			name: 'spaceForgeApps',
			id: spaceKey,
			attributes: { appId, envId, forgeManifestRoute },
		},
		pageState: {
			routeName: name,
			spaceKey,
		},
	},

	MainLayout: {
		navView: CONTAINER_HOME_ID,
		spaceKey,
	},
});
