import { defineMessages } from 'react-intl-next';

export const messages = defineMessages({
	dismissSuggestion: {
		id: 'fabric.editor.ai.spellingAndGrammar.suggestions.dismiss',
		defaultMessage: 'Dismiss suggestion',
		description:
			'The tooltip message display for the dismiss icon button in the suggestion toolbar',
	},
	suggestionInformationTooltip: {
		id: 'fabric.editor.ai.spellingAndGrammar.suggestions.information.no-translate',
		defaultMessage:
			'We’re experimenting with this feature and would welcome any feedback in <feedback>#feedback-spelling-and-grammar</feedback>.',
		description:
			'The tooltip message displayed on the information button which appears in the suggestions floating toolbar',
	},
	improveSentenceToolbarButtonLabel: {
		id: 'fabric.editor.ai.spellingAndGrammar.suggestions.improve-sentence.toolbar-button-label',
		defaultMessage: 'Improve sentence',
		description: 'The floating toolbar button to improve sentence using AI',
	},
	correctSuggestion: {
		id: 'fabric.editor.ai.spellingAndGrammar.suggestions.correct-suggestion',
		defaultMessage: 'Correct suggestion',
		description:
			'The message displayed on the correct suggestion icon dropdown item in the suggestion toolbar',
	},
	incorrectSuggestion: {
		id: 'fabric.editor.ai.spellingAndGrammar.suggestions.incorrect-suggestion',
		defaultMessage: 'Incorrect suggestion',
		description:
			'The message displayed on the incorrect suggestion icon dropdown item in the suggestion toolbar',
	},
});
