import React, { useContext } from 'react';
import { ReactRenderer, type RendererProps } from '@atlaskit/renderer';
import type { DocNode } from '@atlaskit/adf-schema';
import { traverse } from '@atlaskit/adf-utils/traverse';
import type { VisitorCollection } from '@atlaskit/adf-utils/types';
import { RendererActionsContext } from '@atlaskit/renderer/actions';
import { EgressFilteringService } from '@forge/egress';
import type { RenderFn } from '@atlassian/forge-ui-types';
import { useMediaClient } from '@atlaskit/media-client-react';
import { EgressErrorMessage } from '../..';
import { RendererContext } from '../../../context';
import { useEgressPermissionService } from '../../../utils/useEgressPermissionService';
import { isValidImageUrl } from '../../utils/useIsValidImageUrl';

// Keep in sync with platform/packages/forge/forge-react-types/src/components/adfrenderer/index.ts
export type AdfRendererProps = RendererProps & {
	documentWithoutMedia?: RendererProps['document'];
};

const filterInvalidMediaUrls = (
	appDomainName: string | undefined,
	egressServices: EgressFilteringService[],
	document: DocNode,
): {
	filteredDocument: DocNode;
	invalidUrls: string[];
} => {
	const invalidUrls = new Set<string>();

	const visitors: VisitorCollection = {
		media: (node) => {
			const url = node?.attrs?.url;
			if (url && !egressServices.some((service) => isValidImageUrl(url, service, appDomainName))) {
				invalidUrls.add(url);
				return {
					...node,
					attrs: {
						...node.attrs,
						// Remove the URL so it fails to load
						// We use a space because an empty string causes an infinite loading spinner
						url: ' ',
					},
				};
			}
		},
	};

	const filteredDocument = traverse(document, visitors);

	return {
		filteredDocument: filteredDocument ? (filteredDocument as DocNode) : document,
		invalidUrls: Array.from(invalidUrls),
	};
};

const useSupportsMedia = () => {
	try {
		useMediaClient();
		return true;
	} catch (e) {
		// Throws if no MediaClientProvider is found
		return false;
	}
};

export const AdfRenderer = (props: Parameters<RenderFn>[0]) => {
	const supportsMedia = useSupportsMedia();
	const { egress, appDomainName, forgeEnvironment } = useContext(RendererContext);
	const imagesEgressService = useEgressPermissionService('IMAGES', egress);
	const mediaEgressService = useEgressPermissionService('MEDIA', egress);
	const egressServices = [imagesEgressService, mediaEgressService];

	const { document, documentWithoutMedia, ...adfRendererProps } = props.forgeDoc
		.props as AdfRendererProps;

	// The client provides both the document with media and without media, as it doesn't know whether the MediaClientProvider is present
	// Then, in the parent product, we can decide whether to render the document with media or without media
	const doc = !supportsMedia && documentWithoutMedia ? documentWithoutMedia : document;

	const { filteredDocument, invalidUrls } = filterInvalidMediaUrls(
		appDomainName,
		egressServices,
		doc,
	);

	return (
		<>
			{forgeEnvironment === 'DEVELOPMENT' &&
				invalidUrls.map((url) => <EgressErrorMessage key={url} url={url} />)}
			<RendererActionsContext>
				<ReactRenderer {...adfRendererProps} document={filteredDocument} />
			</RendererActionsContext>
		</>
	);
};
