import { di } from 'react-magnetic-di';

import {
	ExperienceName,
	useAIMateExperienceTracker,
} from '@atlassian/conversation-assistant-instrumentation';
import {
	type AssistanceServiceProduct,
	useAssistanceService,
	useMutation,
} from '@atlassian/conversation-assistant-service';

type UseDeleteAgentProps = {
	/** If a parent AssistanceServiceProvider is not rendered, this field MUST be provided in order for agents to work */
	product?: AssistanceServiceProduct;
	/** Defaults to `ai-mate` */
	experienceId?: string;
	onSuccess?: () => void;
	onError?: () => void;
};

export const useDeleteAgent = ({
	experienceId,
	product,
	onSuccess,
	onError,
}: UseDeleteAgentProps = {}) => {
	di(useAssistanceService);
	const experience = useAIMateExperienceTracker();

	const assistanceService = useAssistanceService({
		experienceId,
		product,
	});

	const deleteAgent = useMutation({
		mutationFn: async (id: string) => {
			experience.start({
				id: ExperienceName.DELETE_AGENT,
				name: ExperienceName.DELETE_AGENT,
			});

			return await assistanceService.deleteAgent(id);
		},
		onSuccess: async (data) => {
			experience.succeed({
				name: ExperienceName.DELETE_AGENT,
			});

			onSuccess?.();

			return data;
		},
		onError: (error) => {
			experience.fail({
				name: ExperienceName.DELETE_AGENT,
				error,
			});

			onError?.();
		},
	});

	return { deleteAgent };
};
