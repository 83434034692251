import React from 'react';

import { CONFIG_ITEM_KEYS } from '../../config-items/config-item-keys';
import { createEditorPluginAIConfigItemMarkdown } from '../../config-items/config-items';
import { Icon } from '../assets/icons/prefill';
import { createInsertAtCurrentPosition } from '../config-item-actions/markdown';

import { messages } from './messages';
import { PrefillPrompts } from './types';

const SELECTION_TYPE = 'empty';

export const configItemKeyRecord: Record<PrefillPrompts, CONFIG_ITEM_KEYS> = {
	[PrefillPrompts.BudgetPlanPrompt]: CONFIG_ITEM_KEYS.PREFILL_BUDGET_PLAN,
	[PrefillPrompts.CompetitiveAnalysisPrompt]: CONFIG_ITEM_KEYS.PREFILL_COMPETITIVE_ANALYSIS,
	[PrefillPrompts.DocumentationPrompt]: CONFIG_ITEM_KEYS.PREFILL_DOCUMENTATION,
	[PrefillPrompts.MarketingCampaignPrompt]: CONFIG_ITEM_KEYS.PREFILL_MARKETING_CAMPAIGN,
	[PrefillPrompts.OnboardingGuidePrompt]: CONFIG_ITEM_KEYS.PREFILL_ONBOARDING_GUIDE,
	[PrefillPrompts.ProsAndConsPrompt]: CONFIG_ITEM_KEYS.PREFILL_PROS_AND_CONS,
	[PrefillPrompts.SprintPlanningPrompt]: CONFIG_ITEM_KEYS.PREFILL_SPRINT_PLANNING,
};

export const createPrefillConfigItem = (prompt: PrefillPrompts) => {
	const key = configItemKeyRecord[prompt];
	const message = messages[prompt];
	return createEditorPluginAIConfigItemMarkdown({
		key,
		title: message,
		description: message,
		selectionType: SELECTION_TYPE,
		icon: ({ shownAt }) => <Icon shownAt={shownAt} />,
		intentSchemaId: 'custom_free_generate_intent_schema.json',
		isVisible: ({ editorView }) => !(editorView.state.doc.nodeSize - 4),
		getBackendModel: () => 'assistance-service',
		disableInterrogation: () => false,
		actions: [createInsertAtCurrentPosition({ appearance: 'primary' })],
		isPrefill: true,
	});
};
export const prefillConfigItems = [
	createPrefillConfigItem(PrefillPrompts.MarketingCampaignPrompt),
	createPrefillConfigItem(PrefillPrompts.ProsAndConsPrompt),
	createPrefillConfigItem(PrefillPrompts.CompetitiveAnalysisPrompt),
	createPrefillConfigItem(PrefillPrompts.DocumentationPrompt),
	createPrefillConfigItem(PrefillPrompts.OnboardingGuidePrompt),
	createPrefillConfigItem(PrefillPrompts.SprintPlanningPrompt),
	createPrefillConfigItem(PrefillPrompts.BudgetPlanPrompt),
];
