import uuid from 'uuid/v4';

import { ACTIONS } from './states';
import type { AIProactivePluginState, ProactiveAction } from './states';
import { markRecommendationViewed } from './utils';

export const reducer = (
	pluginState: AIProactivePluginState,
	action: ProactiveAction,
): AIProactivePluginState => {
	switch (action.type) {
		case ACTIONS.UPDATE_PLUGIN_STATE:
			const availableRecommendationIds = new Set<string>(
				(action.data?.proactiveAIBlocks ?? pluginState.proactiveAIBlocks).reduce<string[]>(
					(acc, block) => acc.concat(block.recommendations?.map((r) => r.id) ?? []),
					[],
				),
			);
			return {
				...pluginState,
				...action.data,
				canProactiveContextPanelOpen: availableRecommendationIds.size > 0,
				availableRecommendationIds,
			};
		case ACTIONS.TOGGLE_PROACTIVE_ENABLED:
			return {
				...pluginState,
				toggleCount: pluginState.toggleCount + 1,
				isProactiveEnabled: !pluginState.isProactiveEnabled,
				...(pluginState.isProactiveEnabled && {
					docChangeCountUntilLimit: 0,
				}),
			};
		case ACTIONS.TOGGLE_PROACTIVE_CONTEXT_PANEL:
			return {
				...pluginState,
				isProactiveContextPanelOpen: !pluginState.isProactiveContextPanelOpen,
				analyticsAIInteractionId: uuid(),
				toggleCount: pluginState.toggleCount + 1,
				selectedRecommendationId: pluginState.isProactiveContextPanelOpen
					? undefined
					: pluginState.selectedRecommendationId,
			};
		case ACTIONS.SELECT_PROACTIVE_RECOMMENDATION:
			return {
				...pluginState,
				...action.data,
				proactiveAIBlocks: markRecommendationViewed(
					pluginState,
					action.data.selectedRecommendationId,
				),
				hoveredRecommendationId: !pluginState.allowInlineHoverHighlightWhileRecommendationSelected
					? undefined
					: pluginState.hoveredRecommendationId,
			};
		case ACTIONS.TOGGLE_DISPLAY_ALL_SETTINGS:
			return {
				...pluginState,
				displayAllSuggestions: !pluginState.displayAllSuggestions,
				// Doesn't apply in all suggestion - useful later when we start filtering type of suggestions
				// proactiveAIBlocks: pluginState.displayAllSuggestions
				// 	? pluginState.proactiveAIBlocks.filter(
				// 			(item) => Array.isArray(item.recommendations) && item.recommendations.length > 0,
				// 		)
				// 	: pluginState.proactiveAIBlocks,
			};
		default:
			return pluginState;
	}
};
