/* eslint-disable @atlaskit/design-system/prefer-primitives */
/* eslint-disable @repo/internal/dom-events/no-unsafe-event-listeners */
/**
 * @jsxRuntime classic
 * @jsx jsx
 */
/** @jsxfrag */
import React, { useMemo } from 'react';

// eslint-disable-next-line @atlaskit/ui-styling-standard/use-compiled -- Ignored via go/DSP-18766
import { css, jsx } from '@emotion/react';
import debounce from 'lodash/debounce';
import kebabCase from 'lodash/kebabCase';
import { useIntl } from 'react-intl-next';

import type { EditorAppearance } from '@atlaskit/editor-common/types';
import { Box, xcss } from '@atlaskit/primitives';
import { N40A } from '@atlaskit/theme/colors';
import { token } from '@atlaskit/tokens';

import { ListHeading } from './ListHeading';
import { ListItem } from './ListItem';
import { messages } from './messages';
import {
	suggestionsContainerStyles,
	suggestionsGroupStyles,
	suggestionsHeadingStyles,
} from './styles';
import type { Suggestion, SuggestionGroup } from './types';

const suggestionsGroupWrapStyles = css({
	margin: 0,
});

const suggestionsScrollerPostGAStyles = xcss({
	overflowY: 'auto',
	// When user is typing, the suggestions container shouldn't shrink when there is lots of input
	flex: '0 1 auto',
	borderTop: `1px solid ${token('color.border', N40A)}`,
});

export type Props = {
	appearance: EditorAppearance;
	suggestionsHeading?: string;
	suggestions: Suggestion[];
	value?: string;
	setSuggestion?: (suggestion: Suggestion) => void;
	hideGroupHeading?: boolean;
};

export function reduceSuggestionsIntoGroups(output: SuggestionGroup[], suggestion: Suggestion) {
	let suggestionGroup: string;

	if (typeof suggestion.groupHeading === 'function') {
		suggestionGroup = suggestion.groupHeading();
	} else {
		suggestionGroup = suggestion.groupHeading || '';
	}

	let existingRecordIndex = output.findIndex((s) => s.groupHeading === suggestionGroup);

	// If the group doesn't exist, create it
	if (existingRecordIndex === -1) {
		const suggestionsLength = output.push({
			groupHeading: suggestionGroup,
			suggestions: [],
		});

		existingRecordIndex = suggestionsLength - 1;
	}

	output[existingRecordIndex].suggestions.push(suggestion);

	return output;
}

export const Suggestions = ({
	suggestionsHeading,
	suggestions,
	value,
	setSuggestion,
	hideGroupHeading,
}: Props) => {
	const { formatMessage } = useIntl();

	/**
	 * While scrolling with the mouse, the setSuggestion() function can still be
	 * called by the mousemove event in ListItem.
	 *
	 * So while the scrolling is happening, another scrollTo() is started and
	 * conflicts with the current scrolling momentum, causing the experience to
	 * jitter and feel clunky.
	 *
	 * While scrolling with just the mouse, this is the order of events:
	 * - (multiple) scroll
	 * - mousemove
	 * - (multiple) scroll
	 * - mousemove
	 * - scrollend
	 *
	 * However, if the user leaves the cursor over the list while navigating with
	 * the keyboard and it scrolls to ensure items are visible, the order of
	 * events is:
	 * - (multiple) on scroll
	 * - scrollend
	 * - mousemove
	 *
	 * So to avoid the mousemove from triggering a change, we set a flag to
	 * ignore mousemove events until a bit after its over.
	 */
	const ignoreMouseMoveRef = React.useRef<boolean>(false);
	const scrollerRef = React.useRef<HTMLDivElement>(null);

	const smoothSetSuggestion = React.useCallback(
		(suggestion: Suggestion, eventName: 'focus' | 'mousemove') => {
			if (!setSuggestion) {
				return;
			}

			// Ignore mousemove
			if (eventName === 'mousemove' && ignoreMouseMoveRef.current) {
				return;
			}

			setSuggestion(suggestion);
		},
		[setSuggestion],
	);

	React.useEffect(() => {
		if (!scrollerRef.current) {
			return;
		}

		// Debounced handler to restore tracking after scrolling
		const allowMouseTracking = debounce(() => {
			ignoreMouseMoveRef.current = false;
		}, 75);
		const onScrollEvent = () => {
			ignoreMouseMoveRef.current = true;
			allowMouseTracking();
		};

		const scroller = scrollerRef.current;
		scroller.addEventListener('scroll', onScrollEvent);
		scroller.addEventListener('scrollend', onScrollEvent);

		return () => {
			scroller.removeEventListener('scroll', onScrollEvent);
			scroller.removeEventListener('scrollend', onScrollEvent);
		};
	}, [scrollerRef]);

	const renderedGroupSuggestions = useMemo(() => {
		const customLabelMap: { [key: string]: string | undefined } = {
			'tell-ai': formatMessage(messages.tellAILabel),
			'tell-agent': formatMessage(messages.tellAgentLabel),
		};

		const mapSuggestionsCallback = (suggestionGroup: SuggestionGroup) => {
			return (
				<li
					data-testid={`suggestion-group-${kebabCase(suggestionGroup.groupHeading || 'Atlassian intelligence')}`}
					key={`group_${suggestionGroup.groupHeading}`}
					css={suggestionsGroupWrapStyles}
				>
					{/* eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/design-system/consistent-css-prop-usage -- Ignored via go/DSP-18766 */}
					<ul css={suggestionsGroupStyles}>
						{suggestionGroup.groupHeading && !hideGroupHeading && (
							<ListHeading heading={suggestionGroup.groupHeading} />
						)}
						{suggestionGroup.suggestions.map((suggestion) => {
							return (
								<ListItem
									key={suggestion.value}
									suggestion={suggestion}
									customLabel={customLabelMap[suggestion.value] || undefined}
									highlighted={suggestion.value === value}
									setSuggestion={smoothSetSuggestion}
									hasGroupHeading={!!suggestionGroup.groupHeading && !hideGroupHeading}
								/>
							);
						})}
					</ul>
				</li>
			);
		};

		return (
			(suggestions || [])
				// Group suggestions by heading to make mapping to components easier later on
				.reduce(reduceSuggestionsIntoGroups, [])
				.map(mapSuggestionsCallback)
		);
	}, [formatMessage, smoothSetSuggestion, suggestions, value, hideGroupHeading]);

	if (!renderedGroupSuggestions.length) {
		return null;
	}

	return (
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/design-system/consistent-css-prop-usage -- Ignored via go/DSP-18766
		<Box testId="suggestions-container" xcss={[suggestionsScrollerPostGAStyles]} ref={scrollerRef}>
			{/* eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/design-system/consistent-css-prop-usage -- Ignored via go/DSP-18766 */}
			{suggestionsHeading && <h2 css={suggestionsHeadingStyles}>{suggestionsHeading}</h2>}
			<ul
				data-testid="editor-plugin-ai-suggestion-list"
				id="suggestion-list"
				// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/design-system/consistent-css-prop-usage -- Ignored via go/DSP-18766
				css={suggestionsContainerStyles}
			>
				{renderedGroupSuggestions}
			</ul>
		</Box>
	);
};
