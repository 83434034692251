import gql from 'graphql-tag';

export const SpaceDetailQuery = gql`
	query SpaceDetailQuery(
		$spaceKey: String
		$includeKey: Boolean = false
		$includeAlias: Boolean = false
	) {
		space(key: $spaceKey) {
			id
			name
			alias @include(if: $includeAlias)
			key @include(if: $includeKey)
			type
			dataClassificationTags
		}
	}
`;
