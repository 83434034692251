import React from 'react';

import AKIcon, { type IconProps as AKIconProps, type CustomGlyphProps } from '@atlaskit/icon';
import MagicWandIcon from '@atlaskit/icon/core/magic-wand';
import { token } from '@atlaskit/tokens';

import { RainbowBorder } from './RainbowBorder';
import type { IconProps } from './types';

const IconGlyph = (props: CustomGlyphProps) => (
	<svg
		width="24"
		height="24"
		viewBox="0 0 24 24"
		xmlns="http://www.w3.org/2000/svg"
		role="presentation"
		{...props}
	>
		<path d="M8.45366 7.76225L6.37618 5.68477C6.16881 5.4774 5.8326 5.4774 5.62524 5.68477L5.62453 5.68548C5.41716 5.89284 5.41716 6.22906 5.62453 6.43642L7.70201 8.5139C7.90938 8.72127 8.24559 8.72127 8.45296 8.5139L8.45366 8.5132C8.66103 8.30583 8.66103 7.96962 8.45366 7.76225Z" />
		<path d="M17.6911 5.69892L15.5853 7.80468C15.3858 8.00424 15.3858 8.32779 15.5853 8.52735C15.7849 8.7269 16.1084 8.7269 16.308 8.52735L18.4138 6.42158C18.6133 6.22203 18.6133 5.89848 18.4138 5.69892C18.2142 5.49936 17.8907 5.49936 17.6911 5.69892Z" />
		<path d="M7.64164 15.6144L5.58538 17.6706C5.37215 17.8838 5.37215 18.2296 5.58538 18.4428L5.58608 18.4435C5.79931 18.6567 6.14502 18.6567 6.35824 18.4435L8.41451 16.3872C8.62774 16.174 8.62774 15.8283 8.41451 15.6151L8.4138 15.6144C8.20058 15.4011 7.85487 15.4011 7.64164 15.6144Z" />
		<path d="M6.5 11.5H3.5C3.22386 11.5 3 11.7239 3 12C3 12.2761 3.22386 12.5 3.5 12.5H6.5C6.77614 12.5 7 12.2761 7 12C7 11.7239 6.77614 11.5 6.5 11.5Z" />
		<path d="M20.5 11.5H17.5C17.2239 11.5 17 11.7239 17 12C17 12.2761 17.2239 12.5 17.5 12.5H20.5C20.7761 12.5 21 12.2761 21 12C21 11.7239 20.7761 11.5 20.5 11.5Z" />
		<path d="M12.5 6.5V3.5C12.5 3.22386 12.2761 3 12 3C11.7239 3 11.5 3.22386 11.5 3.5V6.5C11.5 6.77614 11.7239 7 12 7C12.2761 7 12.5 6.77614 12.5 6.5Z" />
		<path d="M12.5 20.5V17.5C12.5 17.2239 12.2761 17 12 17C11.7239 17 11.5 17.2239 11.5 17.5V20.5C11.5 20.7761 11.7239 21 12 21C12.2761 21 12.5 20.7761 12.5 20.5Z" />
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M13.62 12.913L11.854 11.146C11.7602 11.0521 11.633 10.9993 11.5004 10.9992C11.3677 10.9991 11.2404 11.0517 11.1465 11.1455C11.0526 11.2392 10.9998 11.3665 10.9997 11.4991C10.9996 11.6318 11.0522 11.7591 11.146 11.853L12.913 13.62L13.62 12.913ZM12.56 10.439C12.277 10.1659 11.898 10.0149 11.5047 10.0185C11.1114 10.0221 10.7353 10.18 10.4573 10.4582C10.1793 10.7365 10.0217 11.1128 10.0185 11.5061C10.0153 11.8994 10.1666 12.2782 10.44 12.561L17.51 19.631C17.6483 19.7743 17.8138 19.8887 17.9967 19.9674C18.1797 20.0461 18.3765 20.0875 18.5757 20.0894C18.7748 20.0912 18.9724 20.0533 19.1568 19.978C19.3411 19.9027 19.5087 19.7914 19.6496 19.6506C19.7905 19.5098 19.9019 19.3424 19.9774 19.1581C20.053 18.9738 20.091 18.7763 20.0894 18.5771C20.0877 18.3779 20.0464 18.1811 19.9679 17.998C19.8894 17.815 19.7752 17.6494 19.632 17.511L12.56 10.439Z"
		/>
	</svg>
);

export const Icon = (props: IconProps) => {
	const { shownAt } = props;

	return (
		<RainbowBorder shownAt={shownAt}>
			<MagicWandIcon
				label=""
				color="currentColor"
				spacing="spacious"
				LEGACY_fallbackIcon={(props: AKIconProps) => <AKIcon glyph={IconGlyph} {...props} />}
				LEGACY_secondaryColor={token('color.icon')}
			/>
		</RainbowBorder>
	);
};
