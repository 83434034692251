/**
 * @jsxRuntime classic
 * @jsx jsx
 */
/** @jsxFrag */

import { type ReactNode, useCallback, useState } from 'react';

import { jsx } from '@emotion/react'; // eslint-disable-line @atlaskit/ui-styling-standard/use-compiled -- Ignored via go/DSP-18766
import { type MessageDescriptor, useIntl } from 'react-intl-next';

import Button from '@atlaskit/button/new';
import DropdownMenu from '@atlaskit/dropdown-menu';
import ExpandIcon from '@atlaskit/icon/utility/migration/chevron-down';

import messages from './messages';
import { RefineDropdownItems } from './RefineDropdownItems';

export interface RefineDropdownBaseItem {
	icon?: ReactNode;
	title: MessageDescriptor;
	onClick?: () => void;
	children?: RefineDropdownBaseItem[];
}

export interface RefineDropdownClickableItem extends RefineDropdownBaseItem {
	icon: ReactNode;
	title: MessageDescriptor;
	children?: undefined;
	onClick: () => void;
}

export interface RefineDropdownSubMenuItem extends RefineDropdownBaseItem {
	icon: ReactNode;
	title: MessageDescriptor;
	children: Omit<RefineDropdownClickableItem, 'icon'>[];
	onClick?: undefined;
}

export type RefineDropdownItem = RefineDropdownClickableItem | RefineDropdownSubMenuItem;

interface Props {
	items: RefineDropdownItem[];
	isDisabled: boolean;
}

export function RefineDropdown({ items, isDisabled = false }: Props) {
	const { formatMessage } = useIntl();

	const [isOpened, setIsOpened] = useState(false);
	const [currentOpenedSubMenu, setCurrentOpenedSubMenu] =
		useState<RefineDropdownSubMenuItem | null>(null);

	const handleOpenChange = useCallback(({ isOpen }: { isOpen: boolean }) => {
		setIsOpened(isOpen);
		if (!isOpen) {
			setCurrentOpenedSubMenu(null);
		}
	}, []);

	const handleItemClick = useCallback(() => {
		setIsOpened(false);
		setCurrentOpenedSubMenu(null);
	}, []);

	const handleSubMenuOpenChanged = useCallback(
		(subMenu: RefineDropdownSubMenuItem, isOpen: boolean) => {
			setCurrentOpenedSubMenu((current) => {
				// Close the current opened sub menu if it's not the same as the one that was clicked
				if (current === subMenu && !isOpen) {
					return null;
				}

				return subMenu;
			});
		},
		[],
	);

	/**
	 * We need to disable the refiinement button if the user has traversed back in the response
	 * history to a point where the response is not the latest one. This is because the refine
	 * button is only applicable to the latest response.
	 * The DropdownMenu component does not support the `isDisabled` prop so we need to handle the
	 * disabling of the button ourselves.
	 */
	if (isDisabled) {
		return (
			<Button iconAfter={ExpandIcon} isDisabled testId="refine-button-disabled">
				{formatMessage(messages.refineButton)}
			</Button>
		);
	}

	return (
		<DropdownMenu
			trigger={formatMessage(messages.refineButton)}
			testId="refine-dropdown"
			shouldRenderToParent
			isOpen={isOpened}
			onOpenChange={handleOpenChange}
		>
			<RefineDropdownItems
				items={items}
				onItemClick={handleItemClick}
				currentOpenedSubMenu={currentOpenedSubMenu}
				onSubMenuOpenChanged={handleSubMenuOpenChanged}
			/>
		</DropdownMenu>
	);
}
