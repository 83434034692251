import { styled } from '@compiled/react';
import type { FC, ReactNode } from 'react';
import React from 'react';

import { token } from '@atlaskit/tokens';
import { N0, N500 } from '@atlaskit/theme/colors';
import Button from '@atlaskit/button/standard-button';
import type { ButtonProps as StandardButtonProps } from '@atlaskit/button/standard-button';
import { xcss, Box } from '@atlaskit/primitives';

import { shouldShowMobileWeb } from '@confluence/mobile-detection';

import { ErrorPageImage } from './ErrorPageImage';

type StyleProps = { isMobile: boolean };
// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const ErrorContainer = styled.div<StyleProps>({
	display: 'flex',
	flexDirection: 'column',
	justifyContent: 'center',
	alignItems: 'center',
	backgroundColor: token('elevation.surface', N0),
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	height: `${(props) => (props.isMobile ? '100' : '90')}vh`,
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const ErrorPageWrapper = styled.div<StyleProps>({
	display: 'flex',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	flexDirection: (props) => (props.isMobile ? 'column' : 'row'),
	justifyContent: 'center',
	maxWidth: '700px',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const AlignContent = styled.div<StyleProps>({
	alignSelf: 'center',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	textAlign: (props) => (props.isMobile ? 'center' : 'left'),
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values, @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	...(props) => !props.isMobile && { margin: token('space.500', '40px') },
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const ErrorTitle = styled.h1<StyleProps>({
	margin: `${token('space.300', '24px')} 0 ${token('space.300', '24px')}`,
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	fontSize: `${(props) => (props.isMobile ? '28' : '35')}px`,
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	fontWeight: (props) => (props.isMobile ? '300' : '400'),
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	width: `${(props: StyleProps) => (props.isMobile ? '327' : '460')}px`,
	color: token('color.text', N500),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const ErrorMessage = styled.div<StyleProps>({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	fontSize: `${(props: StyleProps) => (props.isMobile ? '17' : '14')}px`,
	fontWeight: 300,
	color: token('color.text', N500),
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	maxWidth: `${(props: StyleProps) => (props.isMobile ? '327' : '415')}px`,
	// eslint-disable-next-line @atlaskit/design-system/ensure-design-token-usage/preview, @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	margin: (props) => (props.isMobile ? '0px auto' : 'unset'),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const ButtonContainer = styled.div<StyleProps>({
	display: 'flex',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	justifyContent: (props) => (props.isMobile ? 'center' : 'left'),
});

const primaryButtonStyles = xcss({
	cursor: 'pointer',
	marginTop: 'space.250',
});

const mobilePrimaryButtonStyles = xcss({
	cursor: 'pointer',
	marginTop: 'space.200',
});

const secondaryButtonStyles = xcss({
	cursor: 'pointer',
	marginTop: 'space.250',
	marginLeft: 'space.200',
});

const mobileSecondaryButtonStyles = xcss({
	cursor: 'pointer',
	marginTop: 'space.200',
	marginLeft: 'space.100',
});

const childrenStyles = xcss({
	// @ts-ignore TODO: refactor to remove need for hardcoded `-90px`
	marginTop: '-90px',
	marginInline: 'space.250',
	marginBottom: 'space.250',
	maxWidth: '700px',
});

const mobileChildrenStyles = xcss({
	// @ts-ignore TODO: refactor to remove need for hardcoded `-74px`
	marginTop: '-74px',
	marginInline: 'space.250',
	marginBottom: 'space.250',
	maxWidth: '700px',
});

export type ButtonProps = StandardButtonProps & Required<Pick<StandardButtonProps, 'children'>>;

type ErrorPageComponentProps = {
	errorImage?: string;
	errorImageDark?: string;
	errorTitle: ReactNode | string;
	errorMessage: ReactNode;
	primaryButtonProps?: ButtonProps;
	secondaryButtonProps?: ButtonProps;
	children?: React.ReactNode;
};

export const ErrorPageComponent: FC<ErrorPageComponentProps> = ({
	errorImage,
	errorImageDark,
	errorTitle,
	errorMessage,
	primaryButtonProps,
	secondaryButtonProps,
	children,
}) => {
	const isMobile = shouldShowMobileWeb();

	return (
		<ErrorContainer isMobile={isMobile}>
			<ErrorPageWrapper isMobile={isMobile}>
				<AlignContent isMobile={isMobile}>
					{errorImage && (
						<ErrorPageImage src={errorImage} srcDark={errorImageDark} isMobile={isMobile} />
					)}
				</AlignContent>
				<AlignContent isMobile={isMobile}>
					<ErrorTitle isMobile={isMobile}>{errorTitle}</ErrorTitle>
					<ErrorMessage isMobile={isMobile}>{errorMessage}</ErrorMessage>
					{(primaryButtonProps?.children || secondaryButtonProps?.children) && (
						<ButtonContainer isMobile={isMobile}>
							{primaryButtonProps?.children && (
								<Box xcss={isMobile ? mobilePrimaryButtonStyles : primaryButtonStyles}>
									<Button appearance="primary" {...primaryButtonProps} />
								</Box>
							)}
							{secondaryButtonProps?.children && (
								<Box xcss={isMobile ? mobileSecondaryButtonStyles : secondaryButtonStyles}>
									<Button appearance="default" {...secondaryButtonProps} />
								</Box>
							)}
						</ButtonContainer>
					)}
				</AlignContent>
			</ErrorPageWrapper>
			{React.Children.count(children) ? (
				<Box xcss={isMobile ? mobileChildrenStyles : childrenStyles}>{children}</Box>
			) : null}
		</ErrorContainer>
	);
};
