// eslint-disable-next-line @atlaskit/ui-styling-standard/use-compiled -- Ignored via go/DSP-18766
import { css } from '@emotion/react';

import { LAYOUT_COLUMN_PADDING, LAYOUT_SECTION_MARGIN } from '@atlaskit/editor-common/styles';
import { akEditorSelectedBorderSize } from '@atlaskit/editor-shared-styles';
import { N40A } from '@atlaskit/theme/colors';
import { token } from '@atlaskit/tokens';

//TODO: AI Button experiment cleanup - platform_editor_ai_ai_button_block_elements
// eslint-disable-next-line @atlaskit/design-system/no-css-tagged-template-expression, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const layoutBorderStyles = css`
	[data-layout-section] [data-layout-column] {
		border: ${akEditorSelectedBorderSize}px solid ${token('color.border', N40A)};
		border-radius: 4px;
		padding: ${LAYOUT_COLUMN_PADDING}px ${LAYOUT_COLUMN_PADDING}px ${LAYOUT_COLUMN_PADDING}px
			${LAYOUT_COLUMN_PADDING}px;
	}
	[data-layout-section] > [data-layout-column] + [data-layout-column] {
		margin-left: ${LAYOUT_SECTION_MARGIN}px;
	}
`;
