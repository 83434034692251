import { type Node as PMNode } from '@atlaskit/editor-prosemirror/model';
import { type MentionNameDetails } from '@atlaskit/mention';

type GetMentionMapProps = {
	node: PMNode;
	getMentionNameDetails: (id: string) => Promise<MentionNameDetails | undefined>;
};

export type MentionMap = { [id: string]: string | undefined };

export const getMentionMap = async ({ node, getMentionNameDetails }: GetMentionMapProps) => {
	const mentionMapping: MentionMap = {};

	const findMentions = async (node: PMNode) => {
		if (node.type.name === 'mention') {
			const mentionNameDetails = await getMentionNameDetails(node.attrs.id);
			if (mentionNameDetails) {
				mentionMapping[node.attrs.id] = mentionNameDetails?.name;
			}
		}

		if (node.content) {
			const promises: Promise<void>[] = [];

			node.content.forEach((child) => {
				promises.push(findMentions(child));
			});

			await Promise.all(promises);
		}
	};

	await findMentions(node);
	return mentionMapping;
};
