/**
 * @jsxRuntime classic
 * @jsx jsx
 */
import { useCallback, useMemo } from 'react';

// eslint-disable-next-line @atlaskit/ui-styling-standard/use-compiled -- Ignored via go/DSP-18766
import { css, jsx } from '@emotion/react';
import { v4 as uuidv4 } from 'uuid';

import { type JSONDocNode } from '@atlaskit/editor-json-transformer';

import type {
	BlurInputMutableRefObject,
	ClearInputMutableRefObject,
	FocusInputMutableRefObject,
	ReplaceInputMutableRefObject,
} from '../PromptForm/useSetInputRef';

const promptEditorWrapperStyles = css({
	display: 'grid',
	position: 'relative',
	flex: 1,
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	'div > .ProseMirror': {
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
		'> p': {
			lineHeight: '20px',
		},

		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors, @atlaskit/ui-styling-standard/no-unsafe-selectors -- Ignored via go/DSP-18766
		'> p:last-child': {
			marginBottom: 0,
			paddingBottom: 0,
		},

		/**
		 * Minimum height is set to 125px for confluence inline comment editor (ProseMirror element)
		 * When Editor AI is invoked in inline comments, same css rule applies here as well.
		 * So overriding it here.
		 */
		minHeight: 'auto',
	},

	// Smart link's inline card view has 0.5em padding (7px or 8px)
	// Because of that scrollbar appearts in prompt input because of limited height available.
	// More details here https://product-fabric.atlassian.net/browse/EDF-455
	// So removed this top and bottom padding here.
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	'.ProseMirror .inlineCardView-content-wrap .card': {
		paddingTop: 0,
		paddingBottom: 0,
	},
});

const promptEditorWrapperOverflowStyles = css({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	'div > .ProseMirror': {
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
		'> p': {
			// We need a tiny bit of padding to avoid inline nodes with borders being visually clipped
			// and wwe need to increase the line height to allow for when inline nodes are focused as
			// they add box-shadow styling of 1px
			// eslint-disable-next-line @atlaskit/design-system/use-tokens-space
			paddingTop: '0.5px',
			lineHeight: '24px',
		},
	},
});

// The types PromptEditorProps and PromptEditor should mirror what is in the editor-ai-injected-editors package
// at `packages/editor/editor-ai-injected-editors/src/editors/prompt.tsx`.
export type PromptEditorProps = {
	type: 'user-input' | 'interrogate';
	autoFocus?: boolean;
	defaultValue?: JSONDocNode;
	placeholder?: string;
	onInputChange?: (input: string) => void;
	onADFChange?: (input: JSONDocNode) => void;
	setFocusHandlers?: (focusFn?: () => boolean, blurFn?: () => boolean) => void;
	setClearHandler?: (clearFn?: () => boolean) => void;
	setReplaceDocumentHandler?: (replaceDocumentFn?: (adf: JSONDocNode) => boolean) => void;
	/**
	 * Having links in Editor is giving false impression to customers that
	 *  we have started supporting links, but we haven't yet as backend will
	 *  still point to xp-gen-ai and leading to hallucinations.
	 * So we have tied enabling links to ConvoAI FF through this prop.
	 */
	enableLinks?: boolean;
};
export type PromptEditor = (props: PromptEditorProps) => JSX.Element;

export type PromptEditorWrapperProps = {
	type: 'user-input' | 'interrogate';
	PromptEditor: PromptEditor;
	autoFocus: boolean;
	defaultValue?: JSONDocNode;
	placeholder?: string;
	focusInputRef?: FocusInputMutableRefObject;
	blurInputRef?: BlurInputMutableRefObject;
	clearInputRef?: ClearInputMutableRefObject;
	replaceInputRef?: ReplaceInputMutableRefObject;
	onKeyDown?: React.KeyboardEventHandler<HTMLDivElement>;
	onInputChange?: (inputValue: string) => void;
	onADFChange?: (inputValue: JSONDocNode) => void;
	onFocus?: () => void;
	onBlur?: React.FocusEventHandler<HTMLDivElement>;
	onCompositionStart?: React.CompositionEventHandler<HTMLDivElement>;
	onCompositionEnd?: React.CompositionEventHandler<HTMLDivElement>;
	enableLinks?: boolean;
};

export const PromptEditorWrapper = ({
	type,
	PromptEditor,
	defaultValue,
	autoFocus,
	focusInputRef,
	blurInputRef,
	clearInputRef,
	replaceInputRef,
	placeholder,
	enableLinks,
	onInputChange,
	onADFChange,
	onBlur,
	onFocus,
	onKeyDown,
	onCompositionStart,
	onCompositionEnd,
}: PromptEditorWrapperProps) => {
	const setFocusHandlers = useCallback(
		(blur?: () => boolean, focus?: () => boolean) => {
			if (blurInputRef) {
				blurInputRef.current = blur;
			}
			if (focusInputRef) {
				focusInputRef.current = focus;
			}
		},
		[blurInputRef, focusInputRef],
	);

	const setClearHandler = useCallback(
		(clear?: () => boolean) => {
			if (clearInputRef) {
				clearInputRef.current = clear;
			}
		},
		[clearInputRef],
	);

	const setReplaceDocumentHandler = useCallback(
		(replaceDocument?: (adf: JSONDocNode) => boolean) => {
			if (replaceInputRef) {
				replaceInputRef.current = replaceDocument;
			}
		},
		[replaceInputRef],
	);

	const componentId = useMemo(() => `${placeholder}-${uuidv4()}`, [placeholder]);

	return (
		// eslint-disable-next-line @atlaskit/design-system/prefer-primitives, jsx-a11y/no-static-element-interactions
		<div
			css={[promptEditorWrapperStyles, promptEditorWrapperOverflowStyles]}
			data-testid="ai-experience-prompt-input"
			onKeyDown={onKeyDown}
			// We're using this div to listen to focus/blur/composition events which bubble up.
			onFocus={onFocus}
			onBlur={onBlur}
			onCompositionStart={onCompositionStart}
			onCompositionEnd={onCompositionEnd}
		>
			<PromptEditor
				type={type}
				key={componentId}
				autoFocus={autoFocus}
				defaultValue={defaultValue}
				placeholder={placeholder}
				onInputChange={onInputChange}
				onADFChange={onADFChange}
				setFocusHandlers={setFocusHandlers}
				setClearHandler={setClearHandler}
				setReplaceDocumentHandler={setReplaceDocumentHandler}
				enableLinks={enableLinks}
			/>
		</div>
	);
};
