import { defineMessages } from 'react-intl-next';

export const messages = defineMessages({
	title: {
		id: 'fabric.editor.ai.config.item.addConclusion.title',
		defaultMessage: 'Add conclusion',
		description:
			'Describes an option to use Atlassian Intelligence to write a conclusion based on the content currently selected by the user',
	},
	description: {
		id: 'fabric.editor.ai.config.item.addConclusion.description',
		defaultMessage: 'Writes a conclusion for you based on the given content',
		description: 'Description for the the Atlassian Intelligence "Add conclusion".',
	},
});
