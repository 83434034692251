import { defineMessages } from 'react-intl-next';

export const messages = defineMessages({
	title: {
		id: 'fabric.editor.ai.config.item.range.generateFromSelection.title',
		defaultMessage: 'Atlassian Intelligence',
		description: '...',
	},
	promptHint: {
		id: 'fabric.editor.ai.config.item.range.generateFromSelection.promptHint',
		defaultMessage: 'Tell AI to write anything or choose from below',
		description: 'Placeholder text in the prompt input guiding user interaction.',
	},
	description: {
		id: 'fabric.editor.ai.config.item.range.generateFromSelection.description',
		defaultMessage: 'Generate from selection',
		description: 'Placeholder text in the prompt input guiding user interaction.',
	},
});
