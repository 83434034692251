/**
 * @jsxRuntime classic
 * @jsx jsx
 */
import React from 'react';

// eslint-disable-next-line @atlaskit/ui-styling-standard/use-compiled -- Ignored via go/DSP-18766
import { jsx } from '@emotion/react';
import { useIntl } from 'react-intl-next';

import { defaultSchema } from '@atlaskit/adf-schema/schema-default';
import type { ExtensionParams } from '@atlaskit/editor-common/extensions';
import type { ProviderFactory } from '@atlaskit/editor-common/provider-factory';
import { JSONTransformer } from '@atlaskit/editor-json-transformer';
import type { Node, Schema } from '@atlaskit/editor-prosemirror/model';
import { Fragment } from '@atlaskit/editor-prosemirror/model';
import { Text } from '@atlaskit/primitives';
import { ReactRenderer } from '@atlaskit/renderer';
import { RendererActionsContext } from '@atlaskit/renderer/actions';
import { ADFEncoder } from '@atlaskit/renderer/utils';
import SectionMessage from '@atlaskit/section-message';
import { useThemeObserver } from '@atlaskit/tokens';

import type { AIPanelParameters } from '../types';
import {
	getAupViolationMessage,
	getErrorMessage,
	getInternalServerErrorMessage,
	getRateLimitErrorMessage,
} from '../utils';

import { aiPanel, aiPanelContainer, prismBackground } from './styles';
import { useAIPanelAnalytics } from './utils';

const jsonTransformer = new JSONTransformer();

type Props = {
	node: ExtensionParams<AIPanelParameters>;
	providerFactory?: ProviderFactory;
};

type NodeContent = {
	type: string;
	text?: string;
	attrs?: any;
	content?: NodeContent[];
};

const createNodesFromContent = (content: NodeContent[], schema: Schema): readonly Node[] => {
	const nodes = [];
	for (const node of content) {
		if (node?.type === 'text') {
			const textNode = schema.text(node.text ?? '');
			nodes.push(textNode);
		} else if (schema.nodes[node.type] && node.content) {
			const contentNodes = createNodesFromContent(node.content, schema);
			const newNode = schema.nodes[node.type].create(node.attrs, contentNodes);
			nodes.push(newNode);
		}
	}
	return nodes;
};

export const AIPanelRenderer = ({ node, providerFactory }: Props) => {
	const { formatMessage } = useIntl();
	const { colorMode } = useThemeObserver();
	const [errorMessage, setErrorMessage] = React.useState<React.ReactNode | string | undefined>(
		undefined,
	);
	const [theme, setTheme] = React.useState<'light' | 'dark' | undefined>(undefined);

	React.useEffect(() => {
		const status = node.parameters?.macroMetadata?.status;
		if (status === 'failed' || status === 'aup-violation') {
			switch (node.parameters?.content) {
				case 'ACCEPTABLE_USE_VIOLATIONS':
					const aupViolationMessage = getAupViolationMessage(formatMessage);
					setErrorMessage(aupViolationMessage);
					break;
				case 'NO_AGENT':
				case 'FEATURE_DISABLED_ON_SITE':
				case 'FEATURE_DISABLED':
				case 'INTERNAL_SERVER_ERROR':
				case 'PLUGIN_ERRORED':
					const internalServerError = getInternalServerErrorMessage(formatMessage);
					setErrorMessage(internalServerError);
					break;
				case 'RATE_LIMIT':
				case 'OPENAI_RATE_LIMIT_USER_ABUSE':
					const rateLimitErrorMessage = getRateLimitErrorMessage(formatMessage);
					setErrorMessage(rateLimitErrorMessage);
					break;
				default:
					const errorMessageDescriptor = getErrorMessage(node.parameters?.content ?? 'API_FAILED');
					const errorMessage = formatMessage(errorMessageDescriptor);
					setErrorMessage(errorMessage);
					break;
			}
		}
		setTheme(colorMode);
	}, [node.parameters?.macroMetadata?.status, node.parameters?.content, formatMessage, colorMode]);

	useAIPanelAnalytics({ node });

	const doc = React.useMemo(() => {
		if (!node?.content || errorMessage) {
			return null;
		}

		const adfEncoder = new ADFEncoder(() => jsonTransformer);
		return adfEncoder.encode(
			jsonTransformer.encode(
				defaultSchema.nodes.doc.create(
					{},
					Fragment.from(
						createNodesFromContent(node.content as unknown as NodeContent[], defaultSchema),
					),
				),
			),
		);
	}, [node.content, errorMessage]);

	if (errorMessage) {
		return (
			// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/design-system/consistent-css-prop-usage -- Ignored via go/DSP-18766
			<div css={prismBackground(theme)}>
				{/* eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/design-system/consistent-css-prop-usage -- Ignored via go/DSP-18766 */}
				<div css={aiPanel}>
					{/* eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/design-system/consistent-css-prop-usage -- Ignored via go/DSP-18766 */}
					<div css={aiPanelContainer}>
						<SectionMessage appearance="error" title={''}>
							<Text as="p">{errorMessage}</Text>
						</SectionMessage>
					</div>
				</div>
			</div>
		);
	}

	if (!doc) {
		return null;
	}

	return (
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/design-system/consistent-css-prop-usage -- Ignored via go/DSP-18766
		<div css={prismBackground(theme)}>
			{/* eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/design-system/consistent-css-prop-usage -- Ignored via go/DSP-18766 */}
			<div css={aiPanel}>
				{/* eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/design-system/consistent-css-prop-usage -- Ignored via go/DSP-18766 */}
				<div css={aiPanelContainer}>
					<RendererActionsContext>
						<ReactRenderer document={doc} appearance="full-width" dataProviders={providerFactory} />
					</RendererActionsContext>
				</div>
			</div>
		</div>
	);
};
