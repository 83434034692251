import gql from 'graphql-tag';

import { inlineCommentFragment } from './InlineCommentFragment.fragment';

const contentFragment = gql`
	fragment contentFragment on Content {
		id
		type
		space {
			id
		}
	}
`;

export const OptimizedInlineCommentsQuery = gql`
	query OptimizedInlineCommentsQuery($pageId: ID!) {
		content(id: $pageId) {
			nodes {
				...contentFragment
			}
		}
		contentOperations: content(id: $pageId) {
			nodes {
				id
				operations {
					operation
					targetType
				}
			}
		}
		comments(pageId: $pageId, type: UNRESOLVED, depth: ROOT, first: 1000) {
			nodes {
				...inlineCommentFragment
				replies {
					...inlineCommentFragment
				}
			}
		}
	}
	${contentFragment}
	${inlineCommentFragment}
`;
