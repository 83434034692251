import { preloadSpaceViews } from '@confluence/space-views/entry-points/preloadSpaceViews';
import { preloadShortcutsSmartLinks } from '@confluence/space-shortcuts/entry-points/preloadShortcutsSmartLinks';
import {
	preloadSideNavigation,
	preloadSideNavigationNeedsContentId,
} from '@confluence/side-navigation/entry-points/preloadSideNavigation';
import { preloadSpaceGuestList } from '@confluence/space-guest-list/entry-points/preloadSpaceGuestList';
import { preloadWhiteboardData } from '@confluence/whiteboard-common/entry-points/preloadWhiteboardData';
import { getPreloaderFnContext } from '@confluence/query-preloader-tools';
import type { RouteMatch } from '@confluence/route';

export const preloadWhiteboardRoute = async (
	match: RouteMatch,
	_url: string,
	isTransition: boolean = false,
) => {
	const { isLicensed } = await getPreloaderFnContext();
	const tasks: Promise<any>[] = [];
	const { spaceKey, contentId } = match.params;

	if (process.env.REACT_SSR) {
		// Only load data for navigation in React SSR since we are delaying them in the SPA
		tasks.push(
			preloadSideNavigation(spaceKey),
			preloadSideNavigationNeedsContentId(spaceKey, contentId),
			preloadSpaceGuestList(spaceKey),
			preloadShortcutsSmartLinks(spaceKey),
		);
	} else if (!isTransition) {
		tasks.push(preloadSpaceViews(spaceKey, isLicensed));
	}

	tasks.push(
		preloadWhiteboardData({
			spaceKey,
			contentId,
			includeAlias: true,
		}),
	);

	return Promise.all(tasks);
};
