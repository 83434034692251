import type { ComponentProps } from 'react';
import { useCallback, useRef } from 'react';

import type { DialogsContainerProps } from '@confluence/dialogs';
import { useDialogs } from '@confluence/dialogs/entry-points/useDialogs';
import { LoadableLazy } from '@confluence/loadable';

type ChangeHubNameDialogLoadableProps = ComponentProps<typeof ChangeHubNameDialogLoadable>;

export function useChangeHubNameDialog(
	props: Omit<ChangeHubNameDialogLoadableProps, keyof DialogsContainerProps> = {},
) {
	const { showModal } = useDialogs();

	const propsRef = useRef<typeof props>();
	propsRef.current = props;

	return useCallback(() => showModal(ChangeHubNameDialogLoadable, propsRef.current!), [showModal]);
}

const ChangeHubNameDialogLoadable = LoadableLazy({
	loader: async () =>
		(
			await import(
				/* webpackChunkName: "loadable-ChangeHubNameDialogLoadable" */ './ChangeHubNameDialog'
			)
		).ChangeHubNameDialog,
});
