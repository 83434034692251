import React from 'react';

import { fg } from '@atlaskit/platform-feature-flags';

import { CONFIG_ITEM_KEYS } from '../../../config-items/config-item-keys';
import { createEditorPluginAIConfigItemMarkdown } from '../../../config-items/config-items';
import { Icon } from '../../assets/icons/findActionItems';
import { createInsertAtTop, createInsertBelow } from '../../config-item-actions/markdown';
import { isMinLength } from '../../config-item-visible/visibility-utils';

import { messages } from './messages';

const SELECTION_TYPE = 'range';

export const findActionItemsFromSelectionConfigItem = createEditorPluginAIConfigItemMarkdown({
	key: CONFIG_ITEM_KEYS.FIND_ACTION_ITEMS,
	title: messages.title,
	description: messages.description,
	selectionType: SELECTION_TYPE,
	intentSchemaId: 'action_items_intent_schema.json',
	isVisible: ({ editorView, positions }) => isMinLength({ editorView, minLength: 1, positions }),
	icon: ({ shownAt }) => <Icon shownAt={shownAt} />,
	actions: [
		createInsertAtTop({ appearance: 'secondary' }),
		createInsertBelow({ appearance: 'primary' }),
	],
	getBackendModel: () => {
		if (fg('platform_editor_ai_assistance_service')) {
			return 'assistance-service';
		}

		return 'xp-gen-ai';
	},
});
