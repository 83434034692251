import gql from 'graphql-tag';

export const RecentActivitySectionQuery = gql`
	query RecentActivitySectionQuery($key: String!) {
		space(key: $key) {
			id
			history {
				createdDate
			}
		}
	}
`;
