import type { DocNode, NonNestableBlockContent } from '@atlaskit/adf-schema';
import { defaultSchema } from '@atlaskit/adf-schema/schema-default';
import { doc, bodiedExtension } from '@atlaskit/adf-utils/builders';
import { validator } from '@atlaskit/adf-utils/validator';
import type { ValidationError } from '@atlaskit/adf-utils/validatorTypes';
import { EXTENSION_NAMESPACE } from '../utils/constants';

export type ForgeConfigPayload = {
	config: Record<string, unknown>;
	insertBody?: DocNode;
	keepEditing?: boolean;
};

export function validateForgeConfigPayload(
	data: unknown,
	{
		isBodiedExtension,
		isInitialInsertion,
	}: { isBodiedExtension: boolean; isInitialInsertion: boolean },
): ForgeConfigPayload {
	if (!isObject(data)) {
		throw new ForgeConfigError('Must provide payload object', 'INVALID_PAYLOAD');
	}

	const { config, keepEditing, insertBody } = data;

	if (!isObject(config)) {
		throw new ForgeConfigError('Invalid "config" provided. Expected object', 'INVALID_CONFIG');
	}

	// Only worth validating the insertBody if it's actually going to be used
	if (insertBody && isInitialInsertion) {
		if (!isBodiedExtension) {
			throw new ForgeConfigError(
				'Cannot set "insertBody" for non bodied extension',
				'INVALID_EXTENSION_TYPE',
			);
		}
		// We need to do basic validation of the insertBody before we can safely do proper ADF validation
		if (!isDocNodeShape(insertBody)) {
			throw new ForgeConfigError(
				'Invalid ADF "insertBody" provided. Expected node of type doc with content',
				'INVALID_BODY',
			);
		}

		const { valid, errors } = validateAdfBody(insertBody);
		if (!valid || errors.length) {
			throw new ForgeConfigError(
				`Invalid ADF "insertBody" provided.\n${errors
					.map((e) => `${e.code}: ${e.message}`)
					.join('\n')}`,
				'INVALID_BODY',
			);
		}
	}
	return {
		config,
		keepEditing: !isInitialInsertion && Boolean(keepEditing),
		insertBody: isInitialInsertion ? (insertBody as DocNode) : undefined,
	};
}

export function handleNodeUpdateError(error: unknown) {
	if (error instanceof Error && error.message.includes('Could not find node')) {
		throw new ForgeConfigError('Macro not found.', 'MACRO_NOT_FOUND');
	}
	throw error;
}

function isObject(obj: any): obj is Record<string, unknown> {
	return obj !== null && typeof obj === 'object' && !Array.isArray(obj);
}

function isDocNodeShape(body: unknown): body is DocNodeShape {
	return isObject(body) && body.type === 'doc' && Array.isArray(body.content);
}

type DocNodeShape = Pick<DocNode, 'type'> & {
	content: unknown[];
};

class ForgeConfigError extends Error {
	code: string;
	constructor(message: string, code: string) {
		super(prefixMessage(message));
		this.code = code;
	}
}

function prefixMessage(message: string) {
	return `view.submit(): ${message}`;
}

function validateAdfBody(body: DocNodeShape): {
	valid: boolean;
	errors: ValidationError[];
} {
	const marks = Object.keys(defaultSchema.marks);
	const nodes = Object.keys(defaultSchema.nodes);
	const validate = validator(nodes, marks, {
		mode: 'strict',
	});

	const { content } = body;
	// Make sure we're validating in the context of the extension node, to prevent invalid nested extensions
	const extension = bodiedExtension({
		extensionType: EXTENSION_NAMESPACE,
		extensionKey: 'dummy-validate-only',
	})(...(content as NonNestableBlockContent[]));

	const errors: ValidationError[] = [];
	const { valid } = validate(doc(extension), (_entity, error) => {
		errors.push(error);
		return undefined;
	});
	return {
		valid,
		errors,
	};
}
