import type { Diff } from './utils';

type DiffPostProcessor = (diffs: Diff[]) => Diff[];

/**
 * This post process attempts to join consecutive replacement-only diffs which are seperated by whitespace. This way
 * they appear as a single suggestion replacement rather then seperate single ones.
 */
export const concatConsecutiveReplacements: DiffPostProcessor = (diffs: Diff[]): Diff[] => {
	const result: Diff[] = [];
	for (let i = 0; i < diffs.length; i++) {
		// This is a very specific use case, were consequetive replacements are seperated only by unchanged white space.
		// This essentially converts them to a single replacement group.
		if (
			diffs[i].type === -1 &&
			diffs?.[i + 1]?.type === 1 &&
			!diffs[i + 1].text.includes('\uFFF9') &&
			!diffs[i + 1].text.includes('\uFFFB')
		) {
			// look ahead for groups of white space follwed by replacements, we will want to join all of them together.
			const grouped: Diff[] = [];
			for (let j = i + 2; j < diffs.length; j++) {
				if (
					diffs?.[j]?.type === 0 &&
					diffs?.[j]?.text.trim().length === 0 &&
					diffs?.[j + 1]?.type === -1 &&
					diffs?.[j + 2]?.type === 1 &&
					!diffs[j + 1].text.includes('\uFFF9') &&
					!diffs[j + 1].text.includes('\uFFFB')
				) {
					grouped.push(diffs?.[j], diffs?.[j + 1], diffs?.[j + 2]);
					j += 3;
				} else {
					break;
				}
			}

			if (grouped.length) {
				const ot = grouped.reduce((acc, d) => acc + (d.type <= 0 ? d.text : ''), diffs[i].text);
				result.push({
					type: -1,
					text: ot,
					length: ot.length,
				});

				const nt = grouped.reduce((acc, d) => acc + (d.type >= 0 ? d.text : ''), diffs[i + 1].text);
				result.push({
					type: 1,
					text: nt,
					length: nt.length,
				});

				i += grouped.length + 1;
			} else {
				result.push(diffs[i]);
			}
		} else {
			result.push(diffs[i]);
		}
	}
	return result;
};

/**
 * This post process attempts to use the context of where diffs are located within a document and decides whether or not
 * they should show/hide a full stop suggestion
 */
export const ignoreTrailingFullStops: DiffPostProcessor = (diffs: Diff[]): Diff[] => {
	const result: Diff[] = diffs.concat();

	// use the original paragraph context to decide whether there should be a . at the end
	if (result.length > 1) {
		if (result[result.length - 2].type === -1 && result?.[result.length - 1]?.type === 1) {
			const removed = result[result.length - 2].text.trimEnd();
			const added = result[result.length - 1].text.trimEnd();

			const r1 = removed.charAt(removed.length - 1) === '.';
			const a1 = added.charAt(added.length - 1) === '.';
			const r2 = removed.substring(0, removed.length - 1);
			const a2 = added.substring(0, added.length - 1);

			if (removed !== added && (r1 || a1) && ((r1 && r2 === added) || (a1 && a2 === removed))) {
				result.splice(-2, 2, {
					type: 0,
					text: diffs[diffs.length - 2].text,
					length: diffs[diffs.length - 2].length,
				});
			}
		}
	}

	return result;
};

export const ignoreTrailingSpaces: DiffPostProcessor = (diffs: Diff[]): Diff[] => {
	const result: Diff[] = diffs.concat();

	if (result.length > 1) {
		if (result[result.length - 2].type === -1 && result?.[result.length - 1]?.type === 1) {
			const removed = result[result.length - 2].text.trimEnd();
			const added = result[result.length - 1].text.trimEnd();

			if (removed === added) {
				result.splice(-2, 2, {
					type: 0,
					text: diffs[diffs.length - 2].text,
					length: diffs[diffs.length - 2].length,
				});
			}
		}
	}

	return result;
};

/**
 * This collection contains a one-way mapping identifying replacement direction and targets which should be ignored from
 * the diff check. If you want a diff to be ignored 2-ways then it needs to be listed twice with the direction reversed.
 *
 * This list is case-insensitive.
 */
export const ignoredDirectReplacements = new Map<string, string>([
	['&', 'and'],
	['and', '&'],
]);

export const ignoreDirectReplacements: DiffPostProcessor = (diffs: Diff[]): Diff[] => {
	// If the replacements is change & -> and then we should ignore it
	const result: Diff[] = [];
	for (let i = 0; i < diffs.length; i++) {
		// We only care about replacement diffs
		if (diffs[i].type === -1 && diffs?.[i + 1]?.type === 1) {
			if (
				ignoredDirectReplacements.get(diffs[i].text.trim().toLowerCase()) ===
				diffs[i + 1].text.trim().toLowerCase()
			) {
				result.push({
					type: 0,
					text: diffs[i].text,
					length: diffs[i].length,
				});

				i += 1;
			} else {
				result.push(diffs[i]);
			}
		} else {
			result.push(diffs[i]);
		}
	}
	return result;
};

/**
 * This will convert delete operations or blank replacements ops to a previous word replacement instead.
 */
export const convertJoinedDeletesIntoReplacement: DiffPostProcessor = (diffs: Diff[]): Diff[] => {
	const result: Diff[] = [];

	for (let i = 0; i < diffs.length; i++) {
		// This is a very specific use case, were consequetive replacements are seperated only by unchanged white space.
		// This essentially converts them to a single replacement group.
		if (
			diffs[i].type === 0 &&
			diffs?.[i + 1]?.type === -1 &&
			diffs?.[i + 2]?.type !== 1 &&
			diffs?.[i]?.text.trim().length !== 0
		) {
			// Removing to the end of a diff
			const rightIndex = [' ', '\uFFFB', '\uFFF9'].reduce((v, c) => {
				const diffText = diffs[i].text;
				let x = diffText.lastIndexOf(c);
				if (x === diffText.trimEnd().length) {
					x = diffText.trimEnd().lastIndexOf(c);
				}
				return x !== -1 && x > v ? x : v;
			}, 0);

			const a = diffs[i].text.substring(0, rightIndex + 1);
			result.push({
				type: 0,
				text: a,
				length: a.length,
			});

			const b = diffs[i].text.substring(rightIndex + 1) + diffs[i + 1].text;
			result.push({
				type: -1,
				text: b,
				length: b.length,
			});

			const c = diffs[i].text.substring(rightIndex + 1);
			result.push({
				type: 1,
				text: c,
				length: c.length,
			});

			i += 1;
		} else {
			result.push(diffs[i]);
		}
	}

	return result;
};

const removeMarkdownCustomTagReplacements =
	(tag: string): DiffPostProcessor =>
	(diffs: Diff[]): Diff[] => {
		// If the replacements is change & -> and then we should ignore it
		const result: Diff[] = [];
		for (let i = 0; i < diffs.length; i++) {
			// We only care about replacement diffs
			if (diffs[i].type === -1 && diffs?.[i + 1]?.type === 1) {
				const o = diffs[i].text;
				const r = diffs[i + 1].text;
				const j = r.indexOf(tag);
				const k = o.indexOf(tag);

				// We should only manipulate and remove the custom tag if it wasn't in the original text and the BE
				// returned it with the replacement text.
				if (j !== -1 && k === -1) {
					// get the string value without the "</custom>" part.
					const v = r.substring(0, j) + r.substring(j + tag.length);
					// If the replacement string without the custom tag is empty then it's likely that the tag is a legitimate correction
					// so we will behave normally in this case
					if (v.trim().length !== 0) {
						// If the only difference was the custom tag, then we will ignore the change
						if (v === o) {
							result.push({
								type: 0,
								text: diffs[i].text,
								length: diffs[i].length,
							});

							i += 1;
						} else {
							//  If the text after removing the custom tag is still different then we
							// should try to generate a value suggestion based on the difference.
							result.push({
								type: -1,
								text: diffs[i].text,
								length: diffs[i].length,
							});

							result.push({
								type: 1,
								text: v,
								length: v.length,
							});

							i += 1;
						}
					} else {
						result.push(diffs[i]);
					}
				} else {
					result.push(diffs[i]);
				}
			} else {
				result.push(diffs[i]);
			}
		}
		return result;
	};

export const removeMarkdownTagReplacements = (diffs: Diff[]): Diff[] => {
	return ['<custom>', '</custom>', '<text>', '</text>'].reduce(
		(diffs, tag) => removeMarkdownCustomTagReplacements(tag)(diffs),
		diffs,
	);
};

/**
 * This is a colletion of "Special" Characters (ie. Punctuation) which we want to exclude from possible diffs, in the event
 * the punctuation in the diff hasn't changed and only the word has.
 */
const specialCharCodes = new Set(
	[
		'!',
		'"',
		'#',
		'$',
		'%',
		'&',
		"'",
		'(',
		')',
		'*',
		'+',
		',',
		'-',
		'.',
		'/',
		':',
		';',
		// '<', // We have to exclude this special character to avoid corrupting the MD+ diff which could have html elements
		// '>',
		'=',
		'?',
		'@',
		'[',
		'\\',
		']',
		'^',
		'_',
		'`',
	].map((v) => v.charCodeAt(0)),
);

// This is a collection of all possible punctuation unicode confusables pulled from the unicode confusables map.
// https://www.unicode.org/Public/security/8.0.0/confusables.txt
// using the special characters list above as a filter to only grab confusables which are associated to the special chars
// listed. This will allow us to ignore confusables which are similar to the punctuation above.
const confusableChars = new Set([
	'\uFF01',
	'\u01C3',
	'\u2D51',
	'\u203C',
	'\uA778',
	'\u055A',
	'\u02BE',
	'\u3003',
	'\u02CA',
	'\u1FBD',
	'\u201C',
	'\u02EE',
	'\u201D',
	'\u02F4',
	'\u0022',
	'\u02BC',
	'\u2034',
	'\u0384',
	'\u02CB',
	'\u1FFE',
	'\u02BB',
	'\u2036',
	'\u05F4',
	'\u1FBF',
	'\u02BA',
	'\u2037',
	'\u2057',
	'\u02F6',
	'\u2019',
	'\u16CC',
	'\u201B',
	'\u07F4',
	'\u1CD3',
	'\u144A',
	'\u05F2',
	'\u07F5',
	'\u02DD',
	'\u1FFD',
	'\uFF07',
	'\u2033',
	'\u1FEF',
	'\u2032',
	'\u0374',
	'\u02C8',
	'\u2018',
	'\u0060',
	'\u02B9',
	'\u055D',
	'\u05F3',
	'\uFF40',
	'\u2035',
	'\uFF02',
	'\u201F',
	'\u00B4',
	'\u05D9',
	'\uA78C',
	'\u02BD',
	'\u3014',
	'\u2772',
	'\uFF3B',
	'\u2768',
	'\u2E28',
	'\uFD3E',
	'\u2773',
	'\u3015',
	'\u2769',
	'\uFD3F',
	'\uFF3D',
	'\u2E29',
]);

/**
 * There's a scenario we need to cover which is what happens when characters are replaced with confusables? For example;
 * U+0022 -> U+201C (ie. " -> “). These are classified as confusable unicode characters.
 * To work around this we will essentially treat any char > 127 (ASCII limit) as a wildcard * when comparing it against
 * a special char.
 *
 * This means if a punctuation character changes to a value outside of the ASCII range
 *
 * @returns A value of true is returned when the chars are different and not marked as special.
 */
const isCharDiff = (a: number, b: number) => {
	const ca = String.fromCharCode(a);
	const cb = String.fromCharCode(b);

	// If the old/new char are the same and they're outside the ASCII range then we want to continue
	// looking for the next ASCII char where the diff should be cut from.
	if (a === b && confusableChars.has(ca)) {
		return false;
	}

	// If the char comparison is not equal, then we're going to see if one of the chars is in the special chars list
	// and the other is in one of the confusables, if so we want to keep looking.
	if (
		a !== b &&
		((specialCharCodes.has(a) && confusableChars.has(cb)) ||
			(specialCharCodes.has(b) && confusableChars.has(ca)))
	) {
		return false;
	}

	// Lastly if the chars are different and not in the special char list then we want
	// to return true to indicate that we have hit a difference.
	//
	return a !== b || !specialCharCodes.has(a);
};

/**
 * This will scan the 2 string in a specified direction looking for a difference in characters or non-special characters.
 * One we find a match then we return the character index of the match.
 */
const findSpecialCharsOffset = (a: string, b: string, dir: 1 | -1) => {
	const n = Math.min(a.length, b.length);
	for (let i = 0; i < n; i++) {
		const m = dir > 0 ? 0 : 1;
		const av = a.charCodeAt(m * (a.length - 1) + i * dir);
		const bv = b.charCodeAt(m * (b.length - 1) + i * dir);
		if (isCharDiff(av, bv)) {
			return i;
		}
	}
	return -1;
};

export const ignoreTrailingAndLeadingSpecialCharacters: DiffPostProcessor = (
	diffs: Diff[],
): Diff[] => {
	const result: Diff[] = [];

	for (let i = 0; i < diffs.length; i++) {
		// We only care about replacement diffs
		if (diffs[i].type === -1 && diffs?.[i + 1]?.type === 1) {
			const a = diffs[i].text;
			const b = diffs[i + 1].text;

			// This is an early escape hatch to avoid scanning the text to find the start/end
			// If this condition passes then we abort and just use the diff unchanged.
			// essentially if the first and last chars do not qualify as specials then we will abort the scan.
			if (
				isCharDiff(a.charCodeAt(0), b.charCodeAt(0)) &&
				isCharDiff(a.charCodeAt(a.length - 1), b.charCodeAt(b.length - 1))
			) {
				result.push(diffs[i]);
				continue;
			}

			// We're going to scan in from the ends to find were the matching punc finishes. A starting scan will treat the text
			// as left aligned, and and end scan will treat the text as right aligned.
			const start = findSpecialCharsOffset(a, b, 1);
			const end = findSpecialCharsOffset(a, b, -1);

			if (start <= 0 && end <= 0) {
				result.push(diffs[i]);
				continue;
			}

			// If the content between the punctuation is not different then we need to nuke this replacement and
			// just mark it as no change. This is because we could have got here because the punctuation could have changed
			// and not the text between it.
			if (a.substring(start, a.length - end) === b.substring(start, b.length - end)) {
				result.push({
					type: 0,
					text: a,
					length: a.length,
				});
				i += 1;
				continue;
			}

			if (start > 0) {
				const head = a.substring(0, start);
				result.push({
					type: 0,
					text: head,
					length: head.length,
				});
			}

			if (start > 0 || end > 0) {
				// we want to remove and add the word between the puncutation.
				result.push({
					type: -1,
					text: a.substring(start, a.length - end),
					length: a.length - start - end,
				});

				result.push({
					type: 1,
					text: b.substring(start, b.length - end),
					length: b.length - start - end,
				});
			}

			if (end > 0) {
				const tail = a.substring(a.length - end);
				result.push({
					type: 0,
					text: tail,
					length: tail.length,
				});
			}

			// Skip ahead 1 place, as this step is replacing 2 diffs, the current one and the next one.
			i += 1;
			continue;
		}

		result.push(diffs[i]);
	}

	return result;
};
