import React from 'react';

import { token } from '@atlaskit/tokens';

import { RainbowBorder } from './RainbowBorder';
import type { IconProps } from './types';

export const Icon = (props: IconProps) => {
	const { shownAt } = props;
	return (
		<RainbowBorder shownAt={shownAt}>
			<svg
				width="24"
				height="24"
				viewBox="0 0 24 24"
				fill="none"
				xmlns="http://www.w3.org/2000/svg"
			>
				<path
					d="M16.5 9.5L18.0581 7.94194C18.3021 7.69786 18.3021 7.30214 18.0581 7.05806L16.9419 5.94194C16.6979 5.69786 16.3021 5.69786 16.0581 5.94194L14.5 7.5M16.5 9.5L11.0176 14.9824C10.8431 15.1569 10.6209 15.2758 10.3789 15.3242L8.25 15.75L8.67578 13.6211C8.72417 13.3791 8.84312 13.1569 9.01762 12.9824L14.5 7.5M16.5 9.5L14.5 7.5M5 18.25H10M16 18.25H14.5M19 18.25H17.5M13 18.25H11.5"
					stroke={token('color.icon', '#44546F')}
					strokeWidth="1.5"
					strokeLinejoin="round"
				/>
			</svg>
		</RainbowBorder>
	);
};
