import React from 'react';

export const RefreshIcon = () => (
	<span aria-label="refresh-icon">
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="13"
			height="12"
			viewBox="0 0 13 12"
			fill="none"
			role="img"
		>
			<path
				d="M3.5 5.75C3.77614 5.75 4 5.97386 4 6.25C4 7.45714 4.95062 8.44224 6.14408 8.49755L6.25 8.5H6.7925L6.14645 7.85355C5.96513 7.67224 5.95218 7.38632 6.10759 7.19006L6.14645 7.14645C6.32776 6.96513 6.61368 6.95218 6.80994 7.10759L6.85355 7.14645L8.35355 8.64645C8.53487 8.82776 8.54782 9.11368 8.39241 9.30994L8.35355 9.35355L6.85355 10.8536C6.65829 11.0488 6.34171 11.0488 6.14645 10.8536C5.96513 10.6722 5.95218 10.3863 6.10759 10.1901L6.14645 10.1464L6.7925 9.5H6.25C4.45507 9.5 3 8.04493 3 6.25C3 5.97386 3.22386 5.75 3.5 5.75ZM6.85355 1.14645C7.03487 1.32776 7.04782 1.61368 6.89241 1.80994L6.85355 1.85355L6.207 2.5H6.75C8.54493 2.5 10 3.95507 10 5.75C10 6.02614 9.77614 6.25 9.5 6.25C9.22386 6.25 9 6.02614 9 5.75C9 4.54286 8.04938 3.55776 6.85592 3.50245L6.75 3.5H6.207L6.85355 4.14645C7.03487 4.32776 7.04782 4.61368 6.89241 4.80994L6.85355 4.85355C6.67224 5.03487 6.38632 5.04782 6.19006 4.89241L6.14645 4.85355L4.64645 3.35355C4.46513 3.17224 4.45218 2.88632 4.60759 2.69006L4.64645 2.64645L6.14645 1.14645C6.34171 0.951184 6.65829 0.951184 6.85355 1.14645Z"
				fill="#626F86"
			/>
		</svg>
	</span>
);
