import React, { useContext, Fragment, useEffect } from 'react';
import { useQuery } from '@apollo/react-hooks';
import classnames from 'classnames';

import { useAnalyticsEvents } from '@atlaskit/analytics-next';

import {
	createRouteParamHook,
	useHistory,
	useLocation,
	useRouteDataRef,
} from '@confluence/route-manager';
import type {
	SpaceForBaseSpaceQueryType,
	SpaceForBaseSpaceQueryVariablesType,
	Space,
} from '@confluence/load-edit-page/entry-points/LoadEditPageQueries';
import { spaceForBaseSpaceQuery } from '@confluence/load-edit-page/entry-points/LoadEditPageQueries';
import { ScreenEvent } from '@confluence/analytics';
import { RestrictedSpace } from '@confluence/no-permission';
// eslint-disable-next-line no-restricted-imports
import { SpaceRestrictionCheck } from '@confluence/restrictions/src/Query/SpaceRestrictionCheck';
import { Space404 } from '@confluence/space-404';
import { SPAViewContext } from '@confluence/spa-view-context';
import { withDocumentTitle } from '@confluence/document-title';

import { SpaceBaseThemedComponent } from './SpaceBaseThemedComponent';

type SpaceBaseProps = {
	children: React.ReactNode;
	spaceKey: string;
	isSpaceAliasFFEnabled: boolean;
	isSpaceLoading: boolean;
	space?: Space | null;
};

const DISPLAY_ROOT = 'display';
const SPACE_ROOT = 'spaces';

const getUrlRoot = (pathname: string | undefined) => {
	if (!pathname) {
		return undefined;
	}
	if (pathname.includes(`/${SPACE_ROOT}/`)) {
		return SPACE_ROOT;
	} else if (pathname.includes(`/${DISPLAY_ROOT}/`)) {
		return DISPLAY_ROOT;
	} else {
		return undefined;
	}
};
const useContentSlug = createRouteParamHook('contentSlug');
const useContentId = createRouteParamHook('contentId');

const LegacySpaceBaseComponent = ({
	children,
	spaceKey,
	space,
	isSpaceAliasFFEnabled,
	isSpaceLoading,
}: SpaceBaseProps) => {
	const contentId = useContentId();
	const contentSlug = useContentSlug();
	const isViewPage = !!contentId || !!contentSlug;
	const routeDataRef = useRouteDataRef();
	const { createAnalyticsEvent } = useAnalyticsEvents();
	const { isAnonymous } = useContext(SPAViewContext);
	const history = useHistory();
	const isFirstRoute = history?.length === 1;
	const location = useLocation();

	const fixSpaceEncodingInSpaceKey = () => {
		const pathname = location?.pathname || '';
		const decodedPathname = decodeURIComponent(pathname);

		if (
			// excludes the cases where there are no + symbols to replace at all
			// eg: /spaces/perfectly-fine-space-key
			pathname.indexOf('+') !== -1 &&
			// excludes the cases where there is a + that follows the space key portion of the path
			// eg: /spaces/DS/other+stuff+that+contains+plus
			spaceKey.indexOf('+') !== -1 &&
			// excludes the cases where the decoded path name still contains a + (from %2B)
			// , but the replacement to strip the + from the space key has already happened in previous SPA transition
			// eg: pathname = '/spaces/space%2Bkey', decodedPathname = '/spaces/space+key', spaceKey = 'space+key'
			decodedPathname.indexOf(`spaces/${spaceKey}`) !== -1
		) {
			history?.replace?.({
				...location,
				// Ensure we are replacing the "+" in the space key not other funny stuff
				pathname: decodedPathname.replace(
					`spaces/${spaceKey}`,
					`spaces/${spaceKey}`.replace(/\+/g, ' '),
				),
			});
		}
	};

	const removeEmailQueryParameters = () => {
		if (!location?.search?.includes('src=mail') && !location?.search?.includes('src.mail.')) {
			return;
		}

		let search = location?.search?.substring(1);
		const params = search
			.split('&')
			.filter((param) => !param.includes('src=mail') && !param.startsWith('src.mail.'));
		search = `?${params.join('&')}`;

		history?.replace?.({
			...location,
			search,
		});
	};

	useEffect(() => {
		fixSpaceEncodingInSpaceKey();
		removeEmailQueryParameters();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [spaceKey]);

	useEffect(() => {
		if (isSpaceLoading) return;
		if (isSpaceAliasFFEnabled && space && space.alias && space.alias !== spaceKey) {
			const pathnameRoot = getUrlRoot(location?.pathname);
			if (pathnameRoot) {
				const routeName = routeDataRef.current?.routeName || null;

				createAnalyticsEvent({
					type: 'sendTrackEvent',
					data: {
						action: 'redirect',
						actionSubject: 'route',
						source: 'SpaceBase',
						attributes: {
							routeName,
							// Space key related attributes.
							areSpaceKeysEqualLowercase: space?.alias?.toLowerCase() === spaceKey?.toLowerCase(),
						},
					},
				}).fire();

				history?.replace?.({
					...location,
					pathname: location?.pathname?.replace(
						`/${pathnameRoot}/${spaceKey}`,
						`/${pathnameRoot}/${space.alias}`,
					),
				});
			}
			return;
		}
		if (
			!isSpaceAliasFFEnabled &&
			space?.key &&
			space?.key !== spaceKey &&
			space?.key?.toLowerCase() === spaceKey?.toLowerCase()
		) {
			history?.replace?.({
				...location,
				pathname: location?.pathname?.replace(
					`/${SPACE_ROOT}/${spaceKey}`,
					`/${SPACE_ROOT}/${space.key}`,
				),
			});
		}
	}, [
		createAnalyticsEvent,
		history,
		isSpaceAliasFFEnabled,
		location,
		space,
		spaceKey,
		routeDataRef,
		isSpaceLoading,
	]);

	return (
		<SpaceRestrictionCheck spaceKey={spaceKey}>
			{({ isSpaceRestricted, isSpaceNotFound }) => {
				if (isSpaceRestricted) {
					return (
						<RestrictedSpace
							goToPreviousPage={history?.goBack}
							isFirstRoute={isFirstRoute}
							contentId={contentId}
						/>
					);
				}
				if (isSpaceNotFound && !isAnonymous && !contentId) {
					return (
						<Fragment>
							<Space404 goBackPreviousPage={history?.goBack} isFirstRoute={isFirstRoute} />
							<ScreenEvent name="spaceNotFoundScreen" id={spaceKey} />
						</Fragment>
					);
				}

				return (
					<div
						data-testid="space-base-wrapper"
						// eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766
						className={classnames({
							'wrapper-space-view-page': isViewPage,
							'wrapper-space-home-page': !isViewPage,
						})}
					>
						<SpaceBaseThemedComponent>{children}</SpaceBaseThemedComponent>
					</div>
				);
			}}
		</SpaceRestrictionCheck>
	);
};

const SpaceBaseComponent = ({
	children,
	spaceKey,
	space,
	isSpaceAliasFFEnabled,
	isSpaceLoading,
}: SpaceBaseProps) => {
	const contentId = useContentId();
	const contentSlug = useContentSlug();
	const isViewPage = !!contentId || !!contentSlug;

	// TODO: rewrite the component so that it has a consistent behavior in SSR and SPA.
	// Shortcut the render logic if executed in SSR, the rest of the logic is intended for SPA only
	// and was copied from a legacy SpaceBase implementation.
	if (process.env.REACT_SSR) {
		return (
			<div
				data-testid="space-base-wrapper"
				// eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766
				className={classnames({
					'wrapper-space-view-page': isViewPage,
					'wrapper-space-home-page': !isViewPage,
				})}
			>
				{children}
			</div>
		);
	} else {
		return LegacySpaceBaseComponent({
			children,
			spaceKey,
			space,
			isSpaceAliasFFEnabled,
			isSpaceLoading,
		});
	}
};

const useSpaceKey = createRouteParamHook('spaceKey');

export const SpaceBase = ({ children }: { children: React.ReactNode }) => {
	const isSpaceAliasFFEnabled = true;
	const spaceKey = useSpaceKey() || '';
	const { data, loading, error } = useQuery<
		SpaceForBaseSpaceQueryType,
		SpaceForBaseSpaceQueryVariablesType
	>(
		// eslint-disable-next-line graphql-relay-compat/no-import-graphql-operations -- Read https://go/connie-relay-migration-fyi
		spaceForBaseSpaceQuery,
		{
			skip: Boolean(process.env.REACT_SSR),
			variables: { spaceKey, includeAlias: isSpaceAliasFFEnabled },
		},
	);
	// clear out the space if there is an error
	const space = error && !loading ? null : data?.spaces?.nodes?.[0];

	return withDocumentTitle(() => space?.name)(SpaceBaseComponent)({
		spaceKey,
		space,
		children,
		isSpaceAliasFFEnabled,
		isSpaceLoading: loading,
	});
};
