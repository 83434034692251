import React from 'react';

import BulletedListIcon from '@atlaskit/icon/core/list-bulleted';
import { token } from '@atlaskit/tokens';

import { CONFIG_ITEM_KEYS } from '../../../config-items/config-item-keys';
import { createEditorPluginAIConfigItemMarkdown } from '../../../config-items/config-items';
import { createInsertBelow, createReplace } from '../../config-item-actions/markdown';
import { isMinLength } from '../../config-item-visible/visibility-utils';

import { messages } from './messages';

const intentSchemaId = 'convert_to_bullet_points.json';

export const convertToBulletListRangeConfigItem = createEditorPluginAIConfigItemMarkdown<'range'>({
	key: CONFIG_ITEM_KEYS.CONVERT_TO_BULLETED_LIST,
	title: messages.title,
	description: messages.description,
	icon: () => <BulletedListIcon label="" color={token('color.icon', '#44546F')} />,
	statusLozengeType: 'beta',
	selectionType: 'range',
	intentSchemaId,
	isVisible: ({ editorView, positions }) => isMinLength({ editorView, minLength: 1, positions }),
	getBackendModel: () => 'assistance-service',
	actions: [
		createInsertBelow({ appearance: 'secondary' }),
		createReplace({ appearance: 'primary' }),
	],
});
