import { defineMessages } from 'react-intl-next';

export const messages = defineMessages({
	title: {
		id: 'fabric.editor.ai.config.item.range.findActionItemsFromSelection.title',
		defaultMessage: 'Find action items',
		description: '...',
	},
	description: {
		id: 'fabric.editor.ai.config.item.range.findActionItemsFromSelection.description',
		defaultMessage: 'Finds action items in the content you provide',
		description: 'Describes the "Find Action Items" Atlassian Intelligence feature',
	},
});
