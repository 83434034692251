import gql from 'graphql-tag';

export const UserSettingsQuery = gql`
	query UserSettingsQuery($isNotLicensed: Boolean!) {
		userPreferences @skip(if: $isNotLicensed) {
			shouldShowCardOnPageTreeHover
			feedRecommendedUserSettingsDismissTimestamp
			userSpacesNotifiedOfExternalCollab
			favouriteTemplateEntityIds
			homePagesDisplayView
			feedType
			feedTab
			recentFilter
			missionControlOverview
			missionControlMetricSuggestions {
				suggestion
				value
				dismissalDateTime
			}
			missionControlFeatureDiscoverySuggestions {
				suggestion
				dismissalDateTime
			}
			globalPageCardAppearancePreference
			homeWidgets {
				id
				state
				__typename
			}
			recentFilter
			isHomeOnboardingDismissed
			theme
			watchMyOwnContent
			nav4OptOut
			__typename
		}
		isDataClassificationEnabled
		featureDiscovery {
			pluginKey
			featureKey
			userKey
			date
		}
	}
`;
