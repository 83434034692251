import type { Fragment } from '@atlaskit/editor-prosemirror/model';
import { Node } from '@atlaskit/editor-prosemirror/model';

export type ContextSelectionScope = 'fullDocument' | 'partialSelection' | 'none';

export type ContentStatistics = {
	nodeCounts: Record<string, number>;
	markCounts: Record<string, number>;
	linksCount: number;
	totalCharacterCount: number;
	contextSelectionScope?: ContextSelectionScope;
};

export class ProseMirrorContentStatistics {
	private content: Node | Fragment;

	constructor(content: Node | Fragment) {
		this.content = content;
	}

	/**
	 * Calculate statistics for the ProseMirror content such as node counts, mark counts and total character count.
	 */
	public collectStatistics(): ContentStatistics {
		const { nodeCounts, markCounts, linksCount } = this.countNodesAndMarks();
		const { totalCharacterCount } = this.calculateCharacterCounts();
		return {
			nodeCounts,
			markCounts,
			linksCount,
			totalCharacterCount,
			contextSelectionScope: undefined,
		};
	}

	private countNodesAndMarks(): {
		nodeCounts: Record<string, number>;
		markCounts: Record<string, number>;
		linksCount: number;
	} {
		let nodeCounts: Map<string, number> = new Map();
		let markCounts: Map<string, number> = new Map();
		let linksCount = 0;

		this.content.descendants((node) => {
			nodeCounts.set(node.type.name, (nodeCounts.get(node.type.name) || 0) + 1);

			const marks = node.marks;
			marks.forEach((mark) => {
				markCounts.set(mark.type.name, (markCounts.get(mark.type.name) || 0) + 1);
			});

			const {
				nodes: { inlineCard },
				marks: { link },
			} = node.type.schema;

			if (node.type === inlineCard && node.attrs.url) {
				linksCount++;
			}
			if (marks.find((mark) => mark.type === link)) {
				linksCount++;
			}
		});

		return {
			nodeCounts: Object.fromEntries(nodeCounts),
			markCounts: Object.fromEntries(markCounts),
			linksCount,
		};
	}

	private calculateCharacterCounts(): {
		totalCharacterCount: number;
	} {
		let allTextContent = '';
		if (this.content instanceof Node) {
			allTextContent = this.content.textContent;
		} else {
			this.content.forEach((child) => {
				allTextContent += child.textContent;
			});
		}
		return {
			totalCharacterCount: allTextContent.length,
		};
	}
}
