// import React from 'react';

import { type EditorAgent } from '@atlassian/generative-ai-modal/utils/agents';

import { CONFIG_ITEM_KEYS } from '../../config-items/config-item-keys';
import { createEditorPluginAIConfigItemMarkdown } from '../../config-items/config-items';
import { type EditorPluginAIProvider } from '../../types';
import { createContinueInChatForAgent } from '../config-item-actions/chat';
import { createInsertAtCurrentPosition, createReplace } from '../config-item-actions/markdown';

import { messages } from './messages';

// import { AgentIcon } from '../assets/icons/agent-icon/AgentIcon';

export const createAgentConfigItem = <T extends 'empty' | 'range'>(
	selectionType: T,
	agent: EditorAgent,
	editorPluginAIProvider: EditorPluginAIProvider,
) => {
	const continueInChat = createContinueInChatForAgent({
		appearance: 'secondary',
		agent,
		product: editorPluginAIProvider.product,
		actionSideEffect: editorPluginAIProvider.actionSideEffects?.['continueInChat'],
		actionOverride: editorPluginAIProvider.actionOverrides?.['continueInChat'],
	});

	return createEditorPluginAIConfigItemMarkdown({
		key: CONFIG_ITEM_KEYS.ROVO_AGENT,
		title: messages.title,
		description: messages.description,
		selectionType,
		intentSchemaId: 'DISABLED',
		agent,
		// Required, since hasInitialPreset guard in palette state machine
		// checks this property for whether to transition to 'preset label'
		// (agent palette mode) during initialisation.
		promptLabel: {
			defaultMessage: agent?.name,
			id: `fabric.editor.ai.config.item.${selectionType}.agent.${agent?.name.toLocaleLowerCase().replace(/\s+/g, '.') || 'label'}`,
		},
		promptHint: messages.promptHint,
		isVisible: () => true,
		getBackendModel: () => 'assistance-service',
		statusLozengeType: 'beta',
		disableInterrogation: () => false,
		actions:
			selectionType === 'empty'
				? [
						continueInChat,
						createInsertAtCurrentPosition({ appearance: 'primary', isRovoAgentAction: true }),
					]
				: [continueInChat, createReplace({ appearance: 'primary', isRovoAgentAction: true })],
		secondaryActions: [],
	});
};
