/* GeneratedContentHash: 1c81eea795b65db22117af2640553e44a6199a829d731e2f582d4993abefb68b */
/*
# This file was automatically generated using "yarn generate:all" command.
# Please do not edit it manually.
# These exports are for client side use only
# Importing into post office backend without a bundler will break the build
#
# Generate source: local/cli/src/commands/generate-all/team-export.ts
*/
import { lazy } from "react";

export default {
	'marketing-screen-space-flag-contentful-usecase': lazy(
			() =>
			import(
				/* webpackChunkName: "[request]" */
				'@post-office/message-template--marketing-screen-space-flag-contentful-usecase/placements/in-app/screen-space-flags/render'
			),
	),
};
