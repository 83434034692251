import { type OptionType } from '@atlaskit/select';
import { type Value } from '@atlaskit/smart-user-picker';
import {
	type Agent,
	type AgentAction,
	type AgentKnowledgeConfiguration,
} from '@atlassian/conversation-assistant-service';

export type ConfluenceFilterSelectType = OptionType & {
	value: 'all' | 'spaces' | 'pages';
};

export type KnowledgeFilterSelectType = OptionType & {
	ari: string;
	iconUrl?: string;
	renderIcon?: () => React.ReactNode;
	spaceKey?: string;
	url: string;
};

export type AgentFormFields = {
	name: string;
	description: string;
	instructions: string;
	conversationStarters: string[];
	agentActions: AgentAction[];
	creator: Value;
	knowledgeScope: boolean;
	confluenceFilterScope?: ConfluenceFilterSelectType;
	confluenceAncestorPageFilters?: KnowledgeFilterSelectType[];
	confluenceSpaceFilters?: KnowledgeFilterSelectType[];
	jiraProjectFilters?: KnowledgeFilterSelectType[];
	knowledgeEnabledFields: KnowledgeEnabledFields;
};

type KnowledgeEnabledFields = {
	[K in AgentKnowledgeConfiguration['source']]?: boolean;
};

export type ValidAgentFormFields = keyof AgentFormFields;

export const isSystemAgent = (agent: Agent) => agent.creator_type === 'SYSTEM';
export const isCustomerAgent = (agent: Agent) => agent.creator_type === 'CUSTOMER';
// BE will deprecate THIRD_PARTY and use FORGE instead
export const isForgeAgent = (agent: Agent) =>
	agent.creator_type === 'THIRD_PARTY' || agent.creator_type === 'FORGE';
export const isPrivateAgent = (agent: Agent) => agent.visibility === 'PRIVATE';
