import gql from 'graphql-tag';

import { WhiteboardTokenFragment } from './WhiteboardToken.fragment';

/*comment to invalidate cache*/
export const WhiteboardUnifiedQuery = gql`
	query WhiteboardUnifiedQuery(
		$contentId: ID!
		$spaceKey: String
		$includeAlias: Boolean = false
		$contentVersion: Int!
	) {
		content(id: $contentId) {
			nodes {
				id
				referenceId
				title
				operations {
					operation
					targetType
				}
				hasRestrictions
				hasViewRestrictions
				hasInheritedRestrictions
				space {
					id
					name
					key
					type
					alias @include(if: $includeAlias)
				}
				type
				status
				ancestors {
					id
					type
					title
					status
				}
				metadata {
					currentuser {
						favourited {
							isFavourite
						}
					}
				}
			}
		}
		spaces(spaceKey: $spaceKey, first: 10) {
			nodes {
				id
				name
				homepage {
					id
				}
			}
		}
		user(current: true) {
			id
			confluence {
				space {
					id
				}
			}
		}
		paywallStatus(id: $contentId)

		...WhiteboardTokenFragment

		dataSecurityPolicy {
			isActionEnabledForContent(
				action: PAGE_EXPORT
				contentId: $contentId
				contentStatus: CURRENT
				spaceKey: $spaceKey
				contentVersion: $contentVersion
			) {
				allowed
			}
		}
	}
	${WhiteboardTokenFragment}
`;
