import { FormattedMessage } from 'react-intl-next';
import React from 'react';

import type { CreateUIAnalyticsEvent } from '@atlaskit/analytics-next/types';

import { CREATE_WHITEBOARD_EXPERIENCE, ExperienceTimeout } from '@confluence/experience-tracker';
import type { ExperienceTrackerAPI } from '@confluence/experience-tracker';
import type { FlagsStateContainer } from '@confluence/flags';
import { markErrorAsHandled } from '@confluence/graphql';
import { getTraceIdFromApolloError } from '@confluence/template-utils';
import { getMonitoringClient } from '@confluence/monitoring';
import { Attribution } from '@confluence/error-boundary';

import { messages } from './messages';
import { getExpectedError } from './error-handling';
import { createWhiteboardOverGraphQL } from './create-whiteboard';
import {
	CREATED_FROM_SLUG,
	INSERT_TEMPLATE_WITH_KEY_QUERY_PARAM,
} from '@confluence/whiteboard-utils';
import { EDIT_PAGE_V2 } from '@confluence/named-routes';

export const createWhiteboardQuickInsertModule = (
	pageId: string,
	spaceId: string,
	spaceKey: string,
	experienceTracker?: ExperienceTrackerAPI,
	createAnalyticsEvent?: CreateUIAnalyticsEvent,
	flags?: FlagsStateContainer,
	templateKey?: string,
) => {
	return async () => {
		try {
			const instrumentedTemplateKey = templateKey ?? 'blank';

			experienceTracker?.start({
				name: CREATE_WHITEBOARD_EXPERIENCE,
				timeout: ExperienceTimeout.CREATE_CONTENT,
				attributes: { flow: 'inlinePageCreation', template: instrumentedTemplateKey },
			});

			// call API to create whiteboard
			const fullUrl = await createWhiteboardOverGraphQL(
				pageId,
				spaceId,
				spaceKey,
				instrumentedTemplateKey,
				createAnalyticsEvent,
			);
			// allow user to navigate directly to whiteboard
			await flags?.showFlag({
				type: 'success-circle',
				title: <FormattedMessage {...messages.successFlagTitle} />,
				actions: [
					{
						content: <FormattedMessage {...messages.successFlagAction} />,
						href: fullUrl,
					},
				],
				description: <FormattedMessage {...messages.successFlagDescription} />,
			});

			experienceTracker?.succeed({ name: CREATE_WHITEBOARD_EXPERIENCE });

			if (templateKey) {
				const url = new URL(fullUrl);
				const originatingUrl = EDIT_PAGE_V2.toUrl({
					contentType: 'pages',
					spaceKey,
					contentId: pageId,
				});

				// for applying a specific template once opened
				url.searchParams.set(INSERT_TEMPLATE_WITH_KEY_QUERY_PARAM, templateKey);
				// for reconstructing the URL on the other side
				url.searchParams.set(CREATED_FROM_SLUG, originatingUrl);

				window.open(url, '_blank', 'noreferrer, noopener');
			}

			return { type: 'embedCard', attrs: { url: fullUrl } };
		} catch (error) {
			const expectedErrorMessage = getExpectedError(error);
			if (expectedErrorMessage) {
				await flags?.showErrorFlag({
					title: <FormattedMessage {...expectedErrorMessage.title} />,
					description: <FormattedMessage {...expectedErrorMessage.description} />,
				});

				experienceTracker?.succeed({
					name: CREATE_WHITEBOARD_EXPERIENCE,
					attributes: { abortReason: 'expectedErrorMessage' },
				});
			} else {
				const monitoringClient = getMonitoringClient();
				monitoringClient.submitError(error, {
					attribution: Attribution.WHITEBOARDS,
				});

				await flags?.showErrorFlag({
					title: <FormattedMessage {...messages.whiteboardDefaultErrorFlagTitle} />,
					description: <FormattedMessage {...messages.whiteboardDefaultErrorFlagDescription} />,
				});

				experienceTracker?.stopOnError({
					name: CREATE_WHITEBOARD_EXPERIENCE,
					error,
					attributes: { cteTraceId: getTraceIdFromApolloError(error) },
				});
			}
			markErrorAsHandled(error);
		}
	};
};
