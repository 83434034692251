import React from 'react';
import { defineMessages, useIntl } from 'react-intl-next';

import { Text, Inline } from '@atlaskit/primitives';

import { RefreshIcon } from '../base-components/assets/RefreshIcon';

const i18n = defineMessages({
	refreshesDailyFooterText: {
		id: 'admin-center.group-summary-block.data-refreshes-daily',
		defaultMessage: 'Refreshes daily',
		description: 'Text stating that the data is refreshed daily',
	},
});

export const DailyRefreshComponent = ({ fontSize }: { fontSize: 'small' | 'medium' }) => {
	const { formatMessage } = useIntl();

	return (
		<Inline alignBlock="center">
			<RefreshIcon />
			<Text size={fontSize} color="color.text.subtle">
				{formatMessage(i18n.refreshesDailyFooterText)}
			</Text>
		</Inline>
	);
};
