import type { Schema as PMSchema } from '@atlaskit/editor-prosemirror/model';

import { convoAIStatelessApiURL } from '../../prebuilt/utils';
import type { EditorPluginAIPromptRequestMarkdown } from '../../types';
import { startStreamingResponse } from '../start-streaming-response';

import type {
	IntentSchemaId,
	IntentSchemaIdWithCustomPrompt,
	PromptRequestOptions,
	TranslateIntentSchema,
} from './types';
import { getSupportedMarkdowns } from './utils/get-supported-markdown-from-schema';
import { streamResponseParser } from './utils/stream-response-parser';

export type CommonConvoAIInput = {
	/** The analytics session ID for current user journey. */
	aiSessionId?: string;
	agentId?: string;
	intentSchemaId: Exclude<IntentSchemaId, IntentSchemaIdWithCustomPrompt | TranslateIntentSchema>;
	editorSchema: PMSchema;
	currentSelection?: string;
	fullDocument?: string;
	userLocale: string;
	currentUrl?: string;
	draftSelectedContentOverride?: string;
};

type ConvoAIInputWithCustomPrompt = Omit<CommonConvoAIInput, 'intentSchemaId'> & {
	intentSchemaId: IntentSchemaIdWithCustomPrompt;
	customPrompt: string;
};

type ConvoAITranslateInput = Omit<CommonConvoAIInput, 'intentSchemaId'> & {
	intentSchemaId: TranslateIntentSchema;
	transformationSubtype: string;
};

type ConvoAIInput = CommonConvoAIInput | ConvoAIInputWithCustomPrompt | ConvoAITranslateInput;

const sanitizeUrl = (url: string) => url.replace(/[^\w\-/.:%?=]+/g, '');

/**
 * A function that will fetch the response from the Convo AI API.
 *
 * @see https://hello.atlassian.net/wiki/spaces/EDAI/pages/3416730365/API+Specs+assistance-service
 *
 * @param input The input to be sent to the Convo AI API.
 * @param customEndpoint A custom endpoint to use for the request instead of the default Convo AI endpoint. It could be
 * 											 a platform specific middleware that will forward the request to the Convo AI API.
 */
export const streamConvoAI = (
	input: ConvoAIInput,
	customEndpoint?: string,
): EditorPluginAIPromptRequestMarkdown => {
	return function promptRequest(requestOptions: PromptRequestOptions) {
		/**
		 * If the current selection is the same as the full document we won't
		 * send it to the backend as it will get it from the full document
		 */

		let draft_selected_content = input.currentSelection;
		if ('draftSelectedContentOverride' in input && input.draftSelectedContentOverride) {
			draft_selected_content = input.draftSelectedContentOverride;
		} else if (input.fullDocument === input.currentSelection) {
			draft_selected_content = undefined;
		}

		const streamingResponseGenerator = startStreamingResponse({
			endpoint: customEndpoint || convoAIStatelessApiURL,
			payload: JSON.stringify({
				recipient_agent_named_id: input.agentId || 'editor_intent_agent',
				agent_input_prompt:
					'customPrompt' in input && input.customPrompt ? input.customPrompt : undefined,
				agent_input_context: {
					ai_session_id: input.aiSessionId,
					content_url: sanitizeUrl(input.currentUrl || document.location.href),
					writing_intent: input.intentSchemaId,
					draft_full_content: input.fullDocument,
					draft_selected_content,
					supported_markdown_nodes: getSupportedMarkdowns(input.editorSchema),
					input_mime_type: 'text/markdown',
					output_mime_type: 'text/markdown',
					locale: input.userLocale,
					product: requestOptions.product,
					transformation_subtype:
						'transformationSubtype' in input && input.transformationSubtype
							? input.transformationSubtype
							: undefined,
				},
			}),
			...requestOptions,
		});

		return streamResponseParser({
			streamingResponseGenerator,
			isConvoAI: true,
		});
	};
};
