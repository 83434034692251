import React from 'react';

const SuggestedEditIcon = () => (
	<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path
			d="M12.75 9L13.8661 7.88388C14.3543 7.39573 14.3543 6.60427 13.8661 6.11612L13.1339 5.38388C12.6457 4.89573 11.8543 4.89573 11.3661 5.38388L10.25 6.5M12.75 9L8.72904 13.021C8.57883 13.1712 8.39281 13.2806 8.18856 13.339L5 14.25L5.91102 11.0614C5.96937 10.8572 6.07883 10.6712 6.22904 10.521L10.25 6.5M12.75 9L10.25 6.5"
			stroke="#44546F"
			strokeWidth="1.5"
			strokeLinejoin="round"
		/>
		<path
			d="M4.5 1.75L5.27 3.73L7.25 4.5L5.27 5.27L4.5 7.25L3.73 5.27L1.75 4.5L3.73 3.73L4.5 1.75Z"
			fill="#44546F"
			stroke="#44546F"
			strokeWidth="1.5"
			strokeLinejoin="round"
		/>
	</svg>
);
export default SuggestedEditIcon;
