import React, { useCallback, useState } from 'react';

import { useIntl } from 'react-intl-next';

import { IconButton } from '@atlaskit/button/new';
import DropdownMenu, { DropdownItem, DropdownItemGroup } from '@atlaskit/dropdown-menu';
import type { EditorView } from '@atlaskit/editor-prosemirror/view';
import Heading from '@atlaskit/heading';
import CrossIcon from '@atlaskit/icon/core/close';
import CoreMoreIcon from '@atlaskit/icon/core/show-more-horizontal';
import Lozenge from '@atlaskit/lozenge';
import { fg } from '@atlaskit/platform-feature-flags';
import { Inline, xcss } from '@atlaskit/primitives';

import type { EditorPluginAIProvider } from '../types';

import messages from './messages';
import { SuggestionSetting } from './setting/suggestion-setting';

const headingStyles = xcss({
	flex: 1,
});

type HeadingItemProps = {
	onClose: () => void;
	editorPluginAIProvider: EditorPluginAIProvider;
	editorView: EditorView;
};
const toolTipProps: React.ComponentProps<typeof IconButton>['tooltip'] = { position: 'top' };

export const HeadingItem = (props: HeadingItemProps) => {
	const { formatMessage } = useIntl();
	const { onClose, editorView } = props;
	const closeTooltip = formatMessage(messages.closeButtonLabel);
	const { handleFeedbackSubmission, product } = props.editorPluginAIProvider;

	const [isOpen, setIsOpen] = useState(false);
	const toggleModal = useCallback(() => setIsOpen(!isOpen), [isOpen]);

	return (
		<Inline space="space.100" alignBlock="center">
			<Inline xcss={headingStyles} space="space.100" alignBlock="center">
				<Heading size="medium" color="color.text">
					{formatMessage(messages.suggestedEditsTitle)}
				</Heading>
				<Lozenge>{formatMessage(messages.betaLabel)}</Lozenge>
				{fg('platform_editor_ai_context_panel_more_menu') && (
					<>
						<DropdownMenu<HTMLButtonElement>
							trigger={({ triggerRef, ...props }) => (
								<IconButton
									{...props}
									appearance="subtle"
									label={formatMessage(messages.moreMenuLabel)}
									icon={CoreMoreIcon}
									ref={triggerRef}
								/>
							)}
							shouldRenderToParent
							placement="bottom-end"
						>
							<DropdownItemGroup>
								<DropdownItem
									onClick={() => {
										toggleModal();
									}}
								>
									{formatMessage(messages.moreMenuManageSuggestions)}
								</DropdownItem>

								<DropdownItem
									onClick={() => {
										if (!handleFeedbackSubmission) {
											return;
										}
										handleFeedbackSubmission({
											sentiment: 'recommendation-feedback',
											editorAttributes: { product },
										});
									}}
								>
									{formatMessage(messages.moreMenuGiveFeedback)}
								</DropdownItem>
							</DropdownItemGroup>
						</DropdownMenu>
						<SuggestionSetting isOpen={isOpen} toggleModal={toggleModal} editorView={editorView} />
					</>
				)}
			</Inline>
			<IconButton
				appearance="subtle"
				label={closeTooltip}
				icon={CrossIcon}
				onClick={onClose}
				isTooltipDisabled={false}
				tooltip={toolTipProps}
			/>
		</Inline>
	);
};
