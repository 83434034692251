import { Decoration } from '@atlaskit/editor-prosemirror/view';

import { type DecorationTransformerParams, ProactiveDecorations } from './index';

const createDecoration = (from: number, to: number, id: string) =>
	Decoration.inline(
		from,
		to,
		{
			nodeName: 'span',
			class: `ai-proactive-recommendation`,
			id: `ai-proactive-${id}`,
			'data-testid': `ai-proactive-recommendation`,
		},
		{
			key: ProactiveDecorations.RECOMMENDATION,
			blockId: id,
		},
	);

export const updateRecommendationDecoration = ({
	decorationSet,
	tr,
	pluginState,
}: DecorationTransformerParams) => {
	const { proactiveAIBlocks } = pluginState;
	const allBlocksWithRecommendations = proactiveAIBlocks.filter(
		(block) => !!block?.recommendations?.length,
	);

	// NOTE: we only want 1 decoration per block regardless of how many recommendations there are.
	const currentIds = allBlocksWithRecommendations.map((block) => block.id);
	const existingIds: Set<string> = new Set();

	// First step: remove decorations for diffObjects that does not exist any more.
	// For performance reasons, we will build existingDecorationDiffObjectIds here as well.
	const decorationsToRemove = decorationSet.find(undefined, undefined, (spec) => {
		if (spec.key !== ProactiveDecorations.RECOMMENDATION) {
			return false;
		}
		existingIds.add(spec.blockId);
		return !currentIds.includes(spec.blockId);
	});
	decorationSet = decorationSet.remove(decorationsToRemove);

	// Second step: map existing decorations set with tr.mapping
	decorationSet = decorationSet.map(tr.mapping, tr.doc);

	// Third step: create new decorations for new diffObjects.
	let decorations = allBlocksWithRecommendations?.reduce<Decoration[]>((acc, { id, from, to }) => {
		if (!existingIds.has(id)) {
			acc.push(createDecoration(from, to, id));
		}
		return acc;
	}, []);

	// Fourth Step: If there are new decorations then add it in decorationsSet
	if (!!decorations?.length) {
		return decorationSet.add(tr.doc, decorations);
	}

	return decorationSet;
};
