import { fg } from '@atlaskit/platform-feature-flags';

import type { EditorPluginAIProvider, ProactiveAIConfig } from '../../types';

export const getConfiguration = (
	editorPluginAIProvider: EditorPluginAIProvider,
): {
	isProactiveAISupported: boolean;
	proactiveAIConfig: ProactiveAIConfig;
} => {
	// NOTE: We need to ensure the feature gate is evaluated last. This is beacuse if the product hasn't enabled
	// the feature then we don't want to hit the gate check since it's not possible for that product to have
	// the feature.
	let isProactiveAISupported =
		!!editorPluginAIProvider.isFullPageExperimentsEnabled &&
		!!editorPluginAIProvider.proactiveAIConfig?.apiUrl &&
		!!editorPluginAIProvider.proactiveAIConfig?.enabled &&
		!!fg('platform_editor_ai_proactive_spelling_and_grammar');

	let proactiveAIConfig = editorPluginAIProvider.proactiveAIConfig;

	if (isProactiveAISupported) {
		proactiveAIConfig = {
			...proactiveAIConfig,
			defaultToggledState: true,
			documentChecker: proactiveAIConfig?.documentChecker
				? {
						...proactiveAIConfig?.documentChecker,
						enabled: false,
					}
				: undefined,
		};
	}

	return {
		isProactiveAISupported,
		proactiveAIConfig,
	};
};
