import type { AnyEventObject } from 'xstate';

import { Fragment, type Node as PMNode } from '@atlaskit/editor-prosemirror/model';
import { fg } from '@atlaskit/platform-feature-flags';
import { editorExperiment } from '@atlaskit/tmp-editor-statsig/experiments';
import { convertMarkdownToProsemirror } from '@atlassian/ai-model-io/convert-markdown-to-prosemirror';
import { checkAssistanceServiceFreeGenerateFg } from '@atlassian/editor-ai-common/utils/check-assistance-service-fg';

import { CONFIG_ITEM_KEYS } from '../config-items/config-item-keys';
import {
	type EditorPluginAIConfigItemMarkdown,
	isInitialContextSelectionRange,
	type Positions,
	type GetInitialContext,
	type TriggerPromptRequest,
} from '../config-items/config-items';
import { sliceOrNodeToMarkdown } from '../config-items/slice-or-node-to-markdown';
import { streamConvoAI } from '../provider/prompt-requests/convo-ai';
import { elevateFreeGenerateDisabled } from '../provider/prompt-requests/elevate';
import { streamIssueReformatterConvoAI } from '../provider/prompt-requests/issue-reformatter-convo-ai';
import { rovoChatWithAgent } from '../provider/prompt-requests/rovo-chat';
import type { ContextList, IntentSchemaId } from '../provider/prompt-requests/types';
import { streamXPGenAI } from '../provider/prompt-requests/xp-gen-ai';
import { getMentionMap, type MentionMap } from '../utils/mentions/get-mention-map';

import type { AIExperienceMachineContext } from './get-ai-experience-service';
import { FAILURE_REASON } from './screens-with-logic/utils/errors';

// if there is no selection, we want to pass the full document
// if there is selection, we want to pass the selection
function getInitialContext({
	context,
	configItem,
}: {
	context: AIExperienceMachineContext;
	configItem: EditorPluginAIConfigItemMarkdown;
}): GetInitialContext {
	const { key, selectionType, intentSchemaId: getIntentSchemaId } = configItem;
	const intentSchemaId =
		typeof getIntentSchemaId === 'function'
			? getIntentSchemaId({ latestPromptResponse: context.latestPromptResponse })
			: getIntentSchemaId;

	return ({ editorView, positions, intl, updateIdMap, mentionMap }) => {
		// right now, selectionType is not accurate, some prompts use 'range' for both empty and range, so we use positions to determine if it is empty
		const isEmpty = positions[0] === positions[1];

		const selection = sliceOrNodeToMarkdown({
			slice: editorView.state.doc.slice(positions[0], positions[1]),
			editorView,
			convertTo: 'markdown-plus',
			updateIdMap,
			selectionType,
			mentionMap,
		});

		const document = sliceOrNodeToMarkdown({
			node: editorView.state.doc,
			editorView,
			convertTo: 'markdown-plus',
			updateIdMap,
			selectionType,
			mentionMap,
		});

		// for Jira Improve Description
		// the previous implementation is always send document and selection
		// but selection isn't used in streamIssueReformatterConvoAI, so we don't send selection here
		if (key === CONFIG_ITEM_KEYS.ADD_STRUCTURE) {
			return {
				document: document.markdown,
				userLocale: intl.locale,
				intentSchemaId,
				contentStatistics: document.contentStatistics,
			};
		}

		// for Brainstorm, Jira Draft reply, don't send document (we have history feedback that LLM was using the document too much)
		// use intentSchemaId here just in case there will be other prompts that use the same intentSchemaId in the future
		if (intentSchemaId === 'brainstorm_intent_schema.json') {
			return {
				userLocale: intl.locale,
				intentSchemaId,
				document: '',
			};
		}

		// for rovo agent
		// the previous implementation is always send document and selection for agent, so keep both
		if (configItem.agent) {
			return {
				document: document.markdown,
				selection: selection.markdown,
				userLocale: intl.locale,
				intentSchemaId: 'DISABLED',
			};
		}

		return isEmpty
			? {
					document: document.markdown,
					userLocale: intl.locale,
					intentSchemaId,
					contentStatistics: document.contentStatistics,
				}
			: {
					selection: selection.markdown,
					// We need to also send the document here as it is required by free generate prompts
					// for platform_editor_ai_knowledge_from_current_page where the user has the option
					// to choose whether to use page knowledge in their prompt
					document:
						configItem.canTogglePageKnowledge &&
						context.usePageKnowledge &&
						editorExperiment('platform_editor_ai_knowledge_from_current_page', true)
							? document.markdown
							: undefined,
					userLocale: intl.locale,
					intentSchemaId,
					contentStatistics: selection.contentStatistics,
				};
	};
}

/**
 * Additional context used in prompt request
 * do no duplicate this in each config item, or it will end like getInitialContext
 */
function getAdditionalContext({
	configItem,
	intentSchemaId,
	latestPromptResponse,
	promptInput,
}: {
	configItem: EditorPluginAIConfigItemMarkdown;
	intentSchemaId: IntentSchemaId;
	latestPromptResponse?: string;
	promptInput?: string;
}): {
	// ConvoAIInputWithCustomPrompt
	// XPGenAIInputWithCustomPrompt
	customPrompt?: string;

	// XPGenAIInputWithCustomPrompt
	draftSelectedContentOverride?: string;

	// ConvoAITranslateInput
	transformationSubtype?: string;
	// XPGenAITranslateInput
	targetTranslationLanguage?: string;
} {
	const { lang } = configItem;

	return {
		// free-generate, free-generate interrogation, Brainstorm, Jira Draft reply
		customPrompt: promptInput,

		// free-generate interrogation, refine response
		draftSelectedContentOverride: latestPromptResponse,

		// translate xp-gen-ai
		targetTranslationLanguage: lang,
		// translate convo-ai
		transformationSubtype: lang,
	};
}

const keyList4XPGenAI: CONFIG_ITEM_KEYS[] = [
	// Brainstorm
	CONFIG_ITEM_KEYS.BRAINSTORM,
	// Find action items
	CONFIG_ITEM_KEYS.FIND_ACTION_ITEMS,
	// Suggest a title
	CONFIG_ITEM_KEYS.SUGGEST_A_TITLE,
	// Summarize writing
	CONFIG_ITEM_KEYS.SUMMARISE_WRITING,
	// Improve writing
	CONFIG_ITEM_KEYS.IMPROVE_WRITING,
	// Fixing Spelling & Grammar
	CONFIG_ITEM_KEYS.FIX_SPELLING_AND_GRAMMAR,
	// Make shorter
	CONFIG_ITEM_KEYS.MAKE_SHORTER,
];

/**
 * key is more accurate than intentSchemaId for deciding whether to use xp-gen-ai or convo-ai
 */
function shouldUseXPGenAI({
	configItem,
	intentSchemaId,
	latestPromptResponse,
}: {
	configItem: EditorPluginAIConfigItemMarkdown;
	intentSchemaId: IntentSchemaId;
	latestPromptResponse: string | undefined;
}) {
	const { key } = configItem;

	// bbc Create pull request description
	if (key === CONFIG_ITEM_KEYS.CREATE_PULL_REQUEST_DESCRIPTION) {
		return !fg('platform_editor_ai_assistance_service_pr_descrp_bb');
	}

	// free-generate and free-generate interrogation
	// this whole function will be removed when xp-gen-ai is disabled, so we don't need to optimize the multiple if here
	if (key === CONFIG_ITEM_KEYS.FREE_GENERATE) {
		if (!latestPromptResponse && checkAssistanceServiceFreeGenerateFg()) {
			return false;
		}
		if (latestPromptResponse && fg('platform_editor_ai_interrogate_with_convo_ai')) {
			return false;
		}
		return true;
	}

	const canUseXPGenAI =
		// Change tone
		key.startsWith('Change tone to ') ||
		// Translate
		key.startsWith('Translate to ') ||
		keyList4XPGenAI.includes(key);

	if (canUseXPGenAI) {
		return !fg('platform_editor_ai_assistance_service');
	}

	return false;
}

function getXPGenAIContextList({
	configItem,
	intentSchemaId,
	latestPromptResponse,
	document,
	selection,
}: {
	configItem: EditorPluginAIConfigItemMarkdown;
	intentSchemaId: IntentSchemaId;
	latestPromptResponse: string | undefined;
	document?: string;
	selection?: string;
}): ContextList {
	if (intentSchemaId === 'brainstorm_intent_schema.json') {
		return [{ type: 'text', entity: '', relationship: undefined }];
	} else if (
		intentSchemaId === 'custom_with_interrogation_intent_schema.json' &&
		latestPromptResponse
	) {
		return [
			{
				type: 'ADF_MARKDOWN_V1',
				entity: latestPromptResponse,
				relationship: 'PREVIOUS_RESPONSE',
			},
		];
	} else if (intentSchemaId === 'suggest_title_intent_schema.json') {
		if (document) {
			return [
				{
					type: 'text',
					entity: document,
					relationship: 'FULL_CONTENT',
				},
			];
		}
		if (selection) {
			return [
				{
					type: 'text',
					entity: selection,
					relationship: 'SELECTION',
				},
			];
		}
	}

	// relationship is based on selectionType
	// document/selection are based on whether positions[0] === positions[1] because selectionType is not accurate
	if (configItem.selectionType === 'empty') {
		return [
			{
				type: 'ADF_MARKDOWN_V1',
				entity: document || selection || '',
				relationship: 'FULL_CONTENT',
			},
		];
	} else {
		return [
			{
				type: 'ADF_MARKDOWN_V1',
				entity: document || selection || '',
				relationship: 'SELECTION',
			},
		];
	}
}

function getPromptRequest({
	context,
	configItem,
	positions,
}: {
	context: AIExperienceMachineContext;
	configItem: EditorPluginAIConfigItemMarkdown;
	positions: Positions;
}): TriggerPromptRequest {
	const { key, agent } = configItem;

	return ({
		analyticsContext,
		initialContext,
		latestPromptResponse,
		promptInput,
		promptInputADF,
		editorSchema,
	}) => {
		// right now, selectionType is not accurate, some prompts use 'range' for both empty and range, so we use positions to determine if it is empty
		const isEmpty = positions[0] === positions[1];
		const intentSchemaId = initialContext.intentSchemaId;

		if (key === CONFIG_ITEM_KEYS.FREE_GENERATE && intentSchemaId === 'DISABLED') {
			return elevateFreeGenerateDisabled;
		}

		const additionalContext = getAdditionalContext({
			configItem,
			intentSchemaId,
			latestPromptResponse,
			promptInput,
		});

		if (shouldUseXPGenAI({ configItem, intentSchemaId, latestPromptResponse })) {
			return streamXPGenAI(
				{
					...additionalContext,
					customPrompt: additionalContext?.customPrompt,
					userLocale: initialContext.userLocale,
					intentSchemaId: intentSchemaId as any,
					editorSchema,
					contextList: getXPGenAIContextList({
						configItem,
						intentSchemaId,
						latestPromptResponse,
						document: initialContext.document,
						selection: 'selection' in initialContext ? initialContext.selection : undefined,
					}),
				},
				configItem.endpoint,
			);
		}

		if (key === CONFIG_ITEM_KEYS.ADD_STRUCTURE) {
			return streamIssueReformatterConvoAI({
				aiSessionId: analyticsContext?.aiSessionId,
				userLocale: initialContext.userLocale,
				editorSchema,
				fullDocument: initialContext.document,
				additionalPayload: {
					...configItem.getAdditionalContext?.(),
				},
			});
		}

		if (agent) {
			const fullDocument =
				!context.usePageKnowledge &&
				editorExperiment('platform_editor_ai_knowledge_from_current_page', true)
					? undefined
					: initialContext.document;

			return rovoChatWithAgent({
				agent,
				customPrompt: promptInput || '',
				customPromptADF: promptInputADF,
				fullDocument,
				currentSelection: isInitialContextSelectionRange(initialContext)
					? initialContext.selection
					: '',
			});
		}

		// Determine fullDocument based on selection and page knowledge
		// if there is no selection, we want to pass the full document
		// if there is selection, we want to pass the selection
		let fullDocument = isEmpty ? initialContext.document : undefined;
		if (editorExperiment('platform_editor_ai_knowledge_from_current_page', true)) {
			const shouldUsePageKnowledge = configItem.canTogglePageKnowledge && context.usePageKnowledge;

			fullDocument = shouldUsePageKnowledge ? initialContext.document : undefined;
		}

		// we can add some general flag to indicate that we don't want to pass the document/selection in future for some prompts
		// if (noDocAndSelection) {
		// 	fullDocument = undefined;
		// 	currentSelection = undefined;
		// }

		// Determine currentSelection based on selection status
		let currentSelection;
		if (!isEmpty && 'selection' in initialContext) {
			currentSelection = initialContext.selection;
		}

		if (!fullDocument) {
			fullDocument = undefined;
		}

		if (!currentSelection) {
			currentSelection = undefined;
		}

		return streamConvoAI(
			{
				...additionalContext,
				aiSessionId: analyticsContext?.aiSessionId,
				userLocale: initialContext.userLocale,
				agentId: configItem.agentId,
				intentSchemaId: intentSchemaId as any,
				editorSchema,
				fullDocument,
				currentSelection,
			},
			configItem.endpoint,
		);
	};
}

function getInitialContextAndPromptRequest({
	callback,
	context,
	configItem,
	mentionMap,
	positions,
}: {
	callback: (event: AnyEventObject) => void;
	context: AIExperienceMachineContext;
	configItem: EditorPluginAIConfigItemMarkdown;
	mentionMap: MentionMap;
	positions: Positions;
}) {
	const initialContext = getInitialContext({ context, configItem })({
		editorView: context.editorView,
		positions,
		intl: context.intl,
		updateIdMap: ({ idMap, selectionType }) => {
			callback({ type: 'update idMap', context: { idMap, selectionType } });
		},
		mentionMap,
	});

	if (initialContext?.contentStatistics) {
		callback({
			type: 'storeContextStatistics',
			contextStatistics: { inputDocument: initialContext.contentStatistics },
		});
	}

	if (!initialContext) {
		throw new Error('Initial context is undefined');
	}

	const promptRequest = getPromptRequest({
		context,
		configItem,
		positions,
	})({
		analyticsContext: context.analyticsContext,
		initialContext,
		promptInput: context.userInput,
		promptInputADF: context.userADFInput,
		latestPromptResponse: context.latestPromptResponse || undefined,
		formatMessage: context.intl.formatMessage,
		editorSchema: context.editorView.state.schema,
	});

	return { initialContext, promptRequest };
}

/**
 * This is responsible for processing the parsed data from
 * streamResponseParser() and passing data into the
 * AIExperienceMachine over in createAIExperienceMachine().
 */
export function streamingService(context: AIExperienceMachineContext) {
	async function callbackHandler(callback: (event: AnyEventObject) => void) {
		const abortController = new AbortController();

		/**
		 * Vary prompt request triggered - if latest response exists, we are interrogating,
		 * and do not want to use the original config item (i.e. may possibly be summarise/transform)
		 *
		 * If it is a generate with tag, the response will be captured within
		 * the latest "latest response, prompt", flow.
		 *
		 * REVISIT THIS: analytics is based on the config item, however
		 * interrogation analytics uses base generate instead to complete;
		 */
		let configItemToUse = context.configItem;
		if (!editorExperiment('platform_editor_ai_refine_response_button', true)) {
			if (context.baseGenerate && context.latestPromptResponse) {
				// If there is a latestPromptResponse and the context.configItem has a key 'Rovo Agent',
				// then we should use the context.configItem so we can interrogate with the Rovo Agent
				configItemToUse =
					context.configItem && context.configItem.key === CONFIG_ITEM_KEYS.ROVO_AGENT
						? context.configItem
						: context.baseGenerate;
			}
		}

		/**
		 * Get mention map for the current document
		 * We can no longer use the node attrs to get the mention name
		 * So we now need to get all the mentions and create a mapping that can be used by the
		 * markdown serializer
		 */
		let mentionMap = {};
		if (context.getMentionNameDetails) {
			mentionMap = await getMentionMap({
				node: context.editorView.state.doc,
				getMentionNameDetails: context.getMentionNameDetails,
			});
		}

		const { initialContext, promptRequest } = getInitialContextAndPromptRequest({
			callback,
			context,
			configItem: configItemToUse,
			mentionMap,
			positions: context.positions,
		});

		const cacheKey = JSON.stringify({
			initialContext,
			userInput: context.userInput,
			agent: context.configItem.agent,
		});

		// Right now we only ever have one object in history as we do not
		// yet support traversing the history object array. This is also to
		// prevent us saving large documents into history multiple times.
		const lastHistoryItem = context.responseHistory.entries[0];
		if (
			lastHistoryItem &&
			cacheKey === lastHistoryItem.cacheKey &&
			context.promptTrigger !== 'interrogate'
		) {
			callback({
				type: 'same cache key',
			});
			return;
		}

		const streaming = promptRequest({
			abortController,
			// Note -- when we collapse to a single "prompt request" with "api v2"
			// this would be a good opportunity to group these and pass them as a group
			generativeAIApiUrl: context.aiProvider.generativeAIApiUrl,
			product: context.aiProvider.product,
			getFetchCustomHeaders: context.aiProvider.getFetchCustomHeaders,
			channelId: context.channelId,
		});

		(async function () {
			for await (const streamItem of streaming) {
				if (streamItem.state === 'loaded') {
					const rovoActions = streamItem.data.rovoActions || [];
					let markdown = streamItem.data.content;

					if (rovoActions.length > 0) {
						const { selectionType } = context.configItem;
						const idealSuggestion = selectionType === 'range' ? 'replace' : 'insert';
						const action =
							rovoActions.find((x) => x.data.suggestion === idealSuggestion) ?? rovoActions[0];

						// There is a scenario where we are in selectionType === 'empty'
						// but AI returns replace. In this case, we override the replace
						// suggestion with insert.
						if (selectionType === 'empty' && action.data.suggestion === 'replace') {
							// This should also update the value by reference in rovoActions
							action.data.suggestion = 'insert';
						}
						// The opposite can happen too
						else if (selectionType === 'range' && action.data.suggestion === 'insert') {
							// This should also update the value by reference in rovoActions
							action.data.suggestion = 'replace';
						}

						markdown += `\n\n---\n\n${action.data.content}`;
					}

					// loading done on success
					callback({
						type: 'complete',
						markdown,
						cacheKey,
						modelInput: {
							selection: isInitialContextSelectionRange(initialContext)
								? initialContext.selection
								: undefined,
						},
						inputOutputDiffRatio: streamItem.data.meta?.inputOutputDiffRatio,
						rovoActions,
					});
					continue;
				} else if (streamItem.state === 'failed') {
					//loading done on api failed

					// If the error comes from ConvoAI
					if ('apiName' in streamItem && streamItem.apiName === 'assistance-service') {
						if (
							['RATE_LIMIT', 'OPENAI_RATE_LIMIT_USER_ABUSE'].includes(streamItem.guard) &&
							'retryAfter' in streamItem
						) {
							callback({
								type: 'error',
								retryAfter: streamItem.retryAfter!,
								errorInfo: {
									failureReason: FAILURE_REASON.RATE_LIMITED,
									statusCode: streamItem.statusCode,
									errorKey: streamItem.guard,
									apiName: streamItem.apiName,
									errorContent: 'error' in streamItem ? streamItem.error : undefined,
								},
							});
							continue;
						}

						if (streamItem.guard === 'HIPAA_CONTENT_DETECTED') {
							callback({
								type: 'error',
								errorInfo: {
									failureReason: FAILURE_REASON.HIPAA_CONTENT,
									statusCode: streamItem.statusCode,
									errorKey: streamItem.guard,
									apiName: streamItem.apiName,
									errorContent: 'error' in streamItem ? streamItem.error : undefined,
								},
							});
							continue;
						}

						if (streamItem.guard === 'EXCEEDING_CONTEXT_LENGTH_ERROR') {
							callback({
								type: 'error',
								errorInfo: {
									failureReason: FAILURE_REASON.TOKEN_LIMIT,
									statusCode: streamItem.statusCode,
									errorKey: streamItem.guard,
									apiName: streamItem.apiName,
									errorContent: 'error' in streamItem ? streamItem.error : undefined,
								},
							});
							continue;
						}

						if (streamItem.guard === 'ACCEPTABLE_USE_VIOLATIONS') {
							callback({
								type: 'error',
								cacheKey,
								modelInput: {
									selection: isInitialContextSelectionRange(initialContext)
										? initialContext.selection
										: undefined,
								},
								errorInfo: {
									failureReason: FAILURE_REASON.AUP_VIOLATION,
									statusCode: streamItem.statusCode,
									errorKey: streamItem.guard,
									apiName: streamItem.apiName,
									errorContent: 'error' in streamItem ? streamItem.error : undefined,
								},
							});
							continue;
						}

						// All other errors from ConvoAI
						callback({
							type: 'error',
							errorInfo: {
								failureReason: FAILURE_REASON.API_FAIL,
								statusCode: streamItem.statusCode,
								errorKey: streamItem.guard,
								apiName: streamItem.apiName,
								errorContent: 'error' in streamItem ? streamItem.error : undefined,
							},
						});
						continue;
					}

					if ('reason' in streamItem && streamItem.reason === 'backend-input-guard') {
						if (streamItem.guard === 'INPUT_EXCEEDS_TOKEN_LIMIT') {
							callback({
								type: 'error',
								errorInfo: {
									failureReason: FAILURE_REASON.TOKEN_LIMIT,
									statusCode: streamItem.statusCode,
									errorKey: streamItem.guard,
								},
							});
							continue;
						}
						if (
							streamItem.guard === 'INPUT_TOO_SHORT_TO_SUMMARIZE' ||
							streamItem.guard === 'INPUT_TOO_SHORT_TO_PROCESS'
						) {
							callback({
								type: 'error',
								errorInfo: {
									failureReason: FAILURE_REASON.INPUT_TOO_SHORT,
									errorKey: streamItem.guard,
									statusCode: streamItem.statusCode,
								},
							});
							continue;
						}
					}
					if ('reason' in streamItem && streamItem.reason === 'rate-limited') {
						callback({
							type: 'error',
							retryAfter: streamItem.retryAfter!,
							errorInfo: {
								failureReason: FAILURE_REASON.RATE_LIMITED,
								statusCode: streamItem.statusCode,
							},
						});
						continue;
					}

					if ('reason' in streamItem && streamItem.reason === 'response-too-similar') {
						callback({
							type: 'response too similar',
							cacheKey,
							modelInput: {
								selection: isInitialContextSelectionRange(initialContext)
									? initialContext.selection
									: undefined,
							},
							inputOutputDiffRatio: streamItem.data.meta?.inputOutputDiffRatio,
						});
						continue;
					}

					callback({
						type: 'error',
						errorInfo: {
							failureReason: FAILURE_REASON.API_FAIL,
							statusCode: streamItem.statusCode,
							errorContent: 'error' in streamItem ? streamItem.error : undefined,
						},
					});
				} else if (streamItem.state === 'aup-violation') {
					callback({
						type: 'error',
						cacheKey,
						modelInput: {
							selection: isInitialContextSelectionRange(initialContext)
								? initialContext.selection
								: undefined,
						},
						errorInfo: {
							failureReason: FAILURE_REASON.AUP_VIOLATION,
							statusCode: streamItem.statusCode,
						},
					});
					continue;
				} else {
					callback({
						type: 'stream',
						markdown: streamItem.data.content,
						loadingStatus: streamItem.data.meta?.loadingStatus,
					});
					continue;
				}
			}
		})();

		// Cleanup function
		return () => {
			abortController.abort();
		};
	}

	return callbackHandler;
}

//TODO: AI Button experiment cleanup - platform_editor_ai_ai_button_block_elements
export function streamingServiceAIButton(context: AIExperienceMachineContext) {
	async function callbackHandler(callback: (event: AnyEventObject) => void) {
		const abortController = new AbortController();

		/**
		 * Vary prompt request triggered - if latest response exists, we are interrogating,
		 * and do not want to use the original config item (i.e. may possibly be summarise/transform)
		 *
		 * If it is a generate with tag, the response will be captured within
		 * the latest "latest response, prompt", flow.
		 *
		 * REVISIT THIS: analytics is based on the config item, however
		 * interrogation analytics uses base generate instead to complete;
		 */
		let configItemToUse = context.configItem;
		if (!editorExperiment('platform_editor_ai_refine_response_button', true)) {
			if (context.baseGenerate && context.latestPromptResponse) {
				// If there is a latestPromptResponse and the context.configItem has a key 'Rovo Agent',
				// then we should use the context.configItem so we can interrogate with the Rovo Agent
				configItemToUse =
					context.configItem && context.configItem.key === CONFIG_ITEM_KEYS.ROVO_AGENT
						? context.configItem
						: context.baseGenerate;
			}
		}

		/**
		 * Get mention map for the current document
		 * We can no longer use the node attrs to get the mention name
		 * So we now need to get all the mentions and create a mapping that can be used by the
		 * markdown serializer
		 */
		let mentionMap = {};
		if (context.getMentionNameDetails) {
			mentionMap = await getMentionMap({
				node: context.editorView.state.doc,
				getMentionNameDetails: context.getMentionNameDetails,
			});
		}

		const { triggeredFor, positions, editorView, idMap } = context;
		const { state } = editorView;
		const { schema, doc } = state;
		if (triggeredFor?.isBlock) {
			const nodeTypeName = triggeredFor.name;
			// Summarise, Make shorter and Suggest a title does not need to preserve structure
			//	and will not be replacing block elements.
			if (
				[
					CONFIG_ITEM_KEYS.SUMMARISE_WRITING,
					CONFIG_ITEM_KEYS.MAKE_SHORTER,
					CONFIG_ITEM_KEYS.SUGGEST_A_TITLE,
					CONFIG_ITEM_KEYS.FIND_ACTION_ITEMS,
				].includes(configItemToUse.key)
			) {
				triggerRequest(positions, (streamContent) => ({ markdown: streamContent }));
			} else if (nodeTypeName === 'layoutSection') {
				const layoutSection = doc.nodeAt(positions[0]);
				if (layoutSection) {
					const emptyLayout = layoutSection.copy();
					const newLayoutColumns: Array<PMNode> = [];

					const triggerRequestCalls: Array<() => void> = [];
					let currentRequestCallIndex = 0;
					const isComplete = () => {
						const nextCallRequest = triggerRequestCalls[currentRequestCallIndex + 1];
						if (nextCallRequest) {
							currentRequestCallIndex = currentRequestCallIndex + 1;
							nextCallRequest();
						}

						return !nextCallRequest;
					};

					layoutSection.forEach(async (node, offset, index) => {
						const startPos = positions[0] + offset;
						const endPos = startPos + node.nodeSize;

						function streamContentProcessor(streamContent: string) {
							const { pmFragment } = convertMarkdownToProsemirror({
								schema,
								markdown: streamContent,
								idMap: idMap!,
								featureToggles: {
									markdownPlus: true,
									markdownPlusExtensions: true,
									markdownPlusPanels: true,
									markdownPlusDecisions: true,
								},
							});
							newLayoutColumns[index] = node.copy(pmFragment);
							const newLayout = emptyLayout.copy(Fragment.from(newLayoutColumns));

							return {
								markdown: streamContent,
								fragment: Fragment.from(newLayout),
							};
						}

						const triggerCellRequest = () => {
							triggerRequest([startPos, endPos], streamContentProcessor, isComplete);
						};
						triggerRequestCalls.push(triggerCellRequest);
					});
					triggerRequestCalls[0]();
				}
			} else if (nodeTypeName === 'panel') {
				const panel = doc.nodeAt(positions[0]);
				if (panel) {
					const emptyPanel = panel.copy();
					const startPos = positions[0] + 1;
					const endPos = positions[1] - 1;

					const streamContentProcessor = (streamContent: string) => {
						const { pmFragment } = convertMarkdownToProsemirror({
							schema,
							markdown: streamContent,
							idMap: idMap!,
							featureToggles: {
								markdownPlus: true,
								markdownPlusExtensions: true,
								markdownPlusPanels: true,
								markdownPlusDecisions: true,
							},
						});
						const newPanel = emptyPanel.copy(pmFragment);

						return {
							markdown: streamContent,
							fragment: Fragment.from(newPanel),
						};
					};

					triggerRequest([startPos, endPos], streamContentProcessor);
				}
			} else if (
				nodeTypeName === 'table' ||
				nodeTypeName === 'tableCell' ||
				nodeTypeName === 'tableHeader'
			) {
				triggerRequest(positions, (streamContent) => ({ markdown: streamContent }));
			} else if (nodeTypeName === 'expand' || nodeTypeName === 'nestedExpand') {
				const expand = doc.nodeAt(positions[0]);
				if (expand) {
					const emptyExpand = expand.copy();
					const startPos = positions[0] + 1;
					const endPos = positions[1] - 1;

					const streamContentProcessor = (streamContent: string) => {
						const { pmFragment } = convertMarkdownToProsemirror({
							schema,
							markdown: streamContent,
							idMap: idMap!,
							featureToggles: {
								markdownPlus: true,
								markdownPlusExtensions: true,
								markdownPlusPanels: true,
								markdownPlusDecisions: true,
							},
						});
						const newExpand = emptyExpand.copy(pmFragment);

						return {
							markdown: streamContent,
							fragment: Fragment.from(newExpand),
						};
					};

					triggerRequest([startPos, endPos], streamContentProcessor);
				}
			} else {
				triggerRequest(positions, (streamContent) => ({ markdown: streamContent }));
			}
		} else {
			triggerRequest(positions, (streamContent) => ({ markdown: streamContent }));
		}

		function triggerRequest(
			positions: Positions,
			streamContentProcessor: (streamContent: string) => { markdown: string; fragment?: Fragment },
			isComplete?: () => boolean,
		) {
			const { initialContext, promptRequest } = getInitialContextAndPromptRequest({
				callback,
				context,
				configItem: configItemToUse,
				mentionMap,
				positions,
			});

			const cacheKey = JSON.stringify({
				initialContext,
				userInput: context.userInput,
				agent: context.configItem.agent,
			});

			// Right now we only ever have one object in history as we do not
			// yet support traversing the history object array. This is also to
			// prevent us saving large documents into history multip[le times.
			const lastHistoryItem = context.responseHistory.entries[0];
			if (
				lastHistoryItem &&
				cacheKey === lastHistoryItem.cacheKey &&
				context.promptTrigger !== 'interrogate'
			) {
				callback({
					type: 'same cache key',
				});
				return;
			}

			const streaming = promptRequest({
				abortController,
				// Note -- when we collapse to a single "prompt request" with "api v2"
				// this would be a good opportunity to group these and pass them as a group
				generativeAIApiUrl: context.aiProvider.generativeAIApiUrl,
				product: context.aiProvider.product,
				getFetchCustomHeaders: context.aiProvider.getFetchCustomHeaders,
				channelId: context.channelId,
			});

			(async function () {
				for await (const streamItem of streaming) {
					if (streamItem.state === 'loaded') {
						const rovoActions = streamItem.data.rovoActions || [];
						let markdown = streamItem.data.content;
						let processedResponse = { markdown };
						if (rovoActions.length > 0) {
							const { selectionType } = context.configItem;
							const idealSuggestion = selectionType === 'range' ? 'replace' : 'insert';
							const action =
								rovoActions.find((x) => x.data.suggestion === idealSuggestion) ?? rovoActions[0];

							// There is a scenario where we are in selectionType === 'empty'
							// but AI returns replace. In this case, we override the replace
							// suggestion with insert.
							if (selectionType === 'empty' && action.data.suggestion === 'replace') {
								// This should also update the value by reference in rovoActions
								action.data.suggestion = 'insert';
							}
							// The opposite can happen too
							else if (selectionType === 'range' && action.data.suggestion === 'insert') {
								// This should also update the value by reference in rovoActions
								action.data.suggestion = 'replace';
							}

							markdown += `\n\n---\n\n${action.data.content}`;
						} else {
							processedResponse = streamContentProcessor(streamItem.data.content);
						}

						// loading done on success
						if (isComplete) {
							const isCompleted = isComplete();
							if (isCompleted) {
								callback({
									type: 'complete',
									...processedResponse,
									cacheKey,
									modelInput: {
										selection: isInitialContextSelectionRange(initialContext)
											? initialContext.selection
											: undefined,
									},
									inputOutputDiffRatio: streamItem.data.meta?.inputOutputDiffRatio,
									rovoActions,
								});
							} else {
								callback({
									type: 'stream',
									...processedResponse,
									loadingStatus: streamItem.data.meta?.loadingStatus,
								});
							}
						} else {
							callback({
								type: 'complete',
								...processedResponse,
								cacheKey,
								modelInput: {
									selection: isInitialContextSelectionRange(initialContext)
										? initialContext.selection
										: undefined,
								},
								inputOutputDiffRatio: streamItem.data.meta?.inputOutputDiffRatio,
								rovoActions,
							});
						}

						continue;
					} else if (streamItem.state === 'failed') {
						//loading done on api failed

						// If the error comes from ConvoAI
						if ('apiName' in streamItem && streamItem.apiName === 'assistance-service') {
							if (
								['RATE_LIMIT', 'OPENAI_RATE_LIMIT_USER_ABUSE'].includes(streamItem.guard) &&
								'retryAfter' in streamItem
							) {
								callback({
									type: 'error',
									retryAfter: streamItem.retryAfter!,
									errorInfo: {
										failureReason: FAILURE_REASON.RATE_LIMITED,
										statusCode: streamItem.statusCode,
										errorKey: streamItem.guard,
										apiName: streamItem.apiName,
										errorContent: 'error' in streamItem ? streamItem.error : undefined,
									},
								});
								continue;
							}

							if (streamItem.guard === 'HIPAA_CONTENT_DETECTED') {
								callback({
									type: 'error',
									errorInfo: {
										failureReason: FAILURE_REASON.HIPAA_CONTENT,
										statusCode: streamItem.statusCode,
										errorKey: streamItem.guard,
										apiName: streamItem.apiName,
										errorContent: 'error' in streamItem ? streamItem.error : undefined,
									},
								});
								continue;
							}

							if (streamItem.guard === 'EXCEEDING_CONTEXT_LENGTH_ERROR') {
								callback({
									type: 'error',
									errorInfo: {
										failureReason: FAILURE_REASON.TOKEN_LIMIT,
										statusCode: streamItem.statusCode,
										errorKey: streamItem.guard,
										apiName: streamItem.apiName,
										errorContent: 'error' in streamItem ? streamItem.error : undefined,
									},
								});
								continue;
							}

							if (streamItem.guard === 'ACCEPTABLE_USE_VIOLATIONS') {
								callback({
									type: 'error',
									cacheKey,
									modelInput: {
										selection: isInitialContextSelectionRange(initialContext)
											? initialContext.selection
											: undefined,
									},
									errorInfo: {
										failureReason: FAILURE_REASON.AUP_VIOLATION,
										statusCode: streamItem.statusCode,
										errorKey: streamItem.guard,
										apiName: streamItem.apiName,
										errorContent: 'error' in streamItem ? streamItem.error : undefined,
									},
								});
								continue;
							}

							// All other errors from ConvoAI
							callback({
								type: 'error',
								errorInfo: {
									failureReason: FAILURE_REASON.API_FAIL,
									statusCode: streamItem.statusCode,
									errorKey: streamItem.guard,
									apiName: streamItem.apiName,
									errorContent: 'error' in streamItem ? streamItem.error : undefined,
								},
							});
							continue;
						}

						if ('reason' in streamItem && streamItem.reason === 'backend-input-guard') {
							if (streamItem.guard === 'INPUT_EXCEEDS_TOKEN_LIMIT') {
								callback({
									type: 'error',
									errorInfo: {
										failureReason: FAILURE_REASON.TOKEN_LIMIT,
										statusCode: streamItem.statusCode,
										errorKey: streamItem.guard,
									},
								});
								continue;
							}
							if (
								streamItem.guard === 'INPUT_TOO_SHORT_TO_SUMMARIZE' ||
								streamItem.guard === 'INPUT_TOO_SHORT_TO_PROCESS'
							) {
								callback({
									type: 'error',
									errorInfo: {
										failureReason: FAILURE_REASON.INPUT_TOO_SHORT,
										errorKey: streamItem.guard,
										statusCode: streamItem.statusCode,
									},
								});
								continue;
							}
						}
						if ('reason' in streamItem && streamItem.reason === 'rate-limited') {
							callback({
								type: 'error',
								retryAfter: streamItem.retryAfter!,
								errorInfo: {
									failureReason: FAILURE_REASON.RATE_LIMITED,
									statusCode: streamItem.statusCode,
								},
							});
							continue;
						}

						if ('reason' in streamItem && streamItem.reason === 'response-too-similar') {
							callback({
								type: 'response too similar',
								cacheKey,
								modelInput: {
									selection: isInitialContextSelectionRange(initialContext)
										? initialContext.selection
										: undefined,
								},
								inputOutputDiffRatio: streamItem.data.meta?.inputOutputDiffRatio,
							});
							continue;
						}

						callback({
							type: 'error',
							errorInfo: {
								failureReason: FAILURE_REASON.API_FAIL,
								statusCode: streamItem.statusCode,
								errorContent: 'error' in streamItem ? streamItem.error : undefined,
							},
						});
					} else if (streamItem.state === 'aup-violation') {
						callback({
							type: 'error',
							cacheKey,
							modelInput: {
								selection: isInitialContextSelectionRange(initialContext)
									? initialContext.selection
									: undefined,
							},
							errorInfo: {
								failureReason: FAILURE_REASON.AUP_VIOLATION,
								statusCode: streamItem.statusCode,
							},
						});
						continue;
					} else {
						callback({
							type: 'stream',
							loadingStatus: streamItem.data.meta?.loadingStatus,
							...streamContentProcessor(streamItem.data.content),
						});
						continue;
					}
				}
			})();
		}

		// Cleanup function
		return () => {
			abortController.abort();
		};
	}

	return callbackHandler;
}
