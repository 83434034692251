import { useCallback, useMemo } from 'react';

import { useIntl } from 'react-intl-next';
import { createHook, createStore } from 'react-sweet-state';

import { useRovoEntitlement } from '@atlassian/conversation-assistant-entitlement';
import { ExperienceName } from '@atlassian/conversation-assistant-instrumentation';
import {
	type Agent,
	type ApiError,
	type AssistanceServiceInjectedParams,
	createHookWithAssistanceService,
	exponentialRetry,
	isRateLimitedError,
	type PostAgentPayload,
	useMutation,
	useQuery,
} from '@atlassian/conversation-assistant-service';

import { getSuggestedOotbAgents } from '../../utils/agents';
import { useDeleteAgent } from '../agent-delete';
import { useAgentFavourites } from '../agent-favourite';
import { getOverridenAgents, useAgentOverrider } from '../agent-overrider';

import { isCreateOrEditAgentAUPError } from './constants';
import messages from './messages';

type CategorisedAgents = {
	public: Agent[];
	private: Agent[];
	system: Agent[];
};

const categoriseAgents = (agents: Agent[]): CategorisedAgents =>
	agents.reduce(
		(categorisedAgents: CategorisedAgents, agent: Agent) => {
			if (agent.creator_type === 'SYSTEM') {
				categorisedAgents.system.push(agent);
			} else if (agent.visibility === 'PUBLIC') {
				categorisedAgents.public.push(agent);
			} else if (agent.visibility === 'PRIVATE') {
				categorisedAgents.private.push(agent);
			} else if (!agent.visibility && agent.creator_type === 'CUSTOMER') {
				categorisedAgents.private.push(agent);
			}

			return categorisedAgents;
		},
		{
			public: [],
			private: [],
			system: [],
		},
	);

const agentsStore = createStore({
	initialState: {
		availableAgents: [] as Agent[],
		categorisedAgents: {
			private: [],
			public: [],
			system: [],
		} as CategorisedAgents,
		agentsInitialized: false,
	},
	name: 'AgentsStore',
	actions: {
		setAgents:
			(availableAgents: Agent[]) =>
			({ setState }) => {
				setState({
					availableAgents,
					categorisedAgents: categoriseAgents(availableAgents),
				});
			},
		getAgentDetails:
			(id: string) =>
			({ getState }) => {
				const { availableAgents } = getState();
				return availableAgents.find((agent) => agent.id === id);
			},
		setAgentsInitialized:
			() =>
			({ setState }) => {
				setState({
					agentsInitialized: true,
				});
			},
	},
});

const useAgentsStore = createHook(agentsStore);

type UseAgentsListProps = {
	filter?: 'favourites';
} & AssistanceServiceInjectedParams;

type UseAgentsControllerProps = {
	onError?: (error: ApiError) => void;
} & AssistanceServiceInjectedParams;

export const useAgentsControllerInternal = createHookWithAssistanceService(
	({
		onError,
		assistanceServiceInjectedParams: {
			assistanceService,
			experienceTracker,
			experienceId,
			product,
		},
	}: UseAgentsControllerProps) => {
		const { isRovoEnabled } = useRovoEntitlement();

		const { agentIdFiltersOverride, agentDefaultIdOverride } = useAgentOverrider();
		const [
			{ availableAgents, categorisedAgents, agentsInitialized },
			{ setAgents, getAgentDetails, setAgentsInitialized },
		] = useAgentsStore();
		const { formatMessage } = useIntl();

		const { favouriteAgent, unfavouriteAgent } = useAgentFavourites({
			experienceId,
			product,
		});

		const favouritedAgents = availableAgents.filter((agent) => agent.favourite);
		const defaultAgent = useMemo(
			() => availableAgents.find((agent) => agent.is_default),
			[availableAgents],
		);
		const nonDefaultAgents = useMemo(
			() => availableAgents.filter((agent) => !agent.is_default),
			[availableAgents],
		);
		const nonDefaultFavouriteAgents = useMemo(
			() => nonDefaultAgents.filter((agent) => !agent.favourite),
			[nonDefaultAgents],
		);

		const fetchAndStoreAvailableAgents = useQuery({
			queryKey: ['fetchAgents'],
			queryFn: async () => {
				try {
					experienceTracker.start({
						id: ExperienceName.FETCH_AGENTS,
						name: ExperienceName.FETCH_AGENTS,
					});

					const agents = await exponentialRetry({
						fn: () => assistanceService.getAgents(),
						errorFilter: isRateLimitedError,
					});

					const transformedAgents = agents.map((agent) => {
						// Rename default agent to Chat, which is displayed in the UI
						if (agent.is_default) {
							agent.name = formatMessage(messages.defaultAgentName);
							agent.description = formatMessage(messages.defaultAgentDescription);
						}

						return agent;
					});

					const overriddenAgents = getOverridenAgents(
						transformedAgents,
						agentIdFiltersOverride,
						agentDefaultIdOverride,
					);

					setAgents(overriddenAgents);

					experienceTracker.succeed({
						name: ExperienceName.FETCH_AGENTS,
					});

					setAgentsInitialized();
				} catch (error: any) {
					experienceTracker.fail({
						name: ExperienceName.FETCH_AGENTS,
						error,
					});

					throw error;
				}
			},
			onError,
			enabled: isRovoEnabled,
			refetchOnWindowFocus: true,
		});

		const createAgent = useMutation({
			mutationFn: async (payload: PostAgentPayload) => {
				experienceTracker.start({
					id: ExperienceName.CREATE_AGENT,
					name: ExperienceName.CREATE_AGENT,
				});
				return await assistanceService.createAgent(payload);
			},
			onSuccess: async (agent: Agent) => {
				await favouriteAgent.mutate(agent.id);

				await fetchAndStoreAvailableAgents.refetch();

				experienceTracker.succeed({
					name: ExperienceName.CREATE_AGENT,
				});
			},
			onError: (error) => {
				if (isCreateOrEditAgentAUPError(error)) {
					experienceTracker.succeed({
						name: ExperienceName.CREATE_AGENT,
					});
					return;
				}

				experienceTracker.fail({
					name: ExperienceName.CREATE_AGENT,
					error,
				});
			},
		});

		const updateAgent = useMutation({
			mutationFn: async (payload: { id: string; data: PostAgentPayload }) => {
				experienceTracker.start({
					id: ExperienceName.UPDATE_AGENT,
					name: ExperienceName.UPDATE_AGENT,
				});

				return await assistanceService.updateAgent(payload.id, payload.data);
			},
			onSuccess: async (_agent: Agent) => {
				await fetchAndStoreAvailableAgents.refetch();

				experienceTracker.succeed({
					name: ExperienceName.UPDATE_AGENT,
				});
			},
			onError: (error) => {
				if (isCreateOrEditAgentAUPError(error)) {
					experienceTracker.succeed({
						name: ExperienceName.UPDATE_AGENT,
					});
					return;
				}

				experienceTracker.fail({
					name: ExperienceName.UPDATE_AGENT,
					error,
				});
			},
		});

		const { deleteAgent } = useDeleteAgent({
			experienceId,
			product,
			onSuccess: () => {
				fetchAndStoreAvailableAgents.refetch();
			},
		});

		const setFavouriteAgent = useCallback(
			async (id: string, isFavourite: boolean) => {
				const updatedAgent = availableAgents.find((a) => a.id === id);
				const setUpdatedAgent = (shouldBeFavourite: boolean) => {
					if (updatedAgent) {
						updatedAgent.favourite = shouldBeFavourite;
						setAgents(
							availableAgents.map((a) => {
								// If the current agent's id matches the new agent's id, return the new agent.
								// Otherwise, return the current agent.
								return a.id === updatedAgent.id ? updatedAgent : a;
							}),
						);
					}
				};

				//Optimistically update favourite value before we mutate
				setUpdatedAgent(isFavourite);

				//Pass callback to roll back optimistic update if mutation fails
				const errorCallback = {
					onError: () => {
						setUpdatedAgent(!isFavourite);
					},
				};
				if (isFavourite) {
					return await favouriteAgent.mutate(id, errorCallback);
				} else {
					return await unfavouriteAgent.mutate(id, errorCallback);
				}
			},
			[availableAgents, setAgents, favouriteAgent, unfavouriteAgent],
		);
		/** This updates the agent on the client side only
		 * Useful for updating the agent after a successful mutation
		 */
		const updateAgentLocally = useCallback(
			(agentId: string, updatedAgent: Partial<Agent>) => {
				const updatedAgents = availableAgents.map((agent) => {
					if (agent.id === agentId) {
						return {
							...agent,
							...updatedAgent,
						};
					}
					return agent;
				});

				setAgents(updatedAgents);
			},
			[availableAgents, setAgents],
		);

		return {
			availableAgents,
			favouritedAgents,
			categorisedAgents,
			fetchAndStoreAvailableAgents,
			createAgent,
			updateAgent,
			deleteAgent,
			setFavouriteAgent,
			getAgentDetails,
			defaultAgent,
			agentsInitialized,
			nonDefaultAgents,
			nonDefaultFavouriteAgents,
			updateAgentLocally,
		};
	},
);

const noopAsync = async () => {};
const noopFn = (): Agent | undefined => undefined;
const noopArray: Agent[] = [];
const noopCategorisedAgent = {
	public: [],
	private: [],
	system: [],
};
export const noopAgent: Agent = {
	id: 'NOOP',
	name: 'NOOP',
	named_id: 'NOOP',
	user_defined_conversation_starters: [],
	description: '',
	available_plugins: {},
	actor_type: 'AGENT',
	creator_type: 'SYSTEM',
	visibility: 'PRIVATE',
	is_default: false,
	favourite: false,
	deactivated: false,
};

export const useAgentsController: typeof useAgentsControllerInternal = (param) => {
	const { isRovoEnabled } = useRovoEntitlement();
	const controllerResult = useAgentsControllerInternal(param);

	const noopUseQuery = useQuery({
		queryKey: ['noop'],
		queryFn: noopAsync,
		enabled: false,
	});

	const noopUseMutation: any = useMutation({
		mutationFn: async () => noopAgent,
		onSuccess: noopAsync,
		onError: noopAsync,
	});

	if (!isRovoEnabled) {
		return {
			availableAgents: noopArray,
			favouritedAgents: noopArray,
			categorisedAgents: noopCategorisedAgent,
			defaultAgent: undefined,
			agentsInitialized: false,
			getAgentDetails: noopFn,
			fetchAndStoreAvailableAgents: noopUseQuery,
			createAgent: noopUseMutation,
			updateAgent: noopUseMutation,
			deleteAgent: noopUseMutation,
			setFavouriteAgent: noopUseMutation,
			nonDefaultAgents: noopArray,
			nonDefaultFavouriteAgents: noopArray,
			updateAgentLocally: noopFn,
		};
	}

	return controllerResult;
};

//Use useAgentsList in contexts in which we want to display at least three default favourited agents, such as in the agent-selector and editor
export const useAgentsList = createHookWithAssistanceService(
	({
		assistanceServiceInjectedParams: { product, experienceId, headers, touchpointSource },
		filter,
	}: UseAgentsListProps) => {
		const { availableAgents, favouritedAgents } = useAgentsController({
			assistanceServiceParams: {
				product,
				experienceId,
				headers,
				touchpointSource,
			},
		});

		const defaultAgents = getSuggestedOotbAgents({ availableAgents, product });

		const defaultAgentsOutsideUserFavourites = defaultAgents.filter(
			(defaultAgent) => !favouritedAgents.find((favAgent) => favAgent.id === defaultAgent.id),
		);

		const favouritedAgentsWithDefault = [
			...favouritedAgents,
			...defaultAgentsOutsideUserFavourites.slice(0, Math.max(3 - favouritedAgents.length, 0)),
		];

		return filter === 'favourites' ? favouritedAgentsWithDefault : availableAgents;
	},
);

export const useSuggestedAgents = createHookWithAssistanceService(
	({ assistanceServiceInjectedParams }) => {
		const { availableAgents, agentsInitialized } = useAgentsController({
			assistanceServiceParams: assistanceServiceInjectedParams,
		});

		const defaultAgents = getSuggestedOotbAgents({
			availableAgents,
			product: assistanceServiceInjectedParams.product,
		});

		return { suggestedAgents: defaultAgents, agentsInitialized };
	},
);
