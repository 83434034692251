import React from 'react';

import type { IntlShape, MessageDescriptor } from 'react-intl-next';

import { fg } from '@atlaskit/platform-feature-flags';

import { CONFIG_ITEM_KEYS } from '../../../config-items/config-item-keys';
import type { EditorPluginAIConfigItemMarkdownAction } from '../../../config-items/config-items';
import { createEditorPluginAIConfigItemMarkdown } from '../../../config-items/config-items';
import type {
	BackendModel,
	GrammarSpellingIntentSchema,
	ImproveQualityIntentSchema,
} from '../../../provider/prompt-requests/types';
import { Icon as FixSpellingIcon } from '../../assets/icons/fixSpelling';
import { Icon as ImproveWritingIcon } from '../../assets/icons/improveWriting';
import type { IconShownAt } from '../../assets/icons/types';
import { createInsertBelow, createReplace } from '../../config-item-actions/markdown';
import { isMinLength } from '../../config-item-visible/visibility-utils';

import { messages as enhancedMessages } from './messages';

const SELECTION_TYPE = 'range';

function createEnhanceOption({
	key,
	title,
	description,
	actions,
	intentSchemaId,
	icon,
	getBackendModel,
}: {
	key: CONFIG_ITEM_KEYS;
	title: MessageDescriptor;
	description: MessageDescriptor;
	actions: EditorPluginAIConfigItemMarkdownAction[];
	intentSchemaId: GrammarSpellingIntentSchema | ImproveQualityIntentSchema;
	icon?: ({
		formatMessage,
		shownAt,
	}: {
		formatMessage: IntlShape['formatMessage'];
		shownAt: IconShownAt;
	}) => JSX.Element;
	getBackendModel: (isInterrogate?: boolean) => BackendModel;
}) {
	return createEditorPluginAIConfigItemMarkdown({
		key,
		title,
		description,
		selectionType: SELECTION_TYPE,
		intentSchemaId,
		isVisible: ({ editorView, positions }) => isMinLength({ editorView, minLength: 1, positions }),
		icon,
		actions,
		getBackendModel,
	});
}

export const improveWriting = createEnhanceOption({
	key: CONFIG_ITEM_KEYS.IMPROVE_WRITING,
	title: enhancedMessages.improveWritingTitle,
	description: enhancedMessages.improveWritingDescription,
	actions: [
		createInsertBelow({ appearance: 'secondary' }),
		createReplace({ appearance: 'primary' }),
	],
	intentSchemaId: 'improve_quality_intent_schema.json',
	icon: ({ shownAt }) => <ImproveWritingIcon shownAt={shownAt} />,
	getBackendModel: () => {
		if (fg('platform_editor_ai_assistance_service')) {
			return 'assistance-service';
		}
		return 'xp-gen-ai';
	},
});

export const fixSpellingAndGrammar = createEnhanceOption({
	key: CONFIG_ITEM_KEYS.FIX_SPELLING_AND_GRAMMAR,
	title: enhancedMessages.fixSpellingGrammarTitle,
	description: enhancedMessages.fixSpellingGrammarDescription,
	actions: [createReplace({ appearance: 'primary' })],
	intentSchemaId: 'grammar_spelling_intent_schema.json',
	icon: ({ shownAt }) => <FixSpellingIcon shownAt={shownAt} />,
	getBackendModel: () => {
		if (fg('platform_editor_ai_assistance_service')) {
			return 'assistance-service';
		}
		return 'xp-gen-ai';
	},
});
