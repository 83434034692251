import React from 'react';

import AKIcon, { type IconProps as AKIconProps, type CustomGlyphProps } from '@atlaskit/icon';
import BriefcaseIcon from '@atlaskit/icon/core/briefcase';
import { token } from '@atlaskit/tokens';

import { RainbowBorder } from './RainbowBorder';
import type { IconProps } from './types';

const IconGlyph = (props: CustomGlyphProps) => (
	<svg
		width="24"
		height="24"
		viewBox="0 0 24 24"
		xmlns="http://www.w3.org/2000/svg"
		role="presentation"
		{...props}
	>
		<path d="M6 20C5.49542 20.0002 5.00943 19.8096 4.63945 19.4665C4.26947 19.1234 4.04284 18.6532 4.005 18.15L4 18V10C3.99984 9.49542 4.19041 9.00943 4.5335 8.63945C4.87659 8.26947 5.34684 8.04284 5.85 8.005L6 8H8V6C7.99984 5.49542 8.19041 5.00943 8.5335 4.63945C8.87659 4.26947 9.34685 4.04284 9.85 4.005L10 4H14C14.5046 3.99984 14.9906 4.19041 15.3605 4.5335C15.7305 4.87659 15.9572 5.34684 15.995 5.85L16 6V8H18C18.5044 8.00009 18.9901 8.19077 19.3599 8.53384C19.7297 8.8769 19.9562 9.34702 19.994 9.85L20 10V18C19.9999 18.5044 19.8092 18.9901 19.4662 19.3599C19.1231 19.7297 18.653 19.9562 18.15 19.994L18 20H6ZM8 10H6V18H7.999L8 10ZM14 10H10L9.999 18H14V10ZM18 10H16V18H18V10ZM14 6H10V8H14V6Z" />
	</svg>
);

export const Icon = (props: IconProps) => {
	const { shownAt } = props;

	return (
		<RainbowBorder shownAt={shownAt}>
			<BriefcaseIcon
				label=""
				color="currentColor"
				spacing="spacious"
				LEGACY_fallbackIcon={(props: AKIconProps) => <AKIcon glyph={IconGlyph} {...props} />}
				LEGACY_secondaryColor={token('color.icon')}
			/>
		</RainbowBorder>
	);
};
