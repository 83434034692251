import { preloadBlogTree } from '@confluence/blog-tree/entry-points/preloadBlogTree';
import { preloadContent } from '@confluence/content-body/entry-points/preloadContent';
import { preloadContentHeader } from '@confluence/content-header/entry-points/preloadContentHeader';
import { preloadContentPrerequisites } from '@confluence/content-prerequisites/entry-points/preloadContentPrerequisites';
import { preloadContentSmartLinks } from '@confluence/content-smartlinks/entry-points/preloadContentSmartLinks';
import { preloadCustomHeaderAndFooter } from '@confluence/custom-header-footer/entry-points/preloadCustomHeaderAndFooter';
import { preloadPageTitleContentProperties } from '@confluence/custom-sites-extensions/entry-points/preloadPageTitleContentProperties';
import { preloadMacrosSSR } from '@confluence/fabric-extension-handlers/entry-points/preloadMacrosSSR';
import { preloadMediaToken } from '@confluence/fabric-media-support/entry-points/preloadMediaToken';
import { preloadFocusedInlineComment } from '@confluence/inline-comments-queries/entry-points/preloadFocusedInlineComment';
import { preloadInlineComments } from '@confluence/inline-comments-queries/entry-points/preloadInlineComments';
import { preloadInlineHighlights } from '@confluence/inline-highlights-query/entry-points/preloadInlineHighlights';
import { COMPANY_HUB_EDIT, EDIT_BLOG_V2, EDIT_PAGE_V2 } from '@confluence/named-routes';
import { preloadEndOfPageRecommendation } from '@confluence/page-recommendations/entry-points/preloadEndOfPageRecommendation';
import { PAGE_TREE_STATUSES } from '@confluence/page-tree/entry-points/pageTreeStatuses';
import { preloadPageTree } from '@confluence/page-tree/entry-points/preloadPageTree';
import {
	getPreloaderFnContext,
	prepareGuardExceptionTask,
} from '@confluence/query-preloader-tools';
import { preloadPageReactions } from '@confluence/reactions/entry-points/preloadPageReactions';
import type { RouteMatch } from '@confluence/route';
import {
	preloadSideNavigation,
	preloadSideNavigationNeedsContentId,
} from '@confluence/side-navigation/entry-points/preloadSideNavigation';
import { preloadSpaceGuestList } from '@confluence/space-guest-list/entry-points/preloadSpaceGuestList';
import { preloadShortcutsSmartLinks } from '@confluence/space-shortcuts/entry-points/preloadShortcutsSmartLinks';
import { preloadSpaceDetail } from '@confluence/space-utils/entry-points';
import { preloadSpaceViews } from '@confluence/space-views/entry-points/preloadSpaceViews';
import { preloadPageStatusQuery } from '@confluence/view-page-common/entry-points/preloadPageStatusQuery';
import { preloadLegacyBridge } from '@confluence/view-page/entry-points/preloadLegacyBridge';
import { ATL_PAGE_CONTENT_FOOTER_ACTIONS } from '@confluence/web-item-location/entry-points/ATL_PAGE_CONTENT_FOOTER_ACTIONS';
import { preloadWebPanelLocation } from '@confluence/web-panel-location/entry-points/preloadWebPanelLocation';
import { fg } from '@confluence/feature-gating';

import {
	getIsEmbeddedConfluence,
	getUsername,
	hasEmbeddedAllowedFeatures,
	shouldPreloadNavigationForTransition,
} from './matchHelpers';
import { matchBlogPage } from './matchRoutes';
/*
 * preloadViewPageRoute preload queries are currently arranged in order of latency, with slowest
 * queries pushed to the tasks array first.
 */
export const preloadViewPageRoute = async (
	match: RouteMatch,
	url: string,
	isTransition: boolean = false,
	previousMatch: RouteMatch | null = null,
) => {
	const { featureFlags, isLicensed, userId, edition } = await getPreloaderFnContext();
	const tasks: Promise<any>[] = [];
	const { spaceKey, contentId } = match.params;
	const username = getUsername(match);
	const isEmbeddedConfluence = getIsEmbeddedConfluence(match);
	const isBlog = Boolean(matchBlogPage(url));
	const isInitialLoad = !isTransition;
	const shouldPreloadNavigation =
		!isEmbeddedConfluence && (isInitialLoad || shouldPreloadNavigationForTransition(previousMatch));

	// Don't preload queries that will be updated by editor if previous route is edit_page
	const isPrevEditRoute =
		previousMatch?.name === EDIT_PAGE_V2.name ||
		previousMatch?.name === EDIT_BLOG_V2.name ||
		previousMatch?.name === COMPANY_HUB_EDIT.name;

	if (isPrevEditRoute && isTransition) {
		// Preload for edit -> view transition
		return preloadContentHeader({
			spaceKey,
			contentId,
			isLicensed,
			userId,
			username,
			edition,
			isPrevEditRoute,
			useNewContentTopper: true,
			isSpaceAliasFFEnabled: true,
			isBlog,
		});
	}

	// 1. Content + Content Header
	tasks.push(
		prepareGuardExceptionTask('Macros', () =>
			preloadMacrosSSR({
				contentId,
				featureFlags,
				useMultipleMacrosQuery: true,
			}),
		),
		prepareGuardExceptionTask('Content', () =>
			preloadContent({
				contentId,
				isBlog,
				spaceKey,
				featureFlags,
			}).then(({ result, hasErrors }): Promise<any> | void => {
				// In case of errors on preloading content the content data may not make it to the
				// Apollo cache. To ensure we can try handling errors or missing content data cases
				// on SSR we preload content prerequisites here.
				// We don't want to preload content dependencies as in case of errors and/or missing
				// content this may result in unnecessary queries.
				if (hasErrors) {
					return preloadContentPrerequisites(contentId, spaceKey, true);
				} else if (process.env.REACT_SSR) {
					return preloadMacrosSSR({
						contentId,
						featureFlags,
						useMultipleMacrosQuery: false,
						contentNodes: result?.data?.content?.nodes,
					});
				}
			}),
		),
		preloadContentHeader({
			spaceKey,
			contentId,
			isLicensed,
			userId,
			username,
			edition,
			isPrevEditRoute,
			useNewContentTopper: true,
			isSpaceAliasFFEnabled: true,
			isBlog,
		}),
		preloadPageStatusQuery(contentId),
		preloadLegacyBridge({ contentId, isBlog }),
	);

	// 2. Page title
	tasks.push(preloadPageTitleContentProperties({ contentId, spaceKey }));

	// 3. Space details
	tasks.push(preloadSpaceDetail(spaceKey));

	// 4. Content Smart Links
	tasks.push(preloadContentSmartLinks(contentId));

	// 5. Navigation
	if (shouldPreloadNavigation) {
		tasks.push(
			preloadSpaceGuestList(spaceKey),
			preloadShortcutsSmartLinks(spaceKey),
			preloadSideNavigation(spaceKey),
			preloadSideNavigationNeedsContentId(spaceKey, contentId),
			preloadSpaceViews(spaceKey, isLicensed),
		);

		// 6. Page tree + blog tree for blog post
		tasks.push(
			preloadPageTree({
				spaceKey,
				...(isBlog ? {} : { contentId }),
				statuses: PAGE_TREE_STATUSES,
				isSuperAdmin: false,
			}),
			preloadBlogTree({ spaceKey }),
		);
	}

	// 7. Inline Comments
	if (match?.query.focusedCommentId) {
		tasks.push(preloadFocusedInlineComment(match?.query.focusedCommentId as string, contentId));
	}
	if (!isEmbeddedConfluence || hasEmbeddedAllowedFeatures('inline-comments', match)) {
		// when rendering an embedded confluence page, we do not want to preload inline comments if they are not requested via the allowlist features
		tasks.push(preloadInlineHighlights(contentId));
		tasks.push(preloadInlineComments({ pageId: contentId }));
	}

	// 8. Reactions
	if (!isEmbeddedConfluence || hasEmbeddedAllowedFeatures('page-reactions', match)) {
		// Preload whether the server has enabled reactions at all to show placeholder
		tasks.push(
			preloadWebPanelLocation({
				contentId,
				location: ATL_PAGE_CONTENT_FOOTER_ACTIONS,
			}),
		);
		// Preload reactions asynchronously so SSR won't wait for it
		// it's best effort preloading so if it preloads then we have the data, otherwise SPA can load it
		if (fg('confluence_ssr_reactions_preload')) {
			tasks.push(preloadPageReactions(contentId));
		}
	}

	// 9. Recommendations
	if (fg('ssr_end_of_page_recommendation')) {
		tasks.push(preloadEndOfPageRecommendation({ contentId, spaceKey }));
	}

	// 10. Stuff only needed for initial load
	if (isInitialLoad) {
		tasks.push(
			// We only refresh token when it is about to expire.
			preloadMediaToken(contentId),
			preloadCustomHeaderAndFooter(spaceKey),
		);
	}

	return Promise.all(tasks);
};
