import { query } from '@confluence/query-preloader-tools';
import { fg } from '@confluence/feature-gating';

import { LegacyBridgeQuery } from '../src/LegacyBridgeQuery.graphql';
import { OptimizedLegacyBridgeQuery } from '../src/OptimizedLegacyBridgeQuery.graphql';

export async function preloadLegacyBridge({ contentId, isBlog }) {
	if (!contentId) {
		return;
	}

	const queryToUse = fg('confluence_fe_call_new_legacy_bridge_query')
		? OptimizedLegacyBridgeQuery
		: LegacyBridgeQuery;

	return query({
		query: queryToUse,
		variables: { contentId, isBlog },
		errorPolicy: 'all',
	});
}
