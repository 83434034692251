import React from 'react';

import { ErrorScreen } from '@atlassian/generative-ai-modal/screens/Error';

import { useAnalyticsFlowEventsQueue } from '../../analytics/analytics-flow/analyticsFlowUtils';
import { useAIExperienceCommonDataContext } from '../useAIExperienceCommonData';

import { ErrorMessage, getStepName, retryEnabled } from './utils/error-handling';
import type { ErrorInfo } from './utils/error-handling';
import { FAILURE_REASON } from './utils/errors';

export function ErrorScreenWithLogic({ errorInfo }: { errorInfo: ErrorInfo }) {
	const aiExperienceCommonData = useAIExperienceCommonDataContext();
	const { endExperience, sendToAIExperienceMachine } = aiExperienceCommonData;

	useAnalyticsFlowEventsQueue({
		stepName: getStepName(errorInfo),
		attributes: {
			errorType: errorInfo.failureReason ?? FAILURE_REASON.UNKNOWN,
			sawAupViolation: errorInfo.failureReason === FAILURE_REASON.AUP_VIOLATION,
			...errorInfo,
		},
	});

	return (
		<ErrorScreen
			text={ErrorMessage(errorInfo)}
			onCancel={() => endExperience()}
			onRetry={() => sendToAIExperienceMachine({ type: 'retry' })}
			showRetryButton={retryEnabled(errorInfo)}
		/>
	);
}
