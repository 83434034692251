import React from 'react';
import { FormattedMessage, defineMessages } from 'react-intl-next';

import Heading from '@atlaskit/heading';
import { Stack } from '@atlaskit/primitives';

import { getCompanyHubSpaceKey } from '@confluence/route-manager/entry-points/companyHubUtils';
import {
	COMPANY_HUB_GENERAL_SETTINGS_EXPERIENCE,
	COMPANY_HUB_GENERAL_SETTINGS_YOUR_HUB_EXPERIENCE,
	COMPANY_HUB_GENERAL_SETTINGS_YOUR_HUB_ACTIONS_EXPERIENCE,
	COMPANY_HUB_GENERAL_SETTINGS_HUB_VIEWS_EXPERIENCE,
	COMPANY_HUB_GENERAL_SETTINGS_HUB_RECENT_ACTIVITY_EXPERIENCE,
	COMPANY_HUB_GENERAL_SETTINGS_MOST_CLICKS_EXPERIENCE,
} from '@confluence/experience-tracker';
import { SubExperienceTrackerContextProvider } from '@confluence/admin-center/entry-points/SubExperienceTracker';

import { GeneralPageShareFeedbackButton } from './GeneralPageShareFeedbackButton';
import { HubDashboard } from './HubDashboard';
import { RecentActivitySection } from './RecentActivitySection';

export const i18n = defineMessages({
	headerTitle: {
		id: 'company-hub.hub-settings.general.header-title',
		defaultMessage: 'Your hub',
		description: 'Header title for the general tab in company hub settings page',
	},
});

const SUB_EXPERIENCES = [
	COMPANY_HUB_GENERAL_SETTINGS_YOUR_HUB_EXPERIENCE,
	COMPANY_HUB_GENERAL_SETTINGS_YOUR_HUB_ACTIONS_EXPERIENCE,
	COMPANY_HUB_GENERAL_SETTINGS_HUB_VIEWS_EXPERIENCE,
	COMPANY_HUB_GENERAL_SETTINGS_HUB_RECENT_ACTIVITY_EXPERIENCE,
	COMPANY_HUB_GENERAL_SETTINGS_MOST_CLICKS_EXPERIENCE,
];

export const GeneralPageContent = () => {
	const spaceKey = getCompanyHubSpaceKey();

	return (
		<SubExperienceTrackerContextProvider
			mainExperience={COMPANY_HUB_GENERAL_SETTINGS_EXPERIENCE}
			subExperiences={SUB_EXPERIENCES}
		>
			<Stack grow="fill" space="space.400">
				<Stack grow="fill" space="space.200">
					<Heading size="medium">
						<FormattedMessage {...i18n.headerTitle} />
					</Heading>
					<HubDashboard spaceKey={spaceKey} />
				</Stack>
				<RecentActivitySection spaceKey={spaceKey} />
				<GeneralPageShareFeedbackButton />
			</Stack>
		</SubExperienceTrackerContextProvider>
	);
};
