export const ExperienceName = {
	LOAD_PANEL: 'aiMateLoadPanel',
	LOAD_BROWSE_AGENTS: 'aiMateLoadBrowseAgents',
	LOAD_CREATE_AGENT: 'aiMateLoadCreateAgent',
	FETCH_CHAT: 'aiMateFetchChat',
	FETCH_AGENTS: 'aiMateFetchAgents',
	FETCH_AGENT_BY_AGENT_ID: 'aiMateFetchAgentByAgentId',
	FETCH_AGENT_BY_IDENTITY_ACCOUNT_ID: 'aiMateFetchAgentByIdentityAccountId',
	CREATE_AGENT: 'aiMateCreateAgent',
	UPDATE_AGENT: 'aiMateUpdateAgent',
	DELETE_AGENT: 'aiMateDeleteAgent',
	FAVOURITE_AGENT: 'aiMateFavouriteAgent',
	UNFAVOURITE_AGENT: 'aiMateUnfavouriteAgent',
	CLEAR_CHAT: 'aiMateClearChat',
	SEND_MESSAGE: 'aiMateSendMessage',
	ABORT_AGENT_STREAM: 'aiMateAbortAgentStream',
	FETCH_FEATURES: 'aiMateFetchFeatures',
	GET_CONVERSATION_CHANNELS: 'aiMateGetConversationChannels',
	UPDATE_CONVERSATION_CHANNEL: 'aiMateUpdateConversationChannel',
	CREATE_SEEDED_CONVERSATION_CHANNEL: 'aiMateCreateSeededConversationChannel',
	PUBSUB_LISTENER: 'aiMatePubSubListener',
	FETCH_AGENT_TENANT_DATA: 'aiMateFetchAgentTenantData',
	RESOLVE_CONVERSATION_ACTION: 'aiMateResolveConversationAction',
	MESSAGE_RENDERER: 'aiMateMessageRenderer',
	FETCH_CONTEXTUAL_STARTERS: 'aiMateFetchContextualStarters',
	GENERATE_CONVERSATION_STARTERS: 'aiMateGenerateConversationStarters',
	FETCH_AGENT_TEAM_DATA: 'aiMateFetchAgentTeamData',
	FETCH_CONNECTED_KNOWLEDGE_SOURCES: 'aiMateFetchConnectedKnowledgeSources',
	FETCH_AGENT_ACTIONS: 'aiMateFetchAgentActions',
	CHANGE_AGENT_OWNER: 'aiMateChangeAgentOwner',
	GET_AGENT_PERMISSIONS: 'aiMateGetAgentPermissions',
	SEND_MESSAGE_NL_AGENT: 'aiMateSendMessageNLAgent',
	/**
	 * If you are updating this, please do not forget to register this value in DataPortal 'attributes' tab - as the possible values for `task`. Otherwise the event will be dropped.
	 * See the guide in https://hello.atlassian.net/wiki/spaces/AA6/pages/3961393753/HOWTO+Create+FE+Reliability+SLO+using+experience+tracker
	 */
} as const satisfies {
	[key: string]: `aiMate${string}`;
};
export type ExperienceNameType = (typeof ExperienceName)[keyof typeof ExperienceName];
