import { createContext, useContext } from 'react';

export type MessageContextData = {
	messageInstanceId?: string;
	messageTemplateId?: string;
	transactionAccountId?: string;
	analyticsDetails?: Record<string, string | number>;
	triggerId?: string;
	messageCreationType?: 'implicit' | 'explicit';
};

const initialValue = {};

export const MessageContext = createContext<MessageContextData>(initialValue);

export const MessageContextProvider = MessageContext.Provider;

export const useMessageContext = () => useContext(MessageContext);
