import { OPERATIONAL_EVENT_TYPE } from '@atlaskit/analytics-gas-types';
import type { CreateUIAnalyticsEvent } from '@atlaskit/analytics-next';
import { useAnalyticsEvents } from '@atlaskit/analytics-next/useAnalyticsEvents';

import { useMetricsContext } from '../context/metrics';
import { FORGE_UI_ANALYTICS_CHANNEL } from '.';

type EnvironmentType = 'PRODUCTION' | 'DEVELOPMENT' | 'STAGING';

type Components = Record<
	string,
	{
		count: number;
		propCount: Record<string, number>;
	}
>;

type BaseEventPayload = {
	source: string;
};

type TrackComponentUsageRenderedPayload = BaseEventPayload & {
	isLegacyUiKit: boolean;
	forgeEnvironment: EnvironmentType;
	forgeReactMajorVersion: string | null;
	components: Components;
};

type TrackBridgePayload = BaseEventPayload & {
	method: string;
	payloadFields?: string[];
};

// For extensibility
type TrackBridgeSucceedPayload = TrackBridgePayload;

type TrackBridgeFailedPayload = TrackBridgePayload & {
	errorName: string;
};

type TrackExtensionLoadedPayload = BaseEventPayload & {
	forgeEnvironment: EnvironmentType;
	renderType: 'CustomUI' | 'UIKit1' | 'UIKit';
	extensionType: 'extension' | 'inlineExtension' | 'bodiedExtension';
	// Used for tracking custom macro config loading
	isConfiguring?: boolean;
	isInserting?: boolean;
};

type FireEventPayload = {
	action: 'succeeded' | 'failed' | 'loaded' | 'rendered';
	actionSubject: 'forge.ui.bridge' | 'forge.ui.analytics.component_usage' | 'forge.ui.extension';
	source: string;
	attributes?: Record<string, unknown>;
};

const fireEvent = (
	createAnalyticsEvent: CreateUIAnalyticsEvent,
	{ action, actionSubject, source, attributes }: FireEventPayload,
) =>
	createAnalyticsEvent({
		eventType: OPERATIONAL_EVENT_TYPE,
		data: {
			action,
			actionSubject,
			attributes,
			source,
			tags: ['forge'],
		},
	}).fire(FORGE_UI_ANALYTICS_CHANNEL);

export const trackBridgeSucceed = (
	createAnalyticsEvent: CreateUIAnalyticsEvent,
	{ source, method, payloadFields }: TrackBridgeSucceedPayload,
) =>
	fireEvent(createAnalyticsEvent, {
		action: 'succeeded',
		actionSubject: 'forge.ui.bridge',
		source,
		attributes: {
			method,
			payloadFields,
		},
	});

export const trackBridgeFailed = (
	createAnalyticsEvent: CreateUIAnalyticsEvent,
	{ source, errorName, method }: TrackBridgeFailedPayload,
) =>
	fireEvent(createAnalyticsEvent, {
		action: 'failed',
		actionSubject: 'forge.ui.bridge',
		source,
		attributes: {
			error: errorName,
			method,
		},
	});

export const trackComponentUsageRendered = (
	createAnalyticsEvent: CreateUIAnalyticsEvent,
	{
		source,
		isLegacyUiKit,
		forgeEnvironment,
		components,
		forgeReactMajorVersion,
	}: TrackComponentUsageRenderedPayload,
) =>
	fireEvent(createAnalyticsEvent, {
		action: 'rendered',
		actionSubject: 'forge.ui.analytics.component_usage',
		source,
		attributes: {
			appType: `UIKit${isLegacyUiKit ? '1' : ''}`,
			forgeEnvironment,
			components,
			forgeReactMajorVersion,
		},
	});

export const trackExtensionLoaded = (
	createAnalyticsEvent: CreateUIAnalyticsEvent,
	{
		source,
		forgeEnvironment,
		renderType,
		extensionType,
		isConfiguring,
		isInserting,
	}: TrackExtensionLoadedPayload,
) =>
	fireEvent(createAnalyticsEvent, {
		action: 'loaded',
		actionSubject: 'forge.ui.extension',
		source,
		attributes: {
			forgeEnvironment,
			renderType,
			extensionType,
			isConfiguring,
			isInserting,
		},
	});

export const useForgeUiAnalyticsEvent = () => {
	const { createAnalyticsEvent } = useAnalyticsEvents();
	const { page: source } = useMetricsContext();

	return {
		trackComponentUsageRendered: ({
			isLegacyUiKit,
			forgeEnvironment,
			components,
			forgeReactMajorVersion,
		}: Omit<TrackComponentUsageRenderedPayload, 'source'>) =>
			trackComponentUsageRendered(createAnalyticsEvent, {
				source,
				isLegacyUiKit,
				forgeEnvironment,
				components,
				forgeReactMajorVersion,
			}),
		trackExtensionLoaded: ({
			forgeEnvironment,
			renderType,
			extensionType,
			isConfiguring,
			isInserting,
		}: Omit<TrackExtensionLoadedPayload, 'source'>) =>
			trackExtensionLoaded(createAnalyticsEvent, {
				source,
				forgeEnvironment,
				renderType,
				extensionType,
				isConfiguring,
				isInserting,
			}),
		trackBridgeSucceed: ({ method }: Omit<TrackBridgeSucceedPayload, 'source'>) =>
			trackBridgeSucceed(createAnalyticsEvent, { source, method }),
		trackBridgeFailed: ({ method, errorName }: Omit<TrackBridgeFailedPayload, 'source'>) =>
			trackBridgeFailed(createAnalyticsEvent, { source, errorName, method }),
	};
};
