import React, { useCallback } from 'react';
import { defineMessages, FormattedMessage, useIntl } from 'react-intl-next';
import { styled } from '@compiled/react';

import Select from '@atlaskit/select';
import { Label } from '@atlaskit/form';
import { useAnalyticsEvents } from '@atlaskit/analytics-next';
import { layers } from '@atlaskit/theme/constants';

import { CardSizes } from '../../../linkCardsTypes';
import type { UpdateMainFormFieldType } from '../../../../shared-components/ConfigPanel/configPanelTypes';
import {
	ConfigPanelSubheading,
	FormFieldErrorMessage,
	useIsDisabledContext,
} from '../../../../shared-components';

export const DEFAULT_CARDS_SIZE = CardSizes.MEDIUM;

type SizeSelectOption = {
	label: React.ReactNode;
	value: CardSizes;
};

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const SizeFieldFlexContainer = styled.div({
	display: 'flex',
	flexDirection: 'column',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const SelectWrapper = styled.div({
	display: 'flex',
	flexDirection: 'column',
});

const i18n = defineMessages({
	sizeTextLabel: {
		id: 'custom-sites-extensions.link-cards.link-cards-config-panel.size-text-label',
		defaultMessage: 'Size',
		description: 'A visible text label for the size section of the link cards config panel',
	},
	sizeSmallDropDownItemLabel: {
		id: 'custom-sites-extensions.link-cards.link-cards-config-panel.size-field.drop-down.item.small.label',
		defaultMessage: 'Small',
		description:
			'A visible label for the select option/selection/item to change card size to small for link card',
	},
	sizeMediumDropDownItemLabel: {
		id: 'custom-sites-extensions.link-cards.link-cards-config-panel.size-field.drop-down.item.medium.label',
		defaultMessage: 'Medium',
		description:
			'A visible label for the select option/selection/item to change card to size to medium for link card',
	},
	sizeLargeDropDownItemLabel: {
		id: 'custom-sites-extensions.link-cards.link-cards-config-panel.size-field.drop-down.item.large.label',
		defaultMessage: 'Large',
		description:
			'A visible label for the select option/selection/item to change card to size to large for link card',
	},
	sizeParameterInvalidMessage: {
		id: 'custom-sites-extensions.link-cards.link-cards-config-panel.size.error.invalid-parameter',
		defaultMessage: 'Select size',
		description:
			'Message for error shown to user when a value for size is not provided or is invalid',
	},
});

const sizeSelectOptions: SizeSelectOption[] = [
	{
		label: <FormattedMessage {...i18n.sizeSmallDropDownItemLabel} />,
		value: CardSizes.SMALL,
	},
	{
		label: <FormattedMessage {...i18n.sizeMediumDropDownItemLabel} />,
		value: CardSizes.MEDIUM,
	},
	{
		label: <FormattedMessage {...i18n.sizeLargeDropDownItemLabel} />,
		value: CardSizes.LARGE,
	},
];

type SizeFieldProps = {
	sizeParameter: CardSizes;
	updateMainFormField: UpdateMainFormFieldType;
};

export const SizeField: React.FC<SizeFieldProps> = ({ sizeParameter, updateMainFormField }) => {
	const { formatMessage } = useIntl();
	const { createAnalyticsEvent } = useAnalyticsEvents();
	const isDisabled = useIsDisabledContext();

	const selectedOption = sizeSelectOptions.find(({ value }) => value === sizeParameter);

	const handleSelectOnChange = useCallback(
		(selectedLabel: any) => {
			updateMainFormField({ size: selectedLabel.value });

			createAnalyticsEvent({
				type: 'sendUIEvent',
				data: {
					action: 'clicked',
					actionSubject: 'dropdownItem',
					actionSubjectId: 'sizeDropdown',
					source: 'cardsExtensionConfig',
					attributes: {
						size: selectedLabel.value,
					},
				},
			}).fire();
		},
		[updateMainFormField, createAnalyticsEvent],
	);

	return (
		<SizeFieldFlexContainer>
			<Label htmlFor="card-size-selector">
				<ConfigPanelSubheading>
					<FormattedMessage {...i18n.sizeTextLabel} />
				</ConfigPanelSubheading>
			</Label>
			<SelectWrapper>
				<Select
					id="card-size-selector"
					inputId="card-size-select-input"
					// eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766
					className="card-size-single-select"
					classNamePrefix="card-size-select"
					isSearchable={false}
					value={selectedOption}
					onChange={handleSelectOnChange}
					options={sizeSelectOptions}
					isDisabled={isDisabled}
					label={formatMessage(i18n.sizeTextLabel)}
					styles={{
						menu: (base) => ({
							...base,
							zIndex: layers.card(),
						}),
					}}
				/>
				{!selectedOption && (
					<FormFieldErrorMessage>
						<FormattedMessage {...i18n.sizeParameterInvalidMessage} />
					</FormFieldErrorMessage>
				)}
			</SelectWrapper>
		</SizeFieldFlexContainer>
	);
};
