/**
 * @jsxRuntime classic
 * @jsx jsx
 */
// eslint-disable-next-line @atlaskit/ui-styling-standard/use-compiled -- Ignored via go/DSP-18766
import { jsx } from '@emotion/react';
import { useIntl } from 'react-intl-next';

import { IconButton } from '@atlaskit/button/new';
import { type Command } from '@atlaskit/editor-common/types';
import type { EditorView } from '@atlaskit/editor-prosemirror/view';
import CrossIcon from '@atlaskit/icon/glyph/cross';
import Modal, {
	ModalBody,
	ModalFooter,
	ModalHeader,
	ModalTitle,
	ModalTransition,
} from '@atlaskit/modal-dialog';
import { Flex, Grid, xcss } from '@atlaskit/primitives';

import { toggleDisplayAllSetting } from '../../pm-plugins/ai-proactive/commands';
import { getPluginState as getProactivePluginState } from '../../pm-plugins/ai-proactive/plugin-factory';

import SuggestedEditIcon from './assets/SuggestedEditIcon';
import messages from './messages';
import { SettingItem } from './setting-item';

const gridStyles = xcss({
	width: '100%',
});

const closeContainerStyles = xcss({
	gridArea: 'close',
});

const titleContainerStyles = xcss({
	gridArea: 'title',
});

type SuggestionSettingProps = {
	isOpen: boolean;
	toggleModal: () => void;
	editorView: EditorView;
};

export const SuggestionSetting = (props: SuggestionSettingProps) => {
	const { isOpen, toggleModal, editorView } = props;

	const { formatMessage } = useIntl();

	const runCommand = (command: Command) => {
		const { state, dispatch } = editorView;
		command(state, dispatch);
	};

	const onToggleDisplayAllSetting = () => {
		runCommand(toggleDisplayAllSetting());
	};

	const proactivePluginState = getProactivePluginState(editorView!.state);

	const { displayAllSuggestions } = proactivePluginState;

	return (
		<ModalTransition>
			{isOpen && (
				<Modal onClose={toggleModal}>
					<ModalHeader>
						<Grid gap="space.200" templateAreas={['title close']} xcss={gridStyles}>
							<Flex xcss={closeContainerStyles} justifyContent="end">
								<IconButton
									appearance="subtle"
									icon={CrossIcon}
									label="Close Modal"
									onClick={toggleModal}
								/>
							</Flex>
							<Flex xcss={titleContainerStyles} justifyContent="start">
								<ModalTitle>{formatMessage(messages.ManageSuggestionTitle)}</ModalTitle>
							</Flex>
						</Grid>
					</ModalHeader>
					<ModalBody>
						<SettingItem
							icon={<SuggestedEditIcon />}
							title={formatMessage(messages.ToggleAllSuggestionsTitle)}
							description={formatMessage(messages.ToggleAllSuggestionsDescription)}
							defaultValue={displayAllSuggestions}
							toggle={onToggleDisplayAllSetting}
						/>
					</ModalBody>
					<ModalFooter />
				</Modal>
			)}
		</ModalTransition>
	);
};
