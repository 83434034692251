import { defineMessages } from 'react-intl-next';

export default defineMessages({
	ManageSuggestionTitle: {
		id: 'fabric.editor.ai.proactive.moreMenu.manageSuggestions.non-final',
		defaultMessage: 'Manage Suggestions',
		description:
			'This is the message displayed in the more menu dropdown in the context panel to allow the user to manage suggestions on the suggestions',
	},
	ToggleAllSuggestionsTitle: {
		id: 'fabric.editor.ai.proactive.moreMenu.ToggleAllSuggestionsTitle.non-final',
		defaultMessage: 'All suggestions',
		description:
			'This is the title displayed in the modal to allow the user to toggle all suggestions on or off',
	},
	ToggleAllSuggestionsDescription: {
		id: 'fabric.editor.ai.proactive.moreMenu.ToggleAllSuggestionsDescription.non-final',
		defaultMessage: 'Toggle to turn suggested edits on or off.',
		description:
			'This is the description displayed in the modal to allow the user to toggle all suggestions on or off',
	},
});
