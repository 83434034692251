import type { ReactNode } from 'react';
import React, { useContext, useCallback, useMemo } from 'react';

import {
	collectAll,
	ExperienceTracker,
	ExperienceTrackerContext,
	ExperienceStart,
	forwardExperience,
	ADMIN_CENTER_OVERVIEW_EXPERIENCE,
	ADMIN_CENTER_OVERVIEW_UPDATE_EXPERIENCE,
	ADMIN_CENTER_EXPERIENCE,
	SPACE_ADMIN_CENTER_EXPERIENCE,
	ADMIN_CENTER_PREMIUM_TRIAL_INSIGHTS_EXPERIENCE,
	ADMIN_CENTER_SUGGESTIONS_EXPERIENCE,
	COMPANY_HUB_GENERAL_SETTINGS_EXPERIENCE,
} from '@confluence/experience-tracker';

const MAIN_EXPERIENCES = [
	ADMIN_CENTER_EXPERIENCE,
	ADMIN_CENTER_OVERVIEW_EXPERIENCE,
	ADMIN_CENTER_OVERVIEW_UPDATE_EXPERIENCE,
	SPACE_ADMIN_CENTER_EXPERIENCE,
	ADMIN_CENTER_PREMIUM_TRIAL_INSIGHTS_EXPERIENCE,
	ADMIN_CENTER_SUGGESTIONS_EXPERIENCE,
	COMPANY_HUB_GENERAL_SETTINGS_EXPERIENCE,
];

type SubExperienceTrackerContextProviderProps = {
	mainExperience: (typeof MAIN_EXPERIENCES)[number];
	subExperiences: string[] | null | undefined;
	children: ReactNode;
};

export const SubExperienceTrackerContextProvider = ({
	mainExperience,
	subExperiences,
	children,
}: SubExperienceTrackerContextProviderProps) => {
	const mainExperienceTracker = useContext(ExperienceTrackerContext);

	const subExperienceTracker = useMemo(() => {
		const tracker = new ExperienceTracker();
		tracker.subscribe((event) => {
			if (MAIN_EXPERIENCES.includes(event.name)) {
				// If event corresponds with the main experience, then forward the event to mainExperienceTracker.
				// mainExperienceTracker handles sending analytics events over the network, whereas subExperienceTracker
				// just collects sub-experience tracking events locally.
				forwardExperience(mainExperienceTracker, event);
			}
		});
		return tracker;
	}, [mainExperienceTracker]);

	// Handler for collecting sub-experience events to succeed/fail/abort the main experience.
	const collect = useCallback(
		(events: any, experience: any) => {
			if (subExperiences) {
				collectAll(subExperiences)(events, experience);
			}
		},
		[subExperiences],
	);

	return (
		<ExperienceTrackerContext.Provider value={subExperienceTracker}>
			<ExperienceStart name={mainExperience} collect={collect} />
			{children}
		</ExperienceTrackerContext.Provider>
	);
};
