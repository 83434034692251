import React, { useContext, useEffect, useMemo } from 'react';
import type { MessageDescriptor } from 'react-intl-next';
import { defineMessages } from 'react-intl-next';
import { useQuery } from '@apollo/react-hooks';
import type { NumberFormatOptionsStyle } from '@formatjs/ecma402-abstract/types/number';

import { Grid } from '@atlaskit/primitives';

import { MetricSettingsContext } from '@confluence/admin-center/entry-points/MetricSettingsContext';
import {
	COMPANY_HUB_ANALYTICS_BOX_ROW_EXPERIENCE,
	ExperienceFailure,
	ExperienceSuccess,
	ExperienceTrackerContext,
} from '@confluence/experience-tracker';
import { usePageState } from '@confluence/page-context';

import { AnalyticBoxBase } from './AnalyticBoxBase';
import { AnalyticBoxesRowQuery } from './AnalyticBoxesRowQuery.graphql';
import type { AnalyticBoxesRowQuery as AnalyticBoxesRowQueryType } from './__types__/AnalyticBoxesRowQuery';
import { Metric } from './SelectedMetricContext';
import { getAdjustedStartDate } from './HubAnalyticsUtils';

const i18n = defineMessages({
	viewsBoxTitle: {
		id: 'company-hub.analytics.boxes-row.views-title',
		defaultMessage: 'Views',
		description: 'Title for the analytic box that displays the total number of company hub views',
	},
	uniqueVisitorsBoxTitle: {
		id: 'company-hub.analytics.boxes-row.unique-visitors-title',
		defaultMessage: 'Unique visitors',
		description:
			'Title for the analytic box that displays the total number of company hub unique visitors',
	},
	clicksBoxTitle: {
		id: 'company-hub.analytics.boxes-row.clicks-title',
		defaultMessage: 'Clicks',
		description:
			'Title for the analytic box that displays the total number of company hub link clicks',
	},
	ctrBoxTitle: {
		id: 'company-hub.analytics.boxes-row.ctr-title',
		defaultMessage: 'Click-thru rate (average)',
		description:
			'Title for the analytic box that displays the company hub click-through-rate average',
	},
});

type AnalyticBox = {
	metric: Metric;
	analyticName: MessageDescriptor;
	analyticValue?: number;
	analyticStyle?: NumberFormatOptionsStyle;
	testId?: string;
};

type AnalyticBoxesRowProps = {
	isSpaceCreatedDateOver1DayAgo: boolean | undefined;
};

export const AnalyticBoxesRow = ({ isSpaceCreatedDateOver1DayAgo }: AnalyticBoxesRowProps) => {
	const { startDate, endDate } = useContext(MetricSettingsContext);
	const [{ contentId }] = usePageState();

	const adjustedStartDate = useMemo(() => {
		return getAdjustedStartDate({ startDate, endDate });
	}, [endDate, startDate]);

	const { data, loading, error } = useQuery<AnalyticBoxesRowQueryType>(
		// eslint-disable-next-line graphql-relay-compat/no-import-graphql-operations -- Read https://go/connie-relay-migration-fyi
		AnalyticBoxesRowQuery,
		{
			variables: {
				contentId,
				startDate: adjustedStartDate.toISOString(),
				endDate: endDate.toISOString(),
			},
			skip: !contentId || !isSpaceCreatedDateOver1DayAgo,
		},
	);

	const views = (isSpaceCreatedDateOver1DayAgo && data?.viewsForPage?.nodes?.[0]?.totalViews) || 0;
	const uniqueVisitors =
		(isSpaceCreatedDateOver1DayAgo && data?.uniqueVisitorsForPage?.nodes?.[0]?.user) || 0;
	const clicks = (isSpaceCreatedDateOver1DayAgo && data?.clicksForPage?.nodes?.[0]?.count) || 0;
	const ctr = (isSpaceCreatedDateOver1DayAgo && data?.ctr?.nodes?.[0]?.ctr) || 0;

	const analyticBoxes: AnalyticBox[] = useMemo(
		() => [
			{
				metric: Metric.VIEWS,
				analyticName: i18n.viewsBoxTitle,
				analyticValue: views,
				testId: 'views-analytic-box',
			},
			{
				metric: Metric.UNIQUE_VISITORS,
				analyticName: i18n.uniqueVisitorsBoxTitle,
				analyticValue: uniqueVisitors,
				testId: 'unique-visitors-analytic-box',
			},
			{
				metric: Metric.CLICKS,
				analyticName: i18n.clicksBoxTitle,
				analyticValue: clicks,
				testId: 'clicks-analytic-box',
			},
			{
				metric: Metric.CTR,
				analyticName: i18n.ctrBoxTitle,
				analyticValue: ctr / 100,
				analyticStyle: 'percent',
				testId: 'ctr-analytic-box',
			},
		],
		[clicks, ctr, uniqueVisitors, views],
	);

	const experienceTracker = useContext(ExperienceTrackerContext);

	useEffect(() => {
		experienceTracker.start({ name: COMPANY_HUB_ANALYTICS_BOX_ROW_EXPERIENCE });
	}, [experienceTracker]);

	return (
		<>
			{error ? (
				<ExperienceFailure name={COMPANY_HUB_ANALYTICS_BOX_ROW_EXPERIENCE} error={error} />
			) : (
				!loading && <ExperienceSuccess name={COMPANY_HUB_ANALYTICS_BOX_ROW_EXPERIENCE} />
			)}
			<Grid gap="space.200" templateColumns="1fr 1fr 1fr 1fr" testId="hub-analytic-boxes">
				{analyticBoxes.map(({ metric, analyticName, analyticValue, analyticStyle, testId }) => (
					<AnalyticBoxBase
						metric={metric}
						analyticName={analyticName}
						analyticValue={analyticValue}
						analyticStyle={analyticStyle}
						isLoading={loading}
						hasError={!!error}
						key={testId}
						testId={testId}
					/>
				))}
			</Grid>
		</>
	);
};
