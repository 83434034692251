/* GeneratedContentHash: fc3b4472c61da666968922ec9a0f8588cbfeb7b5d7eb3e17aba194a67ef0578c */
/*
# This file was automatically generated using "yarn generate:all" command.
# Please do not edit it manually.
# These exports are for client side use only
# Importing into post office backend without a bundler will break the build
#
# Generate source: local/cli/src/commands/generate-all/team-export.ts
*/
import { lazy } from "react";

export default {
	'mercury-edit-hierarchy-spotlight': lazy(
			() =>
			import(
				/* webpackChunkName: "[request]" */
				'@post-office/message-template--mercury-edit-hierarchy-spotlight/placements/in-app/screen-space-flags/render'
			),
	),
};
