import partition from 'lodash/partition';

import type { Node as PMNode } from '@atlaskit/editor-prosemirror/model';
import type { EditorView } from '@atlaskit/editor-prosemirror/view';
import type { IDMap } from '@atlassian/ai-model-io/convert-prosemirror-to-markdown/serializer';

import { sliceOrNodeToMarkdown } from '../../config-items/slice-or-node-to-markdown';
import { type ParagraphChunk } from '../../utils/diff-match-patch/utils';

import type { Suggestion, SuggestionGenerator } from './api';
import {
	MAX_PARTS,
	PART_MAX_SIZE,
	QUEUE_MAX_ADDITIONAL_ATTEMPTS,
	TOTAL_PARTS_MAX_SIZE,
} from './constants';

/**
 * When updating this type, please update the corresponding type guard functions too
 */
type ValidStreamingResponse =
	| {
			id: string;
			part: string;
			metadata?: {
				edit_distance_ratio?: string;
				original_content_length?: string;
				word_count_ratio?: string;
			};
	  }
	| {
			id: string;
			error: string;
	  };

function isValidStreamingResponse(response: unknown): response is ValidStreamingResponse {
	return (
		typeof response === 'object' &&
		response !== null &&
		'id' in response &&
		('part' in response || 'error' in response)
	);
}

type ParseResponseReturnType =
	| {
			result: true;
			request: {
				chunk: ParagraphChunkWithMDPlus;
			};
			response: Suggestion;
	  }
	| {
			result: false;
			error: string;
			chunkId?: string;
	  };

type ParagraphChunkWithMDPlus = ParagraphChunk & {
	markdown: string;
	chunkIdMap: IDMap;
};

const getParagraphChunkWithMDPlus = (
	doc: PMNode,
	editorView: EditorView,
	chunk: ParagraphChunk,
): ParagraphChunkWithMDPlus | undefined => {
	let chunkIdMap = {};
	const { from, to } = chunk;
	const slice = doc.slice(from, to);
	if (!slice) {
		return;
	}
	const { markdown } = sliceOrNodeToMarkdown({
		editorView,
		slice,
		convertTo: 'markdown-plus',
		updateIdMap: ({ idMap }) => {
			chunkIdMap = idMap;
		},
		selectionType: 'range',
	});

	chunk = getChunkWithParentTree(editorView, chunk);

	return {
		...chunk,
		markdown,
		chunkIdMap,
	};
};

const getChunkWithParentTree = (editorView: EditorView, chunk: ParagraphChunk) => {
	const { from } = chunk;

	const resolvedPos = editorView.state.doc.resolve(from);

	const parentTree = [];
	for (let depth = resolvedPos.depth; depth > 0; depth--) {
		const node = resolvedPos.node(depth);
		if (node) {
			parentTree.unshift(node.type.name);
		}
	}

	chunk.parentTree = parentTree;

	return chunk;
};

function parseResponse(
	queue: Array<ParagraphChunkWithMDPlus>,
	response: any,
): ParseResponseReturnType {
	const chunk = queue.find((p) => response?.id === p.id);

	if (!chunk || !isValidStreamingResponse(response)) {
		return {
			result: false,
			error: 'Invalid streaming response',
			// It may be possible that id is in response but part or error does not exist.
			chunkId: response.id,
		};
	}

	if ('error' in response) {
		return {
			result: false,
			error: `Missing part: ${response.error}`,
			chunkId: chunk.id,
		};
	}

	return {
		result: true,
		request: {
			chunk,
		},
		response: {
			chunkId: chunk.id,
			suggestion: response.part,
			chunkIdMap: chunk.chunkIdMap,
			...(response.metadata && {
				metadata: {
					editDistanceRatio: Number(response.metadata.edit_distance_ratio) || undefined,
					wordCountRatio: Number(response.metadata.word_count_ratio) || undefined,
					originalContentLength: Number(response.metadata.original_content_length) || undefined,
				},
			}),
		},
	};
}

/**
 * Only store limited entries for now to prevent the cache from getting too large
 * This is primarily to make sure any Undo / Redo actions where the
 * state reverts to an original checked state won't request the same paragraph again
 */
const paragraphCache = new Map<string, string>();
const PARAGRAPH_CACHE_MAX_SIZE = 128;

export async function* mdPlusSuggestionGenerator({
	endpoint,
	paragraphs,
	editorView,
	locale,
}: {
	endpoint: string;
	paragraphs: Array<ParagraphChunk>;
	editorView: EditorView;
	locale: string;
}): SuggestionGenerator {
	const [cachedParagraphs, uncachedParagraphs] = partition(paragraphs, (p: ParagraphChunk) =>
		paragraphCache.has(p.text),
	);

	if (cachedParagraphs.length > 0) {
		const suggestions: Suggestion[] = [];
		for (const cachedParagraph of cachedParagraphs) {
			const suggestion = paragraphCache.get(cachedParagraph.text);
			if (suggestion) {
				// Re-add the key / value to keep it on top of the Map iter order
				paragraphCache.delete(cachedParagraph.text);
				paragraphCache.set(cachedParagraph.text, suggestion);
				suggestions.push({
					chunkId: cachedParagraph.id,
					suggestion,
				});
			}
		}
		yield {
			state: 'cached',
			suggestions,
		};
	}

	if (!uncachedParagraphs.length) {
		yield { state: 'done' };
		return;
	}

	/**
	 * QUEUE CONSTRAINTS:
	 * Input size sum (all parts): 10,000 chars
	 * Input size per part: 5,000 chars
	 * Max parts: 50
	 */

	const doc = editorView.state.doc;
	const abortController = new AbortController();

	const purgedChunkIds: Array<ParagraphChunk['id']> = [];

	const totalParts = uncachedParagraphs.length;
	let attemptCount = 0;

	// Partition into parts that are within the part size limit and parts that exceed it
	// This is done due to backend limitations
	const [withinLimitsParagraphChunks, exceededLimitsParagraphChunks] = partition(
		uncachedParagraphs,
		(p) => p.text.length <= PART_MAX_SIZE,
	);

	// For all parts that exceed the parts limit, add them to the purged list
	purgedChunkIds.push(...exceededLimitsParagraphChunks.map((p) => p.id));

	while (withinLimitsParagraphChunks.length && attemptCount <= QUEUE_MAX_ADDITIONAL_ATTEMPTS) {
		attemptCount++;
		const queue: ParagraphChunkWithMDPlus[] = [];
		// const queue = new Map<ParagraphChunk['id'], ParagraphChunk['text']>();
		let queueTotalPartsSize = 0;
		/**
		 * Fill the queue with parts up until the constraints have been met or until all parts have been added
		 */
		while (
			withinLimitsParagraphChunks.length &&
			queueTotalPartsSize + withinLimitsParagraphChunks[0].text.length <= TOTAL_PARTS_MAX_SIZE &&
			queue.length <= MAX_PARTS
		) {
			const withinLimitsParagraphChunk = withinLimitsParagraphChunks.shift();
			if (!withinLimitsParagraphChunk) {
				break;
			}
			queueTotalPartsSize += withinLimitsParagraphChunk.text.length;
			const paragraphChunkWithMDPlus = getParagraphChunkWithMDPlus(
				doc,
				editorView,
				withinLimitsParagraphChunk,
			);
			if (paragraphChunkWithMDPlus) {
				queue.push(paragraphChunkWithMDPlus);
			}
		}

		if (!queue.length) {
			break;
		}
		const queuedChunkIds = queue.map((p) => p.id);

		try {
			/**
			 * API Spec documented at https://hello.atlassian.net/wiki/spaces/CA3/pages/3776161653/Bulk+Spelling+and+Grammar+Endpoint
			 */
			const requests: {
				[id: string]: string;
			} = {};

			const context: {
				[id: string]: string[] | undefined;
			} = {};

			// Only add uncached paragraphs to requests, since cached ones have already been yielded
			queue.forEach((chunk) => {
				requests[chunk.id] = chunk.markdown;
				context[chunk.id] = chunk.parentTree;
			});

			const payload = { requests, context };

			// track durations
			const startTime = performance.now();

			const response = await fetch(endpoint, {
				method: 'POST',
				headers: {
					'Content-Type': 'application/json;charset=UTF-8',
					'Accept-Language': locale,
				},
				body: JSON.stringify(payload),
				credentials: 'include',
				signal: abortController.signal,
				mode: 'cors',
			});

			yield {
				state: 'trackedDuration',
				duration: performance.now() - startTime,
			};

			if (!response.ok) {
				yield {
					state: 'failed',
					reason: 'backend',
					errors: [`unexpected response status: ${response.status}`],
					statusCode: response.status,
					failedChunkIds: queuedChunkIds,
				};
				break;
			}

			if (!response.body) {
				yield {
					state: 'failed',
					reason: 'network',
					errors: ['response.body missing'],
					statusCode: response.status,
					failedChunkIds: queuedChunkIds,
				};
				break;
			}

			const processedChunkIds: string[] = [];

			try {
				const reader = response.body.getReader();
				const decoder = new TextDecoder('utf-8');
				let lineBuffer = '';
				let done = false;

				while (!done) {
					const { value, done: doneReading } = await reader.read();
					done = doneReading;
					const chunkValue = decoder.decode(value);
					lineBuffer = lineBuffer + chunkValue;
					// Split the lineBuffer by line breaks
					const lines = lineBuffer.split('\n');
					// Process all complete lines, except for the last one (which might be incomplete)

					const suggestions: Suggestion[] = [];
					const errors: string[] = [];
					const failedChunkIds: string[] = [];

					while (lines.length > 1) {
						const line = lines.shift()!;
						const parsedData = JSON.parse(line);
						const parsedResponse = parseResponse(queue, parsedData);
						if (parsedResponse.result) {
							const { chunkId, metadata, suggestion } = parsedResponse.response;
							if (paragraphCache.size >= PARAGRAPH_CACHE_MAX_SIZE) {
								// .keys().next() will return the oldest created key in the map
								paragraphCache.delete(paragraphCache.keys().next().value);
							}
							paragraphCache.set(parsedResponse.request.chunk.text, suggestion);
							suggestions.push(parsedResponse.response);
							processedChunkIds.push(chunkId);
							if (metadata) {
								yield {
									state: 'receivedChunkMetadata',
									chunkId,
									metadata,
								};
							}
						} else {
							errors.push(parsedResponse.error);
							if (parsedResponse.chunkId) {
								failedChunkIds.push(parsedResponse.chunkId);
							}
						}
					}
					if (suggestions.length) {
						yield { state: 'parsed', suggestions };
					}
					if (errors.length) {
						yield {
							state: 'failed',
							reason: 'backend',
							errors,
							statusCode: response.status,
							failedChunkIds,
						};
					}

					// Keep the last (potentially incomplete) line in the lineBuffer
					lineBuffer = lines[0];
				}
			} catch (parsingError) {
				yield {
					state: 'failed',
					reason: 'parsing',
					errors: ['parsingError'],
					statusCode: response.status,
					failedChunkIds: queuedChunkIds.filter((id) => !processedChunkIds.includes(id)),
				};
			}
		} catch (error: any) {
			if (error.name === 'AbortError') {
				yield {
					state: 'failed',
					reason: 'aborted',
					errors: ['Streaming aborted'],
					statusCode: error.status,
					failedChunkIds: queuedChunkIds,
				};
			} else {
				yield {
					state: 'failed',
					reason: 'unhandled',
					errors:
						error instanceof Error &&
						['RangeError', 'TypeError', 'TransformError'].includes(error.name)
							? [error.message]
							: ['unhandled'],
					statusCode: error.status,
					failedChunkIds: queuedChunkIds,
				};
			}
		}
	}

	/**
	 * Purge any remaining valid paragraphs
	 */
	if (withinLimitsParagraphChunks.length) {
		purgedChunkIds.push(...withinLimitsParagraphChunks.map((p) => p.id));
	}

	if (purgedChunkIds.length) {
		yield {
			state: 'purged',
			totalParts,
			totalPurgedParts: purgedChunkIds.length,
			purgedChunkIds,
		};
	}

	yield { state: 'done' };
	return;
}
