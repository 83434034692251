import React, { useState, useEffect } from 'react';
import { useQuery } from '@apollo/react-hooks';

import { useSessionData } from '@confluence/session-data';
import { fg } from '@confluence/feature-gating';
import { useIsProductAdmin } from '@confluence/current-user';
import { confluenceLocalStorageInstance as localStorage, keys } from '@confluence/storage-manager';
import {
	useLivePageReleasePhase,
	LivePageReleasePhase,
} from '@confluence/live-pages-release-manager';

import { SiteUserPersonalizationQuery } from './queries/SiteUserPersonalizationQuery.experimentalgraphql';
import type {
	SiteUserPersonalizationQuery as SiteUserPersonalizationQueryData,
	SiteUserPersonalizationQueryVariables,
} from './queries/__types__/SiteUserPersonalizationQuery';
import { LivePagesOpenBetaPromptModalLoader } from './LivePagesOpenBetaPromptModalLoader';

const MODAL_FIRST_SEEN_KEY = 'confluence_screen_viewed_livepagesopenbetapromptmodal_first_time';

const hasSeenModalBefore = (data: SiteUserPersonalizationQueryData) => {
	return !!data?.experimentalSiteUserPersonalization?.attributes?.some(
		({ name }) => name === MODAL_FIRST_SEEN_KEY,
	);
};

export const LivePagesOpenBetaPromptModalWrapper = () => {
	const [seenBefore, setSeenBefore] = useState(false);
	const { userId, cloudId } = useSessionData();
	const livePageReleasePhase = useLivePageReleasePhase();
	const { isProductAdmin } = useIsProductAdmin();

	useEffect(() => {
		const localSeenBefore = localStorage.getItem(keys.LIVE_PAGES_OPEN_BETA_PROMPT_MODAL_FIRST_SEEN);
		if (localSeenBefore) {
			setSeenBefore(true);
		}
	}, []);

	const skipQuery =
		!isProductAdmin || // not a product admin
		livePageReleasePhase !== LivePageReleasePhase.OPEN_BETA_INITIAL || // not in open beta initial phase
		seenBefore; // already seen the modal

	const { data, error, loading } = useQuery<
		SiteUserPersonalizationQueryData,
		SiteUserPersonalizationQueryVariables
	>(
		// eslint-disable-next-line graphql-relay-compat/no-import-graphql-operations -- Read https://go/connie-relay-migration-fyi
		SiteUserPersonalizationQuery,
		{
			variables: {
				cloudId,
				userId: userId || '',
			},
			fetchPolicy: 'cache-and-network', // we want to ensure we get the latest data because the trait updates in real time
			skip: skipQuery,
			onCompleted: (result) => {
				setSeenBefore(hasSeenModalBefore(result));
			},
		},
	);

	// only checks if modal passes basic conditions to be shown, does not check coordination with choreographer
	// if the query is skipped, it means the user is not eligible to see the modal
	if (
		skipQuery ||
		!!error ||
		loading ||
		(data && hasSeenModalBefore(data)) ||
		!fg('live_pages_open_beta_in_product_prompt_dismissible')
	) {
		return null;
	}

	return <LivePagesOpenBetaPromptModalLoader />;
};
