/* eslint-disable @atlaskit/design-system/no-css-tagged-template-expression */
// Styles have been copied to packages/editor/generative-ai-modal/examples/utils/components/Modals.tsx
// Updates to this file should also be copied to there.
// eslint-disable-next-line @atlaskit/ui-styling-standard/use-compiled -- Ignored via go/DSP-18766
import { css, keyframes } from '@emotion/react';

import {
	akEditorFloatingPanelZIndex,
	getSelectionStyles,
	SelectionStyle,
} from '@atlaskit/editor-shared-styles';
import { N200, N700 } from '@atlaskit/theme/colors';
import { editorExperiment } from '@atlaskit/tmp-editor-statsig/experiments';
import { token } from '@atlaskit/tokens';
import type { AIThemeColor } from '@atlassian/generative-ai-modal/styles/theme';
// Where do we get this magic number 500?
// - The lowest product z-index we need to be below is 500 from the Feedback Collector.
// As well as the Editor toolbar popup menu, which is also 500.
// This allows us to be above the floating toolbar, but below the feedback collector.
// +2 because tables floating toolbar is +1.
const MAX_Z_INDEX = 500;

// Where do we get this magic number 401?
// - The highest product z-index we need to be above is 401 from the floating toolbar*.
// *in Confluence and Bitbucket. It is 600px in Atlas & 511 in Jira, however that is a more niche case than the Feedback Collector,
// so this is a known trade-off until we have a better solution.
const MIN_Z_INDEX = 402;

// Sets the z-index so it is not greater than the maximum, and less than the minimum.
let akAiModalRegionZIndex = Math.min(
	MAX_Z_INDEX,
	Math.max(MIN_Z_INDEX, akEditorFloatingPanelZIndex + 2),
);

const slideFadeIn = keyframes({
	from: {
		opacity: 0,
		transform: 'translateY(1rem)',
	},
	to: {
		opacity: 1,
		transform: 'translateY(0)',
	},
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const modalStyles = css`
	margin-top: 1rem;
	position: absolute;
	z-index: ${akAiModalRegionZIndex};
	margin-right: -1rem;
	margin-left: -1rem;
	width: calc(var(--content-width) + 2rem);
	animation: ${slideFadeIn} 0.2s ease-out;
	animation-fill-mode: forwards;

	@media (prefers-reduced-motion) {
		animation: none;
	}
`;

const aiSuggestionsInlineCommentResetStyles = css`
	padding: inherit;
	border-bottom: inherit;
	box-shadow: inherit;
`;

const aiSuggestionsSelectedInlineCommentBackgroundStyles = css`
	background-color: ${token('color.background.accent.yellow.subtlest', '#FFF7D6')};
`;

const highlightStyles = css`
	background-color: ${token('color.background.accent.blue.subtlest', '#E9F2FF')};
	border-bottom: 2px solid ${token('color.background.accent.blue.subtler', '#cce0ff')};
`;

const nodeHighlightStyles = css`
	border: 1px solid ${token('color.background.accent.blue.subtle', '#579DFF')};
	box-shadow: 0 0 2px 1px ${token('color.background.accent.blue.subtler', '#cce0ff')};
`;

const cellHighlightStyles = css`
	${nodeHighlightStyles}
	::after {
		border-left: 1px solid ${token('color.background.accent.blue.subtle', '#579DFF')};
		border-bottom: 1px solid ${token('color.background.accent.blue.subtle', '#579DFF')};
	}
`;

const spellingErrorStyles = css`
	border-bottom: 2px solid ${token('color.background.accent.magenta.subtle.hovered', '#F797D2')};
	border-bottom-style: dashed;
`;

const autoCorrectStyles = css`
	border-bottom: 2px solid ${token('color.text.accent.teal', '#206A83')};
	border-bottom-style: dotted;
`;

const spellingAndGrammarSuggestionStyles = css`
	border-bottom: 2px dotted ${token('color.chart.blue.bolder', '#1D7AFC')};
	cursor: pointer;
`;

const spellingAndGrammarSuggestionSelectedStyles = css`
	border-bottom: 2px dotted ${token('color.chart.blue.bolder', '#1D7AFC')};
	background-color: ${token('color.background.accent.blue.subtlest', '#E9F2FF')};
	cursor: pointer;
`;

const proactiveRecommendationStyles = css`
	border-bottom: 2px dotted ${token('color.chart.blue.bolder', '#1D7AFC')};
	cursor: pointer;
`;

const proactiveRecommendationSelectedStyles = css`
	border-bottom: 2px dotted ${token('color.chart.blue.bolder', '#1D7AFC')};
	background-color: ${token('color.background.accent.blue.subtlest', '#E9F2FF')};
	cursor: pointer;
`;

// override standard dropdown max-height 90vh to accomodate for long list of translation languages
const translationsFloatingToolbarDropdownStyles = () => {
	return css`
		[aria-label='Annotation toolbar'],
		[aria-label='Selection toolbar'] {
			[data-role='droplistContent'] {
				max-height: 400px;
				overflow-x: hidden;
				[data-testid^='ak-editor-ai-selection-toolbar-translate-button']
					[data-item-elem-before]
					> span {
					padding: 0;
				}
			}
			[data-testid='ak-editor-ai-selection-toolbar-ai-dropdown'] > span > span {
				// override negative margin on dropdown button
				margin: 0;
			}
		}
	`;
};

const dropdownHeadingStyles = css`
	[aria-label='Selection toolbar'] {
		[data-testid^='ak-editor-ai-selection-toolbar-dropdown-heading'] {
			cursor: default;
			> span {
				gap: initial;
			}
			[data-item-elem-before] > span {
				padding: 0;
			}

			[data-item-title] {
				text-transform: uppercase;
				font-size: 0.75rem;
				font-weight: ${token('font.weight.bold')};
				color: ${token('color.text', N200)};
			}
		}
		[data-testid^='ak-editor-ai-selection-toolbar-dropdown-heading']:hover {
			color: ${token('color.text', N700)};
		}
	}
`;

const rovoAgentsFloatingToolbarDropdownStyles = css`
	[aria-label='Annotation toolbar'],
	[aria-label='Selection toolbar'] {
		[data-role='droplistContent'] {
			[data-testid='ak-editor-ai-selection-toolbar-dropdown-view-all-agents']:not(:only-child) {
				border-block-start: 2px solid
					var(--ds-menu-seperator-color, ${token('color.border', '#e1e3e7')});
				> span {
					gap: 4px;
				}
			}

			button[data-testid^='ak-editor-ai-selection-toolbar-dropdown-agent']
				[data-item-title='true']
				> span {
				white-space: normal;
			}
		}
	}
`;

const aiSelectionToolbarButtonStyles = () => {
	if (editorExperiment('contextual_formatting_toolbar', true)) {
		return css`
			// Reduce ai improve writing button padding as per designs
			button[data-testid='ak-editor-ai-selection-toolbar-ai-button-improve-writing'],
			button[data-testid='ak-editor-ai-selection-toolbar-ai-button-improve-writing']:disabled,
			button[data-testid='ak-editor-ai-selection-toolbar-ai-button-improve-writing']:hover,
			button[data-testid='ak-editor-ai-selection-toolbar-ai-button-improve-writing']:focus {
				padding: 0px ${token('space.025', '2px')};
			}
		`;
	} else {
		return css`
			// Reduce ai button padding as per designs
			button[data-testid='ak-editor-ai-selection-toolbar-ai-button'],
			button[data-testid='ak-editor-ai-selection-toolbar-ai-button']:disabled,
			button[data-testid='ak-editor-ai-selection-toolbar-ai-button']:hover,
			button[data-testid='ak-editor-ai-selection-toolbar-ai-button']:focus,
			// Reduce annotation button padding as per designs
			button[data-testid='ak-editor-annotation-toolbar-create-button'],
			button[data-testid='ak-editor-annotation-toolbar-create-button']:disabled,
			button[data-testid='ak-editor-annotation-toolbar-create-button']:hover,
			button[data-testid='ak-editor-annotation-toolbar-create-button']:focus {
				padding: 0px ${token('space.100', '8px')};
			}

			button[data-testid='ak-editor-ai-selection-toolbar-ai-button'] svg {
				padding-right: ${token('space.050', '4px')};
			}

			button[data-testid='ak-editor-ai-selection-toolbar-ai-button'] > span:only-child {
				svg {
					padding: 0px ${token('space.100', '8px')};
				}
			}
		`;
	}
};

export const globalStyles = (aiThemeColor: AIThemeColor) => {
	return css`
		${translationsFloatingToolbarDropdownStyles()}
		${rovoAgentsFloatingToolbarDropdownStyles}
		${dropdownHeadingStyles}
		.akEditor div.ProseMirror {
			.ai-modal-start-widget,
			.ai-modal-end-widget {
				position: relative;

				&::before {
					content: '';
					background-image: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMyIgaGVpZ2h0PSIyMCIgdmlld0JveD0iMCAwIDMgMjAiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CjxwYXRoIGZpbGwtcnVsZT0iZXZlbm9kZCIgY2xpcC1ydWxlPSJldmVub2RkIiBkPSJNMSAxSDBMMSAxLjg1NzE0VjE4LjE0MzNMMCAxOS4wMDA0SDNMMiAxOC4xNDMzVjEuODU3MTRMMyAxSDJIMVoiIGZpbGw9IiM1NzlERkYiLz4KPHJlY3QgeT0iMTkiIHdpZHRoPSIzIiBoZWlnaHQ9IjEiIGZpbGw9IiM1NzlERkYiLz4KPHJlY3Qgd2lkdGg9IjMiIGhlaWdodD0iMSIgZmlsbD0iIzU3OURGRiIvPgo8L3N2Zz4K');
					z-index: 1;
					display: block;
					position: absolute;
					top: 0;
					bottom: ${token('space.negative.025', '-2px')}; // match highlight height + border
					background-repeat: no-repeat;
					background-position-x: center;
					background-position-y: center;
					background-size: contain;
					aspect-ratio: 3/20; // svg (width / height)
				}
			}

			.ai-modal-start-widget::before {
				left: 0px;
				margin-left: -0.1em;
			}

			.ai-modal-end-widget::before {
				right: 0px;
				margin-right: -0.1em;
			}

			.ai-generated-content-highlight {
				${highlightStyles}
			}

			.ai-selection-highlight {
				${highlightStyles}

				// selection from the right
      &.ak-editor-annotation-draft,
      &.ak-editor-annotation-focus,
      &.ak-editor-annotation-blur {
					all: unset;

					${highlightStyles}
				}
			}

			// TODO: AI Button experiment cleanup - platform_editor_ai_ai_button_block_elements
			.ai-selection-node.ak-editor-panel,
			.ai-selection-node.ak-editor-expand {
				${getSelectionStyles([SelectionStyle.Border, SelectionStyle.Blanket])}
			}
			// TODO: AI Button experiment cleanup - platform_editor_ai_ai_button_block_elements
			.ai-selection-node[data-layout-section] {
				[data-layout-column] {
					${getSelectionStyles([SelectionStyle.Border, SelectionStyle.Blanket])}
				}
			}
			// TODO: AI Button experiment cleanup - platform_editor_ai_ai_button_block_elements
			.ai-selection-node .pm-table-wrapper {
				table tr td,
				table tr th {
					${getSelectionStyles([SelectionStyle.Border, SelectionStyle.Blanket])}
				}
			}

			// TODO: AI Button experiment cleanup - platform_editor_ai_ai_button_block_elements
			.ai-generated-node-highlight.ak-editor-panel,
			.ai-generated-node-highlight.ak-editor-expand {
				${nodeHighlightStyles}
			}
			// TODO: AI Button experiment cleanup - platform_editor_ai_ai_button_block_elements
			.ai-generated-node-highlight[data-layout-section] {
				[data-layout-column] {
					${nodeHighlightStyles}
				}
			}
			// TODO: AI Button experiment cleanup - platform_editor_ai_ai_button_block_elements
			.ai-generated-node-highlight .pm-table-wrapper {
				table tr td,
				table tr th {
					${cellHighlightStyles}
				}
			}

			// selection from the left
			.annotationView-content-wrap {
				.ak-editor-annotation-draft,
				.ak-editor-annotation-focus,
				.ak-editor-annotation-blur {
					&:has(.ai-selection-highlight) {
						all: unset;

						${highlightStyles}
					}
				}
			}

			/**
			 * Proactive styles
			 */

			.ai-proactive-recommendation {
				${proactiveRecommendationStyles}
				.ak-editor-annotation-draft,
        		.ak-editor-annotation-blur,
        		.ak-editor-annotation-focus {
					border-bottom: inherit;
					box-shadow: inherit;
				}
			}

			.ai-proactive-recommendation-selected,
			.ai-proactive-recommendation-hovered {
				${proactiveRecommendationSelectedStyles}
				.ak-editor-annotation-draft,
        		.ak-editor-annotation-blur,
       			 .ak-editor-annotation-focus {
					border-bottom: inherit;
					box-shadow: inherit;
					background-color: inherit;
				}
			}

			/**
			 * Spelling grammar styles
			 */

			.ai-spelling-error {
				${spellingErrorStyles}
			}

			.ai-auto-correct {
				${autoCorrectStyles}
			}

			.ai-suggestions {
				${spellingAndGrammarSuggestionStyles}
			}

			.ai-suggestions-selected {
				${spellingAndGrammarSuggestionSelectedStyles}
			}

			// style for inline comment
			.ai-suggestions {
				.ak-editor-annotation-draft,
				.ak-editor-annotation-blur,
				.ak-editor-annotation-focus {
					${aiSuggestionsInlineCommentResetStyles}
				}
			}

			// style for inline comment is clicked
			.ai-suggestions-selected {
				&.ak-editor-annotation-blur,
				&.ak-editor-annotation-focus {
					${aiSuggestionsSelectedInlineCommentBackgroundStyles}
				}
			}

			// style for inline comment is clicked and then focus on somewhere outside the editor
			// (clicking outside the editor or opening the devtools)
			.ak-editor-annotation-focus {
				&:has(.ai-suggestions-selected) {
					${aiSuggestionsInlineCommentResetStyles}
				}

				.ai-suggestions-selected {
					${aiSuggestionsSelectedInlineCommentBackgroundStyles}
				}
			}
		}

		${aiSelectionToolbarButtonStyles()}
	`;
};
