import { PerformanceObserverEntryTypes } from '../../const';

interface LayoutShiftAttribution {
	node: HTMLElement | null;
	previousRect: DOMRectReadOnly;
	currentRect: DOMRectReadOnly;
}

interface LayoutShift extends PerformanceEntry {
	sources: LayoutShiftAttribution[];
}

declare global {
	interface Window {
		__hadLayoutShift: (predicate: (buffer: LayoutShift[]) => number[]) => number[];
	}
}

export class BufferWithMaxLength<T> {
	buffer: Array<T> = [];

	maxLength: number;

	full: boolean = false;

	// Default maxLength is an arbitrary number but it is long enough to capture tasks within the full experience
	// 1000 x 50ms = 50s which should be long enough for every healthy experience
	constructor(maxLength = 1000) {
		this.maxLength = maxLength;
	}

	push(item: T) {
		if (this.full || this.maxLength === this.buffer.length) {
			this.full = true;
			this.buffer.shift();
		}
		this.buffer.push(item);
	}

	getAll() {
		return this.buffer;
	}
}

export type EntriesBufferType = {
	[key: string]: BufferWithMaxLength<PerformanceEntry>;
};
export type PerformanceEntryBuffer = BufferWithMaxLength<PerformanceEntry>;

export const EntriesBuffer: EntriesBufferType = {
	[PerformanceObserverEntryTypes.LongTask]: new BufferWithMaxLength<PerformanceEntry>(),
	[PerformanceObserverEntryTypes.LayoutShift]: new BufferWithMaxLength<PerformanceEntry>(),
};

// This function is exposed to the window object to be used in the integration tests
// to fail the test if there is a layout shift in the page
const hadLayoutShift = (predicate: (buffer: LayoutShift[]) => number[]) => {
	const buffer = EntriesBuffer[PerformanceObserverEntryTypes.LayoutShift].buffer as LayoutShift[];
	return predicate(buffer);
};

window.__hadLayoutShift = hadLayoutShift;
