/* eslint-disable @atlaskit/design-system/no-html-button */
/**
 * @jsxRuntime classic
 * @jsx jsx
 */
import React from 'react';

// eslint-disable-next-line @atlaskit/ui-styling-standard/use-compiled -- Ignored via go/DSP-18766
import { css, jsx } from '@emotion/react';
import { useIntl } from 'react-intl-next';

import ArrowRightIcon from '@atlaskit/icon/core/arrow-right';
import { token } from '@atlaskit/tokens';

import messages from './messages';

const continueFromLastResponseButtonStyles = css({
	// eslint-disable-next-line @atlaskit/design-system/use-tokens-space -- It's a part of design
	paddingLeft: 14,
	// eslint-disable-next-line @atlaskit/design-system/use-tokens-space -- It's a part of design
	paddingRight: 14,
	color: token('color.text.subtlest'),
	fontWeight: 'unset',
	display: 'flex',
	// The 0.5px is from platform/packages/editor/generative-ai-modal/src/ui/components/PromptEditorWrapper/PromptEditorWrapper.tsx
	// promptEditorWrapperOverflowStyles
	height: `calc(${token('space.600')} + 0.5px)`,
	width: '100%',
	justifyContent: 'center',
	alignItems: 'center',
	border: 'unset',
	background: 'unset',
	gap: token('space.050'),
	cursor: 'pointer',
	'&:hover': {
		backgroundColor: token('color.background.neutral.subtle.hovered', '#091E420F'),
	},
});

const textStyles = css({
	textAlign: 'left',
	flexGrow: 0,
});

const arrowIconStyles = css({
	flexGrow: 1,
	textAlign: 'left',
});

type Props = {
	onClick: (event: React.MouseEvent) => void;
};

export const HistoryInterrogateButton = ({ onClick }: Props) => {
	const { formatMessage } = useIntl();
	const message = formatMessage(messages.continueFromLatestResponse);

	// The atlaskit button currently does not support custom styles so we need to use a button element
	return (
		<button
			type="button"
			onClick={onClick}
			data-test-id="history-interrogate-button"
			css={continueFromLastResponseButtonStyles}
		>
			<p css={textStyles}>{message}</p>
			<span css={arrowIconStyles}>
				<ArrowRightIcon label="" />
			</span>
		</button>
	);
};
