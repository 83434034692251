import React, { type ReactNode } from 'react';
import { defineMessages, FormattedMessage, useIntl } from 'react-intl-next';
import { useQuery } from '@apollo/react-hooks';

import SectionMessage, { SectionMessageAction } from '@atlaskit/section-message';
import { Text, Stack } from '@atlaskit/primitives';
import EmptyState from '@atlaskit/empty-state';
import Link from '@atlaskit/link';

import { useIsProductAdmin } from '@confluence/current-user';
import { ErrorDisplay, isUnauthorizedError } from '@confluence/error-boundary';
import { markErrorAsHandled } from '@confluence/graphql';
import { ADMIN_COMPANY_HUB_PERMISSIONS } from '@confluence/named-routes';

import PermissionsErrorState from './PermissionsErrorState.svg';
import { PermissionsPageContentQuery } from './PermissionsPageContentQuery.graphql';
import type {
	PermissionsPageContentQuery as PermissionsPageContentQueryType,
	PermissionsPageContentQueryVariables,
} from './__types__/PermissionsPageContentQuery';

const ATLASSIAN_COMPANY_HUB_DOCS_URL =
	'https://support.atlassian.com/confluence-cloud/docs/create-and-customize-a-company-hub/';

const i18n = defineMessages({
	infoPanelTitle: {
		id: 'company-hub.hub-settings.permissions.info.title',
		defaultMessage: 'We’re working on making updates to Hub permissions',
		description: 'Text for the title of the info panel',
	},
	infoPanelRedirect: {
		id: 'company-hub.hub-settings.permissions.info.redirect',
		defaultMessage: 'Go to Company Hub Permissions',
		description: 'Text for the redirect of the info panel',
	},
	infoPanelDescription: {
		id: 'company-hub.hub-settings.permissions.info.description',
		defaultMessage: 'In the meantime you can find them in Admin Settings.',
		description: 'Text for the description of the info panel',
	},
	noAccessPanelTitle: {
		id: 'company-hub.hub-settings.permissions.no.access.title',
		defaultMessage: 'Can’t view settings',
		description: 'Text for the title for when a user does not have access to hub permissions',
	},
	noAccessPanelDescription: {
		id: 'company-hub.hub-settings.permissions.no.access.description',
		defaultMessage:
			'Currently, only Admins can make changes to Company Hub permissions. {supportLink}',
		description: 'Text for the description for when a user does not have access to hub permissions',
	},
	noAccessPanelDescriptionLink: {
		id: 'company-hub.hub-settings.permissions.no.access.description.link',
		defaultMessage: 'Learn more',
		description:
			'Text for the description link for when a user does not have access to hub permissions',
	},
});

type PermissionsPageContentProps = {
	spaceKey: string;
};

export const PermissionsPageContent = ({ spaceKey }: PermissionsPageContentProps) => {
	const { isProductAdmin, isAdminCheckLoading: isProductAdminCheckLoading } = useIsProductAdmin();
	const { formatMessage } = useIntl();

	const {
		data,
		loading: isSpaceAdminCheckLoading,
		error,
	} = useQuery<PermissionsPageContentQueryType, PermissionsPageContentQueryVariables>(
		// eslint-disable-next-line graphql-relay-compat/no-import-graphql-operations -- Read https://go/connie-relay-migration-fyi
		PermissionsPageContentQuery,
		{
			skip: isProductAdmin,
			variables: { spaceKey },
		},
	);

	if (error) {
		if (isUnauthorizedError(error)) {
			markErrorAsHandled(error);
		} else {
			return <ErrorDisplay error={error} />;
		}
	}

	let permissionsView: ReactNode = null;
	const isSpaceAdmin = data?.space?.currentUser?.isAdmin;

	if (isProductAdmin || isSpaceAdmin) {
		permissionsView = (
			<SectionMessage
				title={formatMessage(i18n.infoPanelTitle)}
				actions={
					<SectionMessageAction href={ADMIN_COMPANY_HUB_PERMISSIONS.toUrl({ tab: 'users' })}>
						<FormattedMessage {...i18n.infoPanelRedirect} />
					</SectionMessageAction>
				}
			>
				<Text>
					<FormattedMessage {...i18n.infoPanelDescription} />
				</Text>
			</SectionMessage>
		);
	} else if (!isSpaceAdminCheckLoading && !isProductAdminCheckLoading) {
		permissionsView = (
			<EmptyState
				header={formatMessage(i18n.noAccessPanelTitle)}
				description={
					<FormattedMessage
						{...i18n.noAccessPanelDescription}
						values={{
							supportLink: (
								<Link target="_blank" href={ATLASSIAN_COMPANY_HUB_DOCS_URL}>
									<FormattedMessage {...i18n.noAccessPanelDescriptionLink} />
								</Link>
							),
						}}
					/>
				}
				imageUrl={PermissionsErrorState}
				width="wide"
			/>
		);
	}

	return <Stack grow="fill">{permissionsView}</Stack>;
};
