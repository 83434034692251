import React from 'react';

import Icon from '@atlaskit/icon/core/globe';
import TranslateIcon from '@atlaskit/icon/core/translate';
import { token } from '@atlaskit/tokens';

import { type CONFIG_ITEM_KEYS } from '../../../config-items/config-item-keys';
import type { EditorPluginAIConfigItemMarkdown } from '../../../config-items/config-items';
import { createEditorPluginAIConfigItemMarkdown } from '../../../config-items/config-items';
import { createInsertBelow, createReplace } from '../../config-item-actions/markdown';
import { isMinLength } from '../../config-item-visible/visibility-utils';
import { languageMessages, translateToMessages } from '../../translations/messages';
import { getSortedSupportedTranslationLanguages } from '../../translations/utils';

const supportedTranslationLanguages = getSortedSupportedTranslationLanguages();

export const translationEmptyConfigItems = supportedTranslationLanguages
	.map((language) => {
		const lang = languageMessages[language];
		if (!lang) {
			return false;
		}
		return createEditorPluginAIConfigItemMarkdown({
			key: `Translate to ${language}` as CONFIG_ITEM_KEYS,
			title: translateToMessages[`${language}Title`],
			description: translateToMessages[`${language}Description`],
			selectionType: 'empty',
			intentSchemaId: 'translate_intent_schema.json',
			lang: lang.defaultMessage,
			nestingConfig: {
				parentTestId: 'translate-to',
				parentTitle: languageMessages.nestingParentTitlePostGA,
				shortTitle: lang,
			},
			isVisible: ({ editorView, positions }) =>
				isMinLength({ editorView, minLength: 1, positions }),
			icon: () => (
				<TranslateIcon
					label=""
					color="currentColor"
					spacing="spacious"
					LEGACY_fallbackIcon={() => <Icon label="" color={token('color.icon', '#44546F')} />}
				/>
			),
			actions: [
				createInsertBelow({ appearance: 'secondary' }),
				createReplace({ appearance: 'primary' }),
			],
		});
	})
	.filter(Boolean) as EditorPluginAIConfigItemMarkdown<'empty'>[];
