import React from 'react';

import Icon, { type CustomGlyphProps, type IconProps } from '@atlaskit/icon';

const CustomGlyph = (props: CustomGlyphProps) => (
	<svg
		width={14}
		height="100%"
		viewBox="0 0 14 15"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		{...props}
	>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M4.22854 10.7139C4.46164 10.5741 4.72837 10.5002 5.00021 10.5002L12.0003 10.5C12.2764 10.5 12.5002 10.2762 12.5002 10V2C12.5002 1.72386 12.2764 1.5 12.0002 1.5L2.00024 1.50014C1.7241 1.50014 1.50024 1.724 1.50024 2.00014V12.3508L4.22854 10.7139ZM0.75748 14.5457C0.424217 14.7456 0.000244141 14.5055 0.000244141 14.1169V2.00014C0.000244141 0.89558 0.895658 0.000154314 2.00022 0.000138964L12.0002 1.9736e-10C13.1048 -1.53495e-05 14.0002 0.895419 14.0002 2V10C14.0002 11.1046 13.1048 12 12.0003 12L5.00024 12.0002L0.75748 14.5457Z"
			fill="currentColor"
		/>
		<path
			d="M3 6.00018C3 5.4479 3.44772 5.00018 4 5.00018C4.55228 5.00018 5 5.4479 5 6.00018C5 6.55247 4.55228 7.00018 4 7.00018C3.44772 7.00018 3 6.55247 3 6.00018Z"
			fill="currentColor"
		/>
		<path
			d="M6 6.00018C6 5.4479 6.44772 5.00018 7 5.00018C7.55228 5.00018 8 5.4479 8 6.00018C8 6.55247 7.55228 7.00018 7 7.00018C6.44772 7.00018 6 6.55247 6 6.00018Z"
			fill="currentColor"
		/>
		<path
			d="M9 6.00018C9 5.4479 9.44772 5.00018 10 5.00018C10.5523 5.00018 11 5.4479 11 6.00018C11 6.55247 10.5523 7.00018 10 7.00018C9.44772 7.00018 9 6.55247 9 6.00018Z"
			fill="currentColor"
		/>
	</svg>
);

type CustomIconProps = Omit<IconProps, 'glyph'>;

const CustomIcon = (props: CustomIconProps) => <Icon glyph={CustomGlyph} {...props} />;

export const ChatIcon = (props: CustomIconProps) => {
	return <CustomIcon {...props} />;
};
