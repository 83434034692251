import React, { useCallback, useContext, useMemo } from 'react';
import { defineMessages, useIntl } from 'react-intl-next';
import { styled } from '@compiled/react';

import { useAnalyticsEvents } from '@atlaskit/analytics-next';
import Select from '@atlaskit/select';
import { B50, N20A, N30A } from '@atlaskit/theme/colors';
import { token } from '@atlaskit/tokens';

import { useSessionData } from '@confluence/session-data';

import type { GranularityType, MetricSettingsContextValueType } from './MetricSettingsContext';
import { MetricSettingsContext, getMetricSettingsContextValue } from './MetricSettingsContext';
import { DailyRefreshComponent } from './DailyRefreshComponent';

const i18n = defineMessages({
	lastXDaysValue: {
		id: 'admin-center.metric-settings-controller.last-x-days-value',
		defaultMessage: '{count, plural, one {Last day} other {Last {count} days}}',
		description: 'Value to be selected for how many days back in time to look for analytics data',
	},
	lastXWeeksValue: {
		id: 'admin-center.metric-settings-controller.last-x-weeks-value',
		defaultMessage: '{count, plural, one {Last week} other {Last {count} weeks}}',
		description: 'Value to be selected for how many weeks back in time to look for analytics data',
	},
	lastXMonthsValue: {
		id: 'admin-center.metric-settings-controller.last-x-months-value',
		defaultMessage: '{count, plural, one {Last month} other {Last {count} months}}',
		description: 'Value to be selected for how many months back in time to look for analytics data',
	},
	lastXGranularityLabel: {
		id: 'admin-center.metric-settings-controller.last-x-granularity-label',
		defaultMessage: 'What period of time?',
		description: 'Label for selecting how far back in time to look for analytics data',
	},
	granularityDayValue: {
		id: 'admin-center.metric-settings-controller.granularity-day-value',
		defaultMessage: 'Daily',
		description: 'Value to be selected to view analytics data into daily granularity',
	},
	granularityWeekValue: {
		id: 'admin-center.metric-settings-controller.granularity-week-value',
		defaultMessage: 'Weekly',
		description: 'Value to be selected to view analytics data into weekly granularity',
	},
	granularityMonthValue: {
		id: 'admin-center.metric-settings-controller.granularity-month-value',
		defaultMessage: 'Monthly',
		description: 'Value to be selected to view analytics data into monthly granularity',
	},
	granularityLabel: {
		id: 'admin-center.metric-settings-controller.granularity-label',
		defaultMessage: 'What granularity?',
		description: 'Label for selecting what granularity of analytics data to display',
	},
});

const SELECT_STYLE_PROPS = {
	isSearchable: false,
	spacing: 'compact' as const,
	styles: {
		control: (base, state) => ({
			...base,
			backgroundColor: token('color.background.neutral', N20A),
			borderColor: state.isFocused ? token('color.border.selected', B50) : 'transparent',
			':hover': {
				backgroundColor: state.isFocused
					? token('color.background.neutral', N20A)
					: token('color.background.neutral.hovered', N30A),
				borderColor: state.isFocused ? token('color.border.selected', B50) : 'transparent',
			},
		}),
	},
};

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const SettingsRow = styled.div({
	display: 'flex',
	alignItems: 'center',
	gap: token('space.100', '8px'),
	paddingBottom: token('space.500', '40px'),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const SettingsCell = styled.div({
	display: 'flex',
	flexDirection: 'column',
});

const GRANULARITY_VALUES: GranularityType[] = ['DAY', 'WEEK', 'MONTH'];
const LAST_X_DAYS_VALUES = [7, 30, 60];
const LAST_X_WEEKS_VALUES = [4, 12, 24];
const LAST_X_MONTHS_VALUES = [3, 6, 12];

const getGranularityDependentFields = (granularityValue: GranularityType) => {
	if (granularityValue === 'DAY') {
		return {
			granularityLabelMessage: i18n.granularityDayValue,
			lastXGranularitiesLabelMessage: i18n.lastXDaysValue,
			lastGranularitiesValues: LAST_X_DAYS_VALUES,
			defaultLastXGranularitiesValue: LAST_X_DAYS_VALUES[1],
		};
	} else if (granularityValue === 'WEEK') {
		return {
			granularityLabelMessage: i18n.granularityWeekValue,
			lastXGranularitiesLabelMessage: i18n.lastXWeeksValue,
			lastGranularitiesValues: LAST_X_WEEKS_VALUES,
			defaultLastXGranularitiesValue: LAST_X_WEEKS_VALUES[1],
		};
	} else {
		// In this case, granularityValue should be "MONTH".
		// Although it should not happen, if granularityValue is an unexpected value, then we default to monthly.
		return {
			granularityLabelMessage: i18n.granularityMonthValue,
			lastXGranularitiesLabelMessage: i18n.lastXMonthsValue,
			lastGranularitiesValues: LAST_X_MONTHS_VALUES,
			defaultLastXGranularitiesValue: LAST_X_MONTHS_VALUES[1],
		};
	}
};

type FireDropdownAnalyticsEventArgsType = {
	actionSubjectId: string;
	oldGranularity?: GranularityType;
	oldLastXGranularities?: number;
	newGranularity?: GranularityType;
	newLastXGranularities?: number;
};

export type MetricSettingsControllerProps = {
	setMetricSettingsContext: (arg: MetricSettingsContextValueType) => void;
	analyticsSource: 'adminCenter' | 'spaceAdminCenter' | 'companyHubAnalytics';
};

export const MetricSettingsController = ({
	setMetricSettingsContext,
	analyticsSource,
}: MetricSettingsControllerProps) => {
	const { formatMessage } = useIntl();
	const { edition } = useSessionData();
	const { createAnalyticsEvent } = useAnalyticsEvents();
	const metricSettingsContext = useContext(MetricSettingsContext);

	const granularityOptions = useMemo(
		() =>
			GRANULARITY_VALUES.map((value) => ({
				label: formatMessage(getGranularityDependentFields(value).granularityLabelMessage),
				value,
			})),
		[formatMessage],
	);

	const lastXGranularitiesOptions = useMemo(
		() =>
			getGranularityDependentFields(metricSettingsContext.granularity).lastGranularitiesValues.map(
				(value) => ({
					label: formatMessage(
						getGranularityDependentFields(metricSettingsContext.granularity)
							.lastXGranularitiesLabelMessage,
						{ count: value },
					),
					value,
				}),
			),
		[formatMessage, metricSettingsContext],
	);

	const fireDropdownAnalyticsEvent = useCallback(
		({
			actionSubjectId,
			oldGranularity,
			oldLastXGranularities,
			newGranularity,
			newLastXGranularities,
		}: FireDropdownAnalyticsEventArgsType) => {
			createAnalyticsEvent({
				type: 'sendUIEvent',
				data: {
					action: 'clicked',
					actionSubject: 'dropdown',
					actionSubjectId,
					source: analyticsSource,
					attributes: {
						edition,
						...(oldGranularity && oldLastXGranularities
							? { oldValue: `${oldGranularity} ${oldLastXGranularities}` }
							: undefined),
						...(newGranularity && newLastXGranularities
							? { newValue: `${newGranularity} ${newLastXGranularities}` }
							: undefined),
					},
				},
			}).fire();
		},
		[analyticsSource, createAnalyticsEvent, edition],
	);

	return (
		<SettingsRow>
			<SettingsCell>
				<Select
					{...SELECT_STYLE_PROPS}
					label={formatMessage(i18n.granularityLabel)}
					options={granularityOptions}
					value={granularityOptions.find(
						(option) => option.value === metricSettingsContext.granularity,
					)}
					onMenuOpen={() => {
						fireDropdownAnalyticsEvent({ actionSubjectId: 'GranularityMenu' });
					}}
					onChange={(option) => {
						if (option) {
							const newGranularity = option.value;
							const newLastXGranularities =
								getGranularityDependentFields(newGranularity).defaultLastXGranularitiesValue;
							fireDropdownAnalyticsEvent({
								actionSubjectId: 'GranularityMenuItem',
								oldGranularity: metricSettingsContext.granularity,
								oldLastXGranularities: metricSettingsContext.lastXGranularities,
								newGranularity,
								newLastXGranularities,
							});
							setMetricSettingsContext({
								...getMetricSettingsContextValue({
									granularity: newGranularity,
									lastXGranularities: newLastXGranularities,
								}),
								isActivationDateOver1DayAgo: metricSettingsContext.isActivationDateOver1DayAgo,
								activationDate: metricSettingsContext.activationDate,
								hasPublicLinksStatusError: metricSettingsContext.hasPublicLinksStatusError,
							});
						}
					}}
				/>
			</SettingsCell>
			<SettingsCell>
				<Select
					{...SELECT_STYLE_PROPS}
					label={formatMessage(i18n.lastXGranularityLabel)}
					options={lastXGranularitiesOptions}
					value={lastXGranularitiesOptions.find(
						(option) => option.value === metricSettingsContext.lastXGranularities,
					)}
					onMenuOpen={() => {
						fireDropdownAnalyticsEvent({ actionSubjectId: 'IntervalMenu' });
					}}
					onChange={(option) => {
						if (option) {
							const newLastXGranularities = option.value;
							fireDropdownAnalyticsEvent({
								actionSubjectId: 'IntervalMenuItem',
								oldGranularity: metricSettingsContext.granularity,
								oldLastXGranularities: metricSettingsContext.lastXGranularities,
								newGranularity: metricSettingsContext.granularity,
								newLastXGranularities,
							});
							setMetricSettingsContext({
								...getMetricSettingsContextValue({
									granularity: metricSettingsContext.granularity,
									lastXGranularities: newLastXGranularities,
								}),
								isActivationDateOver1DayAgo: metricSettingsContext.isActivationDateOver1DayAgo,
								activationDate: metricSettingsContext.activationDate,
								hasPublicLinksStatusError: metricSettingsContext.hasPublicLinksStatusError,
							});
						}
					}}
				/>
			</SettingsCell>
			<DailyRefreshComponent fontSize="medium" />
		</SettingsRow>
	);
};
