import { fg } from '@confluence/feature-gating';

import { CardSizes, CardAlignment } from '../linkCardsTypes';
import type { LinkCardsParameters, Card } from '../linkCardsTypes';
import { DEFAULT_CARDS_SIZE } from '../LinkCardsConfigPanel/components/Fields/SizeField';
import { DEFAULT_CARDS_ALIGNMENT } from '../LinkCardsConfigPanel/components/Fields/AlignmentField';

const checkIsParameterValueValid = (parameter: any, expectedValues: any[]) => {
	return expectedValues.includes(parameter) ? parameter : null;
};

const checkSize = (size: CardSizes) =>
	checkIsParameterValueValid(size, [
		CardSizes.EXTRA_SMALL,
		CardSizes.SMALL,
		CardSizes.MEDIUM,
		CardSizes.LARGE,
		CardSizes.HERO,
	]);

export const checkAlignment = (alignment: CardAlignment) =>
	checkIsParameterValueValid(alignment, [CardAlignment.LEFT, CardAlignment.CENTER]);

const validateCardsUrls = (cards: Card[]) => {
	cards.forEach((card) => {
		if (!!card?.link && !card?.link.startsWith('http')) {
			card.link = '';
		}
	});
};

export const checkCards = (cards?: Card[]) => {
	if (!cards || cards?.length === 0) {
		return null;
	} else if (Array.isArray(cards)) {
		validateCardsUrls(cards);
		return cards;
	}
};

export const useValidateRequiredParameters = (currentParameters: any): LinkCardsParameters => {
	// removed validatedAlignment only
	if (fg('company_hub_manual_cards_refinement')) {
		const validatedSize = checkSize(currentParameters?.size);
		const validatedCards = checkCards(currentParameters?.cards);

		if (!!validatedSize && !!validatedCards) return currentParameters;

		const validatedRequiredParameters = {
			size: validatedSize || DEFAULT_CARDS_SIZE,
			cards: validatedCards || [],
		} as LinkCardsParameters;

		return {
			...currentParameters,
			...validatedRequiredParameters,
		} as LinkCardsParameters;
	}

	const validatedSize = checkSize(currentParameters?.size);
	const validatedAlignment = checkAlignment(currentParameters?.alignment);
	const validatedCards = checkCards(currentParameters?.cards);

	if (!!validatedSize && !!validatedAlignment && !!validatedCards) return currentParameters;

	const validatedRequiredParameters = {
		size: validatedSize || DEFAULT_CARDS_SIZE,
		alignment: validatedAlignment || DEFAULT_CARDS_ALIGNMENT,
		cards: validatedCards || [],
	} as LinkCardsParameters;

	return {
		...currentParameters,
		...validatedRequiredParameters,
	} as LinkCardsParameters;
};
