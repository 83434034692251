import React from 'react';
import { styled } from '@compiled/react';
import type { MessageDescriptor } from 'react-intl-next';
import { FormattedMessage, useIntl } from 'react-intl-next';

import EditorPanelIcon from '@atlaskit/icon/glyph/editor/panel';
import { token } from '@atlaskit/tokens';
import Tooltip from '@atlaskit/tooltip';
import { B50, B500, N200, N40, N800 } from '@atlaskit/theme/colors';
import { Box, xcss } from '@atlaskit/primitives';
import Button from '@atlaskit/button';

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const HeaderWrapper = styled.div<{ center: boolean }>({
	display: 'flex',
	flexDirection: 'column',
	justifyContent: 'flex-end',
	rowGap: token('space.025', '2px'),
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	alignItems: ({ center }) => (center ? 'center' : 'flex-start'),
	textAlign: 'center',
	minWidth: token('space.400', '32px'),
	height: 'auto',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const GroupNameWrapper = styled.div<{
	setMaxWidth: boolean;
	setWidth: boolean;
}>({
	display: 'flex',
	flexDirection: 'column',
	justifyContent: 'center',
	overflowX: 'visible',
	whiteSpace: 'nowrap',
	fontSize: '12px',
	fontWeight: '600',
	minHeight: '34px',
	textAlign: 'center',
	color: token('color.text', N800),
	// In the case where we set the width, we need to remove the maxWidth to ensure
	// that the full width is used
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	maxWidth: ({ setWidth }) => (setWidth ? 'none' : token('space.600', '48px')),
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	width: ({ setWidth }) => (setWidth ? '170px' : 'initial'),
});

const largeGroupNameStyle = xcss({
	whiteSpace: 'normal',
	wordBreak: 'keep-all',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const ColumnNameWrapper = styled.div({
	display: 'flex',
	whiteSpace: 'nowrap',
	fontSize: '11px',
	fontWeight: '400',
	color: token('color.text.subtle', N200),
	alignSelf: 'center',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const IconWrapper = styled.div({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-important-styles -- Ignored via go/DSP-18766
	height: `${token('space.200', '16px')} !important`,
	borderRadius: '50%',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	button: {
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-important-styles -- Ignored via go/DSP-18766
		height: `${token('space.200', '16px')} !important`,
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-important-styles -- Ignored via go/DSP-18766
		width: `${token('space.200', '16px')} !important`,
	},
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-selectors -- Ignored via go/DSP-18766
	':hover': {
		backgroundColor: token('color.background.accent.blue.subtlest', B50),
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
		span: {
			// eslint-disable-next-line @atlaskit/ui-styling-standard/no-important-styles -- Ignored via go/DSP-18766
			'--icon-primary-color': `${token('color.icon.accent.blue', B500)} !important`,
		},
	},
});

type HeaderCellType = {
	groupName: string;
	columnName: string;
	testKey?: string;
	showGroupName?: boolean;
	tooltipContent?: MessageDescriptor;
	tooltipIconLabel?: MessageDescriptor;
	centerGroupName?: boolean;
	setMaxWidth?: boolean;
	setWidth?: boolean;
};

export const HeaderCell = ({
	groupName,
	columnName,
	showGroupName = false,
	testKey,
	tooltipContent,
	tooltipIconLabel,
	centerGroupName = false,
	setMaxWidth = false,
	setWidth = false,
}: HeaderCellType) => {
	const { formatMessage } = useIntl();

	return (
		<HeaderWrapper center={centerGroupName}>
			<GroupNameWrapper
				data-testid={testKey ? `space-table-header-${testKey}` : undefined}
				aria-label={`${groupName}`}
				setMaxWidth={setMaxWidth}
				setWidth={setWidth}
			>
				{showGroupName ? (
					<Box xcss={[setWidth ? largeGroupNameStyle : false]}>{groupName}</Box>
				) : (
					<Box aria-label={`${groupName}`} />
				)}
			</GroupNameWrapper>

			<ColumnNameWrapper key={groupName + columnName} aria-label={`${columnName}`}>
				{columnName}
				{tooltipContent && tooltipIconLabel && (
					<Tooltip content={<FormattedMessage {...tooltipContent} />}>
						{(tooltipProps) => (
							<IconWrapper>
								<Button
									{...tooltipProps}
									appearance="subtle-link"
									iconBefore={
										<EditorPanelIcon
											size="small"
											label={formatMessage(tooltipIconLabel)}
											primaryColor={token('color.icon', N40)}
										/>
									}
								/>
							</IconWrapper>
						)}
					</Tooltip>
				)}
			</ColumnNameWrapper>
		</HeaderWrapper>
	);
};
