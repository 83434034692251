/**
 * @jsxRuntime classic
 * @jsx jsx
 */
import { useCallback, useEffect, useMemo, useRef } from 'react';

// eslint-disable-next-line @atlaskit/ui-styling-standard/use-compiled -- Ignored via go/DSP-18766
import { jsx } from '@emotion/react';

import type { BlurInputRefType, FocusInputRefType } from '../PromptForm/useSetInputRef';
import { useSetInputRef } from '../PromptForm/useSetInputRef';

import { hiddenDivStyles, inputStyles, multilineInputWrapperStyles } from './styles';

type Props = React.TextareaHTMLAttributes<HTMLTextAreaElement> & {
	focusInputRef?: React.MutableRefObject<FocusInputRefType>;
	blurInputRef?: React.MutableRefObject<BlurInputRefType>;
	onInputChange?: (inputValue: string) => void;
	hasMultipleRows?: boolean;
	setHasMultipleRows?: (hasMultipleRows: boolean) => void;
	buttonWidth?: number;
};

export const PromptMultilineInput = ({
	focusInputRef,
	blurInputRef,
	onBlur,
	onFocus,
	onInputChange,
	hasMultipleRows,
	setHasMultipleRows,
	value,
	buttonWidth,
	onCompositionStart,
	onCompositionEnd,
	...rest
}: Props) => {
	const setFocusAndBlurInputRef = useSetInputRef({
		blurInputRef,
		focusInputRef,
	});

	const wrapperRef = useRef<HTMLDivElement>(null);

	const hiddenDivRef = useRef<HTMLDivElement>(null);
	const hiddenDiv = hiddenDivRef?.current;
	const singleLineHeight = useMemo(
		() => (window && hiddenDiv ? parseFloat(window.getComputedStyle(hiddenDiv).lineHeight) : 0),
		[hiddenDiv],
	);

	const hiddenDivWidthStyles = useMemo(
		() => ({
			width: hasMultipleRows ? `calc(100% - ${buttonWidth}px)` : `100%`,
		}),
		[hasMultipleRows, buttonWidth],
	);

	const onInput: React.ChangeEventHandler<HTMLTextAreaElement> = useCallback(
		(e) => {
			onInputChange?.(e.target.value);
		},
		[onInputChange],
	);

	/**
	 * Use resize observer to listen to changes in height of hidden div
	 * And update hasMultipleRows state if content spans multiple rows
	 * Note: we are listening to changes in a hidden div (with fixed width)
	 * because when the horizontal width of textarea increases,
	 * it causes flickering as the content only fits in one line if buttons are stacked
	 */

	useEffect(() => {
		const resizeObserver = new ResizeObserver((entries) => {
			entries.forEach(() => {
				if (hiddenDiv) {
					setHasMultipleRows?.(hiddenDiv.getBoundingClientRect().height > singleLineHeight);
				}
			});
		});

		if (hiddenDiv) {
			resizeObserver.observe(hiddenDiv);
		}

		return () => {
			resizeObserver.disconnect();
		};
	}, [hiddenDiv, singleLineHeight, setHasMultipleRows]);

	useEffect(() => {
		if (hiddenDiv) {
			setHasMultipleRows?.(hiddenDiv.getBoundingClientRect().height > singleLineHeight);
		}
	}, [hiddenDiv, singleLineHeight, setHasMultipleRows]);

	return (
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/design-system/consistent-css-prop-usage -- Ignored via go/DSP-18766
		<div data-replicated-value={value} css={[multilineInputWrapperStyles]} ref={wrapperRef}>
			<textarea
				ref={setFocusAndBlurInputRef}
				aria-label={rest.placeholder}
				data-testid="ai-experience-prompt-input"
				name="prompt-input"
				autoComplete="off"
				// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/design-system/consistent-css-prop-usage -- Ignored via go/DSP-18766
				css={inputStyles}
				onChange={onInput}
				onBlur={onBlur}
				onFocus={onFocus}
				rows={1}
				value={value}
				// Setting Arbitrary limit to 4000 for now but will further monitor user feedback
				maxLength={4000}
				onCompositionStart={onCompositionStart}
				onCompositionEnd={onCompositionEnd}
				{...rest}
			/>
			{/* Hidden div with fixed width that matches input without buttons is used
      to replicate the textarea and  when input requires multiple lines**/}
			<div
				data-replicated-value={value}
				// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/design-system/consistent-css-prop-usage -- Ignored via go/DSP-18766
				css={[hiddenDivStyles, hiddenDivWidthStyles]}
				ref={hiddenDivRef}
			/>
		</div>
	);
};
