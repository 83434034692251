import { type IntlShape } from 'react-intl-next';
import type { ProviderFactory } from '@atlaskit/editor-common/provider-factory';
import type { ExtensionProvider, ExtensionManifest } from '@atlaskit/editor-common/extensions';
import {
	combineExtensionProviders,
	DefaultExtensionProvider,
} from '@atlaskit/editor-common/extensions';

import type { CreateUIAnalyticsEvent } from '@atlaskit/analytics-next';
import type { ExtensionHandlerWithReferenceFn } from '@atlassian/editor-referentiality';

import {
	createForgeMacroExtensionData,
	createForgeUIAnalyticsContext,
	environmentToForgeProductEnvironment,
} from '@confluence/fabric-providers';
import { getAGGClient } from '@confluence/graphql';

import type { RendererExtensionHandlers } from '@confluence/fabric-extension-lib/entry-points/fabric-extension-lib-types';
import { EXTENSION_TYPE } from '@confluence/fabric-extension-lib/entry-points/extensionConstants';
import { getConfluenceMacrosExtensionProviderForRenderer } from '@confluence/fabric-extension-lib';

import { getAnalyticsWebClient } from '@confluence/analytics-web-client';
import { FlagProvider } from '@confluence/forge-ui';
import { fetchForgeExtensionProvider } from '@confluence/fabric-extension-handlers';
import { getMigrationExtensionManifest } from '../../lib/extensions-common/migration-extension/manifest';
import {
	openMacroBrowserToEditExistingMacro,
	openMacroBrowserForInitialConfiguration,
} from '../../lib/editor-extensions';
import {
	getTemplateVariableExtensionManifest,
	TemplateVariableMode,
} from '../../lib/editor-extensions/template-variable-extension/manifest';
import { getWorkspaceARI } from '@confluence/forge-ui';

export type ExtensionProviderOptions = {
	intl: IntlShape;
	containerId: string; // spaceKey in Next
	accountId?: string | null; // userId in Next
	contentId: string;
	cloudId: string;
	spaceKey?: string;
	spaceId?: string;
	contentType?: string;
	environment: string; // comes from sessionData
	featureFlags?: { [flag: string]: any }; // comes from sessionData
	extensionHandlers: RendererExtensionHandlers;
	showTemplateInputInPreview?: boolean;
	showTemplateVariablesInPreview?: boolean;
	setManifestReadyState?: Function;
	createAnalyticsEvent?: CreateUIAnalyticsEvent;
	activationId: string;
};

/**
 * Populate Forge-based extension providers
 *
 * @param providerFactory provider factory to add extension providers into
 * @param options options to initialize extension providers
 */
export const populateExtensionProviders = (
	providerFactory: ProviderFactory,
	{
		intl,
		containerId,
		accountId,
		cloudId,
		spaceKey,
		spaceId,
		contentId,
		contentType,
		environment,
		extensionHandlers,
		showTemplateInputInPreview,
		showTemplateVariablesInPreview,
		setManifestReadyState,
		createAnalyticsEvent,
		activationId,
	}: ExtensionProviderOptions,
): void => {
	const extensionProvidersList: (Promise<ExtensionProvider> | ExtensionProvider)[] = [];

	const extensionProvider = getConfluenceMacrosExtensionProviderForRenderer(
		{ contentId, spaceKey, spaceId, features: {} },
		extensionHandlers,
		getMigrationExtensionManifest,
		openMacroBrowserToEditExistingMacro,
		openMacroBrowserForInitialConfiguration,
		intl,
		createAnalyticsEvent,
		providerFactory,
	);

	extensionProvidersList.push(extensionProvider);

	if (showTemplateInputInPreview || showTemplateVariablesInPreview) {
		extensionProvidersList.push(
			new DefaultExtensionProvider([
				getTemplateVariableExtensionManifest(
					intl,
					showTemplateInputInPreview ? TemplateVariableMode.INPUT : TemplateVariableMode.CONFIG,
				) as ExtensionManifest<any>,
			]),
		);
	}

	const apolloClient = getAGGClient();
	const contextIds = [getWorkspaceARI({ cloudId, activationId })];

	const forgeExtensionProvider = async () => {
		const getProviderOptions = ({ spaceKey: spaceKeyArg }: { spaceKey: string }) => ({
			accountId: accountId ?? undefined,
			cloudId,
			apolloClient,
			contextIds,
			dataProviders: providerFactory,
			environment: environmentToForgeProductEnvironment(environment),
			product: 'confluence',
			page: 'confluence:macroRenderer',
			isEditing: false,
			analyticsWebClient: getAnalyticsWebClient(),
			forgeUIAnalyticsContext: createForgeUIAnalyticsContext({
				objectId: contentId,
				// @ts-ignore
				objectType: contentType,
				containerId,
				containerType: 'space',
			}),
			extensionData: createForgeMacroExtensionData({
				spaceKey: spaceKeyArg ?? '',
				spaceId: spaceId ?? '',
				contentId,
			}),
			extensionHandlerWithReference: extensionHandlers[
				EXTENSION_TYPE.MACRO_WITH_REFERENCES
			] as ExtensionHandlerWithReferenceFn<any>,
			connectExtensionProvider: extensionProvider,
			FlagProvider,
			extensionsFilter: undefined,
			locale: intl.locale,
		});

		return fetchForgeExtensionProvider({
			getProviderOptions,
			spaceKey,
		});
	};

	extensionProvidersList.push(forgeExtensionProvider());

	if (extensionProvidersList.length > 0) {
		providerFactory.setProvider(
			'extensionProvider',
			Promise.resolve(combineExtensionProviders(extensionProvidersList)),
		);
	}

	// Make sure we set mainfestReadyState always
	void Promise.all(extensionProvidersList).then(() => {
		setManifestReadyState?.(true);
	});
};
