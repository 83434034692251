import React from 'react';

import { isFedRamp } from '@atlassian/atl-context';

import { SpaceAnalyticsPageLoader } from '@confluence/confluence-analytics/entry-points/AnalyticsPagesLoaders';
import { SPACE_ANALYTICS_PLUGIN, FEDRAMP_SPACE_ANALYTICS_PLUGIN } from '@confluence/named-routes';
import {
	CONTAINER_ADMIN_HOME_ID,
	CONTAINER_HOME_ID,
} from '@confluence/app-navigation/entry-points/navigationIDs';
import type { RouteMatch } from '@confluence/route';
import { Redirection } from '@confluence/route-manager';

import SpaceBase from '../../SpaceBase';
import { fg } from '@confluence/feature-gating';

export const SpaceAnalyticsRoute = ({ params: { spaceKey }, search }: RouteMatch) => {
	//TODO - CCIR-11: Clean up this feature gate once the migration is complete
	if (!fg('confluence_frontend_analytics_ui_migration')) {
		const isFedrampEnv = isFedRamp();

		const url = isFedrampEnv
			? FEDRAMP_SPACE_ANALYTICS_PLUGIN.toUrl({ spaceKey })
			: SPACE_ANALYTICS_PLUGIN.toUrl({ spaceKey });
		return <Redirection href={`${url}${search}`} />;
	}

	return (
		<SpaceBase>
			<SpaceAnalyticsPageLoader spaceKey={spaceKey} />
		</SpaceBase>
	);
};

SpaceAnalyticsRoute.NAVIGATION_PARAMS = (
	{ name, params: { spaceKey, tab } }: RouteMatch,
	isNav4Enabled: boolean,
) => ({
	Screen: {
		// screenEvent will be handled in the page components
		pageState: {
			routeName: name,
			spaceKey,
			tab,
		},
	},
	MainLayout: {
		spaceKey,
		navView: isNav4Enabled ? CONTAINER_ADMIN_HOME_ID : CONTAINER_HOME_ID,
	},
});
