// TODO remove this folder when cleanup platform_editor_ai_release_additional_prompts
import React from 'react';

import { CONFIG_ITEM_KEYS } from '../../../config-items/config-item-keys';
import { createEditorPluginAIConfigItemMarkdown } from '../../../config-items/config-items';
import { createInsertBelow, createReplace } from '../../config-item-actions/markdown';
import { isMinLength } from '../../config-item-visible/visibility-utils';

import { messages } from './messages';

const intentSchemaId = 'add_introduction_intent_schema.json';

// TODO swap to @atlaskit/icon once available
const IconAddIntroduction = () => {
	return (
		<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path d="M5 18.25L12 18.25M5 14.25L19 14.25" stroke="#44546F" strokeWidth="1.5" />
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M5.75 9C6.16421 9 6.5 9.33579 6.5 9.75C6.5 10.1642 6.16421 10.5 5.75 10.5C5.33579 10.5 5 10.1642 5 9.75C5 9.33579 5.33579 9 5.75 9ZM5.75 5C6.16421 5 6.5 5.33579 6.5 5.75C6.5 6.16421 6.16421 6.5 5.75 6.5C5.33579 6.5 5 6.16421 5 5.75C5 5.33579 5.33579 5 5.75 5ZM9.625 9.75C9.625 9.33579 9.28921 9 8.875 9C8.46079 9 8.125 9.33579 8.125 9.75C8.125 10.1642 8.46079 10.5 8.875 10.5C9.28921 10.5 9.625 10.1642 9.625 9.75ZM8.875 5C9.28921 5 9.625 5.33579 9.625 5.75C9.625 6.16421 9.28921 6.5 8.875 6.5C8.46079 6.5 8.125 6.16421 8.125 5.75C8.125 5.33579 8.46079 5 8.875 5ZM12.75 9.75C12.75 9.33579 12.4142 9 12 9C11.5858 9 11.25 9.33579 11.25 9.75C11.25 10.1642 11.5858 10.5 12 10.5C12.4142 10.5 12.75 10.1642 12.75 9.75ZM12 5C12.4142 5 12.75 5.33579 12.75 5.75C12.75 6.16421 12.4142 6.5 12 6.5C11.5858 6.5 11.25 6.16421 11.25 5.75C11.25 5.33579 11.5858 5 12 5ZM15.875 9.75C15.875 9.33579 15.5392 9 15.125 9C14.7108 9 14.375 9.33579 14.375 9.75C14.375 10.1642 14.7108 10.5 15.125 10.5C15.5392 10.5 15.875 10.1642 15.875 9.75ZM15.125 5C15.5392 5 15.875 5.33579 15.875 5.75C15.875 6.16421 15.5392 6.5 15.125 6.5C14.7108 6.5 14.375 6.16421 14.375 5.75C14.375 5.33579 14.7108 5 15.125 5ZM19 9.75C19 9.33579 18.6642 9 18.25 9C17.8358 9 17.5 9.33579 17.5 9.75C17.5 10.1642 17.8358 10.5 18.25 10.5C18.6642 10.5 19 10.1642 19 9.75ZM18.25 5C18.6642 5 19 5.33579 19 5.75C19 6.16421 18.6642 6.5 18.25 6.5C17.8358 6.5 17.5 6.16421 17.5 5.75C17.5 5.33579 17.8358 5 18.25 5Z"
				fill="#44546F"
			/>
		</svg>
	);
};

export const addIntroductionEmptyConfigItem = createEditorPluginAIConfigItemMarkdown<'empty'>({
	key: CONFIG_ITEM_KEYS.ADD_INTRODUCTION,
	title: messages.title,
	description: messages.description,
	icon: ({ shownAt }) => <IconAddIntroduction />,
	statusLozengeType: 'beta',
	selectionType: 'empty',
	intentSchemaId,
	isVisible: ({ editorView, positions }) => isMinLength({ editorView, minLength: 1, positions }),
	getBackendModel: () => 'assistance-service',
	actions: [
		createInsertBelow({ appearance: 'secondary' }),
		createReplace({ appearance: 'primary' }),
	],
});

export const addIntroductionRangeConfigItem = createEditorPluginAIConfigItemMarkdown<'range'>({
	key: CONFIG_ITEM_KEYS.ADD_INTRODUCTION,
	title: messages.title,
	description: messages.description,
	icon: ({ shownAt }) => <IconAddIntroduction />,
	statusLozengeType: 'beta',
	selectionType: 'range',
	intentSchemaId,
	isVisible: ({ editorView, positions }) => isMinLength({ editorView, minLength: 1, positions }),
	getBackendModel: () => 'assistance-service',
	actions: [
		createInsertBelow({ appearance: 'secondary' }),
		createReplace({ appearance: 'primary' }),
	],
});
