import { StatsigConfigurations } from '@confluence/statsig-client/entry-points/StatsigConfigurations';
import type { PreloadFailureResult } from '@confluence/fabric-extension-lib/entry-points/fabric-extension-lib-types';
import { getMultiMacroQueryBlocklistFF } from '@confluence/fabric-extension-queries';
import { fg } from '@confluence/feature-gating';

import { preloadMacrosSSRIndividual } from './preloadMacrosSSRIndividual';
import { preloadMacrosSSRMultiple } from './preloadMacrosSSRMultiple';
import { DEFAULT_PRELOAD_COUNT, FG_SSR_EXTENSIONS_BLOCKLIST } from './preloader-constants';

export const preloadMacrosSSR = async ({
	contentId,
	featureFlags,
	useMultipleMacrosQuery,
	contentNodes,
	numberOfExtensionsToSSR = DEFAULT_PRELOAD_COUNT,
}: {
	contentId: string;
	featureFlags: { [key: string]: string | number | boolean };
	useMultipleMacrosQuery?: boolean;
	contentNodes?: any;
	numberOfExtensionsToSSR?: number;
}): Promise<PreloadFailureResult | undefined> => {
	const legacyBlocklistedExtensions = StatsigConfigurations.getString(
		FG_SSR_EXTENSIONS_BLOCKLIST,
		'',
	);

	if (fg('confluence_frontend_use_multiple_macros_query')) {
		if (useMultipleMacrosQuery) {
			return preloadMacrosSSRMultiple(contentId);
		} else {
			// if we're using MacrosQuery but this is the call for the old query, we need to
			// check if there are any blocklisted (for the Macros query) macros on the page
			// and attempt to use the old queries to render them instead
			const blocklistedExtensions = getMultiMacroQueryBlocklistFF();

			return preloadMacrosSSRIndividual(
				contentId,
				contentNodes,
				featureFlags,
				legacyBlocklistedExtensions,
				numberOfExtensionsToSSR,
				blocklistedExtensions,
			);
		}
	}

	return preloadMacrosSSRIndividual(
		contentId,
		contentNodes,
		featureFlags,
		legacyBlocklistedExtensions,
		numberOfExtensionsToSSR,
	);
};
