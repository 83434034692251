import { defineMessages, type MessageDescriptor } from 'react-intl-next';

import { type PrefillPrompts } from './types';

export const messages: Record<PrefillPrompts, MessageDescriptor> = defineMessages({
	marketingCampaignPrompt: {
		id: 'fabric.editor.ai.config.item.prefill.marketingCampaignPrompt',
		defaultMessage: 'Marketing campaign for',
		description:
			'A prefillable AI prompt to create a marketing campaign. When user selects this it will prefill the user input with this text. When displayed, there will also be a trailing indicator (such as ellipsis) appended to this message to indicate that a user can continue this message. The trailing indicator is another translation string that defaults to "...".',
	},
	prosAndConsPrompt: {
		id: 'fabric.editor.ai.config.item.prefill.prosAndConsPrompt',
		defaultMessage: 'Pros and cons table comparing',
		description:
			'A prefillable AI prompt to create a pros and cons table. When user selects this it will prefill the user input with this text. When displayed, there will also be a trailing indicator (such as ellipsis) appended to this message to indicate that a user can continue this message. The trailing indicator is another translation string that defaults to "...".',
	},
	competitiveAnalysisPrompt: {
		id: 'fabric.editor.ai.config.item.prefill.competitiveAnalysisPrompt',
		defaultMessage: 'Competitive analysis on',
		description:
			'A prefillable AI prompt to create a competitive analysis template. When user selects this it will prefill the user input with this text. When displayed, there will also be a trailing indicator (such as ellipsis) appended to this message to indicate that a user can continue this message. The trailing indicator is another translation string that defaults to "...".',
	},
	documentationPrompt: {
		id: 'fabric.editor.ai.config.item.prefill.documentationPrompt',
		defaultMessage: 'Documentation about',
		description:
			'A prefillable AI prompt to create documentation. When user selects this it will prefill the user input with this text. When displayed, there will also be a trailing indicator (such as ellipsis) appended to this message to indicate that a user can continue this message. The trailing indicator is another translation string that defaults to "...".',
	},
	onboardingGuidePrompt: {
		id: 'fabric.editor.ai.config.item.prefill.onboardingGuidePrompt',
		defaultMessage: 'Onboarding guide for',
		description:
			'A prefillable AI prompt to create an onboarding guide. When user selects this it will prefill the user input with this text. When displayed, there will also be a trailing indicator (such as ellipsis) appended to this message to indicate that a user can continue this message. The trailing indicator is another translation string that defaults to "...".',
	},
	sprintPlanningPrompt: {
		id: 'fabric.editor.ai.config.item.prefill.sprintPlanningPrompt',
		defaultMessage: 'Sprint planning for a project about',
		description:
			'A prefillable AI prompt to create a sprint planning template. When user selects this it will prefill the user input with this text. When displayed, there will also be a trailing indicator (such as ellipsis) appended to this message to indicate that a user can continue this message. The trailing indicator is another translation string that defaults to "...".',
	},
	budgetPlanPrompt: {
		id: 'fabric.editor.ai.config.item.prefill.budgetPlanPrompt',
		defaultMessage: 'Budget plan with exchange rates for',
		description:
			'A prefillable AI prompt to create a budget plan with exchange rates. When user selects this it will prefill the user input with this text. When displayed, there will also be a trailing indicator (such as ellipsis) appended to this message to indicate that a user can continue this message. The trailing indicator is another translation string that defaults to "...".',
	},
});
