// eslint-disable-next-line @atlaskit/ui-styling-standard/use-compiled -- Ignored via go/DSP-18766
import { css } from '@emotion/react';

import { N0 } from '@atlaskit/theme/colors';
import { token } from '@atlaskit/tokens';

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const aiFloatingToolbarCss = css({
	backgroundColor: token('elevation.surface.overlay', N0),
	padding: `${token('space.050', '4px')} ${token('space.150', '12px')}`,
	display: 'flex',
	alignItems: 'center',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	'& p': {
		font: token('font.body.small'),
		color: token('color.text.subtlest', '#626F86'),
		marginRight: `${token('space.050', '4px')}`,
	},
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const footerContentContainer = css({
	display: 'flex',
	alignItems: 'center',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	'& p': {
		margin: 0,
		padding: `0 ${token('space.050', '4px')} 0 0`,
	},
});
