/* eslint-disable @atlaskit/design-system/use-primitives-text */
/* eslint-disable @atlaskit/design-system/consistent-css-prop-usage */
/**
 * @jsxRuntime classic
 * @jsx jsx
 */
// eslint-disable-next-line @atlaskit/ui-styling-standard/use-compiled -- Ignored via go/DSP-18766
import { jsx } from '@emotion/react';
import { useIntl } from 'react-intl-next';

import Lozenge from '@atlaskit/lozenge';
import { token } from '@atlaskit/tokens';
import { LogoSvg } from '@atlassian/generative-ai-modal/assets/Logo';

import { messages } from './messages';
import { aiFloatingToolbarCss } from './styles';

export const AIFloatingToolbarFooter = () => {
	const { formatMessage } = useIntl();

	return (
		<div>
			{/* eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/design-system/consistent-css-prop-usage -- Ignored via go/DSP-18766 */}
			<div css={aiFloatingToolbarCss}>
				<p>{formatMessage(messages.contentQuality)}</p>
				<Lozenge>BETA</Lozenge>
			</div>
			{/* eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/design-system/consistent-css-prop-usage -- Ignored via go/DSP-18766 */}
			<div css={aiFloatingToolbarCss}>
				<p>{formatMessage(messages.poweredByAi)}</p>
				<LogoSvg
					alt={formatMessage(messages.logoAltText)}
					size={14}
					colorStart={token('color.text.subtlest', '#626F86')}
					colorStop={token('color.text.subtlest', '#626F86')}
				/>
			</div>
		</div>
	);
};
