/**
 * @jsxRuntime classic
 * @jsx jsx
 */

import { useEffect, useState } from 'react';

// eslint-disable-next-line @atlaskit/ui-styling-standard/use-compiled -- Ignored via go/DSP-18766
import { jsx } from '@emotion/react';
import { useIntl } from 'react-intl-next';

import { ToolbarButton } from '@atlaskit/editor-common/ui-menu';
import type { EditorView } from '@atlaskit/editor-prosemirror/view';
import SVG from '@atlaskit/icon/svg';
import type { SVGProps } from '@atlaskit/icon/types';
import { token } from '@atlaskit/tokens';

import { handleSpellingAndGrammarWithAnalytics } from '../../../pm-plugins/ai-spelling-grammar/commands-with-analytics';
import {
	DOC_CHANGE_COUNT_LIMIT,
	DOC_MINIMUM_SIZE_THRESHOLD,
} from '../../../pm-plugins/ai-spelling-grammar/constants';
import type { AISpellingGrammarPluginState } from '../../../pm-plugins/ai-spelling-grammar/states';
import { getAllDiffObjects } from '../../../pm-plugins/ai-spelling-grammar/utils';

import messages from './messages';

type Props = {
	isSelected: boolean;
	isEligibleForProactiveAISuggestions: boolean;
	hasDiffObjects: boolean;
	onClick: () => void;
	ButtonWrapper?: React.ComponentType<{ children: React.ReactNode }>;
};

const Icon = ({
	size,
	label,
	isDotVisible,
}: SVGProps & {
	isDotVisible: boolean;
}) => (
	<SVG secondaryColor={token('color.icon.accent.blue')} size={size} label={label}>
		{isDotVisible && (
			<rect x="15.9988" y="4" width="4.00122" height="4.00122" rx="2.00061" fill="inherit" />
		)}
		<path
			d="M16.0609 19.778L20 15.8389L18.9389 14.7778L15.5303 18.1864L14.3101 16.9662L13.249 18.0273L14.9998 19.778C15.2928 20.071 15.7678 20.071 16.0609 19.778Z"
			fill="currentColor"
		/>
		<path
			d="M7.36328 15.574L10.6069 6.41431H11.9907V8.12817H11.4956L9.10254 15.574H7.36328ZM9.00732 13.219L9.43896 11.9368H13.6411L14.0728 13.219H9.00732ZM13.9902 15.574L11.6035 8.12817V6.41431H12.4858L15.7358 15.574H13.9902Z"
			fill="currentColor"
		/>
	</SVG>
);

const SpellingAndGrammarBaseButton = ({
	isSelected,
	isEligibleForProactiveAISuggestions,
	hasDiffObjects,
	onClick,
	ButtonWrapper,
}: Props) => {
	const { formatMessage } = useIntl();
	const title = formatMessage(messages.spellingAndGrammarButtonLabel);
	const [isDotVisibleAfterDelay, setIsDotVisibleAfterDelay] = useState(false);
	useEffect(() => {
		if (isEligibleForProactiveAISuggestions) {
			/**
			 * Give the user an arbitrary delay so that the dot change does not
			 * line up with doc change
			 */
			const min = 400,
				max = 800;
			const timeout = setTimeout(
				() => {
					setIsDotVisibleAfterDelay(true);
				},
				// Pick a random number between 1600 and 4800 ms
				Math.floor(min + Math.random() * (max - min)),
			);
			return () => clearTimeout(timeout);
		} else {
			setIsDotVisibleAfterDelay(false);
		}
	}, [isEligibleForProactiveAISuggestions]);

	useEffect(() => {
		if (isSelected) {
			setIsDotVisibleAfterDelay(false);
		}
	}, [isSelected]);

	const button = (
		<ToolbarButton
			selected={isSelected}
			iconAfter={
				<Icon
					label={title}
					/**
					 * Only show the dot if the button is not selected
					 * and always show the dot if there were already diff objects from previous usage
					 */
					isDotVisible={!isSelected && (hasDiffObjects || isDotVisibleAfterDelay)}
				/>
			}
			onClick={onClick}
			title={title}
			aria-label={title}
			testId="atlassian-intelligence-proactive-spelling-and-grammar-buttton"
		/>
	);

	return ButtonWrapper ? <ButtonWrapper key="button-key-wrapper">{button}</ButtonWrapper> : button;
};

export const SpellingAndGrammarToolbarButton = ({
	editorView,
	aiSpellingGrammarPluginState,
	ButtonWrapper,
}: {
	editorView: EditorView;
	aiSpellingGrammarPluginState?: AISpellingGrammarPluginState;
	ButtonWrapper?: React.ComponentType<{ children: React.ReactNode }>;
}) => {
	if (!aiSpellingGrammarPluginState) {
		return null;
	}

	const {
		isSpellingAndGrammarEnabled,
		toggleCount,
		dismissedWords,
		insertionCount,
		initialToggleState,
		docChangeCountUntilLimit,
	} = aiSpellingGrammarPluginState;
	const allDiffObjects = getAllDiffObjects(aiSpellingGrammarPluginState);

	const realtimeAIOnClick = () => {
		const { state, dispatch } = editorView;

		handleSpellingAndGrammarWithAnalytics({
			proactiveToggleCount: toggleCount + 1,
			totalSuggestionsOnPage: allDiffObjects.length,
			dismissedWords: dismissedWords.size,
			insertionCount: insertionCount,
			initialToggleState,
			triggeredFrom: 'aiToolbarButton',
			operationType: 'toggle',
		})(state, dispatch);
	};

	/**
	 * If the document has meaningful text content
	 * and the doc has changed up to N times,
	 * then we want to hint that there may be suggestions
	 */
	const isEligibleForProactiveAISuggestions = Boolean(
		!isSpellingAndGrammarEnabled &&
			docChangeCountUntilLimit === DOC_CHANGE_COUNT_LIMIT &&
			editorView.state.doc.textContent.trim().length > DOC_MINIMUM_SIZE_THRESHOLD,
	);

	return (
		<SpellingAndGrammarBaseButton
			isEligibleForProactiveAISuggestions={isEligibleForProactiveAISuggestions}
			hasDiffObjects={!!allDiffObjects.length}
			isSelected={isSpellingAndGrammarEnabled}
			ButtonWrapper={ButtonWrapper}
			onClick={realtimeAIOnClick}
		/>
	);
};
