import React from 'react';

import { CONFIG_ITEM_KEYS } from '../../../config-items/config-item-keys';
import {
	createEditorPluginAIConfigItemMarkdown,
	type StatusLozengeType,
} from '../../../config-items/config-items';
import { Icon } from '../../assets/icons/brainstorm';
import {
	createInsertAtCurrentPosition,
	createInsertAtTop,
} from '../../config-item-actions/markdown';
import { isMinLength } from '../../config-item-visible/visibility-utils';
import { getExperimentalPlaceholderPromptConfiguration } from '../../experimental-placeholder-prompt-configuration';

const SELECTION_TYPE = 'empty';

export const getExperimentalPromptPlaceholderConfigItemForEmpty = () => {
	const configurations = getExperimentalPlaceholderPromptConfiguration();

	if (configurations) {
		const statusLozengeType =
			configurations.statusType.toLowerCase() === 'new' ||
			configurations.statusType.toLowerCase() === 'beta'
				? (configurations.statusType.toLowerCase() as StatusLozengeType)
				: undefined;
		return {
			...configurations,
			configItem: createEditorPluginAIConfigItemMarkdown({
				key: CONFIG_ITEM_KEYS.EXPERIMENTAL_PROMPT_PLACEHOLDER,
				title: configurations.label,
				description: configurations.description,
				selectionType: SELECTION_TYPE,
				intentSchemaId: configurations.intent,
				icon: ({ shownAt }) => <Icon shownAt={shownAt} />,
				isVisible: ({ editorView }) =>
					configurations.showOnEmpty || isMinLength({ editorView, minLength: 1 }),
				actions: [
					createInsertAtCurrentPosition({ appearance: 'secondary' }),
					createInsertAtTop({ appearance: 'primary' }),
				],
				getBackendModel: () => {
					return 'assistance-service';
				},
				statusLozengeType,
			}),
		};
	}
};
