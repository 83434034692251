// Disable no-re-export rule for entry point files
/* eslint-disable @atlaskit/editor/no-re-export */

import { type EditorView } from '@atlaskit/editor-prosemirror/view';
import { fg } from '@atlaskit/platform-feature-flags';
import { editorExperiment } from '@atlaskit/tmp-editor-statsig/experiments';

import type { EditorPluginAIConfigItemMarkdown } from '../config-items/config-items';
import type { AIPlugin } from '../editor-plugin-ai';
import { EditorPluginAI, NextEditorPluginAI } from '../editor-plugin-ai';
import { createOpenAIModalCommand } from '../pm-plugins/decoration/actions';
import { createEditorPluginAIProvider } from '../provider/provider';
import type { AIGlobalOptIn, SelectionToolbarDropdownConfig } from '../types';

import { prefillConfigItems } from './config-item-prefill/create-prefill-config-item';
import { brainstormConfigItem } from './config-items-empty/brainstorm/brainstorm';
import { getExperimentalPromptPlaceholderConfigItemForEmpty } from './config-items-empty/experimental-prompt-placeholder/experimental-prompt-placeholder';
import { findActionItemsConfigItem } from './config-items-empty/find-action-items/find-action-items';
import { generateContentConfigItem } from './config-items-empty/generate-content/generate-content';
import { suggestTitleConfigItem } from './config-items-empty/suggest-title/suggest-title';
import { summarizePageConfigItem } from './config-items-empty/summarize-page/summarize-page';
import { translationEmptyConfigItems } from './config-items-empty/translate/translate';
import {
	casualTone,
	educationalTone,
	empatheticTone,
	neutralTone,
	professionalTone,
} from './config-items-range/change-tone/change-tone';
import { messages as changeToneMessages } from './config-items-range/change-tone/messages';
import { fixSpellingAndGrammar, improveWriting } from './config-items-range/enhance/enhance';
import { messages as enhanceMessages } from './config-items-range/enhance/messages';
import { getExperimentalPromptPlaceholderConfigItemForRange } from './config-items-range/experimental-prompt-placeholder/experimental-prompt-placeholder';
import { findActionItemsFromSelectionConfigItem } from './config-items-range/find-action-items-from-selection/find-action-items-from-selection';
import { generateFromSelectionConfigItem } from './config-items-range/generate-from-selection/generate-from-selection';
import { messages as shortenMessages } from './config-items-range/shorten/messages';
import { shortenConfigItem } from './config-items-range/shorten/shorten';
import { suggestConfigItem } from './config-items-range/suggest/suggest';
import { messages as summarizeMessages } from './config-items-range/summarize/messages';
import { summarizeConfigItem } from './config-items-range/summarize/summarize';
import {
	translationRangeConfigItems,
	translationRangeConfigItemsMap,
} from './config-items-range/translate/translate';
import {
	addConclusionEmptyConfigItem,
	addConclusionRangeConfigItem,
} from './config-items/add-conclusion/add-conclusion';
import {
	addIntroductionEmptyConfigItem,
	addIntroductionRangeConfigItem,
} from './config-items/add-introduction/add-introduction';
import { convertToBulletListRangeConfigItem } from './config-items/convert-to-bullet-list/convert-to-bullet-list';
import { convertToTableRangeConfigItem } from './config-items/convert-to-table/convert-to-table';
import {
	makeLongerEmptyConfigItem,
	makeLongerRangeConfigItem,
} from './config-items/make-longer/make-longer';
import { rephraseEmptyConfigItem, rephraseRangeConfigItem } from './config-items/rephrase/rephrase';
import messages from './messages';
import { languageMessages } from './translations/messages';
import { getSortedSupportedTranslationLanguages } from './translations/utils';
import type { BaseCreateProviderProps } from './types';
import { _getFetchCustomHeaders, deriveGenerativeAIApiUrl, deriveProactiveAIConfig } from './utils';

export const UNSTABLE_configItems = {
	brainstormConfigItem,
};

/**
 * IMPORTANT: This API is likely to change in future versions when we look
 * to provide more configurability to consumers.
 * In future you may need to refactor to use a new API.
 *
 * ```ts
 * import { UNSTABLE_configItems, UNSTABLE_DO_NOT_USE_ONLY_FOR_CONFLUENCE_triggerConfigItem } from '@atlaskit/editor-plugin-ai/prebuilt/confluence';
 * // ...
 * UNSTABLE_DO_NOT_USE_ONLY_FOR_CONFLUENCE_triggerConfigItem({
 *   editorView,
 *   configItem: UNSTABLE_configItems.brainstormConfigItem,
 * })
 * ```
 */
export const UNSTABLE_DO_NOT_USE_ONLY_FOR_CONFLUENCE_triggerConfigItem = (props: {
	editorView: EditorView;
	configItem: EditorPluginAIConfigItemMarkdown;
}) => {
	const { editorView, configItem } = props;
	const openAIModalCommand = createOpenAIModalCommand({
		state: editorView.state,
		configItem,
		lastTriggeredFrom: 'triggerConfigItem',
		aiGlobalOptIn: {
			status: 'enabled',
			triggerOptInFlow: () => {},
		},
	});
	openAIModalCommand(editorView.state, editorView.dispatch);
};

// Confluence was relying on the deriveGenerativeAIApiUrl function in
// https://stash.atlassian.com/projects/confcloud/repos/confluence-frontend/commits/ad5bd5e4989a173d62fa3b966224a59dc2b296fc
//
// Making this optional and will be removed in the future once it's explicitly set in confluence.
type CreateEditorPluginAIProviderProps = Omit<BaseCreateProviderProps, 'generativeAIApiUrl'> & {
	/**
	 * Note -- optional use is deprecated, and will be removed in the future
	 */
	generativeAIApiUrl?: BaseCreateProviderProps['generativeAIApiUrl'];
};

// Page comment editor
// note -- shape is to maintain consistency with createPageEditorPluginAIProvider
export function createPageCommentEditorPluginAIProvider(props: BaseCreateProviderProps) {
	/**
	 * Not abstracting these defaults up, in order to be explicit about possible
	 * different behaviours between page comment and page editors.
	 */
	// Now deprecated - migrate to handleFeedbackSubmission
	const onExperienceEvent = props.onExperienceEvent || {
		THUMBS_UP: () => {},
		THUMBS_DOWN: () => {},
		REVIEW_STATE_ENTERED: () => {},
		EXPERIENCE_COMPLETE: () => {},
	};

	const getDefaultEmptySelectionPromptList = () => {
		let list: (
			| EditorPluginAIConfigItemMarkdown<'empty'>
			| EditorPluginAIConfigItemMarkdown<'range'>
		)[] = [];

		if (fg('platform_editor_ai_release_additional_prompts')) {
			list = [
				professionalTone,
				summarizeConfigItem,
				improveWriting,
				fixSpellingAndGrammar,
				shortenConfigItem,
				makeLongerEmptyConfigItem,
				rephraseEmptyConfigItem,
				suggestConfigItem,
				brainstormConfigItem,
				empatheticTone,
				casualTone,
				neutralTone,
				educationalTone,
				...translationEmptyConfigItems,
			];
		}
		// cleanup the experiment when cleanup platform_editor_ai_release_additional_prompts
		else if (
			editorExperiment('platform_editor_ai_additional_editor_prompts', 'test', { exposure: true })
		) {
			list = [
				professionalTone,
				summarizeConfigItem,
				improveWriting,
				fixSpellingAndGrammar,
				shortenConfigItem,
				makeLongerEmptyConfigItem,
				rephraseEmptyConfigItem,
				addIntroductionEmptyConfigItem,
				addConclusionEmptyConfigItem,
				suggestConfigItem,
				brainstormConfigItem,
				empatheticTone,
				casualTone,
				neutralTone,
				educationalTone,
				...translationEmptyConfigItems,
			];
		} else {
			list = [
				professionalTone,
				summarizeConfigItem,
				improveWriting,
				suggestConfigItem,
				fixSpellingAndGrammar,
				brainstormConfigItem,
				shortenConfigItem,
				empatheticTone,
				casualTone,
				neutralTone,
				educationalTone,
				...translationEmptyConfigItems,
			];
		}

		const experimentalPromptPlaceholderData = getExperimentalPromptPlaceholderConfigItemForEmpty();
		if (experimentalPromptPlaceholderData) {
			const configItem = experimentalPromptPlaceholderData.configItem;
			const listOrder = experimentalPromptPlaceholderData.listOrder;
			list.splice(listOrder, 0, configItem);
		}
		return list;
	};

	const getDefaultRangeSelectionPromptList = () => {
		let list: EditorPluginAIConfigItemMarkdown<'range'>[] = [];

		if (fg('platform_editor_ai_release_additional_prompts')) {
			list = [
				professionalTone,
				summarizeConfigItem,
				improveWriting,
				fixSpellingAndGrammar,
				shortenConfigItem,
				makeLongerRangeConfigItem,
				rephraseRangeConfigItem,
				convertToBulletListRangeConfigItem,
				convertToTableRangeConfigItem,
				suggestConfigItem,
				empatheticTone,
				casualTone,
				neutralTone,
				educationalTone,
				...translationRangeConfigItems,
			];
		}
		// cleanup the experiment when cleanup platform_editor_ai_release_additional_prompts
		else if (
			editorExperiment('platform_editor_ai_additional_editor_prompts', 'test', { exposure: true })
		) {
			list = [
				professionalTone,
				summarizeConfigItem,
				improveWriting,
				fixSpellingAndGrammar,
				shortenConfigItem,
				makeLongerRangeConfigItem,
				rephraseRangeConfigItem,
				convertToBulletListRangeConfigItem,
				convertToTableRangeConfigItem,
				addIntroductionRangeConfigItem,
				addConclusionRangeConfigItem,
				suggestConfigItem,
				empatheticTone,
				casualTone,
				neutralTone,
				educationalTone,
				...translationRangeConfigItems,
			];
		} else {
			list = [
				professionalTone,
				summarizeConfigItem,
				improveWriting,
				suggestConfigItem,
				fixSpellingAndGrammar,
				shortenConfigItem,
				empatheticTone,
				casualTone,
				neutralTone,
				educationalTone,
				...translationRangeConfigItems,
			];
		}

		const experimentalPromptPlaceholderData = getExperimentalPromptPlaceholderConfigItemForRange();
		if (experimentalPromptPlaceholderData) {
			const configItem = experimentalPromptPlaceholderData.configItem;
			const listOrder = experimentalPromptPlaceholderData.listOrder;
			list.splice(listOrder, 0, configItem);
		}
		return list;
	};

	return createEditorPluginAIProvider({
		disableAISelectionToolbar: true,
		emptyConfig: {
			baseGenerate: generateContentConfigItem,
			getSuggestions() {
				return getDefaultEmptySelectionPromptList();
			},
		},
		rangeConfig: {
			baseGenerate: generateFromSelectionConfigItem,
			getSuggestions() {
				return getDefaultRangeSelectionPromptList();
			},
		},
		generativeAIApiUrl: props.generativeAIApiUrl || deriveGenerativeAIApiUrl(),
		allowProactiveAIFeatures: false,
		proactiveAIConfig: deriveProactiveAIConfig({ enabled: false }),
		product: 'CONFLUENCE',
		getFetchCustomHeaders: props.getFetchCustomHeaders || _getFetchCustomHeaders,
		onExperienceEvent,
		handleFeedbackSubmission: props.handleFeedbackSubmission,
		AIButtonWrapper: props.AIButtonWrapper,
		proactiveAIToolbarButtonWrapper: props.proactiveAIToolbarButtonWrapper,
		PromptEditor: props.PromptEditor,
		providers: props.providers,
	});
}

/**
 * Intended for use with Confluence page comment editors
 */
export const createPageCommentEditorPluginAI = ({
	editorPluginAIProvider,
	aiGlobalOptIn,
}: {
	editorPluginAIProvider: ReturnType<typeof createEditorPluginAIProvider>;
	/**
	 * While you don't have a aiGlobalOptIn setup, you can pass in a dummy
	 * object with the status set to 'enabled' and a no-op triggerOptInFlow
	 *
	 * @example
	 * ```ts
	 * const editorPluginAI = createPageCommentEditorPluginAI({
	 *  editorPluginAIProvider,
	 *  aiGlobalOptIn: { status: 'enabled', triggerOptInFlow: () => {} },
	 * });
	 * ```
	 *
	 * Before setting up the opt-in nag, you may want to refactor to use the
	 * disabled state, rather than the dynamically added plugin strategy.
	 *
	 * @example
	 * ```ts
	 * const editorPluginAI = createPageCommentEditorPluginAI({
	 *  editorPluginAIProvider,
	 *  aiGlobalOptIn: { status: aiOptInStatus ? 'enabled' : 'disabled', triggerOptInFlow: () => {} },
	 * });
	 * ```
	 *
	 * Note: This is intended to be made mandatory once this version lands on confluence
	 */
	aiGlobalOptIn?: AIGlobalOptIn;
}) => {
	const nextEditorPluginAI = new EditorPluginAI({
		editorPluginAIProvider,
		aiGlobalOptIn: aiGlobalOptIn || {
			status: 'enabled',
			triggerOptInFlow: () => {},
		},
	});

	return nextEditorPluginAI;
};

// Page editor
/**
 * This is written here as a "wrapper" in order to retain control of
 * the other values passed into the provider, e.g. configItems,
 * etc. - due to the high level of uncertainty, we'll keep this in place until
 * past phase 3.
 *
 * (design ethos: having control/flexibility in the API)
 *
 */
export const createPageEditorPluginAIProvider = (props: CreateEditorPluginAIProviderProps) => {
	/**
	 * Not abstracting these defaults up, in order to be explicit about possible
	 * different behaviours between page comment and page editors.
	 */
	// Now deprecated - migrate to handleFeedbackSubmission
	const onExperienceEvent = props.onExperienceEvent || {
		THUMBS_UP: () => {},
		THUMBS_DOWN: () => {},
		REVIEW_STATE_ENTERED: () => {},
		EXPERIENCE_COMPLETE: () => {},
	};

	const getDefaultEmptySelectionPromptList = () => {
		let emptySelectionPromptList: (
			| EditorPluginAIConfigItemMarkdown<'empty'>
			| EditorPluginAIConfigItemMarkdown<'range'>
		)[] = [];

		if (fg('platform_editor_ai_release_additional_prompts')) {
			emptySelectionPromptList = [
				professionalTone,
				summarizePageConfigItem,
				improveWriting,
				fixSpellingAndGrammar,
				shortenConfigItem,
				makeLongerEmptyConfigItem,
				rephraseEmptyConfigItem,
				findActionItemsConfigItem,
				suggestTitleConfigItem,
				brainstormConfigItem,
				empatheticTone,
				casualTone,
				neutralTone,
				educationalTone,
				...translationEmptyConfigItems,
			];
		}
		// cleanup the experiment when cleanup platform_editor_ai_release_additional_prompts
		else if (
			editorExperiment('platform_editor_ai_additional_editor_prompts', 'test', { exposure: true })
		) {
			emptySelectionPromptList = [
				professionalTone,
				summarizePageConfigItem,
				improveWriting,
				fixSpellingAndGrammar,
				shortenConfigItem,
				makeLongerEmptyConfigItem,
				rephraseEmptyConfigItem,
				addIntroductionEmptyConfigItem,
				addConclusionEmptyConfigItem,
				findActionItemsConfigItem,
				suggestTitleConfigItem,
				brainstormConfigItem,
				empatheticTone,
				casualTone,
				neutralTone,
				educationalTone,
				...translationEmptyConfigItems,
			];
		} else {
			emptySelectionPromptList = [
				professionalTone,
				summarizePageConfigItem,
				improveWriting,
				findActionItemsConfigItem,
				suggestTitleConfigItem,
				fixSpellingAndGrammar,
				brainstormConfigItem,
				shortenConfigItem,
				empatheticTone,
				casualTone,
				neutralTone,
				educationalTone,
				...translationEmptyConfigItems,
			];
		}

		/**
		 * Note that this Experiment is limited to locale.startsWith('en') in code
		 * It is not done here as prebuilts cannot easily access locale
		 */
		if (editorExperiment('platform_editor_ai_draft_with_ai', true)) {
			emptySelectionPromptList.push(...prefillConfigItems);
		}

		const experimentalPromptPlaceholderDataEmpty =
			getExperimentalPromptPlaceholderConfigItemForEmpty();
		if (experimentalPromptPlaceholderDataEmpty) {
			const configItem = experimentalPromptPlaceholderDataEmpty.configItem;
			const listOrder = experimentalPromptPlaceholderDataEmpty.listOrder;
			emptySelectionPromptList.splice(listOrder, 0, configItem);
		}

		return emptySelectionPromptList;
	};

	const getDefaultRangeSelectionPromptList = () => {
		let rangeSelectionPromptList: EditorPluginAIConfigItemMarkdown<'range'>[] = [];

		if (fg('platform_editor_ai_release_additional_prompts')) {
			rangeSelectionPromptList = [
				professionalTone,
				summarizeConfigItem,
				improveWriting,
				fixSpellingAndGrammar,
				shortenConfigItem,
				makeLongerRangeConfigItem,
				rephraseRangeConfigItem,
				convertToBulletListRangeConfigItem,
				convertToTableRangeConfigItem,
				findActionItemsFromSelectionConfigItem,
				suggestConfigItem,
				empatheticTone,
				casualTone,
				neutralTone,
				educationalTone,
				...translationRangeConfigItems,
			];
		}
		// cleanup the experiment when cleanup platform_editor_ai_release_additional_prompts
		else if (
			editorExperiment('platform_editor_ai_additional_editor_prompts', 'test', { exposure: true })
		) {
			rangeSelectionPromptList = [
				professionalTone,
				summarizeConfigItem,
				improveWriting,
				fixSpellingAndGrammar,
				shortenConfigItem,
				makeLongerRangeConfigItem,
				rephraseRangeConfigItem,
				convertToBulletListRangeConfigItem,
				convertToTableRangeConfigItem,
				addIntroductionRangeConfigItem,
				addConclusionRangeConfigItem,
				findActionItemsFromSelectionConfigItem,
				suggestConfigItem,
				empatheticTone,
				casualTone,
				neutralTone,
				educationalTone,
				...translationRangeConfigItems,
			];
		} else {
			rangeSelectionPromptList = [
				professionalTone,
				summarizeConfigItem,
				improveWriting,
				findActionItemsFromSelectionConfigItem,
				suggestConfigItem,
				fixSpellingAndGrammar,
				shortenConfigItem,
				empatheticTone,
				casualTone,
				neutralTone,
				educationalTone,
				...translationRangeConfigItems,
			];
		}

		const experimentalPromptPlaceholderDataRange =
			getExperimentalPromptPlaceholderConfigItemForRange();
		if (experimentalPromptPlaceholderDataRange) {
			const configItem = experimentalPromptPlaceholderDataRange.configItem;
			const listOrder = experimentalPromptPlaceholderDataRange.listOrder;
			rangeSelectionPromptList.splice(listOrder, 0, configItem);
		}

		return rangeSelectionPromptList;
	};

	return createEditorPluginAIProvider({
		disableAISelectionToolbar: false,
		emptyConfig: {
			baseGenerate: generateContentConfigItem,
			getSuggestions() {
				return getDefaultEmptySelectionPromptList();
			},
		},
		rangeConfig: {
			baseGenerate: generateFromSelectionConfigItem,
			getSuggestions() {
				return getDefaultRangeSelectionPromptList();
			},
			getSelectionToolbarDropdowns() {
				const selectionToolbarOptions: SelectionToolbarDropdownConfig[] = [
					{
						id: 'change-tone',
						label: messages.confluenceChangeToneSelectionToolbarDropdownMenuTitle,
						options: [
							{
								label: changeToneMessages.professionalToneSelectionToolbarDropdownItemTitle,
								configItem: professionalTone,
							},
							{
								label: changeToneMessages.empatheticToneSelectionToolbarDropdownItemTitle,
								configItem: empatheticTone,
							},
							{
								label: changeToneMessages.casualToneSelectionToolbarDropdownItemTitle,
								configItem: casualTone,
							},
							{
								label: changeToneMessages.neutralToneSelectionToolbarDropdownItemTitle,
								configItem: neutralTone,
							},
							{
								label: changeToneMessages.educationalToneSelectionToolbarDropdownItemTitle,
								configItem: educationalTone,
							},
						],
					},
					{
						id: 'rewrite',
						label: messages.confluenceRewriteSelectionToolbarDropdownMenuTitle,
						options: [
							{
								label: enhanceMessages.improveWritingSelectionToolbarDropdownItemTitle,
								configItem: improveWriting,
							},
							{
								label: enhanceMessages.fixSpellingGrammarSelectionToolbarDropdownItemTitle,
								configItem: fixSpellingAndGrammar,
							},
							{
								label: summarizeMessages.selectionToolbarDropdownItemTitle,
								configItem: summarizeConfigItem,
							},
							{
								label: shortenMessages.selectionToolbarDropdownItemTitle,
								configItem: shortenConfigItem,
							},
						],
					},
				];

				const translateOptions = getSortedSupportedTranslationLanguages().map((language) => {
					const label = languageMessages[language];
					return {
						label,
						configItem: translationRangeConfigItemsMap[language],
					};
				});

				selectionToolbarOptions.push({
					id: 'translate',
					label: messages.confluenceTranslateSelectionToolbarDropdownMenuTitle,
					options: translateOptions,
				});
				return selectionToolbarOptions;
			},
		},
		generativeAIApiUrl: props.generativeAIApiUrl || deriveGenerativeAIApiUrl(),
		allowProactiveAIFeatures: props.allowProactiveAIFeatures,
		proactiveAIConfig: {
			...deriveProactiveAIConfig(),
			...props.proactiveAIConfig,
		},
		product: 'CONFLUENCE',
		isFullPageExperimentsEnabled: true,
		getFetchCustomHeaders: props.getFetchCustomHeaders || _getFetchCustomHeaders,
		getChannelVariables: props.getChannelVariables,
		onDocChangeByAgent: props.onDocChangeByAgent,
		onAIProviderChanged: props.onAIProviderChanged,
		onExperienceEvent,
		handleFeedbackSubmission: props.handleFeedbackSubmission,
		AIButtonWrapper: props.AIButtonWrapper,
		proactiveAIToolbarButtonWrapper: props.proactiveAIToolbarButtonWrapper,
		PromptEditor: props.PromptEditor,
		disableInterrogation: props.disableInterrogation ?? false,
		aiUsageDisclaimer: props.aiUsageDisclaimer,
		isRovoEnabled: props.isRovoEnabled ?? true,
		providers: props.providers,
	});
};

/**
 * Intended for use with Confluence page editors (when using the new editor)
 *
 */
export const PageNextEditorPlugin: AIPlugin = ({
	config: { editorPluginAIProvider, aiGlobalOptIn },
	api,
}): ReturnType<typeof NextEditorPluginAI> => {
	const nextEditorPluginAI = NextEditorPluginAI({
		config: {
			editorPluginAIProvider,
			aiGlobalOptIn: aiGlobalOptIn || {
				status: 'enabled',
				triggerOptInFlow: () => {},
			},
		},
		api,
	});
	return nextEditorPluginAI;
};

/**
 * Intended for use with Confluence Page Comment editors (when using the new editor)
 *
 */
export const PageCommentNextEditorPlugin: AIPlugin = PageNextEditorPlugin;

/**
 * Intended for use with Confluence page editors
 *
 */
export const createPageEditorPluginAI = ({
	editorPluginAIProvider,
	aiGlobalOptIn,
}: {
	editorPluginAIProvider: ReturnType<typeof createEditorPluginAIProvider>;
	/**
	 * While you don't have a aiGlobalOptIn setup, you can pass in a dummy
	 * object with the status set to 'enabled' and a no-op triggerOptInFlow
	 *
	 * @example
	 * ```ts
	 * const editorPluginAI = createPageEditorPluginAI({
	 *  editorPluginAIProvider,
	 *  aiGlobalOptIn: { status: 'enabled', triggerOptInFlow: () => {} },
	 * });
	 * ```
	 *
	 * Before setting up the opt-in nag, you may want to refactor to use the
	 * disabled state, rather than the dynamically added plugin strategy.
	 *
	 * @example
	 * ```ts
	 * const editorPluginAI = createPageEditorPluginAI({
	 *  editorPluginAIProvider,
	 *  aiGlobalOptIn: { status: aiOptInStatus ? 'enabled' : 'disabled', triggerOptInFlow: () => {} },
	 * });
	 * ```
	 *
	 * Note: This is intended to be made mandatory once this version lands on confluence
	 */
	aiGlobalOptIn?: AIGlobalOptIn;
}) => {
	const editorPluginAI = new EditorPluginAI({
		editorPluginAIProvider,
		aiGlobalOptIn: aiGlobalOptIn || {
			status: 'enabled',
			triggerOptInFlow: () => {},
		},
	});

	return editorPluginAI;
};
