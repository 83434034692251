/**
 * @jsxRuntime classic
 * @jsx jsx
 */
import React from 'react';

// eslint-disable-next-line @atlaskit/ui-styling-standard/use-compiled -- Ignored via go/DSP-18766
import { jsx } from '@emotion/react';
import { useIntl } from 'react-intl-next';

import { IconButton, type IconButtonProps } from '@atlaskit/button/new';
import ArrowLeftIcon from '@atlaskit/icon/core/arrow-left';
import ArrowRightIcon from '@atlaskit/icon/core/arrow-right';

import messages from './messages';

type Props = {
	direction: 'back' | 'forward';
	onClick: (event: React.MouseEvent) => void;
	tabIndex?: number;
	appearance?: IconButtonProps['appearance'];
	spacing?: IconButtonProps['spacing'];
	isDisabled?: boolean;
};

export const HistoryArrowButton = ({
	direction,
	onClick,
	tabIndex,
	appearance,
	spacing,
	isDisabled,
}: Props) => {
	const { formatMessage } = useIntl();
	const tooltip = formatMessage(
		direction === 'back' ? messages.traverseHistoryBack : messages.traverseHistoryForward,
	);

	const tooltipOptions: IconButtonProps['tooltip'] = {
		position: 'top',
		hideTooltipOnClick: true,
	};

	return (
		<IconButton
			testId={`history-arrow-button-${direction}`}
			type="button"
			onClick={onClick}
			icon={direction === 'back' ? ArrowLeftIcon : ArrowRightIcon}
			label={tooltip}
			appearance={appearance}
			spacing={spacing}
			tabIndex={tabIndex}
			isDisabled={isDisabled}
			isTooltipDisabled={false}
			tooltip={tooltipOptions}
		/>
	);
};
