/* GeneratedContentHash: 1a5276e3605ad78395d281339cb88b6cdf499079e915b878d4e4f78de51617e9 */
/*
# This file was automatically generated using "yarn generate:all" command.
# Please do not edit it manually.
# These exports are for client side use only
# Importing into post office backend without a bundler will break the build
#
# Generate source: local/cli/src/commands/generate-all/team-export.ts
*/
import { lazy } from "react";

export default {
	'test-message-inapp': lazy(
			() =>
			import(
				/* webpackChunkName: "[request]" */
				'@post-office/message-template--test-message-inapp/placements/in-app/screen-space-flags/render'
			),
	),
	'test-message-inapp-implicit-pendo': lazy(
			() =>
			import(
				/* webpackChunkName: "[request]" */
				'@post-office/message-template--test-message-inapp-implicit-pendo/placements/in-app/screen-space-flags/render'
			),
	),
};
