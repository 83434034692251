import { preloadPersonalSpaceDetail } from '@confluence/space-utils/entry-points';
import { preloadIntentBasedOnboarding } from '@confluence/home/entry-points/preloadIntentBasedOnboarding';
import { preloadHome } from '@confluence/home/entry-points/preloadHome';
import { preloadTemplatesCollectionEligible } from '@confluence/home/entry-points/preloadTemplatesCollectionEligible';
import { bestEffortTask, getPreloaderFnContext } from '@confluence/query-preloader-tools';
import { preloadPostOfficeConfluenceHomeBanner } from '@confluence/experiment-post-office-home-banner/entry-points/preloadConfuenceHomeBanner';
import type { RouteMatch } from '@confluence/route';
import { fg } from '@confluence/feature-gating';
import { isContentTypeEnabledInCurrentEnv } from '@confluence/content-types-utils';

export const preloadHomeRoute = async (match: RouteMatch) => {
	const preloadedSessionContext = await getPreloaderFnContext();
	const { featureFlags, userId, cloudId, isLicensed, accessStatus } = preloadedSessionContext;

	const isDatabasesEnabled = isContentTypeEnabledInCurrentEnv('database');

	const tasks: Promise<any>[] = [];

	const canAccessHome =
		accessStatus !== 'ANONYMOUS_ACCESS' && accessStatus !== 'UNLICENSED_AUTHENTICATED_ACCESS';

	if (isLicensed && canAccessHome) {
		tasks.push(
			preloadHome({
				cloudId,
				isLicensed,
				userId,
				matchParams: match.params,
				isSpaceAliasFFEnabled: true,
				isWhiteboardViewedEnabled: Boolean(featureFlags['confluence.frontend.whiteboard.enable']),
				isDatabaseEnabled: isDatabasesEnabled,
			}),
		);
	}

	tasks.push(preloadPersonalSpaceDetail());

	if (userId) {
		tasks.push(preloadIntentBasedOnboarding(), preloadTemplatesCollectionEligible());
	}

	if (userId && fg('post-office-shadow-rollout-confluence-home-ssr')) {
		// Shadow rollout for Confluence Home Banner in SSR only.
		// This should not block any existing SSR steps, and will just run in the background as a promise
		// to allow the Post Office team to gather metrics on our API response time.
		const preloadPostOfficeHome = async () => {
			return preloadPostOfficeConfluenceHomeBanner(preloadedSessionContext);
		};

		tasks.push(bestEffortTask('preloadPostOfficeConfluenceHomeBanner', preloadPostOfficeHome));
	}

	return Promise.all(tasks);
};
