import type { EditorState } from '@atlaskit/editor-prosemirror/state';
import type { EditorView } from '@atlaskit/editor-prosemirror/view';

import type { ProactiveAIDocumentCheckerConfig } from '../types';

import type { ParagraphChunk } from './diff-match-patch/utils';

export interface DocumentChecker {
	setView(view: EditorView): void;
	start(): void;
	stop(): void;
	reset(): void;
}

export class DocumentChunkScanner<T extends ParagraphChunk> implements DocumentChecker {
	private view?: EditorView;
	private timerId?: ReturnType<typeof setTimeout>;
	private stepCount: number = 0;
	private active: boolean = false;

	constructor(
		private config: ProactiveAIDocumentCheckerConfig,
		private getChunks: (state: EditorState) => T[],
		private triggerCallback: (chunks: T[], view: EditorView) => Promise<void>,
	) {}

	isActive(): boolean {
		return this.active;
	}

	private async checkNextBlocks() {
		if (!this.view || !this.active) {
			return;
		}

		const chunks = this.getChunks(this.view.state).slice(0, this.config.blocksPerRequest);

		if (!!chunks?.length) {
			await this.triggerCallback(chunks, this.view);
			// It's possible for the document scanner to be stopped while we're waiting for a chunk to be checked
			// we need to avoid checking the next block if this happens.
			this.active && this.next();
		} else {
			this.stop();
		}
	}

	private next() {
		this.timerId = setTimeout(this.checkNextBlocks.bind(this), this.getStepDelay(++this.stepCount));
	}

	/**
	 * This calculates the delay (in milliseconds) which the document scanner uses when determing when the next server request
	 * should occur.
	 *
	 * When a document is scanned it's broken up into chunks, these chunks are sent to the server in batched requests.
	 * Each batch request is classified as a step. For large documents we want to ensure that we're not overwhelming the
	 * server with too many chunks too often. This delay is designed to gradually increase as each group of chunks is sent
	 * to the server, stopping at a maximum delay value.
	 *
	 * Reseting this document scanner will also reset the step back to the start, in-turn reduce the delay back to the minimum.
	 * If you want to just pause the document scan without resetting the delay, use stop/start instead.
	 */
	private getStepDelay(step: number): number {
		const { delayBetweenChecks, delayIncrease, maxDelay, numberOfRequestsForIncrease } =
			this.config.timings;

		const increase = delayIncrease * Math.floor(step / Math.max(numberOfRequestsForIncrease, 1));
		return Math.max(delayBetweenChecks, Math.min(maxDelay, delayBetweenChecks + increase));
	}

	setView(view: EditorView) {
		this.reset();
		this.view = view;
	}

	start() {
		if (!this.active) {
			this.active = true;
			this.checkNextBlocks();
		}
	}

	stop() {
		this.active = false;
		clearTimeout(this.timerId);
		this.timerId = undefined;
	}

	reset() {
		this.stop();
		this.stepCount = 0;
	}
}
