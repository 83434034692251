import memoize from 'memoize-one';

// eslint-disable-next-line import/no-extraneous-dependencies
import { filter } from '@atlaskit/adf-utils/traverse';
import type { ADDoc, ADNode } from '@atlaskit/editor-common/validator';
import { ADFStages } from '@atlaskit/editor-common/validator';
import { validateADFEntity } from '@atlaskit/editor-common/utils';
import type { ADFEntity } from '@atlaskit/adf-utils/types';
import { getSchemaBasedOnStage } from '@atlaskit/adf-schema/schema-default';

import {
	BODIED_EXTENSION_TYPE,
	EXTENSION_TYPE,
	serverRenderedExtensions,
	supportedExtensions,
} from '@confluence/fabric-extension-lib/entry-points/extensionConstants';
import { extensionToADF } from '@confluence/fabric-extension-lib/entry-points/editor-extensions';
import type {
	PreloadFailureReason,
	PreloadFailureResult,
} from '@confluence/fabric-extension-lib/entry-points/fabric-extension-lib-types';
import { query } from '@confluence/query-preloader-tools';
import {
	getMacroQueryVariables,
	MacroBodyRendererQueryWithTags,
	MacroContentRendererQueryWithTags,
	ContentConverterQuery,
	getContentConverterVariables,
} from '@confluence/fabric-extension-queries';
import type {
	ContentConverterQueryTypes,
	ContentConverterQueryVariables,
} from '@confluence/fabric-extension-queries';

import { DEFAULT_PRELOAD_COUNT } from './preloader-constants';
import { macroPreloadGuard } from './macroPreloadGuard';

export const FF_EDITOR_MACRO_ADF_RENDERING =
	'confluence.frontend.fabric.editor.macro.adf.frontend.render';

const knownExtensionSet = new Set<string>(serverRenderedExtensions);

const getMacroId = (node: ADFEntity) => node.attrs?.parameters?.macroMetadata?.macroId?.value;

const extractExtensions = (adf: ADDoc) => filter(adf, (node) => supportedExtensions.has(node.type));

const augmentWithMarks = (marks?: any[]) => {
	if (!marks?.length) {
		return {};
	}

	const fragments = marks.filter((mark) => mark.type === 'fragment');
	if (fragments.length) {
		return {
			fragmentLocalId: fragments[0].attrs.localId,
		};
	}

	return {};
};

const blocklistCache = new Map<string, boolean>();

// WARN: used for testing only
export const _clearBlocklistCache = () => blocklistCache.clear();

const isBlocklisted = (blocklist: string[], extensionKey: string) => {
	if (blocklist.length === 0) {
		return false;
	}

	if (blocklistCache.has(extensionKey)) {
		return blocklistCache.get(extensionKey);
	}

	const isBlocked =
		blocklist.length > 0 &&
		blocklist.some((block: string) => {
			try {
				const blockRegex = new RegExp(block, 'ig');
				return blockRegex.test(extensionKey);
			} catch (err) {
				// Malformed regex/input, consider extension being not blocked
				return false;
			}
		});

	blocklistCache.set(extensionKey, isBlocked);
	return isBlocked;
};

// The rule of excluding extensions from querying in the prepare phase of SSR are the following:
//  - extension should be located at the top of the page, assuming extensions returned by the
//    adf-utils/filter are in the order from top to down of the page;
//  - extension should be common/legacy, i.e. of type "com.atlassian.confluence.macro.core";
//  - extension should not be a part of those we have a special handling in SPA (excepting children macro);
//  - extensions shouldn't have an output in a content query.
const byExcluded =
	(excludeExtensionIdSet: Set<string>, numberOfExtensionsToSSR: number = 0) =>
	(node: ADFEntity, index: number) => {
		const { parameters, extensionKey, extensionType } = node.attrs || {};

		return (
			index < numberOfExtensionsToSSR &&
			extensionType === EXTENSION_TYPE.MACRO_CORE &&
			(!knownExtensionSet.has(extensionKey) || extensionKey === 'children') &&
			!parameters.macroOutput &&
			!excludeExtensionIdSet.has(getMacroId(node))
		);
	};

const getSchema = memoize(() => getSchemaBasedOnStage(ADFStages.STAGE_0));

const createMacroQueryParamsProvider = (documentADF: any) => {
	// This code (both `getRendererDocument` and `getRendererNodeContent`) inspired by `renderDocument`
	// function in `platform/packages/editor/renderer/src/render-document.ts`
	// Here we convert the `content` of our adf node to renderer/editor/ProseMirror node,
	// which ensures `content` in preloader and in SSR render would match and would not cause apollo cache mismatch.

	const getRendererDocument = memoize(() => {
		const schema = getSchema();
		const validDocumentADF = validateADFEntity(schema, documentADF);
		const pnDoc = schema.nodeFromJSON(validDocumentADF);
		return {
			version: 1,
			type: 'doc',
			content: pnDoc.content.toJSON() as ADFEntity[],
		};
	});

	const getRendererNodeContent = (macroId: string) => {
		const rendererDocument = getRendererDocument();
		const [rendererNode] = filter(rendererDocument, (n) => getMacroId(n) === macroId);

		return (rendererNode.content as ADNode[]) || [];
	};

	return (node: ADFEntity) => {
		const requestADF = extensionToADF(node);
		requestADF.attrs = {
			...requestADF.attrs,
			...augmentWithMarks(node.marks),
		};

		if (requestADF.type === BODIED_EXTENSION_TYPE) {
			requestADF.content = getRendererNodeContent(getMacroId(node));
		}

		delete requestADF.attrs.layout;

		return { ...requestADF, type: node.type };
	};
};

const preloadMacrosSSRAndReturnTopXFailed = async (
	contentId: string,
	contentNodes: any,
	featureFlags: { [key: string]: string | number | boolean },
	numberOfExtensionsToSSR,
	renderOnlyExtensionKeys,
	extensionsBlocklist: string,
) => {
	if (renderOnlyExtensionKeys && renderOnlyExtensionKeys.length === 0) {
		return {};
	}

	const blocklistedExtensions = extensionsBlocklist
		.split(',')
		.map((extensionName) => extensionName.trim())
		.filter(Boolean);

	// Catch error if contentNodes is empty
	if (!contentNodes || !contentNodes[0]) {
		return {
			general: 'contentNodesEmpty',
		};
	}
	// Leave early when there's TinyMCE or borked content
	if (contentNodes[0].body.dynamic?.representation !== 'atlas_doc_format') {
		return {};
	}

	// Exclude extensions that are included into the content response as we don't need
	// to load any data for these extensions.
	const excludeExtensionIdSet = new Set<string>(
		(contentNodes[0].macroRenderedOutput || []).map((ext) => ext.key),
	);

	let documentADF;
	try {
		documentADF = JSON.parse(contentNodes[0].body.dynamic.value);
	} catch (err) {
		// While contentNodes[0].body.dynamic.value should be defined in all valid cases,
		// and should it not, the experience for the user will be broken anyway, we want
		// to swallow this error, skip macros preloading and let SPA handle the case, as
		// SPA already have proper experience tracking.
		return;
	}

	const toMacroQueryParams = createMacroQueryParamsProvider(documentADF);

	const extensions = extractExtensions(documentADF)
		.filter(byExcluded(excludeExtensionIdSet, numberOfExtensionsToSSR || DEFAULT_PRELOAD_COUNT))
		.filter(
			(node) =>
				!renderOnlyExtensionKeys || renderOnlyExtensionKeys.includes(node?.attrs?.extensionKey),
		)
		.map(toMacroQueryParams);

	if (numberOfExtensionsToSSR === 0) {
		return extensions.length === 0 ? {} : undefined;
	}

	const preloadResult = await Promise.all(
		extensions.map(async (extensionADF) => {
			const { isMacroContentAndBody, ...variables } = getMacroQueryVariables({
				adf: extensionADF,
				contentId,
				featureFlags,
			});

			const attr = extensionADF.attrs;
			const extensionKey = attr?.extensionKey;

			const failure = (reason: PreloadFailureReason) => ({
				[`${extensionKey}:${getMacroId(extensionADF)}`]: reason,
			});
			const failureIfNotSuccess = (reason: PreloadFailureReason) => (success: boolean) =>
				success ? undefined : failure(reason);

			// we may block some extensions via feature flags
			if (isBlocklisted(blocklistedExtensions, extensionKey)) {
				return failure('blocklist');
			}

			let macroBodyPreloadPromise: Promise<PreloadFailureResult | undefined> =
				Promise.resolve(undefined);

			if (extensionADF.type === BODIED_EXTENSION_TYPE) {
				macroBodyPreloadPromise = macroPreloadGuard(
					`ExtensionContentConverter_${extensionKey || 'unknown'}`,
					() =>
						query<ContentConverterQueryTypes, ContentConverterQueryVariables>({
							variables: getContentConverterVariables({
								content: extensionADF.content,
								contentId,
							}),
							query: ContentConverterQuery,
							context: { single: true },
						}),
				).then(failureIfNotSuccess('timeout-macrobody'));
			}

			const macroPreloadPromise = macroPreloadGuard(
				`Extension_${extensionKey || 'unknown'}`,
				() => {
					return query({
						variables,
						query: isMacroContentAndBody
							? MacroBodyRendererQueryWithTags
							: MacroContentRendererQueryWithTags,
						context: { single: true },
					});
				},
			).then(failureIfNotSuccess('timeout'));

			const [macroPreloadResult, macroBodyPreloadResult] = await Promise.all([
				macroPreloadPromise,
				macroBodyPreloadPromise,
			]);

			return { ...(macroPreloadResult || {}), ...(macroBodyPreloadResult || {}) };
		}),
	);

	const preloadFailures = preloadResult ? preloadResult.filter((item) => !!item) : [];
	return Object.assign({}, ...preloadFailures);
};

// For the returning result of the function there are following cases:
// - object with some fields - preload was performed with some fails
// - empty object - when
// -- TinyMCE page, or
// -- preload was performed without any fail, valid content with zero
//      legacy macro would produce this result
// - undefined - content is `broken` or there are macros eligible for preload,
//      but there was no attempt to preload due to disabled FF
export async function preloadMacrosSSRIndividual(
	contentId: string,
	contentNodes: any,
	featureFlags: { [key: string]: string | number | boolean },
	extensionsBlocklist: string,
	numberOfExtensionsToSSR = DEFAULT_PRELOAD_COUNT,
	renderOnlyExtensionIds?: string[],
): Promise<PreloadFailureResult | undefined> {
	// Function has early return so we extracted it out to avoid setting global in every return
	return (window['__SSR_MACRO_PRELOAD_TOPX_FAILS__'] = await preloadMacrosSSRAndReturnTopXFailed(
		contentId,
		contentNodes,
		featureFlags,
		numberOfExtensionsToSSR,
		renderOnlyExtensionIds,
		extensionsBlocklist,
	));
}
