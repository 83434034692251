import FeatureGates from '@atlaskit/feature-gate-js-client';
import { fg } from '@atlaskit/platform-feature-flags';

import { type ProactiveAIConfig } from '../types';

// Note -- when updating this function -- also update
// the one used in packages/editor/editor-plugin-ai/examples/utils/getDevFetchCustomHeaders.ts
export function _getFetchCustomHeaders(): HeadersInit {
	const currentHost = window.location.origin;

	switch (true) {
		case currentHost === 'https://statlas.prod.atl-paas.net':
			// Update this to add custom headers when run in atlaskit branch deploys
			// ie.
			// {
			//   'X-CloudID': 'Magic string'
			// }
			return {};
		default:
			return {};
	}
}

export function deriveHost() {
	const {
		origin = '',
		port = '',
		hostname = '',
	} = typeof window.location !== 'undefined' ? window.location : {};

	if (
		(hostname === 'localhost' && port !== '8081') ||
		[
			'atlaskit-website-staging.stg-east.frontend.public.atl-paas.net',
			'statlas.prod.atl-paas.net',
			'atlaskit.atlassian.com',
		].some((h) => hostname === h)
	) {
		return 'https://pug.jira-dev.com';
	}

	return origin;
}

export function deriveGenerativeAIApiUrl() {
	return `${deriveHost()}/gateway/api/editor-ai/v1/generative/ai`;
}

/**
 * Tech Spec: https://hello.atlassian.net/wiki/spaces/CA3/pages/3776161653/Bulk+Spelling+and+Grammar+Endpoint
 */
export function deriveProactiveAISpellingAndGrammarApiUrl() {
	const version = fg('platform_editor_ai_proactive_ai_md_plus') ? 'v2' : 'v1';
	const url = new URL(`/gateway/api/${version}/grammar`, deriveHost());

	const getExperimentValue = FeatureGates.getExperimentValue;
	const variation = getExperimentValue(
		'editor_ai_-_proactive_ai_model_variations',
		'cohort',
		'not-enrolled',
	);

	if (variation !== 'not-enrolled' && variation !== 'control') {
		url.searchParams.set('variation', variation);
	}

	return url.toString();
}

export function deriveProactiveAIApiUrl() {
	return `${deriveHost()}/gateway/api/assist/ai/v2/ai-feature/proactive`;
}

export function deriveProactiveAIConfig(overrides?: Partial<ProactiveAIConfig>) {
	return {
		enabled: false,
		apiUrl: deriveProactiveAISpellingAndGrammarApiUrl(),
		defaultToggledState: false,
		timings: {
			currentChunks: 4000,
			currentChunksMaxWait: 30000,
			nonCurrentChunks: 2000,
		},
		...overrides,
	};
}

export const convoAIStatelessApiURL = `${deriveHost()}/gateway/api/assist/chat/v1/invoke_agent/stream`;
export const convoAIStatefulApiURL = `${deriveHost()}/gateway/api/assist/chat/v1/channel`; // + `/${channelID}/message/stream` at the end;
