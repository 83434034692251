import { parse } from 'url';

import type { FC } from 'react';
import { useEffect, useContext } from 'react';

import { useAnalyticsEvents } from '@atlaskit/analytics-next';

import { isEmbeddedConfluence_DO_NOT_USE } from '@atlassian/embedded-confluence/isEmbeddedConfluence';

import type { RoutesContextType } from './RoutesContext';
import { RoutesContext } from './RoutesContext';

type RedirectionProps = {
	href?: string;
	name?: string;
	params?: Parameters<RoutesContextType['toUrl']>[1];
	to?: string;
	usePush?: boolean;
	forceReload?: Parameters<RoutesContextType['push']>[1];
};

export const Redirection: FC<RedirectionProps> = ({
	href = null,
	name,
	params,
	to = null,
	usePush = false,
	forceReload = false,
}) => {
	const { createAnalyticsEvent } = useAnalyticsEvents();
	const isSpaceAliasFFEnabled = true;

	const { push, replace, toUrl, getHash, getQueryParams, location, getHref, matchRoute } =
		useContext(RoutesContext);

	const getUrl = () => {
		const customPath = href || to;
		let parsedUrl = {};
		if (customPath) {
			parsedUrl = parse(customPath, true);
		}

		return toUrl(name, {
			hash: getHash(),
			query: getQueryParams(),
			...params,
			...parsedUrl,
		});
	};

	// When it's in Embedded Confluence we want to disable SSR redirection optimization and let SPA redirection
	// otherwise iframe onload event will be triggered too early and cause Embedded Confluence to display login/storage access page
	if (process.env.REACT_SSR && !isEmbeddedConfluence_DO_NOT_USE()) {
		// This instructs cc-frontend-ssr to throw away the SSR output and write an HTML meta redirect instead
		window['__SSR_REDIRECT__'] = getUrl();
	}

	useEffect(() => {
		const oldRouteFromPathname = location?.pathname ? matchRoute(location.pathname) : null;
		const oldRouteFromHref = isSpaceAliasFFEnabled ? matchRoute(getHref()) : null;
		const oldRoute = isSpaceAliasFFEnabled ? oldRouteFromHref : oldRouteFromPathname;
		const oldRouteName = oldRoute?.name || null;
		const getAnalyticsAttributes = ({ newRouteName }: { newRouteName: string | undefined }) => ({
			oldRouteName: oldRouteName,
			newRouteName: newRouteName,

			// It is safe to fire an event with the names of the route params because they are not UGC.
			// They are part of our route definitions. In contrast, the values of the route params are UGC.
			// Additionally, both the names and values of the route query params are UGC because they are
			// read directly from the URL without matching against our route definitions.
			oldRouteParamNames: Object.keys(oldRoute?.params || {}),
			newRouteParamNames: Object.keys(params || {}),

			// Space key related attributes.
			isOldRouteSpaceKeyDefined: !!oldRoute?.params?.spaceKey,
			isNewRouteSpaceKeyDefined: !!params?.spaceKey,
			areSpaceKeysEqual: oldRoute?.params?.spaceKey === params?.spaceKey,
			areSpaceKeysEqualLowercase:
				oldRoute?.params?.spaceKey?.toLowerCase() === params?.spaceKey?.toLowerCase(),
		});

		const doRedirect = () => {
			const gottenUrl = getUrl();
			createAnalyticsEvent({
				type: 'sendUIEvent',
				data: {
					action: 'redirect',
					actionSubject: oldRouteName,
					actionSubjectId: name || matchRoute(gottenUrl)?.name,
					source: oldRouteName,
				},
			}).fire();

			if (isSpaceAliasFFEnabled) {
				createAnalyticsEvent({
					type: 'sendTrackEvent',
					data: {
						action: 'redirect',
						actionSubject: 'route',
						source: 'Redirection',
						attributes: getAnalyticsAttributes({
							newRouteName: name || matchRoute(gottenUrl)?.name,
						}),
					},
				}).fire();
			}

			(usePush ? push : replace)(gottenUrl, forceReload);
		};

		if (isSpaceAliasFFEnabled) {
			try {
				doRedirect();
			} catch (error) {
				createAnalyticsEvent({
					type: 'sendTrackEvent',
					data: {
						action: 'redirectFailed',
						actionSubject: 'route',
						source: 'Redirection',
						attributes: {
							...getAnalyticsAttributes({ newRouteName: name }),
							errorMessage: error?.message,
						},
					},
				}).fire();

				throw error;
			}
		} else {
			doRedirect();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return null;
};
