import React from 'react';
import { defineMessages, FormattedMessage } from 'react-intl-next';

import { LinkButton } from '@atlaskit/button/new';
import ArrowUpRightIcon from '@atlaskit/icon/core/arrow-up-right';

import { COMPANY_HUB_EDIT } from '@confluence/named-routes';

export const i18n = defineMessages({
	editHubButtonLabel: {
		id: 'company-hub.hub-settings.general.dashboard.edit-hub-button-label',
		defaultMessage: 'Edit hub',
		description: 'Label of button that takes the user to the Company hub editor',
	},
});

export const EditHubButton = () => (
	<LinkButton
		iconBefore={ArrowUpRightIcon}
		href={COMPANY_HUB_EDIT.toUrl()}
		analyticsContext={{ source: 'dashboardEditHubButton' }}
	>
		<FormattedMessage {...i18n.editHubButtonLabel} />
	</LinkButton>
);
