// import type { Schema as PMSchema } from '@atlaskit/editor-prosemirror/model';
import { type JSONDocNode } from '@atlaskit/editor-json-transformer';
import { type EditorAgent } from '@atlassian/generative-ai-modal/utils/agents';

import { convoAIStatefulApiURL } from '../../prebuilt/utils';
import type { EditorPluginAIPromptRequestMarkdown } from '../../types';
import { startStreamingResponse } from '../start-streaming-response';

import type { PromptRequestOptions } from './types';
import { streamResponseParser } from './utils/stream-response-parser';

/**
 * Opens chat session with Rovo Agent.
 *
 * Intended for use in EditorPluginAI config items and Rovo agents.
 * @see {@link [EditorPluginAI](../../editor-plugin-ai.tsx)}
 * @see https://hello.atlassian.net/wiki/spaces/EDAI/pages/3753154519/Rovo+Agents+in+Editor
 */

type ChatInput = {
	agent: EditorAgent;
	customPrompt: string;
	customPromptADF?: JSONDocNode;
	fullDocument?: string;
	currentSelection?: string;
};

/**
 * A generator function that will fetch the response from the Convo AI API.
 *
 * Status: WIP (wrong assistance-service agent and payload)
 */
export const rovoChatWithAgent = (input: ChatInput): EditorPluginAIPromptRequestMarkdown => {
	const { agent } = input;

	return function promptRequest(requestOptions: PromptRequestOptions) {
		const promptADF = input.customPromptADF || {
			version: 1,
			type: 'doc',
			content: [
				{
					type: 'paragraph',
					content: [
						{
							type: 'text',
							text: input.customPrompt,
						},
					],
				},
			],
		};

		// Just the main URL, remove the extra stuff like hashes/query params
		const browserUrl =
			requestOptions.getChannelVariables?.('rovo-agent')?.contentURL ??
			window.location.origin + window.location.pathname;

		const streamingResponseGenerator = startStreamingResponse({
			endpoint: convoAIStatefulApiURL,
			experienceId: 'ai-mate',
			channelId: requestOptions.channelId,

			// For payload spec, see https://hello.atlassian.net/wiki/spaces/CONVAI/pages/3783312372/Actions+Contract+for+Confluence+Content+Suggestion
			payload: JSON.stringify({
				content: promptADF,
				// Is this meant to be `agent_input_context` in the future?
				context: {
					browser_url: browserUrl,
					editor: {
						document: {
							type: 'text/markdown',
							content: input.fullDocument,
						},
						selection: {
							type: 'text/markdown',
							content: input.currentSelection,
						},
					},
				},
				recipient_agent_named_id: agent.namedId,
				mimeType: 'text/adf',
				store_message: true,
				// "citations_enabled": true
			}),
			// Hardcoded for now?
			// getFetchCustomHeaders: () => {
			// 	return {
			// 		"x-issynthetic-traffic": "false",
			// 	};
			// },
			...requestOptions,
		});

		return streamResponseParser({
			streamingResponseGenerator,
			isConvoAI: true,
		});
	};
};
