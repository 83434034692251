import type { Node as PMNode } from '@atlaskit/editor-prosemirror/model';

import type { ParagraphChunk } from '../../../utils/diff-match-patch/utils';
import type { RateLimiter } from '../../../utils/rate-limiter';
import type { TriggerContext } from '../commands';

import { requestGenerator } from './request-generator';

export type TransformAction =
	| 'CONVERT_TO_TABLE'
	| 'CONVERT_TO_NOTE_PANEL'
	| 'CONVERT_TO_INFO_PANEL'
	| 'CONVERT_TO_WARN_PANEL'
	| 'CONVERT_TO_ERROR_PANEL'
	| 'CONVERT_TO_SUCCESS_PANEL'
	| 'SPLIT_PARAGRAPH';

export type Recommendation = {
	/**
	 * This is the block chunk id which contains this recommendation.
	 */
	chunkId: string;
	/**
	 * This is the raw recommendation id supplied from the BE
	 */
	rid: string;
	/**
	 * This is a unique id for the recommendation
	 */
	id: string;
	transformType: 'REPLACE_PARAGRAPH';
	transformContent: PMNode;
	transformAction: TransformAction;
	isViewed: boolean;
};

type ResponseState =
	| { state: 'parsed' | 'cached'; recommendations: Recommendation[] }
	| { state: 'trackedDuration'; duration: number }
	| { state: 'rate-limited'; failedChunkIds: string[] }
	| {
			state: 'failed';
			reason: 'network' | 'backend' | 'aborted' | 'parsing' | 'unhandled' | 'rate-limited';
			errors: string[];
			statusCode: number;
			failedChunkIds: string[];
	  }
	| {
			state: 'purged';
			totalParts: number;
			totalPurgedParts: number;
			purgedChunkIds: string[];
	  }
	| { state: 'done' };

export type RequestGenerator = AsyncGenerator<ResponseState>;

// NOTE: It's important that we maintain a consistent API regardless of which endpoint we hit.
// This is a generic interface we should be able to use for any version of the provider.
export type ProactiveAIProvider = (
	paragraphs: Array<ParagraphChunk>,
) => RequestGenerator | undefined;

/**
 * This will filter out chunks from being streamed to the service which are not valid.
 */
export const isChunkExcluded = (chunk: ParagraphChunk) =>
	chunk.from === chunk.to || !chunk.text || !chunk.text.trim().length;

/* istanbul ignore next */
// This V1 endpoint is the final endpoint we're targetting for hello release.
export const fetchAIParagraphs =
	(
		proactiveAIApiUrl: string,
		product: string,
		context: TriggerContext,
		rateLimiter: RateLimiter | undefined,
	): ProactiveAIProvider =>
	(paragraphs: Array<ParagraphChunk>) => {
		const included = paragraphs.filter((chunk) => !isChunkExcluded(chunk));
		if (!!included.length) {
			return requestGenerator({
				endpoint: proactiveAIApiUrl,
				paragraphs: included,
				product,
				context,
				rateLimiter,
			});
		}

		return;
	};
