import React from 'react';

import { fg } from '@atlaskit/platform-feature-flags';

import { CONFIG_ITEM_KEYS } from '../../../config-items/config-item-keys';
import { createEditorPluginAIConfigItemMarkdown } from '../../../config-items/config-items';
import { Icon } from '../../assets/icons/summarize';
import { createInsertAtCurrentPosition } from '../../config-item-actions/markdown';
import { isMinLength } from '../../config-item-visible/visibility-utils';

import { messages } from './messages';

export const summarizePageConfigItem = createEditorPluginAIConfigItemMarkdown({
	key: CONFIG_ITEM_KEYS.SUMMARISE_WRITING,
	title: messages.title,
	description: messages.description,
	selectionType: 'empty',
	intentSchemaId: 'summarize_intent_schema.json',
	icon: ({ shownAt }) => <Icon shownAt={shownAt} />,
	isVisible: ({ editorView }) => isMinLength({ editorView, minLength: 1 }),
	actions: [createInsertAtCurrentPosition({ appearance: 'primary' })],
	getBackendModel: () => {
		if (fg('platform_editor_ai_assistance_service')) {
			return 'assistance-service';
		}

		return 'xp-gen-ai';
	},
});
