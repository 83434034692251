import { SafePlugin } from '@atlaskit/editor-common/safe-plugin';
import type { Decoration } from '@atlaskit/editor-prosemirror/view';
import { DecorationSet } from '@atlaskit/editor-prosemirror/view';

import { createInlineDecoration } from '../decoration/actions';
import { getPluginState } from '../decoration/decoration-plugin-factory';

import { selectionPreviewPluginKey } from './selection-preview-plugin-key';

type SelectionPreviewPluginState = {
	selectionPreview: boolean;
};

export const createSelectionPreviewPlugin = () => {
	return new SafePlugin<SelectionPreviewPluginState>({
		key: selectionPreviewPluginKey,
		state: {
			init: (_, state) => ({ selectionPreview: false }),
			apply: (tr, _pluginState, _oldEditorState) => {
				if (tr.getMeta('showSelectionPreview')) {
					return { selectionPreview: true };
				}
				if (tr.getMeta('hideSelectionPreview')) {
					return { selectionPreview: false };
				}
				return _pluginState;
			},
		},
		props: {
			decorations: (editorState) => {
				const pluginState = selectionPreviewPluginKey.getState(editorState);
				if (pluginState.selectionPreview) {
					const { modalDecorationSet } = getPluginState(editorState);
					const modalDecorations: Decoration[] = modalDecorationSet.find(
						0,
						editorState.doc.content.size,
					);
					const existingHighlightDecoration = modalDecorations.filter(
						(x) => x?.spec?.key === 'inlineDecoration',
					)[0];
					//TODO: AI Button experiment cleanup - platform_editor_ai_ai_button_block_elements
					const nodeDecoration = modalDecorations.filter(
						(x) => x?.spec?.key === 'ai-nodeDecoration',
					)[0];
					//TODO: AI Button experiment cleanup - platform_editor_ai_ai_button_block_elements
					if (!nodeDecoration && !existingHighlightDecoration) {
						const highlightDecoration = createInlineDecoration(0, editorState.doc.content.size);
						return DecorationSet.create(editorState.doc, [highlightDecoration]);
					}
				}
				return DecorationSet.empty;
			},
		},
	});
};
