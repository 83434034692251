import { defineMessages } from 'react-intl-next';

export const messages = defineMessages({
	contentQuality: {
		id: 'fabric.editor.ai.ai-panels.contentQuality',
		defaultMessage: 'Content quality may vary',
		description: 'Content quality may vary label',
	},
	poweredByAi: {
		id: 'fabric.editor.ai.ai-panels.poweredByAi',
		defaultMessage: 'Powered by AI',
		description: 'Powered by AI label',
	},
	logoAltText: {
		id: 'fabric.editor.ai.ai-panels.logoAltText',
		defaultMessage: 'Atlassian Intelligence Logo',
		description: 'Atlassian Intelligence logo alt text',
	},
});
