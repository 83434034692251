import uuid from 'uuid/v4';

import { SafePlugin } from '@atlaskit/editor-common/safe-plugin';
import type { ExtractInjectionAPI, PMPluginFactoryParams } from '@atlaskit/editor-common/types';
import type { EditorState } from '@atlaskit/editor-prosemirror/state';
import type { EditorView } from '@atlaskit/editor-prosemirror/view';
import { DecorationSet } from '@atlaskit/editor-prosemirror/view';
import { type MentionNameDetails } from '@atlaskit/mention';

import { addAnalytics, createUnifiedAnalyticPayload } from '../../analytics/utils';
import type { AIPlugin } from '../../editor-plugin-ai';
import type { ProactiveAIConfig } from '../../types';
import { DocumentChunkScanner } from '../../utils/document-checker';
import { RateLimiter } from '../../utils/rate-limiter';

import { aiProactivePluginKey } from './ai-proactive-plugin-key';
import {
	clearSelectRecommendation,
	closeProactiveAISuggestionDisplay,
	createTriggerProactiveCheck,
	disableNeedProactiveRecommendations,
	selectRecommendation,
	updateChunksWithRecommendations,
} from './commands';
import { RATE_LIMITER_DELAY, RATE_LIMITER_JITTER, RATE_LIMITER_ATTEMPTS } from './constants';
import { createInitialState, createPluginState, getPluginState } from './plugin-factory';
import { ACTIONS } from './states';
import { getBlockFromRecommendationId, getSelectedRecommendations } from './utils';

/**
 * Plugin factory
 */
export function createProactiveAIPlugin(options: {
	dispatch: PMPluginFactoryParams['dispatch'];
	getIntl: PMPluginFactoryParams['getIntl'];
	proactiveAIConfig: ProactiveAIConfig;
	product: string;
	api: ExtractInjectionAPI<AIPlugin> | undefined;
	getMentionNameDetails?: (id: string) => Promise<MentionNameDetails | undefined>;
}) {
	const { dispatch, getIntl, proactiveAIConfig, product, api, getMentionNameDetails } = options;
	return new SafePlugin({
		key: aiProactivePluginKey,
		state: createPluginState(
			dispatch,
			createInitialState({
				proactiveAIApiUrl: proactiveAIConfig.apiUrl,
				documentChecker: proactiveAIConfig.documentChecker?.enabled
					? new DocumentChunkScanner(
							proactiveAIConfig.documentChecker,
							(state) => {
								const pluginState = getPluginState(state);
								return (pluginState?.proactiveAIBlocks ?? []).filter(
									(chunk) => !!chunk.invalidatedForDocChecker,
								);
							},
							(chunks, view) =>
								updateChunksWithRecommendations(chunks, {
									view,
									locale: getIntl().locale,
									getMentionNameDetails: getMentionNameDetails,
								}),
						)
					: undefined,
				rateLimiter: new RateLimiter(
					RATE_LIMITER_DELAY,
					RATE_LIMITER_JITTER,
					RATE_LIMITER_ATTEMPTS,
				),
				defaultToggledState: proactiveAIConfig.defaultToggledState,
				product: product,
			}),
		),
		view(view: EditorView) {
			const { documentChecker, rateLimiter } = getPluginState(view.state);
			const { locale } = getIntl();
			const {
				triggerProactiveCheck,
				cancelDebouncedAndThrottledCheck,
				triggerProactiveCheckImmediately,
			} = createTriggerProactiveCheck(proactiveAIConfig.timings);

			if (documentChecker) {
				// 	// If view updates then reset document checker. That will stop checking for S+G for existing blocks.
				documentChecker.setView(view);
			}

			const cleanupRateLimiter = rateLimiter?.init({
				onRetry: () =>
					triggerProactiveCheckImmediately({
						view,
						locale,
						getMentionNameDetails: getMentionNameDetails,
					}),
				onRetryEnqueue: () => {
					documentChecker?.stop();
					cancelDebouncedAndThrottledCheck();
				},
				onRetriesExhausted: () => {
					// When the rate limiter has reached its max attempts we need to disable all blocks which are pending a server check
					disableNeedProactiveRecommendations('rateLimiterMaxRetries')(view.state, view.dispatch);
				},
			});

			const unsubscribeViewModeChange = api?.editorViewMode?.sharedState.onChange((sharedState) => {
				if (
					sharedState.nextSharedState?.mode !== sharedState.prevSharedState?.mode &&
					sharedState.nextSharedState?.mode !== 'edit'
				) {
					closeProactiveAISuggestionDisplay('viewModeChanged')(view.state, view.dispatch);
				}
			});

			return {
				update: (view: EditorView, prevState: EditorState) => {
					const { isProactiveEnabled } = getPluginState(view.state);
					if (
						isProactiveEnabled &&
						(!prevState.doc.eq(view.state.doc) || !prevState.selection.eq(view.state.selection))
					) {
						triggerProactiveCheck({
							view,
							locale,
							getMentionNameDetails: getMentionNameDetails,
						});
					}
				},
				destroy: () => {
					unsubscribeViewModeChange?.();
					cancelDebouncedAndThrottledCheck();
					/**
					 * Here we will reset regardless of documentCheckerEnabled or not.
					 * In case if proactiveAIConfig is changed between when view is destroyed
					 * 	and we goes from enabled documentChecker to disabled one.
					 */
					documentChecker?.reset();

					cleanupRateLimiter?.();
				},
			};
		},
		props: {
			decorations: (state): DecorationSet | undefined => {
				const {
					isProactiveEnabled,
					decorationSet,
					alwaysDisplayProactiveInlineDecorations,
					isProactiveContextPanelOpen,
					displayAllSuggestions,
				} = getPluginState(state);

				if (
					!isProactiveEnabled ||
					api?.editorViewMode?.sharedState.currentState()?.mode !== 'edit' ||
					!displayAllSuggestions
				) {
					return DecorationSet.empty;
				}

				if (alwaysDisplayProactiveInlineDecorations || isProactiveContextPanelOpen) {
					return decorationSet;
				}

				return DecorationSet.empty;
			},
			handleClick: (view, pos, event) => {
				const { state, dispatch } = view;
				if (state.selection.from !== state.selection.to) {
					return false;
				}

				const pluginState = getPluginState(state);

				const { isProactiveEnabled, selectedRecommendationId, isProactiveContextPanelOpen } =
					pluginState;

				if (!isProactiveEnabled || !isProactiveContextPanelOpen) {
					return false;
				}

				if (selectedRecommendationId) {
					return clearSelectRecommendation()(state, dispatch);
				} else {
					const selectedRecommendations = getSelectedRecommendations(pluginState, pos);

					const currentSelectedRecommendationId =
						selectedRecommendations?.find((r) => r.id === selectedRecommendationId)?.id ??
						selectedRecommendations?.[0]?.id;

					if (currentSelectedRecommendationId) {
						return selectRecommendation(currentSelectedRecommendationId)(state, dispatch);
					}
				}

				return false;
			},
		},
		appendTransaction: (_, oldState, newState) => {
			const oldPluginState = getPluginState(oldState);
			const newPluginState = getPluginState(newState);

			const {
				selectedRecommendationId: prevSelectedRecommendationId,
				isProactiveContextPanelOpen: prevIsProactiveContextPanelOpen,
			} = oldPluginState;
			const {
				isProactiveEnabled,
				selectedRecommendationId,
				alwaysDisplayProactiveInlineDecorations,
				isProactiveContextPanelOpen,
			} = newPluginState;

			const isOpening =
				isProactiveContextPanelOpen &&
				prevIsProactiveContextPanelOpen !== isProactiveContextPanelOpen;

			const isSelecting =
				!!selectedRecommendationId &&
				prevSelectedRecommendationId !== selectedRecommendationId &&
				!oldState.selection.eq(newState.selection);

			/*
				The following is a workaround to handle the initiate and viewed events for unified analytics.
				The issue is we need to generate an store a new uuid for tracking every time an initiate occurs.
				And this uuid will be shared with all unified anayltic events there after, or until another initiate occurs.
			*/
			if (
				isProactiveEnabled &&
				(isOpening || isSelecting) &&
				!!selectedRecommendationId &&
				(alwaysDisplayProactiveInlineDecorations || isProactiveContextPanelOpen) &&
				newState.selection.from === newState.selection.to
			) {
				const { recommendation } = getBlockFromRecommendationId(
					newPluginState,
					selectedRecommendationId,
				);

				if (recommendation) {
					const analyticsAIInteractionId = uuid();
					return addAnalytics({
						editorState: newState,
						tr: newState.tr,
						payload: createUnifiedAnalyticPayload(
							'initiated',
							analyticsAIInteractionId,
							recommendation.transformAction,
							true,
						),
					}).setMeta(aiProactivePluginKey, {
						type: ACTIONS.UPDATE_PLUGIN_STATE,
						data: {
							analyticsAIInteractionId,
						},
					});
				}
			}
		},
	});
}
