import { query, writeQuery } from '@confluence/query-preloader-tools';
import { fg } from '@confluence/feature-gating';

import {
	InlineCommentsQuery,
	InlineCommentsWithSpaceKeyQuery,
} from './InlineCommentsQuery.graphql';
import type {
	InlineCommentsWithSpaceKeyQuery as InlineCommentsWithSpaceKeyQueryType,
	InlineCommentsWithSpaceKeyQueryVariables,
} from './__types__/InlineCommentsWithSpaceKeyQuery';
import { OptimizedInlineCommentsQuery } from './OptimizedInlineCommentsQuery.graphql';

export type preloadInlineCommentsProps = {
	pageId?: string;
	spaceKey?: string;
};

export function preloadInlineComments({ pageId, spaceKey }: preloadInlineCommentsProps) {
	const inlineCommentsQueryToUse = fg('confluence_fe_call_new_inline_comments_query')
		? OptimizedInlineCommentsQuery
		: InlineCommentsQuery;

	if (!pageId && spaceKey) {
		return query<InlineCommentsWithSpaceKeyQueryType, InlineCommentsWithSpaceKeyQueryVariables>({
			query: InlineCommentsWithSpaceKeyQuery,
			variables: { spaceKey },
			fetchPolicy: 'network-only',
		}).then(({ data }) => {
			if (data?.spaceHomepage?.id) {
				writeQuery({
					query: inlineCommentsQueryToUse,
					variables: {
						pageId: data?.spaceHomepage?.id,
					},
					data: {
						content: data?.spaceHomepage
							? {
									nodes: [data?.spaceHomepage],
									// @ts-ignore
									__typename: 'PaginatedContentListWithChild',
								}
							: null,
						comments: data?.space?.homepageComments || null,
					},
				});
			}
		});
	}
	return query({
		query: inlineCommentsQueryToUse,
		variables: { pageId },
		fetchPolicy: 'network-only',
	});
}
