import React, { useContext } from 'react';
import { FormattedMessage, defineMessages } from 'react-intl-next';

import { Box, Flex, Inline, Stack, Text, Pressable, xcss } from '@atlaskit/primitives';
import Image from '@atlaskit/image';

import { RoutesContext } from '@confluence/route-manager';

import CardIcon from './CardIcon.svg';

const i18n = defineMessages({
	clickCountText: {
		id: 'company-hub.recent-activity.leaderboard-row.label',
		defaultMessage: '{count, plural, one {{count} click} other {{count} clicks}}',
		description:
			'Text describing the number of clicks for an item in the most clicked leaderboard on the hub dashboard page',
	},
});

const CardIconContainerStyles = xcss({
	flexShrink: 0,
});

const MostClickedTextStyles = xcss({
	overflow: 'hidden',
});

const TitleTextStyles = xcss({
	textOverflow: 'ellipsis',
	overflow: 'hidden',
	whiteSpace: 'nowrap',
});

const CountTextStyles = xcss({
	lineHeight: '14px',
	textAlign: 'start',
});

const PressableStyles = xcss({
	height: '50px',
	borderRadius: '3px',
	':hover': {
		backgroundColor: 'color.background.neutral.subtle.hovered',
	},
	':active': {
		backgroundColor: 'color.background.neutral.subtle.pressed',
	},
});

export type RecentActivityLeaderboardRowProps = {
	page:
		| {
				id: string;
				title: string;
				webuiLink: string | null;
		  }
		| undefined;
	count: number;
};

export const RecentActivityLeaderboardRow = ({
	page,
	count,
}: RecentActivityLeaderboardRowProps) => {
	const { push } = useContext(RoutesContext);

	return (
		<Pressable
			xcss={PressableStyles}
			backgroundColor="color.background.neutral.subtle"
			onClick={() => {
				if (page?.webuiLink) {
					push(`/wiki${page.webuiLink}`);
				}
			}}
		>
			<Inline alignBlock="center" grow="fill" space="space.150">
				<Flex xcss={CardIconContainerStyles}>
					<Image src={CardIcon} alt="Card icon" />
				</Flex>
				<Stack xcss={MostClickedTextStyles}>
					<Box xcss={TitleTextStyles}>{page?.title}</Box>
					{typeof count !== 'undefined' && (
						<Text size="small" color="color.text.subtlest">
							<Box xcss={CountTextStyles}>
								<FormattedMessage {...i18n.clickCountText} values={{ count }} />
							</Box>
						</Text>
					)}
				</Stack>
			</Inline>
		</Pressable>
	);
};
