import type { ReactNode } from 'react';
import React, { useContext, useEffect, Fragment } from 'react';
import type { ApolloError } from 'apollo-client';

import {
	ExperienceTrackerContext,
	ExperienceStart,
	ExperienceStop,
	ExperienceSuccess,
} from '@confluence/experience-tracker';
import { ErrorDisplay } from '@confluence/error-boundary';

import { processErrors } from '../../admin-center-utils';

const EXPERIENCE_TIMEOUT_MS = 10000;

type SubExperienceTrackerProps = {
	errors: (ApolloError | undefined)[];
	experienceName: string;
	children: ReactNode;
	loading: boolean | undefined;
};

export const SubExperienceTracker = ({
	errors,
	experienceName,
	children,
	loading,
}: SubExperienceTrackerProps) => {
	const experienceTracker = useContext(ExperienceTrackerContext);
	const unexpectedErrors = processErrors(errors);

	useEffect(() => {
		return () => {
			experienceTracker.abort({
				name: experienceName,
				reason: 'User navigated away',
			});
		};
	}, [experienceName, experienceTracker]);

	return (
		<>
			<ExperienceStart name={experienceName} timeout={EXPERIENCE_TIMEOUT_MS} />
			{unexpectedErrors?.map((unexpectedError, index) => (
				<Fragment key={`error-${experienceName}-${index}`}>
					<ErrorDisplay error={unexpectedError} />
					<ExperienceStop name={experienceName} error={unexpectedError} />
				</Fragment>
			))}
			{children}
			{!loading && !unexpectedErrors && <ExperienceSuccess name={experienceName} />}
		</>
	);
};
