/* eslint-disable @atlaskit/design-system/no-css-tagged-template-expression -- needs manual remediation */
// eslint-disable-next-line @atlaskit/ui-styling-standard/use-compiled -- Ignored via go/DSP-18766
import { css } from '@emotion/react';

import { N200, N900 } from '@atlaskit/theme/colors';
import { fontFamily } from '@atlaskit/theme/constants';
import { token } from '@atlaskit/tokens';

// ED-51: This is to increase the specificity of the input styles
// to override the default input styles applied by Trello
const INCREASE_SPECIFICITY = '&&';

// eslint-disable-next-line @atlaskit/design-system/ensure-design-token-usage/preview, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const inputStyles = css`
	${INCREASE_SPECIFICITY} {
		border: none;
		color: ${token('color.text.accent.gray.bolder', N900)};
		background: none;
		font-size: 14px;
		font-family: ${fontFamily()};
		&:focus {
			outline: none;
		}
		&:placeholder-shown {
			text-overflow: ellipsis;
		}
		&::placeholder {
			color: ${token('color.text.subtlest', N200)};
		}
		width: 100%;
		padding: 0;
		resize: none;
		overflow: hidden;
		line-height: 20px;
	}
`;

// CSS applied for autogrowing textarea.
// Refer to https://css-tricks.com/the-cleanest-trick-for-autogrowing-textareas/ for more info
// eslint-disable-next-line @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const multilineInputWrapperStyles = css`
	display: grid;
	position: relative;
	flex: 1;
	::after {
		content: attr(data-replicated-value) ' ';
		white-space: pre-wrap;
		visibility: hidden;
		${inputStyles}
	}
	> textarea,
	::after {
		${inputStyles}

		/* Place on top of each other */
    grid-area: 1 / 1 / 2 / 2;
	}
`;

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const hiddenDivStyles = css`
	position: absolute;
	visibility: hidden;
	bottom: 0;
	::after {
		content: attr(data-replicated-value) ' ';
		white-space: pre-wrap;
		visibility: hidden;
		${inputStyles}
	}
`;
