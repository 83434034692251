import React, { useCallback, useEffect } from 'react';
import { defineMessages, FormattedMessage } from 'react-intl-next';
import { useQuery } from '@apollo/react-hooks';
import type { ApolloError } from 'apollo-client';

import Tooltip from '@atlaskit/tooltip';
import { IconButton } from '@atlaskit/button/new';
import ShowMoreHorizontalIcon from '@atlaskit/icon/core/show-more-horizontal';
import DropdownMenu, { DropdownItem, DropdownItemGroup } from '@atlaskit/dropdown-menu';
import ClockIcon from '@atlaskit/icon/core/clock';
import EyeOpenStrikethroughIcon from '@atlaskit/icon/core/eye-open-strikethrough';
import FieldTextIcon from '@atlaskit/icon-lab/core/field-text';
import { useAnalyticsEvents } from '@atlaskit/analytics-next';

import { usePageContentId } from '@confluence/page-context';
import { SubExperienceTracker } from '@confluence/admin-center/entry-points/SubExperienceTracker';
import { Attribution, withErrorBoundary } from '@confluence/error-boundary';
import { CONTENT_HISTORY } from '@confluence/named-routes';
import { useIsProductAdmin } from '@confluence/current-user';
import { COMPANY_HUB_GENERAL_SETTINGS_YOUR_HUB_ACTIONS_EXPERIENCE } from '@confluence/experience-tracker';

import { useChangeHubNameDialog } from '../change-hub-name-dialog/useChangeHubNameDialog';
import { useDeactivateHubDialog } from '../unpublish-hub-dialog/useDeactivateHubDialog';

import type {
	HubActionsDropdownQuery as HubActionsDropdownQueryType,
	HubActionsDropdownQueryVariables,
} from './__types__/HubActionsDropdownQuery';
import { HubActionsDropdownQuery } from './HubActionsDropdownQuery.graphql';

export const i18n = defineMessages({
	ellipsisMenuLabel: {
		id: 'company-hub.hub-settings.general.dashboard.ellipsis-menu.label',
		defaultMessage: 'More actions',
		description: 'Label of ellipsis menu to adjust Company hub settings',
	},
	ellipsisMenuItemHistory: {
		id: 'company-hub.hub-settings.general.dashboard.ellipsis-menu.history',
		defaultMessage: 'History',
		description:
			'Ellipsis menu item in hub dashboard that allows the user to go to the Company hub history page',
	},
	ellipsisMenuItemRename: {
		id: 'company-hub.hub-settings.general.dashboard.ellipsis-menu.rename',
		defaultMessage: 'Rename',
		description:
			'Ellipsis menu item in hub dashboard that allows the user to go rename their Company hub',
	},
	ellipsisMenuItemDeactivate: {
		id: 'company-hub.hub-settings.general.dashboard.ellipsis-menu.deactivate',
		defaultMessage: 'Deactivate',
		description:
			'Ellipsis menu item in hub dashboard that allows the user to go to deactivate their Company hub',
	},
	ellipsisMenuItemRenameTooltip: {
		id: 'company-hub.hub-settings.general.dashboard.ellipsis-menu.rename.tooltip',
		defaultMessage: 'Only Hub admins can rename the Hub.',
		description:
			'Tooltip in hub dashboard that notifies the user that they need to be hub admin to rename their Company hub',
	},
	ellipsisMenuItemDeactivateTooltip: {
		id: 'company-hub.hub-settings.general.dashboard.ellipsis-menu.deactivate.tooltip',
		defaultMessage: 'Only product admins can deactivate the Hub.',
		description:
			'Tooltip in hub dashboard that notifies the user that they need to be product admin to deactivate their Company hub',
	},
});

type HubActionsDropdownProps = {
	spaceKey: string;
	onError?: (error: ApolloError | undefined) => void;
	isHubPublished?: boolean;
};

const HubActionsDropdownComponent = ({
	spaceKey,
	isHubPublished,
	onError,
}: HubActionsDropdownProps) => {
	const dropdownItems: React.ReactNode[] = [];

	const { createAnalyticsEvent } = useAnalyticsEvents();
	const fireAnalyticsEvent = useCallback(
		(action) => {
			createAnalyticsEvent({
				type: 'sendUIEvent',
				data: {
					action: 'clicked',
					actionSubject: 'button',
					actionSubjectId: `hubActionsDropdown${action}`,
					source: 'hubActionsDropdown',
				},
			}).fire();
		},
		[createAnalyticsEvent],
	);

	const [contentId] = usePageContentId();
	const { data, loading, error } = useQuery<
		HubActionsDropdownQueryType,
		HubActionsDropdownQueryVariables
	>(
		// eslint-disable-next-line graphql-relay-compat/no-import-graphql-operations -- Read https://go/connie-relay-migration-fyi
		HubActionsDropdownQuery,
		{
			variables: {
				spaceKey,
			},
		},
	);

	useEffect(() => {
		onError?.(error);
	}, [error, onError]);

	// History
	if (contentId && spaceKey) {
		dropdownItems.push(
			<DropdownItem
				key="history"
				elemBefore={<ClockIcon label="" />}
				onClick={() => fireAnalyticsEvent('History')}
				href={CONTENT_HISTORY.toUrl({ contentId, spaceKey })}
			>
				<FormattedMessage {...i18n.ellipsisMenuItemHistory} />
			</DropdownItem>,
		);
	}

	// Rename
	const isSpaceAdmin = data?.space?.currentUser?.isAdmin;
	const changeHubNameDialog = useChangeHubNameDialog({ isFirstTimeDialog: false });
	const openChangeHubNameDialog = useCallback(() => {
		changeHubNameDialog();
		fireAnalyticsEvent('Rename');
	}, [changeHubNameDialog, fireAnalyticsEvent]);

	dropdownItems.push(
		<Tooltip
			position="bottom"
			content={<FormattedMessage {...i18n.ellipsisMenuItemRenameTooltip} />}
			canAppear={() => !isSpaceAdmin}
		>
			<DropdownItem
				key="rename"
				elemBefore={<FieldTextIcon label="" />}
				onClick={openChangeHubNameDialog}
				isDisabled={!isSpaceAdmin}
			>
				<FormattedMessage {...i18n.ellipsisMenuItemRename} />
			</DropdownItem>
		</Tooltip>,
	);

	// Deactivate
	const { isProductAdmin } = useIsProductAdmin();
	const deactivateHubDialog = useDeactivateHubDialog({ contentId });
	const openDeactivateHubDialog = useCallback(() => {
		deactivateHubDialog();
		fireAnalyticsEvent('Deactivate');
	}, [deactivateHubDialog, fireAnalyticsEvent]);

	if (isHubPublished) {
		dropdownItems.push(
			<Tooltip
				position="bottom"
				content={<FormattedMessage {...i18n.ellipsisMenuItemDeactivateTooltip} />}
				canAppear={() => !isProductAdmin}
			>
				<DropdownItem
					key="deactivate"
					elemBefore={<EyeOpenStrikethroughIcon label="" />}
					onClick={openDeactivateHubDialog}
					isDisabled={!isProductAdmin}
				>
					<FormattedMessage {...i18n.ellipsisMenuItemDeactivate} />
				</DropdownItem>
			</Tooltip>,
		);
	}

	return (
		<SubExperienceTracker
			experienceName={COMPANY_HUB_GENERAL_SETTINGS_YOUR_HUB_ACTIONS_EXPERIENCE}
			errors={[error]}
			loading={loading}
		>
			<DropdownMenu
				placement="bottom-end"
				shouldRenderToParent
				trigger={({ triggerRef, ...props }) => (
					<Tooltip hideTooltipOnClick content={<FormattedMessage {...i18n.ellipsisMenuLabel} />}>
						<IconButton
							{...props}
							label={<FormattedMessage {...i18n.ellipsisMenuLabel} />}
							ref={triggerRef}
							icon={ShowMoreHorizontalIcon}
						/>
					</Tooltip>
				)}
			>
				<DropdownItemGroup>{dropdownItems}</DropdownItemGroup>
			</DropdownMenu>
		</SubExperienceTracker>
	);
};

export const HubActionsDropdown = withErrorBoundary({
	attribution: Attribution.COMPANY_HUB,
})(HubActionsDropdownComponent);
