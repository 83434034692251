import React from 'react';

import { fg } from '@atlaskit/platform-feature-flags';

import { CONFIG_ITEM_KEYS } from '../../../config-items/config-item-keys';
import { createEditorPluginAIConfigItemMarkdown } from '../../../config-items/config-items';
import { Icon } from '../../assets/icons/brainstorm';
import { createInsertAtCurrentPosition } from '../../config-item-actions/markdown';

import { messages } from './messages';

const SELECTION_TYPE = 'empty';

export const brainstormConfigItem = createEditorPluginAIConfigItemMarkdown({
	key: CONFIG_ITEM_KEYS.BRAINSTORM,
	title: messages.title,
	description: messages.description,
	selectionType: SELECTION_TYPE,
	agentId: 'editor_agent',
	intentSchemaId: 'brainstorm_intent_schema.json',
	promptLabel: messages.promptLabel,
	promptHint: messages.promptHint,
	icon: ({ shownAt }) => <Icon shownAt={shownAt} />,
	isVisible: () => true,
	actions: [createInsertAtCurrentPosition({ appearance: 'primary' })],
	getBackendModel: () => {
		if (fg('platform_editor_ai_assistance_service')) {
			return 'assistance-service';
		}

		return 'xp-gen-ai';
	},
});
