import { useCallback } from 'react';

import { type JSONDocNode } from '@atlaskit/editor-json-transformer';

export type FocusInputRefType = (() => void) | undefined;
export type FocusInputMutableRefObject = React.MutableRefObject<FocusInputRefType>;

export type BlurInputRefType = (() => void) | undefined;
export type BlurInputMutableRefObject = React.MutableRefObject<BlurInputRefType>;

export type ClearInputRefType = (() => boolean) | undefined;
export type ClearInputMutableRefObject = React.MutableRefObject<ClearInputRefType>;

export type ReplaceInputRefType = ((adf: JSONDocNode) => boolean) | undefined;
export type ReplaceInputMutableRefObject = React.MutableRefObject<ReplaceInputRefType>;

type Props = {
	focusInputRef?: FocusInputMutableRefObject;
	blurInputRef?: BlurInputMutableRefObject;
};

export const useSetInputRef = ({ focusInputRef, blurInputRef }: Props) => {
	return useCallback(
		(el: HTMLInputElement | HTMLTextAreaElement | null) => {
			if (el) {
				if (focusInputRef) {
					focusInputRef.current = () => el.focus();
				}
				if (blurInputRef) {
					blurInputRef.current = () => el.blur();
				}
				// Set selection to end of the input
				el.selectionStart = el.selectionEnd = el.value.length;
			}
		},
		[focusInputRef, blurInputRef],
	);
};
