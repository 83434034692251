import React, { useCallback, useContext, useState } from 'react';
import { useIntl, defineMessages } from 'react-intl-next';
import { useMutation } from '@apollo/react-hooks';

import Button from '@atlaskit/button';
import { useAnalyticsEvents } from '@atlaskit/analytics-next';
import type { OptionData } from '@atlaskit/user-picker';
import { Box, Inline, xcss } from '@atlaskit/primitives';
import { Radio } from '@atlaskit/radio';
import ChevronLeftLargeIcon from '@atlaskit/icon/glyph/chevron-left-large';

import { fg } from '@atlaskit/platform-feature-flags';

import { useIsProductAdmin } from '@confluence/current-user';
import {
	SitePermissionTypeFilter,
	SitePermissionType,
	UserAndGroupSearchPicker,
} from '@confluence/user-and-group-search';
import {
	ADMIN_ALL_SPACES_SPACE_PERMISSIONS,
	ADMIN_SPACE_PERMISSIONS,
} from '@confluence/named-routes';
import { Attribution, ErrorDisplay, withErrorBoundary } from '@confluence/error-boundary';
import type { FlagsStateContainer } from '@confluence/flags';
import { withFlags } from '@confluence/flags';
import {
	BULK_SPACE_PERMISSIONS_EXPERIENCE,
	ExperienceTrackerContext,
} from '@confluence/experience-tracker';
import { useSessionData } from '@confluence/session-data';
import { ConfluenceEdition } from '@confluence/change-edition/entry-points/ConfluenceEdition';

import {
	BulkSetSpacePermissionSpaceType,
	BulkSetSpacePermissionSubjectType,
	SpacePermissionType,
} from './graphql/__types__/BulkSpacePermissionsMutation';
import { RemovalWarning } from './RemovalWarning';
import { BulkSpacePermissionsTable } from './BulkSpacePermissionsTable';
import { RestrictedView } from './restricted/RestrictedView';
import type {
	BulkSpacePermissionsMutation as AddToSpacePermissions,
	BulkSpacePermissionsMutationVariables as AddToSpacePermissionsVariables,
} from './graphql/__types__/BulkSpacePermissionsMutation';
import { BulkSpacePermissionsModal } from './space-permissions-dialog/BulkSpacePermissionsModal';
import { BulkSetSpacePermissionAsyncMutation } from './graphql/BulkSetSpacePermissionAsyncMutation.graphql';

export const ANALYTICS_SOURCE = 'bulkSpacePermissionsScreen';

const i18n = defineMessages({
	backToSpacePermissions: {
		id: 'bulk-permissions.space-permissions-link',
		defaultMessage: 'Space permissions',
		description: 'Link to a space permissions page',
	},
	bulkAddHeader: {
		id: 'bulk-permissions.space.header',
		defaultMessage: 'Add people to all spaces',
		description: 'Header text of the bulk permissions page',
	},
	titleSubtext: {
		id: 'bulk-permissions.space.title-subtext',
		defaultMessage:
			'Quickly add (or remove) an individual user or group to all spaces on your Confluence site. This will overwrite any existing access they have, which means it can also be a good way to change access for a user or group in all spaces.',
		description: 'Subtext of a header within a bulk permissions page',
	},
	userPickerHeader: {
		id: 'bulk-permissions.space.user-picker-header',
		defaultMessage: 'Choose who to add or remove<asterisk>*</asterisk>',
		description: 'Header text of the user picker, with an asterisk indicating a required field',
	},
	UserPickerSubtext: {
		id: 'bulk-permissions.space.user-picker-subtext',
		defaultMessage: 'You may only select one for this action.',
		description: 'Subtext of the user picker',
	},
	spaceSelectionHeader: {
		id: 'bulk-permissions.space.space-selection-header',
		defaultMessage: 'Choose which spaces',
		description: 'Header text of the space selection section',
	},
	spaceSelectionOne: {
		id: 'bulk-permissions.space.space-selection-one',
		defaultMessage: 'All (excluding personal)',
		description: 'Option to select all Confluence spaces except personal spaces',
	},
	spaceSelectionTwo: {
		id: 'bulk-permissions.space.space-selection-two',
		defaultMessage: 'All',
		description: 'Option to select all Confluence spaces including personal spaces',
	},
	spaceSelectionThree: {
		id: 'bulk-permissions.space.space-selection-three',
		defaultMessage: 'Only personal',
		description: 'Option to select personal Confluence spaces',
	},
	permissionsSelectionHeader: {
		id: 'bulk-permissions.space.permissions-selection-header',
		defaultMessage: 'Choose space permissions',
		description: 'Header text of the permissions selection section',
	},
	permissionsSelectionSubtext: {
		id: 'bulk-permissions.space.permissions-selection-subtext',
		defaultMessage:
			'Select which permissions the user or group should have in all spaces. This will overwrite any existing permissions they have. <b>This can’t be undone</b> so make sure to double check who you’re adding and what permissions they should have.',
		description: 'Subtext of the permissions selection section',
	},
	permissionsSelectionSubtextAdditional: {
		id: 'bulk-permissions.space.permissions-selection-subtext-additional',
		defaultMessage: 'To remove permissions from all spaces, uncheck the view permission.',
		description: 'Additional subtext of the permissions selection section',
	},
	asyncSuccessFlagTitle: {
		id: 'bulk-permissions.space.async.update.success-flag-title',
		defaultMessage: 'Bulk permissions change processing',
		description: 'Success flag title for async update',
	},
	asyncSuccessFlagDescription: {
		id: 'bulk-permissions.space.async.update.success-flag-description',
		defaultMessage: 'These changes can take a while. We’ll notify you when it’s finished.',
		description: 'Async update flag message description',
	},
	errorFlagTitle: {
		id: 'bulk-permissions.space.error-flag-title',
		defaultMessage: 'Bulk permissions change failed',
		description: 'Error flag message title',
	},
	errorFlagDescription: {
		id: 'bulk-permissions.space.error-flag-description',
		defaultMessage: 'We ran into a problem completing your bulk permissions change. Try again.',
		description: 'Error flag message description',
	},
	userPickerPlaceholder: {
		id: 'bulk-permissions.space.user-picker-placeholder',
		defaultMessage: 'Enter a person or group',
		description: 'Placeholder text for the user picker',
	},
	requirementMessage: {
		id: 'bulk-permissions.space.requirement-message',
		defaultMessage: '<asterisk>* </asterisk> Required to perform action',
		description: 'Message that indicates a required field',
	},
	applyButtonTextAdd: {
		id: 'bulk-permissions.space.apply-button-text-add',
		defaultMessage: 'Add to all',
		description: 'Text for an apply button',
	},
	applyButtonTextRemove: {
		id: 'bulk-permissions.space.apply-button-text-remove',
		defaultMessage: 'Remove from all',
		description: 'Text for an apply button',
	},
});

const bulkAddHeaderStyle = xcss({
	fontWeight: 500,
	fontSize: 24,
	lineHeight: '28px',
	paddingBottom: 'space.100',
	paddingTop: 'space.300',
});

const bulkSubtextStyle = xcss({
	fontWeight: 400,
	fontSize: 14,
	lineHeight: '20px',
});

const subtextStyle = xcss({
	fontWeight: 400,
	fontSize: 14,
	lineHeight: '20px',
});

const userPickerHeaderStyle = xcss({
	fontWeight: 500,
	fontSize: 16,
	lineHeight: '20px',
	display: 'flex',
	flexDirection: 'row',
	paddingTop: 'space.300',
	paddingBottom: 'space.100',
});

const asteriskStyle = xcss({
	color: 'color.text.accent.red',
});

const userPickerFineTextStyle = xcss({
	color: 'color.text.accent.gray',
	marginTop: 'space.050',
	fontSize: 11,
	lineHeight: '14px',
	fontWeight: 400,
});

const spaceSelectionHeaderStyle = xcss({
	fontWeight: 500,
	fontSize: 16,
	lineHeight: '20px',
	paddingBottom: 'space.100',
	paddingTop: 'space.300',
});

const contentStyle = xcss({
	marginLeft: 'space.1000',
	marginRight: 'space.1000',
});

const backButtonStyle = xcss({
	marginLeft: 'space.negative.200',
	paddingTop: 'space.300',
});

const userPickerStyle = xcss({
	paddingBottom: 'space.050',
});

const requirementMessageStyle = xcss({
	fontSize: 14,
	lineHeight: '20px',
	fontWeight: 400,
	paddingTop: 'space.300',
});

export enum SpaceSelection {
	AllExcludingPersonal = 'all-excluding-personal',
	AllIncludingPersonal = 'all-including-personal',
	OnlyPersonal = 'only-personal',
}

export interface PrincipalOption extends OptionData {
	extra?: any;
}

const getPrincipalId = (principal: PrincipalOption | undefined) => {
	return principal?.type === 'user' ? principal?.extra?.accountId : principal?.extra?.id;
};

export const BulkSpacePermissionsPage = withFlags(
	withErrorBoundary({
		attribution: Attribution.PERMISSIONS_EXPERIENCE,
	})(({ flags }: { flags: FlagsStateContainer }) => {
		const intl = useIntl();
		const { edition } = useSessionData();
		const { createAnalyticsEvent } = useAnalyticsEvents();
		const { isProductAdmin, isAdminCheckLoading } = useIsProductAdmin();
		const [spacePermissions, setSpacePermissions] = useState<SpacePermissionType[]>([
			SpacePermissionType.VIEW_SPACE,
		]);
		const [stagedPrincipal, setStagedPrincipal] = React.useState<PrincipalOption | undefined>(
			undefined,
		);
		const [spaceSelection, setSpaceSelection] = React.useState<SpaceSelection>(
			SpaceSelection.AllExcludingPersonal,
		);

		const experienceTracker = useContext(ExperienceTrackerContext);
		const [showDialog, setShowDialog] = React.useState(false);

		const isRemoval = !spacePermissions.includes(SpacePermissionType.VIEW_SPACE);

		const closeDialog = useCallback(() => {
			setShowDialog(false);
		}, [setShowDialog]);

		const setInitState = useCallback(() => {
			setStagedPrincipal(undefined);
			setSpaceSelection(SpaceSelection.AllExcludingPersonal);
			setSpacePermissions([SpacePermissionType.VIEW_SPACE]);
		}, []);

		const onMutationErrored = useCallback(
			(error: Error) => {
				closeDialog();
				void flags.showErrorFlag({
					id: 'bulk-space-permissions.add.error',
					title: intl.formatMessage(i18n.errorFlagTitle),
					description: intl.formatMessage(i18n.errorFlagDescription),
					isAutoDismiss: true,
				});
				experienceTracker.stopOnError({
					name: BULK_SPACE_PERMISSIONS_EXPERIENCE,
					error,
				});
			},
			[closeDialog, flags, intl, experienceTracker],
		);

		const onAsyncMutationSubmitted = useCallback(
			(data) => {
				closeDialog();
				/**
				 * The backend is counting the mutation as a success for some exceptions
				 * but returning an error in the mutation response.  We need to check for
				 * these here.
				 */
				if (data?.bulkSetSpacePermissionAsync?.errors?.length > 0) {
					onMutationErrored(
						new Error(
							'Bulk space permissions mutation failed',
							data?.bulkSetSpacePermissionAsync?.errors,
						),
					);
				} else {
					void flags.showSuccessFlag({
						id: 'bulk-space-permissions.async.update.success',
						title: intl.formatMessage(i18n.asyncSuccessFlagTitle),
						description: intl.formatMessage(i18n.asyncSuccessFlagDescription),
						isAutoDismiss: true,
					});
					experienceTracker.succeed({
						name: BULK_SPACE_PERMISSIONS_EXPERIENCE,
					});
				}
				setInitState();
			},
			[closeDialog, setInitState, onMutationErrored, flags, intl, experienceTracker],
		);

		const getBulkSpacePermissionSubjectType = useCallback((): BulkSetSpacePermissionSubjectType => {
			return stagedPrincipal?.type === 'user'
				? BulkSetSpacePermissionSubjectType.USER
				: BulkSetSpacePermissionSubjectType.GROUP;
		}, [stagedPrincipal]);

		const getBulkSpacePermissionSpaceType =
			useCallback((): (BulkSetSpacePermissionSpaceType | null)[] => {
				switch (spaceSelection) {
					case SpaceSelection.AllExcludingPersonal: {
						return [
							BulkSetSpacePermissionSpaceType.GLOBAL,
							BulkSetSpacePermissionSpaceType.COLLABORATION,
							BulkSetSpacePermissionSpaceType.KNOWLEDGE_BASE,
						];
					}
					case SpaceSelection.AllIncludingPersonal: {
						return [
							BulkSetSpacePermissionSpaceType.PERSONAL,
							BulkSetSpacePermissionSpaceType.GLOBAL,
							BulkSetSpacePermissionSpaceType.COLLABORATION,
							BulkSetSpacePermissionSpaceType.KNOWLEDGE_BASE,
						];
					}
					case SpaceSelection.OnlyPersonal: {
						return [BulkSetSpacePermissionSpaceType.PERSONAL];
					}
					default: {
						return [null];
					}
				}
			}, [spaceSelection]);

		const [bulkUpdateAsync, { loading: asyncLoading, error: asyncError }] = useMutation<
			AddToSpacePermissions,
			AddToSpacePermissionsVariables
		>(
			// eslint-disable-next-line graphql-relay-compat/no-import-graphql-operations -- Read https://go/connie-relay-migration-fyi
			BulkSetSpacePermissionAsyncMutation,
			{
				onCompleted: onAsyncMutationSubmitted,
				onError: (err: Error) => {
					closeDialog();
					onMutationErrored(err);
				},
				variables: {
					spacePermissions,
					subjectType: getBulkSpacePermissionSubjectType(),
					subjectId: getPrincipalId(stagedPrincipal),
					spaceTypes: getBulkSpacePermissionSpaceType(),
				},
			},
		);

		const openDialog = useCallback(() => {
			experienceTracker.start({
				name: BULK_SPACE_PERMISSIONS_EXPERIENCE,
			});
			setShowDialog(true);
		}, [setShowDialog, experienceTracker]);

		const handleBackClick = useCallback(() => {
			createAnalyticsEvent({
				type: 'sendUIEvent',
				data: {
					actionSubject: 'button',
					action: 'clicked',
					actionSubjectId: 'defaultspaceperms',
					source: ANALYTICS_SOURCE,
				},
			}).fire();
		}, [createAnalyticsEvent]);

		const onRadioChange = useCallback(
			(e: React.ChangeEvent<HTMLInputElement>) => {
				setSpaceSelection(e.target.value as SpaceSelection);
				createAnalyticsEvent({
					type: 'sendUIEvent',
					data: {
						actionSubject: 'radio',
						action: 'selected',
						actionSubjectId: 'bulkSpacePermissionsType',
						source: ANALYTICS_SOURCE,
						attributes: {
							spaceSelection: e.target.value,
						},
					},
				}).fire();
			},
			[createAnalyticsEvent],
		);

		const onPrincipalChange = useCallback(
			(received: OptionData | undefined) => {
				// Only send an analytic event if a principal isn't deselected
				if (received) {
					createAnalyticsEvent({
						type: 'sendUIEvent',
						data: {
							actionSubject: 'principal',
							action: 'selected',
							actionSubjectId: 'bulkSpacePermissionsPrincipal',
							source: ANALYTICS_SOURCE,
							attributes: {
								principal: received.type,
							},
						},
					}).fire();
				}
				setStagedPrincipal(received);
			},
			[createAnalyticsEvent],
		);

		const onDialogConfirm = useCallback(() => {
			createAnalyticsEvent({
				type: 'sendUIEvent',
				data: {
					actionSubject: 'button',
					action: 'clicked',
					actionSubjectId: 'bulkSpacePermissionsConfirm',
					source: ANALYTICS_SOURCE,
				},
			}).fire();
			void bulkUpdateAsync();
		}, [createAnalyticsEvent, bulkUpdateAsync]);

		const onDialogCancel = useCallback(() => {
			createAnalyticsEvent({
				type: 'sendUIEvent',
				data: {
					actionSubject: 'button',
					action: 'clicked',
					actionSubjectId: 'bulkSpacePermissionsCancel',
					source: ANALYTICS_SOURCE,
				},
			}).fire();
			closeDialog();
			experienceTracker.abort({
				name: BULK_SPACE_PERMISSIONS_EXPERIENCE,
				reason: 'User closed the dialog',
			});
		}, [createAnalyticsEvent, experienceTracker, closeDialog]);

		// Don't render the page until we know if the user is a product admin
		// to avoid a flicker of the restricted view.
		if (isAdminCheckLoading) {
			return null;
		}

		if (!isProductAdmin || edition === ConfluenceEdition.FREE) {
			return <RestrictedView />;
		}

		return (
			<>
				{asyncError && <ErrorDisplay error={asyncError} />}
				<Box xcss={contentStyle}>
					<Box xcss={backButtonStyle}>
						<Button
							onClick={handleBackClick}
							appearance="link"
							href={
								fg('confluence_frontend_individual-spaces-uplift')
									? ADMIN_ALL_SPACES_SPACE_PERMISSIONS.toUrl()
									: ADMIN_SPACE_PERMISSIONS.toUrl()
							}
							iconBefore={<ChevronLeftLargeIcon label="" />}
						>
							{intl.formatMessage(i18n.backToSpacePermissions)}
						</Button>
					</Box>
					<Box xcss={bulkAddHeaderStyle}>{intl.formatMessage(i18n.bulkAddHeader)}</Box>
					<Box xcss={bulkSubtextStyle}>{intl.formatMessage(i18n.titleSubtext)}</Box>
					<Box xcss={userPickerHeaderStyle}>
						{intl.formatMessage(i18n.userPickerHeader, {
							asterisk: (chunks: React.ReactNode[]) => <Box xcss={asteriskStyle}>{chunks}</Box>,
						})}
					</Box>
					<Box xcss={userPickerStyle}>
						<UserAndGroupSearchPicker
							data-test-id="user-and-group-search"
							fieldId="bulk-permissions-user-picker"
							includeGroup
							withUsers
							isMulti={false}
							smart={false}
							placeholder={intl.formatMessage(i18n.userPickerPlaceholder)}
							sitePermissionTypeFilter={SitePermissionTypeFilter.NONE}
							// @ts-ignore - Type 'null' is not assignable to type 'string | ((input: { inputValue: string; }) => string | null)'
							// Using `ts-ignore` and not `ts-expect-error` here as TypeScript will fail on this line without `ts-ignore` but with `ts-expect-error`
							// This error was introduced after upgrading to TypeScript 5
							noOptionsMessage={null}
							onChange={(received) => {
								// @ts-ignore - Argument of type 'Value' is not assignable to parameter of type 'OptionData | undefined'
								// Using `ts-ignore` and not `ts-expect-error` here as TypeScript will fail on this line without `ts-ignore` but with `ts-expect-error`
								// This error was introduced after upgrading to TypeScript 5
								onPrincipalChange(received);
							}}
							value={stagedPrincipal || []}
							filterSearchResults={(value) =>
								value?.extra?.permissionType !== SitePermissionType.EXTERNAL
							}
						/>
					</Box>
					<Box xcss={userPickerFineTextStyle}>{intl.formatMessage(i18n.UserPickerSubtext)}</Box>
					<Box xcss={spaceSelectionHeaderStyle}>
						{intl.formatMessage(i18n.spaceSelectionHeader)}
					</Box>
					<Box>
						<Radio
							value={SpaceSelection.AllExcludingPersonal}
							onChange={onRadioChange}
							isChecked={spaceSelection === SpaceSelection.AllExcludingPersonal}
							name="space-selection"
							label={intl.formatMessage(i18n.spaceSelectionOne)}
						/>
						<Radio
							value={SpaceSelection.AllIncludingPersonal}
							onChange={onRadioChange}
							isChecked={spaceSelection === SpaceSelection.AllIncludingPersonal}
							name="space-selection"
							label={intl.formatMessage(i18n.spaceSelectionTwo)}
						/>
						<Radio
							value={SpaceSelection.OnlyPersonal}
							onChange={onRadioChange}
							isChecked={spaceSelection === SpaceSelection.OnlyPersonal}
							name="space-selection"
							label={intl.formatMessage(i18n.spaceSelectionThree)}
						/>
					</Box>
					<Box xcss={spaceSelectionHeaderStyle}>
						{intl.formatMessage(i18n.permissionsSelectionHeader)}
					</Box>
					<Box xcss={subtextStyle}>
						{intl.formatMessage(i18n.permissionsSelectionSubtext, {
							b: (chunks: React.ReactNode[]) => <b>{chunks}</b>,
						})}
						<br />
						<Box paddingBlockStart="space.200">
							{intl.formatMessage(i18n.permissionsSelectionSubtextAdditional)}
						</Box>
					</Box>
					<BulkSpacePermissionsTable
						spacePermissions={spacePermissions}
						setSpacePermissions={setSpacePermissions}
					/>
					{isRemoval && <RemovalWarning />}
					<Button
						appearance="primary"
						onClick={openDialog}
						isDisabled={stagedPrincipal === undefined}
					>
						{intl.formatMessage(isRemoval ? i18n.applyButtonTextRemove : i18n.applyButtonTextAdd)}
					</Button>
					<Inline xcss={requirementMessageStyle}>
						{intl.formatMessage(i18n.requirementMessage, {
							asterisk: (chunks: React.ReactNode[]) => <Box xcss={asteriskStyle}>{chunks}</Box>,
						})}
					</Inline>
				</Box>
				{showDialog && (
					<BulkSpacePermissionsModal
						principalName={stagedPrincipal?.name || ''}
						isRemoval={isRemoval}
						spaceSelection={spaceSelection}
						isLoading={asyncLoading}
						onConfirm={onDialogConfirm}
						onCancel={onDialogCancel}
					/>
				)}
			</>
		);
	}),
);
