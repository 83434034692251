import React from 'react';

import type { ExtensionManifest } from '@atlaskit/editor-common/extensions';

import Icon from '../../../../prebuilt/config-items-empty/generate-content/Icon';
import { AIPanelRenderer } from '../../ai-panel-renderer/AIPanelRenderer';
import type { AIManifest, AIPanelParameters } from '../../types';

import { messages } from './messages';

const buildActionItemsPanelManifest = ({
	intl,
	providerFactory,
}: AIManifest): ExtensionManifest<AIPanelParameters> => {
	return {
		title: intl.formatMessage(messages.actionItemsPageTitle),
		type: 'com.atlassian.ai-blocks',
		key: 'ai-action-items-block',
		description: intl.formatMessage(messages.actionItemsPageDescription),
		icons: {
			'48': async () => () => <Icon alt={intl.formatMessage(messages.actionItemsPageTitle)} />,
		},
		modules: {
			nodes: {
				aiActionItemsBodiedExtension: {
					type: 'bodiedExtension',
					render:
						async () =>
						({ node }) => {
							return <AIPanelRenderer node={node} providerFactory={providerFactory} />;
						},
				},
			},
		},
	};
};

export default buildActionItemsPanelManifest;
