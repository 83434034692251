import React from 'react';

import { fg } from '@atlaskit/platform-feature-flags';
import { checkAssistanceServiceFreeGenerateFg } from '@atlassian/editor-ai-common/utils/check-assistance-service-fg';
import sharedMessages from '@atlassian/generative-ai-modal/utils/messages';

import { CONFIG_ITEM_KEYS } from '../../../config-items/config-item-keys';
import { createEditorPluginAIConfigItemMarkdown } from '../../../config-items/config-items';
import { createInsertAtCurrentPosition } from '../../config-item-actions/markdown';

import { default as Icon } from './Icon';
import { messages } from './messages';

const SELECTION_TYPE = 'empty';

export const generateContentConfigItem = createEditorPluginAIConfigItemMarkdown({
	key: CONFIG_ITEM_KEYS.FREE_GENERATE,
	title: sharedMessages.aiButtonTitle,
	description: sharedMessages.aiUniversalLongCallToAction,
	promptHint: messages.promptHint,
	selectionType: SELECTION_TYPE,
	agentId: 'editor_agent',
	canTogglePageKnowledge: true,
	intentSchemaId: ({ latestPromptResponse }) => {
		return latestPromptResponse
			? 'custom_with_interrogation_intent_schema.json'
			: 'custom_free_generate_intent_schema.json';
	},
	icon: ({ formatMessage }) => <Icon alt={formatMessage(sharedMessages.aiButtonTitle)} />,
	// No prompt label is required -- as this is intended to be set via the
	// the baseGenerate
	isVisible: () => true,
	actions: [createInsertAtCurrentPosition({ appearance: 'primary' })],
	quickInsertPriority: Number.NEGATIVE_INFINITY,
	getBackendModel: (isInterrogate) => {
		if (isInterrogate && fg('platform_editor_ai_interrogate_with_convo_ai')) {
			return 'assistance-service';
		}

		if (!isInterrogate && checkAssistanceServiceFreeGenerateFg()) {
			return 'assistance-service';
		}

		// If we're not interrogating, we use xp-gen-ai
		return 'xp-gen-ai';
	},
});
