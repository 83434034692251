import React from 'react';

import { fg } from '@atlaskit/platform-feature-flags';

import { CONFIG_ITEM_KEYS } from '../../../config-items/config-item-keys';
import { createEditorPluginAIConfigItemMarkdown } from '../../../config-items/config-items';
import { Icon } from '../../assets/icons/suggestTitle';
import {
	createInsertAtCurrentPosition,
	createInsertAtTop,
	createReplaceExistingConfluenceTitle,
} from '../../config-item-actions/markdown';
import { isMinLength } from '../../config-item-visible/visibility-utils';

import { messages } from './messages';

const SELECTION_TYPE = 'range';

export const suggestConfigItem = createEditorPluginAIConfigItemMarkdown({
	key: CONFIG_ITEM_KEYS.SUGGEST_A_TITLE,
	title: messages.title,
	description: messages.description,
	selectionType: SELECTION_TYPE,
	intentSchemaId: 'suggest_title_intent_schema.json',
	isVisible: ({ editorView, positions }) => isMinLength({ editorView, minLength: 1, positions }),
	icon: ({ shownAt }) => <Icon shownAt={shownAt} />,
	actions: [
		createInsertAtCurrentPosition({ appearance: 'secondary' }),
		createInsertAtTop({ appearance: 'primary' }),
	],
	getBackendModel: () => {
		if (fg('platform_editor_ai_assistance_service')) {
			return 'assistance-service';
		}

		return 'xp-gen-ai';
	},
});

/**
 * EXPERIMENTAL: Util for experimental flow where 'Suggest title' in
 * Confluence title toolbar will trigger the 'Suggest a title' flow in Editor AI.
 */
export const suggestConfigItemForConfluenceTitleToolbar = createEditorPluginAIConfigItemMarkdown({
	key: CONFIG_ITEM_KEYS.SUGGEST_A_TITLE,
	title: messages.title,
	description: messages.description,
	selectionType: 'range',
	intentSchemaId: 'suggest_title_intent_schema.json',
	isVisible: ({ editorView, positions }) => isMinLength({ editorView, minLength: 1, positions }),
	icon: ({ shownAt }) => <Icon shownAt={shownAt} />,
	actions: [createReplaceExistingConfluenceTitle({ appearance: 'primary' })],
	getBackendModel: () => {
		if (fg('platform_editor_ai_assistance_service')) {
			return 'assistance-service';
		}

		return 'xp-gen-ai';
	},
});
