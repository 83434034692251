import { defineMessages } from 'react-intl-next';

export default defineMessages({
	traverseHistoryBack: {
		id: 'fabric.editor.ai.config.item.action.traverseHistoryBack',
		defaultMessage: 'View previous response',
		description: 'Move back in history to the previous AI-generated content and prompt',
	},
	traverseHistoryForward: {
		id: 'fabric.editor.ai.config.item.action.traverseHistoryForward',
		defaultMessage: 'View next response',
		description: 'Move forward in history to the next AI-generated content and prompt',
	},
});
