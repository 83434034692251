import { query } from '@confluence/query-preloader-tools';
import { BreadcrumbsQuery } from '@confluence/breadcrumbs-utils/entry-points/BreadcrumbsQuery.graphql';

export function preloadBreadcrumbs(
	spaceKey,
	contentId,
	skipContent = false,
	isSpaceAliasFFEnabled = false,
) {
	return query({
		query: BreadcrumbsQuery,
		variables: {
			spaceKey,
			contentId,
			skipContent,
			includeAlias: isSpaceAliasFFEnabled,
		},
		// Must be preloaded with network only to get fresh data
		fetchPolicy: 'network-only',
	});
}
