import { di } from 'react-magnetic-di';

import {
	ExperienceName,
	useAIMateExperienceTracker,
} from '@atlassian/conversation-assistant-instrumentation';
import {
	useAssistanceService,
	type UseAssistanceServiceParams,
	useMutation,
} from '@atlassian/conversation-assistant-service';

type UseAgentFavouritesProps = UseAssistanceServiceParams & {
	onSuccess?: (params: { favourite: boolean }) => void;
	onError?: (params: { favourite: boolean }) => void;
};

export const useAgentFavourites = ({
	onError,
	onSuccess,
	experienceId = 'ai-mate',
	product,
}: UseAgentFavouritesProps = {}) => {
	di(useAssistanceService);

	const assistanceService = useAssistanceService({
		experienceId,
		product,
	});

	const experience = useAIMateExperienceTracker();

	const favouriteAgent = useMutation({
		mutationFn: async (id: string) => {
			experience.start({
				id: ExperienceName.FAVOURITE_AGENT,
				name: ExperienceName.FAVOURITE_AGENT,
			});

			return await assistanceService.favouriteAgent(id);
		},
		onSuccess: async () => {
			experience.succeed({
				name: ExperienceName.FAVOURITE_AGENT,
			});

			onSuccess?.({ favourite: true });
		},
		onError: (error) => {
			experience.fail({
				name: ExperienceName.FAVOURITE_AGENT,
				error,
			});

			onError?.({ favourite: true });
		},
	});

	const unfavouriteAgent = useMutation({
		mutationFn: async (id: string) => {
			experience.start({
				id: ExperienceName.UNFAVOURITE_AGENT,
				name: ExperienceName.UNFAVOURITE_AGENT,
			});

			return await assistanceService.unfavouriteAgent(id);
		},
		onSuccess: async () => {
			experience.succeed({
				name: ExperienceName.UNFAVOURITE_AGENT,
			});

			onSuccess?.({ favourite: false });
		},
		onError: (error) => {
			experience.fail({
				name: ExperienceName.UNFAVOURITE_AGENT,
				error,
			});

			onError?.({ favourite: false });
		},
	});

	return {
		favouriteAgent,
		unfavouriteAgent,
	};
};
