import React from 'react';
import { FormattedMessage, defineMessages, useIntl } from 'react-intl-next';
import { useQuery } from 'react-apollo';

import { ChartSummaryBaseCard } from '@confluence/admin-center/entry-points/ChartSummaryBaseCard';
import { useEventMetricData } from '@confluence/admin-center/entry-points/useEventMetricData';
import { getBrowserTimezone } from '@confluence/confluence-analytics/entry-points/getBrowserTimezone';
import { MetricSettingsContextDefaultValue } from '@confluence/admin-center/entry-points/MetricSettingsContext';
import {
	getGranularityTextForMainStat,
	getMainStatProps,
} from '@confluence/admin-center/entry-points/MainStat-utils';
import { COMPANY_HUB_GENERAL_SETTINGS_HUB_VIEWS_EXPERIENCE } from '@confluence/experience-tracker';
import { SubExperienceTracker } from '@confluence/admin-center/entry-points/SubExperienceTracker';

import { RecentActivityChartQuery } from './RecentActivityChartQuery.graphql';
import type { RecentActivityChartQuery as RecentActivityChartQueryType } from './__types__/RecentActivityChartQuery';

const i18n = defineMessages({
	hubViewsMainStat: {
		id: 'company-hub.recent-activity-chart.main-statistic',
		defaultMessage: '{value} {lastGranularityDescription}',
		description:
			'Displays the total number of hub items for the hub views chart on the hub dashboard page',
	},
	viewsLabel: {
		id: 'company-hub.recent-activity-chart.views-label',
		defaultMessage: 'Views',
		description: 'Views line label for the total hub views chart on the hub dashboard page',
	},
	chartTitle: {
		id: 'company-hub.recent-activity-chart.title',
		defaultMessage: 'Hub views',
		description: 'Section title for the total hub views chart on the hub dashboard page',
	},
});

export const RecentActivityChart = ({
	spaceId,
	isSpaceCreatedDateOver1DayAgo,
	sectionQueryLoading,
}: {
	spaceId: string | null | undefined;
	isSpaceCreatedDateOver1DayAgo: boolean | undefined;
	sectionQueryLoading: boolean;
}) => {
	const { formatMessage, formatNumber } = useIntl();
	const { startDate, endDate, granularity } = MetricSettingsContextDefaultValue;

	const timezone = getBrowserTimezone();

	const { data, loading, error } = useQuery<RecentActivityChartQueryType>(
		// eslint-disable-next-line graphql-relay-compat/no-import-graphql-operations -- Read https://go/connie-relay-migration-fyi
		RecentActivityChartQuery,
		{
			variables: {
				spaceId,
				startDate: startDate.toISOString(),
				endDate: endDate.toISOString(),
				timezone,
				granularity,
			},
			skip: !isSpaceCreatedDateOver1DayAgo || !spaceId,
			context: {
				single: true,
			},
		},
	);

	const lineDataToProcess = [
		{
			description: formatMessage(i18n.viewsLabel),
			timeseriesData: data?.timeseriesPageBlogCount?.nodes || [],
		},
	];

	const { lines, mainStatCount, subStat } = useEventMetricData(lineDataToProcess);

	return (
		<SubExperienceTracker
			experienceName={COMPANY_HUB_GENERAL_SETTINGS_HUB_VIEWS_EXPERIENCE}
			errors={[error]}
			loading={loading}
		>
			<ChartSummaryBaseCard
				title={formatMessage(i18n.chartTitle)}
				lines={lines}
				isAreaChart
				loading={sectionQueryLoading || loading}
				hasError={!!error}
				granularity={granularity}
				mainStat={getMainStatProps({
					count: mainStatCount,
					formatNumber,
					description: i18n.hubViewsMainStat,
					descriptionValues: {
						lastGranularityDescription: (
							<FormattedMessage {...getGranularityTextForMainStat(granularity)} />
						),
					},
				})}
				subStat={subStat}
			/>
		</SubExperienceTracker>
	);
};
