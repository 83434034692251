import { useContext } from 'react';

import { fg } from '@confluence/feature-gating';
import { SPAViewContext } from '@confluence/spa-view-context';

// TAP trait name and values
const LIVE_EDIT_AB_TEST_TRAIT_NAME = 'confluence_live_page_opt_in';
const LIVE_EDIT_AB_TEST_OPTED_IN_VALUE = 'experiment_opted_in';
const LIVE_EDIT_AB_TEST_OPTED_OUT_VALUE = 'experiment_opted_out';
const LIVE_PAGES_BETA_TRAIT_NAME = 'confluence_live_pages_beta';
const LIVE_PAGES_BETA_DEFAULT_ON_VALUE = 'beta-default-on';
const LIVE_PAGES_BETA_DEFAULT_OFF_VALUE = 'beta-default-off';
const LIVE_PAGES_BETA_DISABLED_VALUE = 'beta-disabled';

export enum LivePageReleasePhase {
	NOT_ENABLED = 'not_enabled',
	CLOSED_BETA = 'closed_beta',
	AB_TEST_OPTED_IN = 'ab_test_opted_in', // Tenant is in ab testing and has turned on live pages
	AB_TEST_OPTED_OUT = 'ab_test_opted_out', // Tenant is in ab testing and has turned off live pages
	OPEN_BETA_INITIAL = 'open_beta_initial', // Tenant is in open beta, but has never turned on the live pages.
	OPEN_BETA_DEFAULT_ON = 'open_beta_default_on', // Tenant is in open beta, and live pages have been turned on (opt in). This means the default creation method is live pages.
	OPEN_BETA_DEFAULT_OFF = 'open_beta_default_off', // Tenant is in open beta, and live pages have been turned off (opt out). Live pages are still available, but the default creation method is classic pages.
	OPEN_BETA_DISABLED = 'open_beta_disabled', // Tenant was in open beta but has asked support to disable live pages all together.
	UNKNOWN = 'unknown',
}

export const useLivePageReleasePhase = (): LivePageReleasePhase => {
	const { experimentFeatures } = useContext(SPAViewContext);

	let openBetaTraitValue;
	let abTestTraitValue;
	try {
		openBetaTraitValue = JSON.parse(experimentFeatures)?.[LIVE_PAGES_BETA_TRAIT_NAME];
		abTestTraitValue = JSON.parse(experimentFeatures)?.[LIVE_EDIT_AB_TEST_TRAIT_NAME];
	} catch (e) {
		openBetaTraitValue = null;
		abTestTraitValue = null;
	}

	// Open Beta release phase
	if (fg('confluence_live_pages_open_beta')) {
		if (openBetaTraitValue === LIVE_PAGES_BETA_DEFAULT_ON_VALUE) {
			return LivePageReleasePhase.OPEN_BETA_DEFAULT_ON;
		} else if (openBetaTraitValue === LIVE_PAGES_BETA_DEFAULT_OFF_VALUE) {
			return LivePageReleasePhase.OPEN_BETA_DEFAULT_OFF;
		} else if (openBetaTraitValue === LIVE_PAGES_BETA_DISABLED_VALUE) {
			return LivePageReleasePhase.OPEN_BETA_DISABLED;
		} else {
			return LivePageReleasePhase.OPEN_BETA_INITIAL;
		}
	}

	// AB testing release phase
	/**
	 * Evaluating both feature gate and trait value here because:
	 *
	 * Feature gate value is available faster but may not always have the most up to date value.
	 * There is some latency between updating the tenant trait and then updating statsig feature gate.
	 * Checking the trait value will get the most recent value before statsig feature gate does so if the tenant opts in recently we will get the correct value quicker.
	 *
	 * For AB testing, frontend care more about getting the correct opt in status because sites are opted in through Landing process,
	 * 	where opt out is through manual process and user is informed by the UI that manual opt in/ opt out status change may take time to apply.
	 *
	 */
	if (
		abTestTraitValue === LIVE_EDIT_AB_TEST_OPTED_IN_VALUE ||
		fg('confluence_live_pages_ab_test_opted_in')
	) {
		return LivePageReleasePhase.AB_TEST_OPTED_IN;
	}

	if (abTestTraitValue === LIVE_EDIT_AB_TEST_OPTED_OUT_VALUE) {
		return LivePageReleasePhase.AB_TEST_OPTED_OUT;
	}

	// Closed Beta release phase
	if (fg('confluence_live_pages')) {
		return LivePageReleasePhase.CLOSED_BETA;
	}

	// Default
	return LivePageReleasePhase.NOT_ENABLED;
};
