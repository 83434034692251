import { defineMessages } from 'react-intl-next';

export const messages = defineMessages({
	title: {
		id: 'fabric.editor.ai.config.item.range.shorten.title',
		defaultMessage: 'Make shorter',
		description:
			'Describes an option to use Atlassian Intelligence to shorten the content currently selected by the user',
	},
	description: {
		id: 'fabric.editor.ai.config.item.range.shorten.description',
		defaultMessage: 'Shortens the content to make it more concise',
		description: 'Description for the the Atlassian Intelligence "Shorten".',
	},
	selectionToolbarDropdownItemTitle: {
		id: 'fabric.editor.ai.config.item.range.shorten.selectionToolbar.dropdownItem.title',
		defaultMessage: 'Make shorter',
		description: 'Title for Atlassian Intelligence "Shorten" in editor selection floating toolbar',
	},
});
