import mean from 'lodash/mean';

import { EVENT_TYPE } from '@atlaskit/editor-common/analytics';
// import { type ApplyChangeHandler } from '@atlaskit/editor-plugin-context-panel';
// import type { Schema } from '@atlaskit/editor-prosemirror/model';
// import type { EditorState } from '@atlaskit/editor-prosemirror/state';
import type { EditorView } from '@atlaskit/editor-prosemirror/view';
// import { getExperimentCohort } from '@atlassian/generative-ai-modal/utils/experiments';

// import type { EditorAIAnalyticEventPayload } from '../../analytics/types';
// import type { ProactiveAISuggestionAEP } from '../../analytics/types';
import { addAnalytics } from '../../analytics/utils';
// import { DiffMatchPatch } from '../../utils/diff-match-patch/diff-match-patch';
import type { ParagraphChunk } from '../../utils/diff-match-patch/utils';
import { median } from '../../utils/median';

import { aiProactivePluginKey } from './ai-proactive-plugin-key';
// import type { Recommendation } from './api';
// import type { ProactiveAIBlock } from './states';
// import type { Recommendation } from './api';
import {
	fireAPIError,
	// insertRecommendation,
	// removeRecommendation,
	// startProactiveAI,
	// toggleProactiveAI,
} from './commands';
import {
	ANALYTICS_ACTION_SUBJECT_ID,
	ANALYTICS_EXPERIENCE_NAME,
	ANALYTICS_FEATURE_NAME,
	// END_PUNC_CHARS,
	// MIDDLE_PUNC_CHARS,
	// START_PUNC_CHARS,
} from './constants';
// import { getPluginState } from './plugin-factory';
// import type { ProactiveAIBlock } from './states';
// import { isFullStopOmittedInContainerType } from './utils';

// const getCommonAnalyticsAttributes = (): Record<string, string> => {
// 	const variation = getExperimentCohort('editor_ai_-_proactive_ai_model_variations');
// 	if (variation !== 'control' && variation !== 'not-enrolled') {
// 		return {
// 			variation,
// 		};
// 	}
// 	return {
// 		// variation: 'default',
// 	};
// };

// const getSuggestionMetadata = ({
// 	blockOrSentenceFromDiffObject,
// 	schema,
// }: {
// 	blockOrSentenceFromDiffObject?: ProactiveAIBlock;
// 	schema: Schema;
// }) => {
// 	if (!blockOrSentenceFromDiffObject) {
// 		return;
// 	}

// 	const metadata = {
// 		// ...blockOrSentenceFromDiffObject?.metadata,
// 		inlineNodeCount: blockOrSentenceFromDiffObject?.ignoredRanges.filter(
// 			({ type }) => type === 'inlineNode',
// 		).length,
// 		contextualFormatting: isFullStopOmittedInContainerType(
// 			schema,
// 			blockOrSentenceFromDiffObject.containerType,
// 		),
// 	};

// 	return metadata;
// };

// export const getPunctuationAttributes = (
// 	diffObject: DiffObject,
// ): { isFullStopAdded: boolean; isPunctuationChanged: boolean } => {
// 	const { text, originalText } = diffObject;

// 	if (
// 		!originalText ||
// 		!text ||
// 		// if it's the same length or 1 character difference between the texts
// 		!(originalText.length === text.length || Math.abs(originalText.length - text.length) === 1)
// 	) {
// 		return {
// 			isFullStopAdded: false,
// 			isPunctuationChanged: false,
// 		};
// 	}

// 	const diffs: Array<{ [0]: -1 | 0 | 1; [1]: string }> = DiffMatchPatch.diff_main(
// 		originalText,
// 		text,
// 		false,
// 	);

// 	switch (diffs.length) {
// 		case 2: {
// 			const [diff1, diff2] = diffs;
// 			// If there's only 1 addition in the diff at the end
// 			if (diff1[0] === 0 && diff2[0] === 1) {
// 				return {
// 					isFullStopAdded: diff2[1] === '.',
// 					isPunctuationChanged: END_PUNC_CHARS.includes(diff2[1]),
// 				};
// 			}
// 			// If there's only 1 addition in the diff at the beginning
// 			if (diff1[0] === 1 && diff2[0] === 0) {
// 				return {
// 					isFullStopAdded: false,
// 					isPunctuationChanged: START_PUNC_CHARS.includes(diff1[1]),
// 				};
// 			}
// 			break;
// 		}
// 		case 3: {
// 			const [diff1, diff2, diff3] = diffs;
// 			// If punctuation was added or removed in the middle
// 			if (diff1[0] === 0 && diff2[0] !== 0 && diff3[0] === 0) {
// 				return {
// 					isFullStopAdded: false,
// 					isPunctuationChanged: MIDDLE_PUNC_CHARS.includes(diff2[1]),
// 				};
// 			}
// 			break;
// 		}
// 		case 4: {
// 			const [diff1, diff2, diff3, diff4] = diffs;
// 			// If there's 1 replacement (deletion + addition) in the middle
// 			if (diff1[0] === 0 && diff2[0] === -1 && diff3[0] === 1 && diff4[0] === 0) {
// 				return {
// 					isFullStopAdded: false,
// 					isPunctuationChanged:
// 						MIDDLE_PUNC_CHARS.includes(diff2[1]) && MIDDLE_PUNC_CHARS.includes(diff3[1]),
// 				};
// 			}
// 			break;
// 		}
// 		default: {
// 			break;
// 		}
// 	}

// 	return {
// 		isFullStopAdded: false,
// 		isPunctuationChanged: false,
// 	};
// };

// export const insertRecommendationWithAnalytics = (
// 	...args: Parameters<typeof insertRecommendation>
// ) =>
// 	withAnalytics({
// 		payload: (state: EditorState) => {
// 			const { analyticsAIInteractionId } = getPluginState(state);
// 			const [_, triggeredFrom] = args;
// 			return {
// 				action: 'inserted',
// 				actionSubject: 'editorPluginAI',
// 				actionSubjectId: ANALYTICS_ACTION_SUBJECT_ID,
// 				attributes: {
// 					aiInteractionID: analyticsAIInteractionId,
// 					triggeredFrom,
// 					// TODO: Add more information about the recommendation which was inserted.
// 				},
// 				eventType: EVENT_TYPE.TRACK,
// 			} as ProactiveAISuggestionAEP;
// 		},
// 	})(insertRecommendation(...args));

// export const removeRecommendationWithAnalytics = (
// 	...args: Parameters<typeof removeRecommendation>
// ) =>
// 	withAnalytics({
// 		payload: (state: EditorState) => {
// 			const { analyticsAIInteractionId } = getPluginState(state);
// 			const [_, triggeredFrom] = args;
// 			return {
// 				action: 'dismissed',
// 				actionSubject: 'editorPluginAI',
// 				actionSubjectId: ANALYTICS_ACTION_SUBJECT_ID,
// 				attributes: {
// 					aiInteractionID: analyticsAIInteractionId,
// 					triggeredFrom,
// 				},
// 				eventType: EVENT_TYPE.TRACK,
// 			} as ProactiveAISuggestionAEP;
// 		},
// 	})(removeRecommendation(...args));

// export const initiateProactiveAIWithAnalytics = (aiInteractionID: string) =>
// 	withAnalytics({
// 		payload: {
// 			action: 'initiated',
// 			actionSubject: 'aiInteraction',
// 			actionSubjectId: 'editorPluginAI',
// 			attributes: {
// 				// ...getCommonAnalyticsAttributes(),
// 				singleInstrumentationID: aiInteractionID,
// 				aiInteractionID: aiInteractionID,
// 				aiFeatureName: ANALYTICS_FEATURE_NAME,
// 				aiExperienceName: ANALYTICS_EXPERIENCE_NAME,
// 				proactiveAIGenerated: 1,
// 				userGeneratedAI: 0,
// 				isAIFeature: 1,
// 			},
// 			eventType: EVENT_TYPE.TRACK,
// 		},
// 	});

// export const handleSpellingAndGrammarWithAnalytics = ({
// 	proactiveToggleCount,
// 	totalSuggestionsOnPage,
// 	dismissedWords,
// 	insertionCount,
// 	initialToggleState,
// 	triggeredFrom,
// 	operationType,
// }: {
// 	proactiveToggleCount: number;
// 	totalSuggestionsOnPage: number;
// 	dismissedWords: number;
// 	insertionCount: number;
// 	initialToggleState: boolean;
// 	triggeredFrom: string;
// 	operationType: 'start' | 'toggle';
// }) => {
// 	const analyticsPayload: EditorAIAnalyticEventPayload = {
// 		action: 'toggled',
// 		actionSubject: 'editorPluginAI',
// 		actionSubjectId: ANALYTICS_ACTION_SUBJECT_ID,
// 		attributes: {
// 			...getCommonAnalyticsAttributes(),
// 			toggledCount: proactiveToggleCount,
// 			initialToggleState,
// 			totalSuggestions: totalSuggestionsOnPage,
// 			totalAcceptedSuggestions: insertionCount,
// 			totalDismissedSuggestions: dismissedWords,
// 			triggeredFrom,
// 		},
// 		eventType: EVENT_TYPE.TRACK,
// 	};
// 	const operation = operationType === 'start' ? startProactiveAI : toggleProactiveAI;
// 	const operationWithAnalytics = withAnalytics({ payload: analyticsPayload })(
// 		operation(proactiveToggleCount),
// 	);
// 	return operationWithAnalytics;
// };

const getFireAPIReceivedAnalytics = () => {
	let apiReceivedCount = 0;
	const apiReceivedSamplingRate = 10;
	let durationArray: number[] = [];
	return ({
		view,
		duration,
		totalSuggestions,
		totalAcceptedSuggestions,
		totalDismissedSuggestions,
	}: {
		view: EditorView;
		duration: number;
		totalSuggestions: number;
		totalAcceptedSuggestions: number;
		totalDismissedSuggestions: number;
	}) => {
		apiReceivedCount++;
		durationArray.push(duration);
		if (apiReceivedCount >= apiReceivedSamplingRate) {
			/**
			 * min / max will not be undefined as this function samples
			 * durations after X amount of times, and this is only calculated
			 * at the end of the sampling
			 */
			const minDuration = Math.min(...durationArray)!;
			const maxDuration = Math.max(...durationArray)!;

			const meanDuration = mean(durationArray);
			const medianDuration = median(durationArray);

			const tr = addAnalytics({
				editorState: view.state,
				tr: view.state.tr,
				payload: {
					action: 'apiReceived',
					actionSubject: 'editorPluginAI',
					actionSubjectId: ANALYTICS_ACTION_SUBJECT_ID,
					attributes: {
						// ...getCommonAnalyticsAttributes(),
						meanDuration,
						medianDuration,
						minDuration,
						maxDuration,
						totalSuggestions,
						totalAcceptedSuggestions,
						totalDismissedSuggestions,
					},
					eventType: EVENT_TYPE.OPERATIONAL,
				},
			});
			view.dispatch(tr);

			// reset sampling
			apiReceivedCount = 0;
			durationArray = [];
		}
	};
};

export const fireAPIReceivedAnalytics = getFireAPIReceivedAnalytics();

export const fireAPIErrorWithAnalytics = ({
	view,
	failedChunkIds,
	errors,
	reason,
	statusCode,
}: {
	view: EditorView;
	failedChunkIds: Array<ParagraphChunk['id']>;
	errors: string[];
	reason: string;
	statusCode: number;
}) =>
	fireAPIError((tr) => {
		const uniqueInteractionID = tr.getMeta(aiProactivePluginKey);
		let trWithAnalytics = tr;
		errors.forEach((error) => {
			trWithAnalytics = addAnalytics({
				editorState: view.state,
				tr: trWithAnalytics,
				payload: {
					action: 'apiError',
					actionSubject: 'editorPluginAI',
					actionSubjectId: ANALYTICS_ACTION_SUBJECT_ID,
					attributes: {
						// ...getCommonAnalyticsAttributes(),
						error,
						reason,
						statusCode,
					},
					eventType: EVENT_TYPE.OPERATIONAL,
				},
			});
		});

		trWithAnalytics = addAnalytics({
			editorState: view.state,
			tr: trWithAnalytics,
			payload: {
				action: 'error',
				actionSubject: 'aiResult',
				actionSubjectId: 'editorPluginAI',
				eventType: EVENT_TYPE.TRACK,
				attributes: {
					singleInstrumentationID: uniqueInteractionID || '',
					aiInteractionID: uniqueInteractionID || '',
					aiFeatureName: ANALYTICS_FEATURE_NAME,
					aiExperienceName: ANALYTICS_EXPERIENCE_NAME,
					proactiveAIGenerated: 1,
					userGeneratedAI: 0,
					isAIFeature: 1,
					aiErrorMessage: reason,
					aiErrorCode: statusCode,
				},
			},
		});

		return trWithAnalytics;
	});

// export const disableCheckForPurgedChunksWithAnalytics = ({
// 	purgedChunkIds,
// 	totalParts,
// 	totalPurgedParts,
// }: {
// 	purgedChunkIds: Array<ParagraphChunk['id']>;
// 	totalParts: number;
// 	totalPurgedParts: number;
// }) => {
// 	return withAnalytics({
// 		payload: {
// 			action: 'apiPurged',
// 			actionSubject: 'editorPluginAI',
// 			actionSubjectId: ANALYTICS_ACTION_SUBJECT_ID,
// 			attributes: {
// 				...getCommonAnalyticsAttributes(),
// 				totalParts,
// 				totalPurgedParts,
// 			},
// 			eventType: EVENT_TYPE.OPERATIONAL,
// 		},
// 	})(disableNeedSpellingAndGrammar(purgedChunkIds));
// };

// export const viewSuggestionWithAnalytics = (aiInteractionId: string, tr: Transaction) =>
// 	addAnalytics({
// 		editorState,
// 		tr,
// 		payload: {
// 			action: 'viewed',
// 			actionSubject: 'aiResult',
// 			actionSubjectId: 'editorPluginAI',
// 			attributes: {
// 				aiFeatureName: ANALYTICS_FEATURE_NAME,
// 				aiInteractionID: aiInteractionId ?? '',
// 				singleInstrumentationID: aiInteractionId ?? '',
// 				proactiveAIGenerated: 1,
// 				userGeneratedAI: 0,
// 				isAIFeature: 1,
// 				aiExperienceName: ANALYTICS_EXPERIENCE_NAME,
// 			},
// 			eventType: EVENT_TYPE.TRACK,
// 		},
// 	});

// export const ignoreSuggestionWithAnalytics = ({
// 	aiInteractionID,
// 	editorState,
// 	selectedDiff,
// 	blockOrSentenceFromDiffObject,
// 	transaction,
// }: {
// 	aiInteractionID: string;
// 	editorState: EditorState;
// 	selectedDiff: DiffObject;
// 	blockOrSentenceFromDiffObject?: ProactiveAIBlock;
// 	transaction?: Transaction;
// }) =>
// 	addAnalytics({
// 		editorState,
// 		tr: transaction ?? editorState.tr,
// 		payload: {
// 			action: 'ignored',
// 			actionSubject: 'editorPluginAI',
// 			actionSubjectId: ANALYTICS_ACTION_SUBJECT_ID,
// 			attributes: {
// 				aiInteractionID,
// 				...getPunctuationAttributes(selectedDiff),
// 				...getCommonAnalyticsAttributes(),
// 				...getSuggestionMetadata({
// 					schema: editorState.schema,
// 					blockOrSentenceFromDiffObject,
// 				}),
// 			},
// 			eventType: EVENT_TYPE.TRACK,
// 		},
// 	});
