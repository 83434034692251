// TODO remove this folder when cleanup platform_editor_ai_release_additional_prompts
import React from 'react';

import { CONFIG_ITEM_KEYS } from '../../../config-items/config-item-keys';
import { createEditorPluginAIConfigItemMarkdown } from '../../../config-items/config-items';
import { createInsertBelow, createReplace } from '../../config-item-actions/markdown';
import { isMinLength } from '../../config-item-visible/visibility-utils';

import { messages } from './messages';

const intentSchemaId = 'add_conclusion_intent_schema.json';

// TODO swap to @atlaskit/icon once available
const IconAddConclusion = () => {
	return (
		<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path d="M5 5.74999L19 5.74999M5 9.75L19 9.75" stroke="#44546F" strokeWidth="1.5" />
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M5.75 15C6.16421 15 6.5 14.6642 6.5 14.25C6.5 13.8358 6.16421 13.5 5.75 13.5C5.33579 13.5 5 13.8358 5 14.25C5 14.6642 5.33579 15 5.75 15ZM5.75 19C6.16421 19 6.5 18.6642 6.5 18.25C6.5 17.8358 6.16421 17.5 5.75 17.5C5.33579 17.5 5 17.8358 5 18.25C5 18.6642 5.33579 19 5.75 19ZM9.625 14.25C9.625 14.6642 9.28921 15 8.875 15C8.46079 15 8.125 14.6642 8.125 14.25C8.125 13.8358 8.46079 13.5 8.875 13.5C9.28921 13.5 9.625 13.8358 9.625 14.25ZM8.875 19C9.28921 19 9.625 18.6642 9.625 18.25C9.625 17.8358 9.28921 17.5 8.875 17.5C8.46079 17.5 8.125 17.8358 8.125 18.25C8.125 18.6642 8.46079 19 8.875 19ZM12.75 14.25C12.75 14.6642 12.4142 15 12 15C11.5858 15 11.25 14.6642 11.25 14.25C11.25 13.8358 11.5858 13.5 12 13.5C12.4142 13.5 12.75 13.8358 12.75 14.25ZM12 19C12.4142 19 12.75 18.6642 12.75 18.25C12.75 17.8358 12.4142 17.5 12 17.5C11.5858 17.5 11.25 17.8358 11.25 18.25C11.25 18.6642 11.5858 19 12 19ZM15.875 14.25C15.875 14.6642 15.5392 15 15.125 15C14.7108 15 14.375 14.6642 14.375 14.25C14.375 13.8358 14.7108 13.5 15.125 13.5C15.5392 13.5 15.875 13.8358 15.875 14.25ZM18.25 15C18.6642 15 19 14.6642 19 14.25C19 13.8358 18.6642 13.5 18.25 13.5C17.8358 13.5 17.5 13.8358 17.5 14.25C17.5 14.6642 17.8358 15 18.25 15Z"
				fill="#44546F"
			/>
		</svg>
	);
};

export const addConclusionEmptyConfigItem = createEditorPluginAIConfigItemMarkdown<'empty'>({
	key: CONFIG_ITEM_KEYS.ADD_CONCLUSION,
	title: messages.title,
	description: messages.description,
	icon: ({ shownAt }) => <IconAddConclusion />,
	statusLozengeType: 'beta',
	selectionType: 'empty',
	intentSchemaId,
	isVisible: ({ editorView, positions }) => isMinLength({ editorView, minLength: 1, positions }),
	getBackendModel: () => 'assistance-service',
	actions: [
		createInsertBelow({ appearance: 'secondary' }),
		createReplace({ appearance: 'primary' }),
	],
});

export const addConclusionRangeConfigItem = createEditorPluginAIConfigItemMarkdown<'range'>({
	key: CONFIG_ITEM_KEYS.ADD_CONCLUSION,
	title: messages.title,
	description: messages.description,
	icon: ({ shownAt }) => <IconAddConclusion />,
	statusLozengeType: 'beta',
	selectionType: 'range',
	intentSchemaId,
	isVisible: ({ editorView, positions }) => isMinLength({ editorView, minLength: 1, positions }),
	getBackendModel: () => 'assistance-service',
	actions: [
		createInsertBelow({ appearance: 'secondary' }),
		createReplace({ appearance: 'primary' }),
	],
});
