import { SafePlugin } from '@atlaskit/editor-common/safe-plugin';
import type { PMPluginFactoryParams } from '@atlaskit/editor-common/types';
import { type NodeType } from '@atlaskit/editor-prosemirror/model';
import { NodeSelection, TextSelection } from '@atlaskit/editor-prosemirror/state';
import { findParentNode } from '@atlaskit/editor-prosemirror/utils';
import { editorExperiment } from '@atlaskit/tmp-editor-statsig/experiments';

import { getSupportedNodesForAIModal } from '../../utils/ai-button';

import { aiButtonPluginKey } from './ai-button-plugin-key';

export type AIButtonPluginState = {
	isSelectionValidForAIExperience: boolean;
	//TODO: AI Button experiment cleanup - platform_editor_ai_ai_button_block_elements
	triggeredFor?: NodeType;
};

export const createAIButtonPlugin = (options: { dispatch: PMPluginFactoryParams['dispatch'] }) => {
	const { dispatch } = options;

	return new SafePlugin<AIButtonPluginState>({
		key: aiButtonPluginKey,

		state: {
			init: (_, state) => ({ isSelectionValidForAIExperience: true }),

			apply: (tr, _pluginState, _oldEditorState, newEditorState) => {
				if (tr.selectionSet) {
					const selection = tr.selection;
					const { $from } = selection;
					const parent = $from.parent;
					const { codeBlock, tableHeader, tableCell, tabeRow, table } = newEditorState.schema.nodes;
					const isNodeSelection = selection instanceof NodeSelection;
					const isSelectionInCodeBlock = parent.type === codeBlock;
					const isCellSelection = [tableHeader, tableCell, tabeRow, table].includes(parent.type);

					// AI button is always disabled for Node selection.
					// AI button is always disabled for Cell selection.
					// AI button is always disabled for selection in code block.
					// For all other selection and empty selections AI button is enabled.
					let isSelectionValidForAIExperience = true;
					if (isSelectionInCodeBlock || isNodeSelection || isCellSelection) {
						isSelectionValidForAIExperience = false;
					}

					let newPluginState: AIButtonPluginState = {
						isSelectionValidForAIExperience,
					};

					//TODO: AI Button experiment cleanup - platform_editor_ai_ai_button_block_elements
					const getParentSupportedForAIButton = () => {
						const supportedNodesForAI = getSupportedNodesForAIModal(newEditorState);

						if (isNodeSelection) {
							return supportedNodesForAI.has(selection.node.type) ? selection.node : undefined;
						} else {
							const parentNodeFindResult = findParentNode((node) =>
								supportedNodesForAI.has(node.type),
							)(selection);
							return parentNodeFindResult?.node;
						}
					};
					const parentSupportedForAIButton = getParentSupportedForAIButton();
					const { from, to } = selection;
					if ((from === to || isNodeSelection || isCellSelection) && parentSupportedForAIButton) {
						if (
							editorExperiment('platform_editor_ai_ai_button_block_elements', 'test', {
								exposure: true,
							})
						) {
							const isTextSelection = selection instanceof TextSelection;
							const isSelectionValidForAIExperience =
								!isSelectionInCodeBlock && (isTextSelection || !!parentSupportedForAIButton);
							const triggeredFor = parentSupportedForAIButton?.type;

							newPluginState = {
								isSelectionValidForAIExperience,
								triggeredFor,
							};
						}
					}

					// We need to dispatch state change
					// As we use WithPluginState to enable/disable AI button.
					dispatch(aiButtonPluginKey, newPluginState);

					return newPluginState;
				}

				return _pluginState;
			},
		},
	});
};
