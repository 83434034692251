import { defineMessages } from 'react-intl-next';

export const messages = defineMessages({
	title: {
		id: 'fabric.editor.ai.config.item.empty.suggestTitle.title',
		defaultMessage: 'Suggest a title',
		description:
			'Title for "Suggest a Title" Atlassian Intelligence feature shown in editor typeahead and element browser.',
	},
	description: {
		id: 'fabric.editor.ai.config.item.empty.suggestTitle.description',
		defaultMessage: 'Suggests a title for content you provide',
		description:
			'Describes "Suggest a Title" Atlassian Intelligence feature shown in editor typeahead and element browser.',
	},
});
