import React from 'react';

import AKIcon, { type IconProps as AKIconProps, type CustomGlyphProps } from '@atlaskit/icon';
import TextSpellcheckIcon from '@atlaskit/icon/core/text-spellcheck';
import { token } from '@atlaskit/tokens';

import { RainbowBorder } from './RainbowBorder';
import type { IconProps } from './types';

const IconGlyph = (props: CustomGlyphProps) => (
	<svg
		width="24"
		height="24"
		viewBox="0 0 24 24"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		{...props}
	>
		<path
			d="M12 4C12.676 4 13.284 4.399 13.556 5.008L13.609 5.142L16.944 14.67C17.0283 14.9108 17.0173 15.1748 16.9134 15.4078C16.8094 15.6408 16.6203 15.8252 16.3848 15.9234C16.1493 16.0215 15.8851 16.0259 15.6465 15.9357C15.4078 15.8455 15.2127 15.6674 15.101 15.438L15.056 15.33L14.232 12.973L14.117 12.993L14 13H10C9.92255 12.9999 9.84536 12.9909 9.77 12.973L8.944 15.33C8.86313 15.5614 8.70011 15.755 8.4859 15.8741C8.27169 15.9932 8.0212 16.0294 7.782 15.976L7.67 15.944C7.43863 15.8631 7.24502 15.7001 7.12593 15.4859C7.00684 15.2717 6.97057 15.0212 7.024 14.782L7.056 14.67L10.391 5.142C10.5078 4.80818 10.7255 4.51893 11.0139 4.31432C11.3024 4.10971 11.6473 3.99987 12.001 4H12ZM12 6.598L10.46 11H13.542L12.002 6.598H12Z"
			fill={token('color.icon', '#44546F')}
		/>
		<path
			d="M14 17L16 19L20 15"
			stroke={token('color.icon', '#44546F')}
			strokeWidth="2"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
	</svg>
);

export const Icon = (props: IconProps) => {
	const { shownAt } = props;

	return (
		<RainbowBorder shownAt={shownAt}>
			<TextSpellcheckIcon
				label=""
				color="currentColor"
				spacing="spacious"
				LEGACY_fallbackIcon={(props: AKIconProps) => <AKIcon glyph={IconGlyph} {...props} />}
			/>
		</RainbowBorder>
	);
};
