import React, { useContext, useState } from 'react';
import { defineMessages, FormattedMessage, useIntl } from 'react-intl-next';
import { styled } from '@compiled/react';
import { useQuery } from '@apollo/react-hooks';
import type { ApolloError } from 'apollo-client';

import InformationIcon from '@atlaskit/icon/utility/information';
import Image from '@atlaskit/image';
import { Stack, Text, Flex, Inline, xcss } from '@atlaskit/primitives';
import Lozenge from '@atlaskit/lozenge';
import { token } from '@atlaskit/tokens';
import Tooltip, { TooltipPrimitive } from '@atlaskit/tooltip';
import type { TooltipPrimitiveProps } from '@atlaskit/tooltip';
import Avatar from '@atlaskit/avatar';
import Button from '@atlaskit/button/new';
import ErrorIcon from '@atlaskit/icon/core/error';

import { timeFormatter } from '@confluence/save-indicator';
import { SPAViewContext } from '@confluence/spa-view-context';
import { FrontCoverStateEnum } from '@confluence/route-manager/entry-points/companyHubUtils';
import { COMPANY_HUB_GENERAL_SETTINGS_YOUR_HUB_EXPERIENCE } from '@confluence/experience-tracker';
import { SubExperienceTracker } from '@confluence/admin-center/entry-points/SubExperienceTracker';

import type {
	HubInfoQueryVariables,
	HubInfoQuery as HubInfoQueryType,
} from './__types__/HubInfoQuery';
import { HubInfoQuery } from './HubInfoQuery.graphql';
import { HubActionsDropdown } from './HubActionsDropdown';
import { EditHubButton } from './EditHubButton';
import hubPreviewImage from './assets/hub-preview-light.svg';
import hubPreviewDarkImage from './assets/hub-preview-dark.svg';

export const i18n = defineMessages({
	publishedPill: {
		id: 'company-hub.hub-settings.general.dashboard.pill.published',
		defaultMessage: 'Published',
		description: 'Pill that informs the user if their hub is in a published state',
	},
	publishedTooltip: {
		id: 'company-hub.hub-settings.general.dashboard.tooltip.published',
		defaultMessage: 'Your hub is visible to everyone in the company',
		description: 'Pill that informs the user if their hub is in a unpublished state',
	},
	notPublishedPill: {
		id: 'company-hub.hub-settings.general.dashboard.pill.not-published',
		defaultMessage: 'Not published',
		description: 'Pill that informs the user if their hub is in a unpublished state',
	},
	notPublishedTooltip: {
		id: 'company-hub.hub-settings.general.dashboard.tooltip.not-published',
		defaultMessage:
			'Your hub is only visible to you and other admin and editors. The rest of the company will not see your hub until you publish.',
		description: 'Pill that informs the user if their hub is in a unpublished state',
	},
	versionLabel: {
		id: 'company-hub.hub-settings.general.dashboard.version-label',
		defaultMessage: 'Version {versionNumber}',
		description: 'The version number of the Company hub page content',
	},
	lastUpdatedText: {
		id: 'company-hub.hub-settings.general.dashboard.edited-time',
		defaultMessage: 'Edited {formattedTime}',
		description:
			'Text that displays to the user the last time the Company hub page content was updated',
	},
	errorPrimaryTitle: {
		id: 'company-hub.hub-settings.general.dashboard.error.primary.title',
		defaultMessage: 'We’re having trouble loading this card.',
		description:
			'Text that is shown to the user when there is an error loading the hub dashboard card component',
	},
	errorSecondaryTitle: {
		id: 'company-hub.hub-settings.general.dashboard.error.secondary-title',
		defaultMessage: 'Reload to try again.',
		description:
			'CTA button that is shown next to error message so the user can reload the page in case of error in their hub dashboard card',
	},
});

const containerStyles = xcss({
	minWidth: '250px',
	borderRadius: token('border.radius.200'),
	border: `1px solid ${token('color.border')}`,
	background: token('elevation.surface.overlay'),
	padding: 'space.200',
});

const middleSectionStyles = xcss({
	justifyContent: 'space-around',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const customTooltipContent = styled<TooltipPrimitiveProps>(TooltipPrimitive)({
	background: token('elevation.surface.overlay'),
	borderRadius: token('border.radius'),
	boxShadow: token('elevation.shadow.overlay'),
	color: token('color.text'),
	padding: `${token('space.100')} ${token('space.150')}`,
	maxWidth: '300px',
});

const HubStatus = ({ isPublished }: { isPublished: boolean }) => {
	const { formatMessage } = useIntl();
	return (
		<Flex gap="space.050" alignItems="center">
			<Lozenge appearance={isPublished ? 'success' : undefined}>
				<FormattedMessage {...(isPublished ? i18n.publishedPill : i18n.notPublishedPill)} />
			</Lozenge>
			<Tooltip
				component={customTooltipContent}
				position="bottom-start"
				content={formatMessage(isPublished ? i18n.publishedTooltip : i18n.notPublishedTooltip)}
			>
				<InformationIcon label="" color={token('color.icon')} />
			</Tooltip>
		</Flex>
	);
};

const LastUpdatedSection = ({ displayName, profilePicturePath, formattedTime }) => (
	<Flex gap="space.025" alignItems="center">
		<Tooltip content={displayName}>
			<Inline>
				<Avatar src={profilePicturePath} name={displayName} size="xsmall" />
			</Inline>
		</Tooltip>
		<Text color="color.text.subtle" size="small">
			<FormattedMessage {...i18n.lastUpdatedText} values={{ formattedTime }} />
		</Text>
	</Flex>
);

const InlineMessageError = () => {
	const reloadPage = () => window.location.reload();
	return (
		<Flex gap="space.075" alignItems="center">
			<ErrorIcon label="" color={token('color.icon.danger')} />
			<Text>
				<FormattedMessage {...i18n.errorPrimaryTitle} />
			</Text>
			<Button appearance="subtle" spacing="compact" onClick={reloadPage}>
				<FormattedMessage {...i18n.errorSecondaryTitle} />
			</Button>
		</Flex>
	);
};

type HubDashboardProps = {
	spaceKey: string;
};

export const HubDashboard = ({ spaceKey }: HubDashboardProps) => {
	const { frontCoverState, companyHubName } = useContext(SPAViewContext);
	const { formatMessage } = useIntl();
	const isHubPublished = frontCoverState === FrontCoverStateEnum.SHOWN;
	const [hubActionsError, setHubActionsError] = useState<ApolloError>();

	const { data, loading, error } = useQuery<HubInfoQueryType, HubInfoQueryVariables>(
		// eslint-disable-next-line graphql-relay-compat/no-import-graphql-operations -- Read https://go/connie-relay-migration-fyi
		HubInfoQuery,
		{
			variables: { key: spaceKey },
		},
	);

	const versionData = data?.space?.homepageV2?.version;
	const displayName = versionData?.by?.displayName ?? '';
	const profilePicturePath = versionData?.by?.profilePicture?.path ?? '';
	const lastUpdated = versionData?.when ? new Date(versionData.when) : null;
	const formattedTime =
		lastUpdated && !isNaN(lastUpdated.getTime()) ? timeFormatter(lastUpdated, formatMessage) : null;

	const versionNumber = versionData?.number;

	if (loading) {
		return (
			<Flex xcss={containerStyles} testId="loading-state">
				<Image src={hubPreviewImage} srcDark={hubPreviewDarkImage} alt="Hub preview image" />
			</Flex>
		);
	}

	return (
		<SubExperienceTracker
			experienceName={COMPANY_HUB_GENERAL_SETTINGS_YOUR_HUB_EXPERIENCE}
			errors={[error]}
			loading={false}
		>
			<Stack grow="fill" space="space.150">
				<Flex wrap="wrap" gap="space.200" justifyContent="space-between" xcss={containerStyles}>
					<Flex wrap="wrap" gap="space.200">
						<Image src={hubPreviewImage} srcDark={hubPreviewDarkImage} alt="" />
						<Stack xcss={middleSectionStyles}>
							<HubStatus isPublished={isHubPublished} />
							<Stack>
								<Text size="large" weight="semibold">
									{companyHubName}
								</Text>
								{versionNumber && (
									<Text color="color.text.subtle" size="small">
										<FormattedMessage {...i18n.versionLabel} values={{ versionNumber }} />
									</Text>
								)}
							</Stack>
							{formattedTime && (
								<LastUpdatedSection
									displayName={displayName}
									profilePicturePath={profilePicturePath}
									formattedTime={formattedTime}
								/>
							)}
						</Stack>
					</Flex>
					<Flex gap="space.050" alignItems="start">
						<EditHubButton />
						<HubActionsDropdown
							onError={setHubActionsError}
							spaceKey={spaceKey}
							isHubPublished={isHubPublished}
						/>
					</Flex>
				</Flex>
				{error || hubActionsError ? <InlineMessageError /> : null}
			</Stack>
		</SubExperienceTracker>
	);
};
