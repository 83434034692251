import React from 'react';

import { useIntl } from 'react-intl-next';

// import Button from '@atlaskit/button/new';
import Heading from '@atlaskit/heading';
import Image from '@atlaskit/image';
import { Stack, Text } from '@atlaskit/primitives';

import NoSuggestionsImageDark from '../assets/no-suggestions/dark.svg';
import NoSuggestionsImageLight from '../assets/no-suggestions/light.svg';
import messages from '../messages';

export const NoSuggestionsState = ({
	handleReloadSuggestions,
}: {
	handleReloadSuggestions?: () => void;
}) => {
	const { formatMessage } = useIntl();
	<Image src={NoSuggestionsImageLight} srcDark={NoSuggestionsImageDark} />;

	return (
		<Stack space="space.200" alignInline="center">
			<Image src={NoSuggestionsImageLight} srcDark={NoSuggestionsImageDark} />
			<Stack space="space.150" alignInline="center">
				<Heading color="color.text" size="small">
					{formatMessage(messages.noSuggestionsStateTitle)}
				</Heading>
				<Text align="center" color="color.text" size="medium">
					{formatMessage(messages.noSuggestionsStateDescription)}
				</Text>
				{/* <Button appearance="primary" onClick={handleReloadSuggestions}>
					{formatMessage(messages.reloadSuggestionsButtonLabel)}
				</Button> */}
			</Stack>
		</Stack>
	);
};
