import gql from 'graphql-tag';

export const RecentActivityLeaderboardQuery = gql`
	query RecentActivityLeaderboardQuery($startTime: String!, $endTime: String!) {
		countGroupByPage(
			startTime: $startTime
			endTime: $endTime
			eventName: [companyHubLink_clicked]
			limit: 5
			sortOrder: "desc"
		) {
			nodes {
				page
				count
			}
		}
	}
`;

export const RecentActivityLeaderboardContentQuery = gql`
	query RecentActivityLeaderboardContentQuery($ids: [ID!]!) {
		content(first: 5, ids: $ids) {
			nodes {
				id
				title
				links {
					webui
				}
			}
		}
	}
`;
