import React from 'react';

import type { EditorView } from '@atlaskit/editor-prosemirror/view';
import { Box, Inline, Stack, xcss } from '@atlaskit/primitives';

import { type Recommendation } from '../pm-plugins/ai-proactive/api';
import { toggleDisplayAllSetting } from '../pm-plugins/ai-proactive/commands';
import type { EditorPluginAIProvider } from '../types';

import { HeadingItem } from './heading-item';
import { RecommendationItem, SpellingGrammarItem } from './recommendation-item';
import { EnableSuggestionsState } from './states/enable-suggestions';
import { NoSuggestionsState } from './states/no-suggestions';

const panelContainerStyles = xcss({
	paddingInlineStart: 'space.0',
	height: '100%',
	width: '100%',
});

type RecommendationPanelProps = {
	recommendations: Recommendation[];
	hoveredRecommendationId: string | undefined;
	selectedRecommendationId: string | undefined;
	isSpellingGrammarEnabled: boolean;
	spellingGrammarErrorCount?: number;
	onRecommendationApply: ({ recommendation }: { recommendation: Recommendation }) => void;
	onRecommendationDismiss: ({ recommendation }: { recommendation: Recommendation }) => void;
	onRecommendationSelect: ({ recommendation }: { recommendation: Recommendation }) => void;
	onRecommendationHover: ({ recommendation }: { recommendation: Recommendation }) => void;
	onRecommendationLeave: ({ recommendation }: { recommendation: Recommendation }) => void;
	onPanelClose: () => void;
	onSpellingGrammar: () => void;
	editorPluginAIProvider: EditorPluginAIProvider;
	editorView: EditorView;
	displayAllSuggestions: boolean;
};
const emptyStateStyles = xcss({
	flex: 4,
});
// Add padding to the empty state to center it's center vertically (4/4+1)/2 = 40% from the top.
const padEmptyStateStyles = xcss({
	flex: 1,
});
export const RecommendationPanel = (props: RecommendationPanelProps) => {
	const {
		recommendations,
		hoveredRecommendationId,
		selectedRecommendationId,
		isSpellingGrammarEnabled,
		spellingGrammarErrorCount,
		onRecommendationApply,
		onPanelClose,
		onRecommendationDismiss,
		onRecommendationSelect,
		onSpellingGrammar,
		onRecommendationHover,
		onRecommendationLeave,
		editorPluginAIProvider,
		editorView,
		displayAllSuggestions,
	} = props;

	return (
		<Stack xcss={panelContainerStyles}>
			<HeadingItem
				onClose={onPanelClose}
				editorPluginAIProvider={editorPluginAIProvider}
				editorView={editorView}
			/>
			{recommendations?.length > 0 || (spellingGrammarErrorCount ?? 0) > 0 ? (
				<>
					{isSpellingGrammarEnabled && (spellingGrammarErrorCount ?? 0) > 0 && (
						<SpellingGrammarItem
							errorCount={spellingGrammarErrorCount ?? 0}
							onClick={onSpellingGrammar}
						/>
					)}
					{recommendations?.map((r) => (
						<RecommendationItem
							key={r.id}
							id={r.id}
							action={r.transformAction}
							isViewed={r.isViewed}
							isHovered={r.id === hoveredRecommendationId}
							isSelected={r.id === selectedRecommendationId}
							onApply={() => onRecommendationApply({ recommendation: r })}
							onDismiss={() => onRecommendationDismiss({ recommendation: r })}
							onClick={() => onRecommendationSelect({ recommendation: r })}
							onMouseEnter={() => onRecommendationHover({ recommendation: r })}
							onMouseLeave={() => onRecommendationLeave({ recommendation: r })}
							onPreview={() => onRecommendationSelect({ recommendation: r })}
						/>
					))}
				</>
			) : (
				<>
					<Inline xcss={emptyStateStyles} alignBlock="center">
						{!displayAllSuggestions ? (
							<EnableSuggestionsState
								handleEnable={() => {
									toggleDisplayAllSetting()(editorView.state, editorView.dispatch);
								}}
							/>
						) : (
							<NoSuggestionsState />
						)}
					</Inline>
					<Box xcss={padEmptyStateStyles}></Box>
				</>
			)}
		</Stack>
	);
};
