import { fg } from '@confluence/feature-gating';

import { getNav4OptOutCache } from './useNav4OptOut';

/**
 * YOU SHOULD PROBABLY CALL `useIsNav4Enabled` INSTEAD OF THIS FUNCTION.
 * Please check with #cc-disco before using.
 */
// eslint-disable-next-line confluence-feature-gating/inline-usage
export const getIsNav4Enabled = () => fg('confluence_nav_4') && !getNav4OptOutCache();
