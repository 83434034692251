/**
 * @jsxRuntime classic
 * @jsx jsx
 */
// eslint-disable-next-line @atlaskit/ui-styling-standard/use-compiled -- Ignored via go/DSP-18766
import { jsx } from '@emotion/react';
import type ApolloClient from 'apollo-client';
import { type Extension } from '@atlassian/forge-ui/ui';

import { type CoreData, type ExtensionData } from '@atlassian/forge-ui-types';
import { useInvokeAdfExportExtension } from '../../utils/useInvokeAdfExportExtension';
import { ReactRenderer } from '@atlaskit/renderer';
import { type ProviderFactory } from '@atlaskit/editor-common/provider-factory';
import { RendererActionsContext } from '@atlaskit/renderer/actions';
import { Loader } from '@atlassian/forge-ui/ui';
import { useIntl } from 'react-intl-next';
import SectionMessage from '@atlaskit/section-message';
import { messages } from '../i18n';
import { fg } from '@atlaskit/platform-feature-flags';

interface AdfExportRendererProps {
	accountId: string;
	apolloClient: ApolloClient<object>;
	contextIds: string[];
	coreData: CoreData;
	dataProviders: ProviderFactory;
	extension: Extension;
	extensionData: ExtensionData;
	extensionPayload: Record<string, any>;
	extensionId: string;
	extensionTitle?: string;
}

export const AdfExportRenderer = ({
	apolloClient,
	contextIds,
	coreData,
	dataProviders,
	extensionData,
	extensionId,
	extensionPayload,
}: AdfExportRendererProps) => {
	const { adfDoc, error, isLoading } = useInvokeAdfExportExtension({
		apolloClient,
		contextIds,
		extensionId,
		coreData,
		extensionData,
		extensionPayload,
	});
	const intl = useIntl();
	if (fg('add_loader_component_to_adfexportrenderer')) {
		if (error) {
			return (
				<SectionMessage appearance="error">
					{intl.formatMessage(messages.exportMacroError)}
				</SectionMessage>
			);
		}
		if (isLoading) {
			return <Loader />;
		}
	} else {
		if (isLoading || error) {
			return null;
		}
	}

	if (!adfDoc) {
		return null;
	}

	return (
		<RendererActionsContext>
			<ReactRenderer dataProviders={dataProviders} document={adfDoc} />
		</RendererActionsContext>
	);
};
