import React from 'react';
import { FormattedMessage, defineMessages } from 'react-intl-next';
import { useQuery } from 'react-apollo';
import { differenceInDays } from 'date-fns';

import Link from '@atlaskit/link';
import { Box, Flex, Inline, xcss, Stack } from '@atlaskit/primitives';
import Heading from '@atlaskit/heading';

import { DailyRefreshComponent } from '@confluence/admin-center/entry-points/DailyRefreshComponent';
import {
	Attribution,
	ErrorBoundary,
	ErrorDisplay,
	isUnauthorizedError,
} from '@confluence/error-boundary';
import { markErrorAsHandled } from '@confluence/graphql';
import { SubExperienceTracker } from '@confluence/admin-center/entry-points/SubExperienceTracker';
import {
	COMPANY_HUB_GENERAL_SETTINGS_HUB_VIEWS_EXPERIENCE,
	COMPANY_HUB_GENERAL_SETTINGS_HUB_RECENT_ACTIVITY_EXPERIENCE,
	COMPANY_HUB_GENERAL_SETTINGS_MOST_CLICKS_EXPERIENCE,
	ExperienceStart,
	ExperienceSuccess,
} from '@confluence/experience-tracker';

import { RecentActivitySectionQuery } from './RecentActivitySectionQuery.graphql';
import type { RecentActivitySectionQuery as RecentActivitySectionQueryType } from './__types__/RecentActivitySectionQuery';
import { RecentActivityChart } from './RecentActivityChart';
import { RecentActivityLeaderboard } from './RecentActivityLeaderboard';

const i18n = defineMessages({
	sectionTitle: {
		id: 'company-hub.recent-activity-section.title',
		defaultMessage: 'Recent Activity',
		description: 'Title for the recent activity section on the hub dashboard page',
	},
	leaderboardTitle: {
		id: 'company-hub.recent-activity.leaderboard.title',
		defaultMessage: 'Most clicked items',
		description: 'Title for the most clicked items leaderboard on the hub dashboard page',
	},
	viewAnalyticsLink: {
		id: 'company-hub.recent-activity.view-analytics-link',
		defaultMessage: 'View Analytics',
		description: 'Link text to navigate to the analytics page',
	},
});

const chartContainerStyles = xcss({
	flexGrow: 5,
	flexBasis: 0,
	minWidth: '415px',
});

const leaderboardContainerStyles = xcss({
	flexGrow: 3,
	flexBasis: 0,
	minWidth: '300px',
});

const responsiveStyles = xcss({
	minWidth: '250px',
	maxWidth: '1024px',
	flexDirection: 'row',
	containerType: 'inline-size',
});

const linkStyles = xcss({
	color: 'color.link',
});

type RecentActivitySectionProps = {
	spaceKey: string;
};

export const RecentActivitySection = ({ spaceKey }: RecentActivitySectionProps) => {
	const { data, loading, error } = useQuery<RecentActivitySectionQueryType>(
		// eslint-disable-next-line graphql-relay-compat/no-import-graphql-operations -- Read https://go/connie-relay-migration-fyi
		RecentActivitySectionQuery,
		{
			variables: {
				key: spaceKey,
			},
			skip: !spaceKey,
		},
	);

	const spaceId = data?.space?.id;
	const spaceCreatedDate = data?.space?.history?.createdDate
		? new Date(data.space.history.createdDate)
		: undefined;
	const isSpaceCreatedDateOver1DayAgo = spaceCreatedDate
		? differenceInDays(new Date(), spaceCreatedDate) >= 1
		: undefined;

	const renderContent = () => {
		if (loading) {
			return <Box testId="loading-state" />;
		}

		if (error) {
			if (isUnauthorizedError(error)) {
				markErrorAsHandled(error);
				// We need to start and succeed these experiences from RecentActivitySection because else
				// we never render the child component and the experience never starts
				// and therefore is not collected by the main experience
				const experiencesToCollect = [
					COMPANY_HUB_GENERAL_SETTINGS_HUB_VIEWS_EXPERIENCE,
					COMPANY_HUB_GENERAL_SETTINGS_MOST_CLICKS_EXPERIENCE,
				];
				const collectExperiences = experiencesToCollect.map((experience) => (
					<>
						<ExperienceStart name={experience} />
						<ExperienceSuccess name={experience} />
					</>
				));
				return <Box testId="unauthorized-error-state">{collectExperiences}</Box>;
			} else {
				return (
					<ErrorDisplay error={error}>
						<Box testId="generic-error-state" />
					</ErrorDisplay>
				);
			}
		}

		if (!spaceId) {
			return <Box testId="no-space-id-found" />;
		}

		return (
			<Stack space="space.200">
				<Inline alignBlock="center">
					<Heading as="h2" size="medium">
						<FormattedMessage {...i18n.sectionTitle} />
					</Heading>
				</Inline>
				<Inline alignBlock="stretch" spread="space-between">
					<DailyRefreshComponent fontSize="small" />
					<Box xcss={linkStyles}>
						<Link href="/wiki/company-hub/analytics" appearance="subtle">
							<FormattedMessage {...i18n.viewAnalyticsLink} />
						</Link>
					</Box>
				</Inline>
				<ErrorBoundary attribution={Attribution.COMPANY_HUB}>
					<Flex gap="space.200" xcss={responsiveStyles} wrap="wrap">
						<Box xcss={chartContainerStyles}>
							<RecentActivityChart
								spaceId={spaceId}
								isSpaceCreatedDateOver1DayAgo={isSpaceCreatedDateOver1DayAgo}
								sectionQueryLoading={loading}
							/>
						</Box>
						<Box xcss={leaderboardContainerStyles}>
							<RecentActivityLeaderboard
								isSpaceCreatedDateOver1DayAgo={isSpaceCreatedDateOver1DayAgo}
								sectionQueryLoading={loading}
							/>
						</Box>
					</Flex>
				</ErrorBoundary>
			</Stack>
		);
	};

	return (
		<SubExperienceTracker
			experienceName={COMPANY_HUB_GENERAL_SETTINGS_HUB_RECENT_ACTIVITY_EXPERIENCE}
			errors={[error]}
			loading={loading}
		>
			{renderContent()}
		</SubExperienceTracker>
	);
};
