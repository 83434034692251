import React from 'react';

import { CONFIG_ITEM_KEYS } from '../../../config-items/config-item-keys';
import { createEditorPluginAIConfigItemMarkdown } from '../../../config-items/config-items';
import { createInsertBelow, createReplace } from '../../config-item-actions/markdown';
import { isMinLength } from '../../config-item-visible/visibility-utils';

import { messages } from './messages';

const intentSchemaId = 'make_longer.json';

// TODO swap to @atlaskit/icon once available
const IconMakeLonger = () => {
	return (
		<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path
				d="M5 5.75L19 5.75M5 9.91667L19 9.91667M5 14.0833L19 14.0833M5 18.25L12 18.25"
				stroke="#44546F"
				strokeWidth="1.5"
			/>
		</svg>
	);
};

export const makeLongerEmptyConfigItem = createEditorPluginAIConfigItemMarkdown<'empty'>({
	key: CONFIG_ITEM_KEYS.MAKE_LONGER,
	title: messages.title,
	description: messages.description,
	icon: ({ shownAt }) => <IconMakeLonger />,
	statusLozengeType: 'beta',
	selectionType: 'empty',
	intentSchemaId,
	isVisible: ({ editorView, positions }) => isMinLength({ editorView, minLength: 1, positions }),
	getBackendModel: () => 'assistance-service',
	actions: [
		createInsertBelow({ appearance: 'secondary' }),
		createReplace({ appearance: 'primary' }),
	],
});

export const makeLongerRangeConfigItem = createEditorPluginAIConfigItemMarkdown<'range'>({
	key: CONFIG_ITEM_KEYS.MAKE_LONGER,
	title: messages.title,
	description: messages.description,
	icon: ({ shownAt }) => <IconMakeLonger />,
	statusLozengeType: 'beta',
	selectionType: 'range',
	intentSchemaId,
	isVisible: ({ editorView, positions }) => isMinLength({ editorView, minLength: 1, positions }),
	getBackendModel: () => 'assistance-service',
	showInRefineDropdown: true,
	actions: [
		createInsertBelow({ appearance: 'secondary' }),
		createReplace({ appearance: 'primary' }),
	],
});
