import type { ComponentProps } from 'react';
import { useCallback, useRef } from 'react';

import type { DialogsContainerProps } from '@confluence/dialogs';
import { useDialogs } from '@confluence/dialogs/entry-points/useDialogs';
import { LoadableLazy } from '@confluence/loadable';

type UnpublishHubDialogLoadableProps = ComponentProps<typeof UnpublishHubDialogLoadable>;

export function useDeactivateHubDialog(
	props: Omit<UnpublishHubDialogLoadableProps, keyof DialogsContainerProps> = {},
) {
	const { showModal } = useDialogs();

	const propsRef = useRef<typeof props>();
	propsRef.current = props;

	return useCallback(() => showModal(UnpublishHubDialogLoadable, propsRef.current!), [showModal]);
}

const UnpublishHubDialogLoadable = LoadableLazy({
	loader: async () =>
		(
			await import(
				/* webpackChunkName: "loadable-UnpublishHubDialogLoadable" */ './UnpublishHubDialog'
			)
		).UnpublishHubDialog,
});
