import {
	buildBasePath,
	type PostOfficeEnvironmentValues,
	type PostOfficeContextValue,
} from '@atlassian/post-office-context';
import { requestInitialData } from '@atlassian/post-office-placement-shared/request-initial-data';

import { type PreloadFnContext, restQuery } from '@confluence/query-preloader-tools';

import {
	type ConfluenceSessionDataFields,
	sessionToPostOfficeContext,
	transformConfluenceEnvToPostOfficeEnvironment,
} from './session-to-post-office-context';

type OptionalEnvConfigsForPostOfficeEnvironment = Omit<PostOfficeEnvironmentValues, 'envConfigs'> &
	Partial<Pick<PostOfficeEnvironmentValues, 'envConfigs'>>;

/**
 * This is a cached fetcher that is used by the Post Office requests.
 *
 * See also `post-office/packages/post-office-clients/post-office-placement-shared/src/Placement.tsx` for the initial data fetching.
 *
 * @param url
 * @returns
 */
const confluenceFetcher = async (url: string, headers?: HeadersInit) => {
	const placementKey = url.match(/placements\/([\w-]+)/);
	const claimKey = `post-office/placement/${placementKey?.[1]}`;

	return restQuery(url, { method: 'GET', headers, claimKey });
};

/**
 * Common method for setting up the Post Office placement request URL with the environment and product context
 *
 * @param placementId
 * @param contextOptions
 * @returns
 */
const preloadPostOfficePlacement = async (
	placementId: string,
	contextOptions: {
		postOfficeContext: PostOfficeContextValue;
		environment: OptionalEnvConfigsForPostOfficeEnvironment;
	},
) => {
	const { postOfficeContext, environment } = contextOptions;

	const confluenceFetcherWithS2SHeaders = async (url: string) => {
		let hostEnv = 'prod';
		if (environment.currentEnv !== 'production') {
			hostEnv = 'staging';
		}

		const fetchHeaders = {
			'x-ssr-service-name': 'post-office', // Tells Tesseract to S2S with `post-office`
			'x-ssr-service-env': hostEnv, // Defaults to current Env, however Post Office is not in dev
		};

		return confluenceFetcher(url.replace('gateway/api/post-office/', ''), fetchHeaders);
	};

	return requestInitialData(
		placementId,
		postOfficeContext,
		confluenceFetcherWithS2SHeaders,
		buildBasePath(environment.currentEnv, environment.envConfigs),
	);
};

/**
 * Returns a fetcher for Post Office placement API that is shared with the SSR preload
 *
 * @param placementId
 * @returns
 */
export const postOfficePlacementFetch = (url: string) => {
	return confluenceFetcher(url);
};

/**
 * Preload a Post Office placement API via SSR
 *
 * @param placementId
 * @param preloadedSessionContext
 * @returns
 */
export const preloadPostOfficePlacementWithSessionContext = async (
	placementId: string,
	preloadedSessionContext: Pick<PreloadFnContext, ConfluenceSessionDataFields | 'environment'>,
) => {
	return preloadPostOfficePlacement(placementId, {
		postOfficeContext: sessionToPostOfficeContext(preloadedSessionContext),
		environment: {
			currentEnv: transformConfluenceEnvToPostOfficeEnvironment(
				preloadedSessionContext.environment,
			),
		},
	});
};
