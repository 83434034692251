import gql from 'graphql-tag';

export const RecentActivityChartQuery = gql`
	query RecentActivityChartQuery(
		$spaceId: String!
		$startDate: String!
		$endDate: String!
		$timezone: String!
		$granularity: AnalyticsTimeseriesGranularity!
	) {
		timeseriesPageBlogCount(
			spaceId: [$spaceId]
			contentType: page
			contentAction: viewed
			startTime: $startDate
			endTime: $endDate
			timezone: $timezone
			granularity: $granularity
		) {
			nodes {
				date
				count
			}
		}
	}
`;
